<template>
  <div v-if="this.$route.name == 'Media'">
    <div class="card-inner">
      <a :href="card.properties.link.url" target="_blank" v-for="card in displayedcardContents" :key="card" class="card">
        <div class="card-text">
          <h1 class="card-title Pretendard-700">
            {{ card.properties.title.title[0]?.plain_text }}
          </h1>
          <p class="card-content Pretendard-400">
            {{ card.properties.content.rich_text[0]?.plain_text }}
          </p>
          <div class="card-bottom">
            <p class="card-created-from Pretendard-500">{{ card.properties.press.rich_text[0]?.plain_text }}</p>
            <p class="card-created-at">{{ card.properties.created_at.date.start }}</p>
          </div>
        </div>
        <img :src="card.properties.img.url" alt="Card Img">
      </a>
    </div>
    <pageNation 
      :currentPage="currentPage" 
      :totalPages="totalPages" 
      @changePage="currentPage = $event"/>
  </div>
</template>

<script>
import pageNation from "./pageNation.vue"


export default {
  data() {
    return {
      cardContents:[
      {
        mediaCard : {
          title : "this.media.results[i].properties.title.title[0]?.plain_text",
          contents : "media.results[i].properties.content.rich_text[0]?.plain_text",
          createdFrom : "media.results[i].properties.press.rich_text[0]?.plain_text",
          createdAt : "",
          cardImg : "media.results[i].properties.img.url",
          url : "media.results[i].properties.link.url",
        }
      }

    ],
    currentPage: 1,
    perPage: 9
    }
  },
  props : {
    media : Array,
    mediaLen : Number
  },
  components : {
    pageNation
  },
  computed: {
    displayedcardContents() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.media?.results?.slice(start, start + this.perPage);
    },
    totalPages() {
      return Math.ceil(this.mediaLen / this.perPage);
    },
  },

}
</script>

<style scoped>
.card-inner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
}

.card {
  width: clamp(220px, 21.53vw, 410px);
  height: clamp(300px, 23.75rem, 500px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 5rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.card:hover {
  box-shadow: 2px 4px 20px rgba(149, 157, 163, 0.5);
}
.card:hover .card-title{
  color: #13377C;
}


.card img {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 9.5rem;
  object-fit: cover;
  
}
.card-text {
  padding: 1.25rem 1.563rem 1.06rem 1.563rem;
  
}
.card-title {
  font-size: 1.375rem;
  line-height: 130%;
  color: #000000;
  overflow:hidden;
  text-overflow:ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-content {
  font-size: 14px;
  line-height: 130%;
  color: #494949;
  margin-top: 1.5rem;
  overflow:hidden;
  text-overflow:ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.card-bottom {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.card-created-from {
  font-size: 14px;
  line-height: 130%;
  color: #999999;
}
.card-created-at {
  font-size: 12px;
  line-height: 130%;
  color: #999999;
}



/* @media screen and (max-width: 1040px) {
.card {
width: clamp(220px, 21.53vw, 450px);
}
.card img {
  height: 7.7rem;
}
}

@media screen and (max-width: 740px) {
.card {
width: clamp(220px, 40.53vw, 600px);
height: 370px;
margin-bottom: 4rem;
}
.card img {
  height: 11rem;
}

}


@media screen and (max-width: 500px) {
.card {
width: clamp(130px, 44vw, 400px);
height: 240px;
margin-bottom: 2.5rem;
}

.card img {
  height: 6.5rem;
}

.card-title {
  font-size: 14px;
}

.card-content {
  font-size: 13.5px;
  margin-top: 1rem;
  -webkit-line-clamp: 2;
}
.card-text {
  padding: 0.9rem;
}
.card-bottom {
  margin-top: 1rem;
}
.card-created-from {
  font-size: 11.5px;
}
.card-created-at {
  font-size: 11.5px;
}

} */
</style>