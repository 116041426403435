<template>
  <div>
    <pageSection1 :language="language" v-if="this.$route.name == 'Media'" />
    <section class="section2">
      <div class="section2-inner">
        <h2 v-if="language === 'ko'" class="media-sub-title Pretendard-700 mt-145">주요공시</h2>
        <h2 v-if="language === 'en'" class="media-sub-title Pretendard-700 mt-145">Announcement</h2>
        <div class="media-table">
          <table class="table-main">
            <tbody class="border-top-bottom">
              <tr v-for="item in displayedDisclosures" :key="item.id">
                <router-link
                  :to="{ name: 'MediaDetail', params: { id: item.id } }"
                  class="table-link"
                >
                  <td class="table-row">
                    {{ item.properties.type.rich_text[0].text.content }}
                  </td>
                  <td class="table-title">
                    {{ item.properties.title.title[0].text.content }}
                  </td>
                  <td class="table-date mobile-display-none">
                    {{ item.properties.created_at.date.start }}
                  </td>
                </router-link>
              </tr>
              <tr v-show="displayedDisclosures?.length < perPage">
                <td class="table-row" colspan="3">&nbsp;</td>
              </tr>
            </tbody>
          </table>
          <pageNation
            :currentPage="currentPage"
            :totalPages="totalPages"
            @changePage="currentPage = $event"
          />
        </div>
        <h2 class="media-sub-title Pretendard-700 mt-80 mb-40">Media</h2>
        <cardList :media="media" :mediaLen="mediaLen" />
      </div>
    </section>
  </div>
</template>

<script>
import iesgnews from '../../assets/js/media.json';
import pageSection1 from '../pageSection1.vue';
import pageNation from '../pageNation.vue';
import cardList from '../cardList.vue';
import axios from 'axios'
// import axios from 'axios'

function countDigits(number) {
  return Math.max(Math.floor(Math.log10(Math.abs(number))), 0) + 1;
}

export default {
  name: 'Media',
  data() {
    return {
      iesgnews: '',
      currentPage: 1,
      perPage: 3,
      disclosures: '',
      media: '',
      mediaLen: null,
    };
  },
  computed: {
    displayedDisclosures() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.disclosures?.results?.slice(start, start + this.perPage);
    },
    totalPages() {
      return Math.ceil(this.disclosures?.results?.length / this.perPage);
    },
    sortedResults() {
      return this.disclosures?.results?.slice().sort((a, b) => {
        const countA = countDigits(a.properties.id.number);
        const countB = countDigits(b.properties.id.number);
        return countA - countB;
      });
    }
  },

  components: {
    pageSection1,
    pageNation,
    cardList
  },

  props: {
    imgSrc: String,
    language: String
  },

  methods: {
    replaceByDefault(e) {
      const randNum = Math.floor(Math.random() * 100);
      e.target.src = this.imgSrc + randNum;
    }
  },
  beforeCreate() {
    axios
      .get('https://iesg-media-news.sdt-e21.workers.dev/')
      .then((response) => {
        const data = response.data;
        this.media = data;
        this.mediaLen = this.media.results.length;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get('https://iesg-media-major-disclosures.sdt-e21.workers.dev/')
      .then((response) => {
        const data = response.data;
        this.disclosures = data;
      })
      .catch((error) => {
        console.log(error);
      });

  },

  created() {
    this.iesgnews = iesgnews;
  }
};
</script>

<style scoped src="./media.css"></style>
