<template>
  <div>
    <div class="up1040">
      <pageSection1 :language="language" v-show="showPageSection" />
      <div v-if="language == 'ko'">
        <div class="inner mt-110 justify-content-center" id="contact-section">
          <PromotionCard
            v-if="this.$route.name === 'VKlanding'"
            :cardName="'i-ESG 스타트업 Promotion Price'"
            :cardContent="'벤처기업협회와 i-ESG가 연간이용료 40% 할인을 제공합니다.'"
            :type="'price'"
            :monthly="'25만원'"
            :yearly="'180만원'"
            :discountPer="'40%'"
            :discountPri="'25만원'"
            :discountPri2="'15만원'"
          />
          <PromotionCard
            v-if="this.$route.name === 'VKMlanding'"
            :cardName="'i-ESG 중소기업 Promotion Price'"
            :cardContent="'벤처기업협회와 i-ESG가 연간이용료 30% 할인을 제공합니다.'"
            :type="'price'"
            :monthly="'150만원'"
            :yearly="'1260만원'"
            :discountPer="'30%'"
            :discountPri="'150만원'"
            :discountPri2="'105만원'"
          />
          <PromotionCard
            :cardName="'i-ESG 문의하기'"
            :cardContent="'상담문의를 남겨주시면 담당자가 빠르게 연락드리겠습니다.'"
            :type="'contact'"
          />
        </div>
        <div class="inner mt-150 justify-content-center">
          <iframe
            src="https://www.youtube.com/embed/6J5A9a6Lumk"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
        <div class="inner mt-150">
          <div class="column">
            <h1 class="title">
              i-ESG는 기업들이 느끼는 애로사항을 해결하는 <br />
              <span class="color">end-to-end</span> 솔루션을 제시합니다.
            </h1>
            <div class="card-container">
              <!-- <endToEndCard :cardName="'ESG 자가진단'" />
							<endToEndCard :cardName="'ESG 자가진단'" />
							<endToEndCard :cardName="'ESG 자가진단'" />
							<endToEndCard :cardName="'ESG 자가진단'" /> -->
              <img
                class="end-card"
                src="../../assets/imgs/Lending_img/endToend1.png"
                alt="ESG 자가진단"
              />
              <img
                class="end-card"
                src="../../assets/imgs/Lending_img/endToend2.png"
                alt="이해관계자 의견 수립"
              />
              <img
                class="end-card"
                src="../../assets/imgs/Lending_img/endToend3.png"
                alt="ESG 리포팅과 AI 피드백"
              />
              <img
                class="end-card"
                src="../../assets/imgs/Lending_img/endToend4.png"
                alt="실시간 동향파악 & 이력관리"
              />
            </div>
          </div>
        </div>
        <div class="inner mt-150">
          <div class="column">
            <h1 class="title">
              i-ESG가 직접 묻고, 확인한 기업들의 Pain points. <br />
              <span class="color">실제적으로 활용가능한</span> 솔루션 도출에 집중했습니다.
            </h1>
            <div class="img-container">
              <img src="../../assets/imgs/Lending_img/PainPoint.png" alt="ESG Pain Point" />
            </div>
          </div>
        </div>
        <div class="inner mt-150">
          <div class="column">
            <h1 class="title">i-ESG와 함께해야하는 <span class="color">이유</span></h1>
            <div class="img-card-container">
              <img
                class="img-card"
                src="../../assets/imgs/Lending_img/reason1.png"
                alt="대표그룹의 사내벤처로서의 신인도"
              />
              <img
                class="img-card"
                src="../../assets/imgs/Lending_img/reason2.png"
                alt="빅데이터에 기반한 객관적인 서비스"
              />
              <img
                class="img-card"
                src="../../assets/imgs/Lending_img/reason3.png"
                alt="ESG~SW 개발 전반의 풍부한 역량"
              />
            </div>
          </div>
        </div>
        <div class="contact mt-150">
          <div class="column justify-content-center align-items-center">
            <h1 class="contact-text">더이상 미룰 수 없는 ESG 관리 지금 시작하세요!</h1>
            <button class="contact-button" @click="scrollToContact">문의하기</button>
            <p class="copy-right">COPYRIGHT(C) 2022 i-ESG ALL RIGHT RESERVED.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="down1040">
      <section class="bg-VKlanding">
        <div class="gradient" />
        <div class="inner2" v-if="this.$route.name === 'VKlanding'">
          <h2
            v-if="this.$route.name == 'VKlanding' && language === 'ko'"
            class="pricing-sub-title2"
          >
            벤처기업협회 회원사 대상<br />
            ESG 특화 솔루션 i-ESG가<br />
            연간이용료 <span class="spanTitle">40%할인</span>을 제공합니다.
          </h2>
          <div class="box1">
            i-ESG 솔루션은 수많은 기업들과의<br />
            인터뷰를 통해 검증된<br />
            ESG 특화 관리 솔루션입니다.
          </div>
          <div class="box1">
            최대 90% 저렴한 가격으로<br />
            지속 활용가능한 ESG 관리체계를<br />
            구축할 수 있는 합리적인 솔루션입니다.
          </div>
        </div>
        <div class="inner2" v-if="this.$route.name === 'VKMlanding'">
          <h2 class="pricing-sub-title2">
            벤처기업협회 회원사 대상<br />
            ESG 특화 솔루션 i-ESG가<br />
            연간이용료 <span class="spanTitle">30%할인</span>을 제공합니다.
          </h2>
          <div class="box1">
            ESG로 인한 거래중단과 같은<br />
            기업의 Risk가 증가하고 있습니다.
          </div>
          <div class="box1">
            지속적인 성장을 위해서는<br />
            ESG 대응 및 관리는 필수입니다.
          </div>
        </div>
      </section>
      <div v-if="language == 'ko'">
        <div class="inner mt-50 column">
          <div v-if="this.$route.name === 'VKlanding'" class="column inner">
            <h5 class="mobile-color-title">i-ESG 스타트업 Promotion Price</h5>
            <p class="mobile-content">
              벤처기업협회와 i-ESG가<br />
              연간이용료 40% 할인을 제공합니다.
            </p>
          </div>
          <div v-if="this.$route.name === 'VKMlanding'" class="column inner">
            <h5 class="mobile-color-title">i-ESG 중소기업 Promotion Price</h5>
            <p class="mobile-content">
              벤처기업협회와 i-ESG가<br />
              연간이용료 30% 할인을 제공합니다.
            </p>
          </div>
          <div class="center">
            <PromotionCardMobile
              v-if="this.$route.name === 'VKlanding'"
              :cardName="'i-ESG 스타트업 Promotion Price'"
              :cardContent="'벤처기업협회와 i-ESG가 연간이용료 40% 할인을 제공합니다.'"
              :type="'price'"
              :monthly="'25만원'"
              :yearly="'180만원'"
              :discountPer="'40%'"
              :discountPri="'25만원'"
              :discountPri2="'15만원'"
            />
            <PromotionCardMobile
              v-if="this.$route.name === 'VKMlanding'"
              :cardName="'i-ESG 중소기업 Promotion Price'"
              :cardContent="'벤처기업협회와 i-ESG가 연간이용료 30% 할인을 제공합니다.'"
              :type="'price'"
              :monthly="'150만원'"
              :yearly="'1260만원'"
              :discountPer="'30%'"
              :discountPri="'150만원'"
              :discountPri2="'105만원'"
            />
          </div>

          <h5 class="mobile-color-title mt-50" id="contact-section-mobile">i-ESG 문의하기</h5>
          <p class="mobile-content">상담문의를 남겨주시면 담당자가 연락드리겠습니다.</p>
          <PromotionCardMobile
            :cardName="'i-ESG 문의하기'"
            :cardContent="'상담문의를 남겨주시면 담당자가 빠르게 연락드리겠습니다.'"
            :type="'contact'"
          />
        </div>
        <div class="inner mt-100 justify-content-center">
          <iframe
            src="https://www.youtube.com/embed/6J5A9a6Lumk"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
        <div class="inner mt-100">
          <div class="column">
            <h1 class="title-mobile">
              i-ESG는 기업들이 느끼는<br />
              애로사항을 해결하는 <br />
              <span class="color">end-to-end</span> 솔루션을 제시합니다.
            </h1>
            <div class="card-container">
              <div class="card-row">
                <img
                  class="end-card-mobile"
                  src="../../assets/imgs/Lending_img/endToend-Mobile1.png"
                  alt="ESG 자가진단"
                />
                <img
                  class="end-card-mobile"
                  src="../../assets/imgs/Lending_img/endToend-Mobile2.png"
                  alt="이해관계자 의견 수립"
                />
              </div>
              <div class="card-row">
                <img
                  class="end-card-mobile"
                  src="../../assets/imgs/Lending_img/endToend-Mobile3.png"
                  alt="ESG 리포팅과 AI 피드백"
                />
                <img
                  class="end-card-mobile"
                  src="../../assets/imgs/Lending_img/endToend-Mobile4.png"
                  alt="실시간 동향파악 & 이력관리"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="inner mt-100">
          <div class="column">
            <h1 class="title-mobile">
              i-ESG가 직접 묻고,<br />
              확인한 기업들의 Pain points. <br />
            </h1>
            <h1 class="title-mobile mt-10">
              <span class="color">실제적으로 활용가능한</span><br />
              솔루션 도출에 집중했습니다.
            </h1>
            <div class="img-container-mobile mt-40">
              <img
                class="pain-img"
                src="../../assets/imgs/Lending_img/PainPoint-mobile.png"
                alt="ESG Pain Point"
              />
            </div>
          </div>
        </div>
        <div class="inner mt-100">
          <div class="column">
            <h1 class="title-mobile">i-ESG와 함께해야하는 <span class="color">이유</span></h1>
            <div class="img-card-container">
              <img
                class="img-card"
                src="../../assets/imgs/Lending_img/reason-mobile1.png"
                alt="대표그룹의 사내벤처로서의 신인도"
              />
              <img
                class="img-card"
                src="../../assets/imgs/Lending_img/reason-mobile2.png"
                alt="빅데이터에 기반한 객관적인 서비스"
              />
              <img
                class="img-card"
                src="../../assets/imgs/Lending_img/reason-mobile3.png"
                alt="ESG~SW 개발 전반의 풍부한 역량"
              />
            </div>
          </div>
        </div>
        <div class="contact mt-100">
          <div class="column justify-content-center align-items-center">
            <h1 class="contact-text">
              더이상 미룰 수 없는<br />
              ESG 관리 지금 시작하세요!
            </h1>
            <button class="contact-button" @click="scrollToContact">문의하기</button>
            <p class="copy-right">COPYRIGHT(C) 2022 i-ESG ALL RIGHT RESERVED.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setMeta from '@/utils/setMeta';
import pageSection1 from '../pageSection1.vue';
import PromotionCard from './PromotionCard.vue';
import PromotionCardMobile from './PromotionCardMobile.vue';
// import endToEndCard from './endToEndCard.vue'

export default {
  name: 'VKlanding',
  props: {
    language: String
  },
  beforeCreate() {
    setMeta({
      title: 'i-ESG | VKlanding',
      description: 'i-ESG와 함께하는 기업들',
      path: this.$route.path,
      keywords: 'ESG Partners,ESG 기업, ESG 통합관리, ESG, ESG 관리, ESG 솔루션'
    });
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  components: {
    pageSection1,
    PromotionCard,
    PromotionCardMobile
  },
  computed: {
    showPageSection() {
      return (
        this.windowWidth > 1040 &&
        (this.$route.name == 'VKlanding' || this.$route.name == 'VKMlanding')
      );
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    scrollToContact() {
      let targetId = 'contact-section';

      if (this.windowWidth <= 1040) {
        targetId = 'contact-section-mobile';
      }

      const element = document.getElementById(targetId);
      if (element) {
        const topPosition = element.offsetTop - 120;
        window.scrollTo({
          top: topPosition,
          behavior: 'smooth'
        });
      }
    }
  }
};
</script>

<style scoped>
.down1040 {
  display: none;
}
.inner {
  flex-direction: row;
  display: flex;
  width: 65vw;
  height: 100%;
  margin: auto;
  gap: 2.78vw;
}
.justify-content-center {
  display: flex;
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.mt-110 {
  margin-top: 110px;
}
.mt-150 {
  margin-top: 150px;
}

iframe {
  height: 36.56vw;
  width: 100%;
}
.title {
  justify-content: flex-start;
  color: #242424;
  font-family: Pretendard;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}
.color {
  color: #1da76d;
}
.card-container {
  margin-top: 2.5rem;
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  gap: 20.5px;
}
.img-container {
  width: 100%;
  margin-top: 20px;
}
img {
  width: 100%;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.img-card {
  border-radius: 10px;
  width: 31%;
  box-shadow: 0px 0px 40px 0px rgba(153, 201, 169, 0.5);
}
.img-card-container {
  justify-content: space-between;
  display: flex;
  margin-top: 40px;
}
.width-100 {
  width: 100%;
}
.contact {
  background-image: url(../../assets/imgs/Lending_img/Contact.png);
  background-size: cover;
}

.contact-text {
  color: #96f9bd;
  text-align: center;
  font-family: Pretendard;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 8.75rem;
}

.contact-button {
  width: 12.625rem;
  display: flex;
  justify-content: center;
  align-items: ceter;
  border: none;
  border-radius: 10px;
  background: #96f9bd;
  box-shadow: 0px 0px 30px 0px rgba(191, 206, 196, 0.5);
  padding: 1rem 3.5rem;
  margin-top: 3.125rem;
  color: #000915;
  font-family: Pretendard;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.copy-right {
  color: #a7adb2;
  text-align: center;
  font-family: Pretendard;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-top: 5rem;
  margin-bottom: 2.938rem;
}

.end-card {
  width: 25%;
}

/* @media (max-width: 1200px) {
			#contact-section {
				flex-direction: column;
				align-items: center;
			}
			.img-card-container, .card-container {
        flex-direction: column;
        align-items: center;
			}

			.img-card, .end-card {
					margin: 20px 0;
					width: 60%;
			}
			.inner {
			flex-direction: row;
			display: flex;
			width: 90vw;
			height: 100%;
			margin: auto;
			gap: 2.78vw;
			}
			.mt-150 {
				margin-top: 150px;
			}
			.mt-110 {
				margin-top: 110px;
			}

			iframe {
			height: 50.56vw;
			width: 100%;
		}
		@media (max-width: 1040px) {
			.down1040{
				display: initial;
			}
			.up1040 {
				display: none;
			}
			.mt-50{
				margin-top: 50px;
			}
			.mt-100{
				margin-top: 100px;
			}
			.bg-VKlanding {
				background-image: url(../../assets/imgs/Lending_img/VKLanding.png);
				width: 100%;
				height: 42rem;
				background-size: cover;
				background-position : 70%;
				z-index: -99;
			}
			.pricing-sub-title2 { 
				color: #FFF;
				text-align: center;
				font-family: Pretendard;
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 120%;
				margin-top: 3.5rem;
				z-index: 1;
			}
			.spanTitle {
				color: #96F9BD;
			}
			.inner2{
				flex-direction: column;
				display: flex;
				width: auto;
				align-items: center;
				justify-content: center;
				height: 100%;
			}
			.box1 {
				color: #FFF;
				text-align: center;
				font-family: Pretendard;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 150%;
				border-radius: 10px;
				background: rgba(255, 255, 255, 0.10);
				display: flex;
				width: 280px;
				padding: 10px 0px;
				justify-content: center;
				align-items: center;
				margin-top: 2.5rem;
				z-index: 1;
			}
			.gradient {
				width: 100%;
				height: 42rem;
				position: absolute;
				background: linear-gradient(216deg, #0057BD 0%, #1AD98A 100%);
				opacity: 0.45;
			}
			.column {
				display: flex;
				flex-direction: column;
			}
			.mobile-color-title {
				color: #1DA76D;
				font-family: Pretendard;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
			.mobile-content {
				color: #7A8087;
				font-family: Pretendard;
				font-size: 13px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			.center {
				display: flex;
				justify-content: center;
			}
			.card-row {
				display: flex;
				justify-content: center;
				flex-direction: row;
				gap: 20px;
			}
			.end-card-mobile {
				width: 45%
			}
			.title-mobile {
				color: #242424;
				font-family: Pretendard;
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: 140%;
			}
			.mt-10 {
				margin-top: 10px;
			}
			.mt-40 {
				margin-top: 40px;
			}
			.img-card{
				border-radius: 10px;
				width: 90%;
				box-shadow: 0px 0px 40px 0px rgba(153, 201, 169, 0.50);
			}
			.img-card-container{
				margin-top: 30px;
			}
			.contact-text{
				color: #96F9BD;
				text-align: center;
				font-family: Pretendard;
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
			.pain-img {
				width: 90%;
			}
			.img-container-mobile {
				width: 100%;
				margin-top: 40px;
				justify-content: center;
				display: flex;
			}
		}
} */
</style>
