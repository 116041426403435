<template>
    <div class="price-table">
      <table class="table">
        <thead>
          <tr>
            <th class="table-cell-top table-cell "></th>
            <th class="table-cell-top table-cell color-head-cell fixed-width">Basic</th>
            <th class="table-cell-top table-cell highlighted-cell fixed-width">Basic+컨설팅</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td v-if="index !== items.length - 1" class="table-cell" :class="{ 'table-cell-bottom': index === items.length - 2 }" :rowspan="index === items.length - 2 ? '2' : '1'">
              {{ item.name }}
              <img v-if="item.name !== '금액' && item.name !== ''" src="../../assets/imgs/Lending_img/infoIcon.svg" class="tooltip-icon" @mouseover="showTooltip(index)" @mouseout="hideTooltip(index)" alt="Info Icon"/>
              <span v-if="tooltipVisible[index] && item.name !== '금액'" class="tooltip">{{ item.tooltip }}</span>
            </td>
            <td class="table-cell" :class="{ 'table-cell-bottom': index === items.length - 1, 'special-padding': index <= 5 }">
              <img v-if="index <= 2" src="../../assets/imgs/Lending_img/checkIcon.svg" alt="Check Icon"/>
              <div v-if="index === 4" class="pay-container">
                <h4 class="pay-title">월간 결제</h4>
                <p class="pay-content">{{ monthly }}</p>
              </div>
              <div v-if="index === 5" class="pay-container">
                <h4 class="pay-title">연간 결제</h4>
                <p class="pay-content pay-content-yearly">{{ yearly }}</p>
                <p class="pay-content-per">{{ discountPer }}할인</p>
                <div class="discount-container">
                  <p class="discountPri">
                    <span class="line-through">{{ discountPri }}</span>/월
                  </p>
                  <img src="../../assets/imgs/Lending_img/Arrow4.svg" alt="">
                  <p class="discountPri2">
                    {{ discountPri2 }}/월
                  </p>
                </div>
              </div>
            </td>
            <td v-if="index !== items.length - 1" class="table-cell highlighted-cell" :class="{ 'table-cell-bottom': index === items.length - 2, 'special-padding': index <= 3 }" :rowspan="index === items.length - 2 ? '2' : '1'">
              <img v-if="index <= 3" src="../../assets/imgs/Lending_img/checkIcon2.svg" alt="Check Icon"/>
              <span v-if="index === 3" class="special-text">벤처기업협회<br>회원사 대상 특별 할인</span>
              <p v-if="index === 4" class="special-text">Basic {{ discountPer }} 할인 + α</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        items: [
          { name: "ESG 대시보드", tooltip: "기업진단현황 + 빅데이터 동향정보 + 국내외 트렌드 + AI 기반 비즈니스 사례 등 종합관리 대시보드" },
          { name: "ESG 자가진단", tooltip: "데이터 기반 ESG 진단 Set 제공+대시보드+진단이력관리+PDF리포트" },
          { name: "ESG 공시자동화", tooltip: "글로벌 공시기준 데이터 + AI 추천 + 리포팅 자동화 + 대시보드 + 협업툴" },
          { name: "ESG 컨설팅", tooltip: "ESG특화교육 + 전문가 상시자문 + 현장실사 + 공시컨설팅 + 솔루션교육" },
          { name: "금액", tooltip: "" },
          { name: "", tooltip: ""}
        ],
        tooltipVisible: Array(5).fill(false)
      };
    },
    props: {
          monthly: String,
          yearly: String,
          discountPer: String,
          discountPri: String,
          discountPri2: String,
      },
    mounted() {
      console.log(this.tooltipVisible)
    },
    
    methods: {
      showTooltip(index) {
        console.log("Showing tooltip for index:", index);
        
        this.tooltipVisible[index] = true;
        console.log(this.tooltipVisible)
        
      },
      hideTooltip(index) {
        this.tooltipVisible[index] = false;
        console.log(this.tooltipVisible)
      }
    }
  };
  </script>
  
  <style scoped>
  .price-table {
    width: 100%;
  }
  .table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .table-cell {
    vertical-align: middle;
    position: relative;
    padding: 1.938rem 1rem;
    text-align: center;
    border-left: 0.5px solid #D8DBDD;
    border-right: 0.5px solid #D8DBDD;
    border-bottom: 0.5px solid #D8DBDD;
    font-size: 12px;
  }
  
  .table-cell:first-child {
    border-left: none;
  }
  
  .table-cell:last-child {
    border-right: none;
  }
  
  .fixed-width {
    width: 32%;
  }
  
  .table-cell-top {
    border-top: none;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    padding: 21px 4px;
  }
  
  .table-cell-bottom {
    border-bottom: none;
  }
  
  .highlighted-cell {
    background-color: #F2F4F6;
    color: #1B4380;
  }
  
  .color-head-cell {
    color: #1B806A;
  }
  
  .tooltip-icon {
    margin-left: 2px;
    cursor: pointer;
    width: 12px;
    height: 12px;
    vertical-align: middle;
  }
  
  .tooltip {
      position: absolute;
      z-index: 1;
      width: 17vw;
      padding: 0.625rem;
      font-size: 0.85rem;
      box-sizing: border-box;
      background: #FFFFFF;
      border-radius: 10px;
      left: calc(100% - 0.5vw);
      bottom: 1rem;
      filter: drop-shadow(2px 4px 20px rgba(149, 157, 163, 0.3));
      color: #7D7D7D;
      line-height: 140%;
  }
  
  .special-text {
      color: #1B4380;
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
      letter-spacing: -0.48px;
      display: block;
      margin-top: 10px; /* 추가된 부분: 아이콘과 텍스트 간의 간격을 조정하기 위해 */
  }
  
  .table-cell.special-padding {
      padding: 10px 4.5px;
  }
  
  .pay-container{
    display: flex;
    flex-direction: column;
  }
  
  .pay-title{
    color: #7A8087;
    text-align: center;
    font-family: Pretendard;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
  .pay-content{
    color: #242424;
    text-align: center;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
  }
  
  .pay-content-yearly{
    font-weight: 700;
    text-decoration-line: underline;
  }
  
  .pay-content-per{
    color: #1DA76D;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .discountPri{
    color: #7A8087;
    font-family: Pretendard;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  
  .line-through{
    text-decoration: line-through;
  }
  .discount-container{
    display: flex;
    margin-top: 4px;
    justify-content: center;
  }
  
  .discountPri2{
    color: #242424;
    text-align: center;
    font-family: Pretendard;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
  
  </style>
  