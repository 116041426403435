import { useMeta } from 'vue-meta'

export default (params = {}) => {
  useMeta({
    title: params.title,
    meta: [
      {vmid: 'description', name: 'description', content: params.description},
      {vmid: 'keywords', name: 'keywords', content: params.keywords},
      {vmid: 'og:title', name: 'og:title', content: params.title},
      {vmid: 'og:description', name: 'og:description', content: params.description},
      // {vmid: 'og:url', name: 'og:url', content: process.env.VUE_APP_BASE_URL + params.path},
      {vmid: 'og:url', name: 'og:url', content: 'http://i-esg.io' + params.path},
      { property: 'og:url', content: 'http://i-esg.io' + params.path },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'i-ESG - 체계적인 ESG 관리 솔루션' },
      { property: 'og:description', content: '기업들의 ESG 대응한계를 해소하는 ESG 특화 B2B 디지털 관리 솔루션' },
      { property: 'og:image', content: 'public/main_logo.png' },
      { property: 'og:locale', content: 'ko_KR' },
      { property: 'fb:app_id', content: 'public/favicon.ico' },
      { name: 'twitter:card', content: 'public/main_logo.png' },
      { name: 'twitter:title', content: 'i-ESG - 체계적인 ESG 관리 솔루션' },
      { name: 'twitter:description', content: '기업들의 ESG 대응한계를 해소하는 ESG 특화 B2B 디지털 관리 솔루션' },
      { name: 'twitter:image', content: 'public/main_logo.png' },
      { name: 'twitter:url', content: 'http://i-esg.io' + params.path },
      { name: 'twitter:site', content: '@website-username' }
    ]
  })
}