<template>
  <div>
    <pageSection1 v-show="this.$route.name == 'Insights'" :language="language" />
    <div class="insights-p2">
      <div class="inner" data-aos="fade-up" data-aos-offset="200">
        <section class="insight">
          <div class="top">
            <div class="title Pretendard-700">ESG Trends</div>
            <router-link to="/insights_more" v-if="language === 'ko'">
              <a href="" class="more Pretendard-700" @click="$emit('moveTab', 'ESG Trend')">
                더 보기 >
              </a>
            </router-link>
            <router-link to="/insights_more" v-if="language === 'en'">
              <a href="" class="more Pretendard-700" @click="$emit('moveTab', 'ESG Trend')">
                More >
              </a>
            </router-link>
          </div>
          <div class="bottom">
            <a
              v-for="post in 3"
              :key="post"
              :href="
                language === 'en'
                  ? glo[glo_new_length - post]?.site_source
                  : ko[ko_new_length - post]?.site_source
              "
            >
              <article class="card">
                <div
                  :style="{
                    backgroundImage: `url(${
                      language === 'en'
                        ? glo[glo_new_length - post]?.site_image
                        : ko[ko_new_length - post]?.site_image
                    })`
                  }"
                  class="img"
                />
                <div class="description">
                  <h1 class="Pretendard-600">
                    {{
                      language === 'en'
                        ? glo[glo_new_length - post]?.site_subject
                        : ko[ko_new_length - post]?.site_subject
                    }}
                  </h1>
                  <p class="Pretendard-400">
                    {{
                      language === 'en'
                        ? glo[glo_new_length - post]?.site_content
                        : ko[ko_new_length - post]?.site_content
                    }}
                  </p>
                </div>
              </article>
            </a>
          </div>
          <div class="mobile-bottom">
            <a v-for="post in 2" :key="post" :href="ko[ko_new_length - post]?.site_source">
              <article class="card">
                <div
                  :style="{ backgroundImage: `url(${ko[ko_new_length - post]?.site_image})` }"
                  class="img"
                />
                <div class="description">
                  <h1 class="Pretendard-600">{{ ko[ko_new_length - post]?.site_subject }}</h1>
                  <p class="Pretendard-400">{{ ko[ko_new_length - post]?.site_content }}</p>
                </div>
              </article>
            </a>
          </div>
        </section>
        <section class="insight" v-if="language !== 'en'">
          <div class="top">
            <div class="title Pretendard-700">ESG Seminars</div>
            <router-link to="/insights_more" v-if="language === 'ko'">
              <a href="" class="more Pretendard-700" @click="$emit('moveTab', 'ESG Seminar')">
                더 보기 >
              </a>
            </router-link>
            <router-link to="/insights_more" v-if="language === 'en'">
              <a href="" class="more Pretendard-700" @click="$emit('moveTab', 'ESG Seminar')">
                More >
              </a>
            </router-link>
          </div>
          <div class="bottom">
            <a v-for="(post, i) in 3" :key="post" :href="semi[i]?.site_source">
              <article class="card">
                <img
                  :src="semi[i]?.site_image"
                  @error="replaceByDefault"
                  alt="ESG 세미나"
                  class="img"
                />
                <div class="description">
                  <h1 class="Pretendard-600">{{ semi[i]?.site_subject }}</h1>
                  <p class="Pretendard-400">
                    일시 : {{ $dayjs(semi[i]?.created_at).format('YYYY-MM-DD') }} <br />
                    행사 주최/주관 : {{ semi[i]?.site_organizer }}
                  </p>
                </div>
              </article>
            </a>
          </div>
          <div class="mobile-bottom">
            <a v-for="(post, i) in 2" :key="post" :href="semi[i]?.site_source">
              <article class="card">
                <img
                  :src="semi[i]?.site_image"
                  @error="replaceByDefault"
                  alt="ESG 세미나"
                  class="img"
                />
                <div class="description">
                  <h1 class="Pretendard-600">{{ semi[i]?.site_subject }}</h1>
                  <p class="Pretendard-400">
                    일시 : {{ semi[i]?.date }} <br />
                    행사 주최/주관 : {{ semi[i]?.organizer }}
                  </p>
                </div>
              </article>
            </a>
          </div>
        </section>
        <section class="insight">
          <div class="top">
            <div class="title Pretendard-700">ESG Educations</div>
            <router-link to="/insights_more" v-if="language === 'ko'">
              <a href="" class="more Pretendard-700" @click="$emit('moveTab', 'ESG Education')">
                더 보기 >
              </a>
            </router-link>
            <router-link to="/insights_more" v-if="language === 'en'">
              <a href="" class="more Pretendard-700" @click="$emit('moveTab', 'ESG Education')">
                More >
              </a>
            </router-link>
          </div>
          <div class="bottom">
            <router-link to="/insights_more" class="card_edu">
              <article class="card_edu" @click="$emit('moveTab', 'ESG Education')">
                <div class="img" />
              </article>
            </router-link>
          </div>
          <div class="mobile-bottom">
            <router-link to="/insights_more" class="card_edu">
              <article class="card_edu" @click="$emit('moveTab', 'ESG Education')">
                <div class="img" />
              </article>
            </router-link>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import setMeta from '@/utils/setMeta';
import pageSection1 from '../pageSection1.vue';

export default {
  name: 'Insights',
  beforeCreate() {
    setMeta({
      title: 'i-ESG | Intelligence',
      description: 'i-ESG를 통해 우리회사의 ESG를 체계적으로 관리하고 다양한 컨텐츠를 만나보세요',
      path: this.$route.path,
      keywords: 'ESG Trends, ESG Seminars, ESG Education, ESG, ESG 관리, ESG 솔루션'
    });
  },
  props: {
    news: Array,
    semi: Array,
    edu: Array,
    tab: String,
    ko: Array,
    glo: Array,
    imgSrc: String,
    ko_new_length: String,
    glo_new_length: String,
    language: String
  },
  components: {
    pageSection1
  },
  methods: {
    replaceByDefault(e) {
      const randNum = Math.floor(Math.random() * 100);
      e.target.src = this.imgSrc + randNum;
    },
    fire() {
      this.emitter.emit('tab', this.tab);
    }
  }
};
</script>

<style scoped src="./insights.css"></style>
