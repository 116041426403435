<template>
  <div class="contact-form">
    <Form @submit="submitForm" :validation-schema="schema" v-slot="{ errors, resetForm }">
      <div class="row">
				<div class="column">
					<Field name="name" v-model="formData.name" placeholder="담당자 이름 / 직책*" class="text-box" />
					<span v-if="errors.name" class="error-text">{{ errors.name }}</span>
				</div>
      </div>
      <div class="row">
				<div class="column">
					<Field name="company" v-model="formData.company" placeholder="회사명*" class="text-box" />
					<span v-if="errors.company" class="error-text">{{ errors.company }}</span>
				</div>
      </div>
			<div class="row">
				<div class="column">
					<Field name="department" v-model="formData.department" placeholder="담당부서*" class="text-box" />
					<span v-if="errors.department" class="error-text">{{ errors.department }}</span>
				</div>
      </div>

      <div class="row">
				<div class="column">
					<Field name="email" v-model="formData.email" type="email" placeholder="이메일*" class="text-box" />
					<span v-if="errors.email" class="error-text">{{ errors.email }}</span>
				</div>
      </div>
			<div class="row">
        <div class="column">
					<Field name="phone" v-model="formData.phone" placeholder="전화번호*" class="text-box" />
					<span v-if="errors.phone" class="error-text">{{ errors.phone }}</span>
				</div>
      </div>

      <div class="row">
				<div class="column">
					<Field as="textarea" name="questions" v-model="formData.questions" placeholder="문의 내용*" class="text-area" />
					<span v-if="errors.questions" class="error-text">{{ errors.questions }}</span>
				</div>
      </div>

			<div class="privacy-agreement">
				<input type="checkbox" id="privacyCheckbox" v-model="privacyAgreed" />
				<label for="privacyCheckbox">
					i-ESG 개인정보 처리방침에 동의합니다.<br>
					<a href="#" @click.prevent="showSubmodal">개인정보 처리방침 보기</a>
				</label>
				<span v-if="errors.privacyAgreed" class="error-text">{{ errors.privacyAgreed }}</span>
			</div>

      <button type="submit" @click="resetFormFunc = resetForm" class="submit-button" :disabled="!privacyAgreed">문의하기</button>
    </Form>
    <Submodal :viewmodal="viewmodal" @closemodal="viewmodal = 0" />
  </div>
</template>

<script>
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import Submodal from '../Modal/SubModal.vue'

export default {
  components: {
    Form,
    Field,
    Submodal
  },
	data() {
    const typeValue = this.$route.name === 'VKlanding' ? "벤처 KIT 스타트업" : (this.$route.name === 'VKMlanding' ? "벤처 KIT 중소기업" : '');

    return {
      formData: {
        name: '',
        position: '',
        company: '',
        department: '',
        funnel: 'SNS',
        email: '',
        phone: '',
        questions: '',
        type: typeValue
      },
      viewmodal: 0,
      resetFormFunc: null,
      privacyAgreed: false,
      schema: this.getSchema()
    };
  },
  methods: {
    showSubmodal() {
      this.viewmodal = 1;
    },
    getSchema() {
      return yup.object({
        name: yup.string().required('필수 항목입니다.'),
        position: yup.string().required('필수 항목입니다.'),
        company: yup.string().required('필수 항목입니다.'),
        department: yup.string().required('필수 항목입니다.'),
        email: yup.string().required('필수 항목입니다.').email('유효한 이메일 주소를 입력하세요.'),
        phone: yup.string().required('필수 항목입니다.'),
        questions: yup.string().required('필수 항목입니다.'),
        privacyAgreed: yup.boolean().oneOf([true], '개인정보 처리방침에 동의해야 합니다.')
      });
    },
    async submitForm() {
      console.log("Form submitted:", this.formData);
      try {
        const params = new URLSearchParams();
        for (let [key, value] of Object.entries(this.formData)) {
          params.append(key, value);
        }
				const result = await axios.post('https://solution.i-esg.io/API/question/create', params);
        console.log(result.data);
        if (this.resetFormFunc) {
          this.resetFormFunc();
        }

        this.formData = {
          name: '',
          position: '',
          company: '',
          department: '',
          funnel: '',
          email: '',
          phone: '',
          questions: '',
          type: this.$route.name === 'VKlanding' ? "벤처 KIT 스타트업" : (this.$route.name === 'VKMlanding' ? "벤처 KIT 중소기업" : '')
        };

        this.showAlert();
      } catch (err) {
        console.log(err);
        this.showError();
      }
    },
    showAlert() {
      const title = this.language === 'en' ? 'Completed' : '완료되었습니다.';
      const text = this.language === 'en' ? 'Thank you' : '감사합니다.';
      Swal.fire({
        icon: 'success',
        title: title,
        text: text,
        confirmButtonColor: '#000116'
      });
    },
    showError() {
      const title = this.language === 'en' ? 'Transmission Error' : '전송오류';
      const text = this.language === 'en' ? 'Please try again.' : '다시 시도하십시오.';
      Swal.fire({
        icon: 'error',
        title: title,
        text: text,
        confirmButtonColor: '#000116'
      });
    }
  }
};
</script>
  
  <style scoped>
  .contact-form {
		margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
		width: 100%;
  }
  
  .text-box {
    display: flex;
    /* height: 70px; */
    padding: 10px 16px;
    align-items: center;
    gap: 16px;
    /* flex: 1 0 0; */
    border-radius: 10px;
    border: 1px solid var(--border-20, #E5E5E5);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.48px;
  }

  .with-asterisk::placeholder::after {
    content: "*";
    color: #1B806A;
    position: absolute;
    right: 10px;;
	}

  .text-box::placeholder, .text-area::placeholder {
  color: var(--color-gray-50, #8E959B);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.48px;
}
  
  .text-area {
    display: flex;
    height: 236px;
    padding: 10px 16px;
    align-items: flex-start;
    gap: 22.5px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.48px;
		resize: none;
  }

	.column {
		display: flex;
		flex-direction: column;
		gap: 5px;
		width: 100%;
	}
  
  .row {
    display: flex;
    gap: 20px;
		margin-bottom: 20px;
  }
  
  .error-text {
    color: red;
    font-size: 0.8rem;
		margin-left: 8px;
		margin-top: 8px;
		margin-bottom: 8px;
  }
  
  .submit-button {
    width: 100%;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--color-gray-button-agree, #303140);
    color: #FFF;
    cursor: pointer;
		border: none;
  }

	.privacy-agreement {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 22.5px;
	font-family: Pretendard;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	color: var(--color-gray-70, #5C5F66);
}

.privacy-agreement input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border: 2px solid var(--border-20, #E5E5E5);
  cursor: pointer;
}

.privacy-agreement label {
  cursor: pointer;
}

.privacy-agreement a {
  color: #0077cc; /* 예시 색상입니다. */
  text-decoration: underline;
}

.submit-button:disabled {
  background: #F2F4F6;
	border: none;
	cursor: default;
	color: #A7ADB2;
}
  </style>
