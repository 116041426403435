<template>
  <div class="display-none">
    <Carousel :wrap-around="true" :autoplay="13000">
      <Slide class="a" v-for="(slide, b) in checking" :key="slide">
        <router-link to="/insights_more" class="carousel-title" @click="fire(b)">
          <div class="Pretendard-700">{{ ESG[b] }}</div>
        </router-link>
        <div v-for="(c, i) in Cnt[b]" :key="c" class="carousel__item" :style="ChangeImgHeight(b)">
          <router-link
            v-show="ESG[b] == 'ESG Education'"
            to="/insights_more"
            class="card"
            :style="[ChangeImgHeight(b), ChangeWidth(b)]"
            @click="$emit('moveTab', 'ESG Education')"
          >
            <img
              :src="checking[b][i]?.site_image"
              class="card-img-top"
              alt="ESG 교육"
              :style="[ChangeImgHeight(b), ChangeWidth(b)]"
            />
          </router-link>
          <a
            v-show="ESG[b] == 'ESG Trend'"
            :href="ko[ko_new_length - i - 1]?.site_source"
            class="card"
            target="_blank"
            :style="[ChangeImgHeight(b), ChangeWidth(b)]"
          >
            <img
              :src="ko[ko_new_length - i - 1]?.site_image"
              class="card-img-top"
              alt="ESG 뉴스"
              :style="[ChangeImgHeight(b), ChangeWidth(b)]"
            />
            <div class="card-body">
              <h5 class="card-title Pretendard-600">
                {{ ko[ko_new_length - i - 1]?.site_subject }}
              </h5>
              <p class="card-text Pretendard-400">
                {{ ko[ko_new_length - i - 1]?.site_content }}
              </p>
            </div>
          </a>
          <a
            v-show="ESG[b] == 'ESG Seminar'"
            :href="semi[i]?.site_source"
            class="card"
            target="_blank"
            :style="[ChangeImgHeight(b), ChangeWidth(b)]"
          >
            <img
              :src="semi[i]?.site_image"
              @error="replaceByDefault"
              class="card-img-top"
              alt="ESG 세미나"
              :style="[ChangeImgHeight(b), ChangeWidth(b)]"
            />
            <div class="card-body">
              <h5 class="card-title Pretendard-600">
                {{ semi[i]?.site_subject }}
              </h5>
              <p class="card-text Pretendard-400">
                일시 : {{ $dayjs(semi[i]?.created_at).format('YYYY-MM-DD') }} <br />
                행사 주최/주관 : {{ semi[i]?.site_organizer }}
              </p>
            </div>
          </a>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
  name: 'CarouselT',
  components: { Carousel, Slide, Navigation },
  props: {
    ko: Array,
    ext: Array,
    semi: Array,
    Cnt: Array,
    EduData: Object,
    imgSrc: String,
    ko_new_length: String
  },
  data() {
    return {
      ESG: [
        'ESG Trend',
        'ESG Seminar',
        'ESG Education',
        'ESG Trend',
        'ESG Seminar',
        'ESG Education'
      ],
      checking: [this.ko, this.semi, this.EduData, this.ko, this.semi, this.EduData]
    };
  },
  methods: {
    replaceByDefault(e) {
      const randNum = Math.floor(Math.random() * 10);
      e.target.src = this.imgSrc + randNum;
    },
    fire(b) {
      this.emitter.emit('movetab', this.ESG[b]);
    },
    ChangeImgHeight(i) {
      if (this.Cnt[i] === 1) {
        return {
          height: '100%'
        };
      }
    },
    ChangeWidth(i) {
      if (this.Cnt[i] === 1) {
        return {
          width: '100%'
        };
      }
    }
  }
};
</script>

<style scoped src="./carouselT.css"></style>
