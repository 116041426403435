<template>
  <div class="formhieght">
    <h1 class="title">{{ modaldata.Mtitle }}</h1>
    <form @submit.prevent="submitForm">
      <div class="input-box">
        <div class="input-name-box">
          <input
            class="input-name"
            v-if="modaldata.Mname"
            type="text"
            v-model="form.name"
            :placeholder="modaldata.Mname"
            pattern=".*\/.*"
            required
            @invalid="handleInvalidInputName"
          />
        </div>
        <input
          v-if="modaldata.Mcompany"
          :placeholder="modaldata.Mcompany"
          v-model="form.company"
          required
          @invalid="handleInvalidInput"
        />
        <input
          v-if="modaldata.Mdepartment"
          type="text"
          v-model="form.department"
          :placeholder="modaldata.Mdepartment"
          required
          @invalid="handleInvalidInput"
        />
        <input
          v-if="modaldata.Memail"
          type="email"
          v-model="form.email"
          :placeholder="modaldata.Memail"
          required
          oninvalid="handleEmailInvalid"
          oninput="handleEmailInvalid"
          @invalid="handleEmailInvalid"
          @input="handleEmailInvalid"
        />
        <input
          v-if="modaldata.Mphone"
          type="text"
          v-model="form.phone"
          :placeholder="modaldata.Mphone"
          required
          @invalid="handleInvalidInput"
        />
        <textarea
          v-if="modaldata.Mquestions"
          type="text"
          v-model="form.questions"
          :placeholder="modaldata.Mquestions"
          required
          @invalid="handleInvalidInput"
        ></textarea>
        <div class="funnel">
          <p v-if="language !== 'en'">유입 경로</p>
          <p v-if="language == 'en'">Funnel</p>
          <div v-if="language !== 'en'" class="funnelOtion">
            <div v-for="(option, index) in options" :key="index">
              <label class="funnelLabel">
                <input
                  class="radioInput"
                  type="radio"
                  name="option"
                  :value="option"
                  v-model="form.funnel"
                  @change="setSelectedOption(option)"
                  required
                  @invalid="handleInvalidInput"
                />
                {{ option }}
              </label>
            </div>
          </div>

          <div v-if="language == 'en'" class="funnelOtion">
            <div v-for="(option, index) in optionsEN" :key="index">
              <label class="funnelLabel">
                <input
                  class="radioInput"
                  type="radio"
                  name="option"
                  :value="option"
                  v-model="form.funnel"
                  @change="setSelectedOption(option)"
                  required
                  @invalid="handleInvalidInput"
                />
                {{ option }}
              </label>
            </div>
          </div>

          <div class="option5" v-if="form.funnel === '기타'">
            <label v-if="language !== 'en'" for="otherInput">기타 :</label>
            <label v-if="language == 'en'" for="otherInput">ETC :</label>
            <input class="option5Input" type="text" id="otherInput" v-model="otherInput" required @invalid="handleInvalidInput" />
          </div>
        </div>
      </div>
      <div class="check">
        <input type="checkbox" id="checkClick" required @invalid="handleInvalidInput" v-model="infochecked" />
        <label v-if="language !== 'en'" for="checkClick"
          >요청된 사항을 처리하기 위해 i-ESG가 위에 제출된 개인정보를 저장 및 처리하는 데
          동의합니다.</label
        >
        <label v-if="language == 'en'" for="checkClick"
          >By clicking submit, you consent to communication regarding this inquiry and to the
          storing and processing of your personal data in accordance with our Privacy Policy.</label
        >
      </div>
      <PrivacyConsentTable :language="language" />
      <div class="button-container">
        <button v-if="modaldata.Mbutton" type="submit" style="color: #000000; background-color: #1AD98A; width: 50%; ">
          {{ modaldata.Mbutton }}
        </button>
        <a v-if="modaldata.Mdownload" :href="`${publicPath}기후변화교육_소개자료_i-ESG.pdf`" download>
          <button type="submit" style="color: #000000">
            {{ modaldata.Mdownload }}
          </button>
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import PrivacyConsentTable from './PrivacyConsentTable.vue';

export default {
  name: 'Submitform',
  props: {
    modalshow: String,
    modaldata: Object,
    language: String
  },
  components : {
    PrivacyConsentTable
  },
  data() {
    return {
      options: ['SNS', '뉴스 기사', '홍보 메일', '지인 소개', '기타'],
      optionsEN: ['SNS', 'News', 'PR Mail', 'Acquaintance', 'ETC'],
      option1Selected: false,
      option2Selected: false,
      option3Selected: false,
      option4Selected: false,
      option5Selected: false,
      otherInput: '',
      publicPath: process.env.BASE_URL,
      form: {
        name: '',
        position: '',
        email: '',
        funnel: '',
        company: '',
        phone: '',
        questions: '',
        type: this.modaldata.Mtype
      },
      infochecked: false,
      submit: false
    };
  },
  methods: {
    translate(key) {
      const translations = {
        en: {
          fillField: "Please fill out this field.",
          includeAt: "Please include ‘@’ in the email address. There is no ‘@’ in.",
          afterAt: "Please enter the part after ‘@’. ‘@’ is incomplete.",
          selectOption: "Please select one of the following options.",
          checkToContinue: "Please check this box to continue.",
        },
        ko: {
          fillField: "이 입력란을 작성하세요.",
          includeAt: "Please enter the part after ‘@’. ‘@’ is incomplete.",
          afterAt: "Please enter the part after ‘@’. ‘@’ is incomplete.",
          selectOption: "다음 옵션 중 하나를 선택하세요.",
          checkToContinue: "계속하려면 이 확인란을 선택하세요.",
        }
      };
      return translations[this.language][key] || key;
    },
    handleInvalidInputName(event) {
      event.target.setCustomValidity('');
      if (!event.target.validity.valid && this.language === 'ko') {
        event.target.setCustomValidity('입력값에 / 문자를 포함하여 성함과 직급을 작성해주세요.');
      }
      if (!event.target.validity.valid && this.language === 'en') {
        event.target.setCustomValidity('Please write your name and position including the “/” character.');
      }
    },
    handleInvalidInput(event) {
      event.target.setCustomValidity('');
      if (!event.target.validity.valid) {
        event.target.setCustomValidity(this.translate('fillField'));
      }
    },

    handleEmailInvalid(event) {
      const textbox = event.target;
      if (textbox.value === '') {
        textbox.setCustomValidity(this.translate('fillField'));
      } else if (textbox.validity.typeMismatch) {
        textbox.setCustomValidity(this.translate('includeAt').replace('There is no ‘@’ in.', `There is no ‘@’ in ${textbox.value}.`));
        if (textbox.value.includes('@') && textbox.value.split('@')[1] === '') {
          const emailMessage = this.translate('afterAt').replace('‘@’ is incomplete.', `‘${textbox.value}’ is incomplete.`);
          textbox.setCustomValidity(emailMessage);
        }
      } else {
        textbox.setCustomValidity('');
      }
      textbox.oninput = function() {
        textbox.setCustomValidity('');
      };
    },
    async submitForm() {
      try {
        const params = new URLSearchParams();
        for (let [key, value] of Object.entries(this.form)) {
          params.append([key], value);
        }
        const result = await axios.post('https://solution.i-esg.io/API/question/create', params);
        this.clearAll();
        return result.data, this.showAlert();
      } catch (err) {
        console.log(err);
        this.showError();
      }
    },
    clearAll() {
      (this.form.name = ''),
        (this.form.email = ''),
        (this.form.company = ''),
        (this.form.phone = ''),
        (this.form.questions = ''),
        (this.form.position = ''),
        (this.form.department = ''),
        (this.form.funnel = ''),
        (this.option1Selected = false),
        (this.option2Selected = false),
        (this.option3Selected = false),
        (this.option4Selected = false),
        (this.option5Selected = false),
        (this.infochecked = false);
    },
    adown() {
      var a = document.createElement('a');
      a.style = 'display: none';
      a.href = this.publicPath + '기후변화교육_소개자료_i-ESG.pdf';
      a.download = '기후변화교육_소개자료_i-ESG.pdf';
      document.body.appendChild(a);
      a.click();

      setTimeout(function () {
        document.body.removeChild(a);
        URL.revokeObjectURL('i-esg-hp/public/기후변화교육_소개자료_i-ESG.pdf'); // 메모리 해제
      }, 100);
    },
    showError() {
      const title = this.language === 'en' ? 'Transmission Error' : '전송오류';
      const text = this.language === 'en' ? 'Please try again.' : '다시 시도하십시오.';
      this.$swal({
        icon: 'failed',
        title: title,
        text: text,
        confirmButtonColor: '#000116'
        });
    },
    showAlert() {
      const title = this.language === 'en' ? 'Completed' : '완료되었습니다.';
      const text = this.language === 'en' ? 'Thank you' : '감사합니다.';
      this.$swal({
        icon: 'success',
        title: title,
        text: text,
        confirmButtonColor: '#000116'
        }).then(() => {
        this.$emit('closeModal');
      });
    },

    setSelectedOption(selectedOption) {
      this.selectedOption = selectedOption;
      if (this.selectedOption === '기타') {
        this.selectedValue = this.otherInput;
      } else {
        switch (this.selectedOption) {
          case 'SNS':
            this.option1Selected = true;
            this.option2Selected = false;
            this.option3Selected = false;
            this.option4Selected = false;
            this.option5Selected = false;
            this.selectedValue = 'SNS';
            break;
          case '뉴스 기사':
            this.option1Selected = false;
            this.option2Selected = true;
            this.option3Selected = false;
            this.option4Selected = false;
            this.option5Selected = false;
            this.selectedValue = '뉴스 기사';
            break;
          case '홍보 메일':
            this.option1Selected = false;
            this.option2Selected = false;
            this.option3Selected = true;
            this.option4Selected = false;
            this.option5Selected = false;
            this.selectedValue = '홍보 메일';
            break;
          case '지인 소개':
            this.option1Selected = false;
            this.option2Selected = false;
            this.option3Selected = false;
            this.option4Selected = true;
            this.option5Selected = false;
            this.selectedValue = '지인 소개';
            break;
          default:
            this.option1Selected = false;
            this.option2Selected = false;
            this.option3Selected = false;
            this.option4Selected = false;
            this.option5Selected = false;
            this.selectedValue = '';
            break;
        }
      }
    }
  }
};
</script>

<style scoped src="./contactModal.css"></style>
