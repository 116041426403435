<template>
  <div class="home" id="home" style="overflow: hidden">
    <Carousel :itemsToShow="1" :autoplay="5000" class="main-carousel">
      <!-- <Slide class="go-to-promotion">
        <div class="promotion-gradient-layer"></div>
        <div class="entrance-view" v-if="language == 'ko'">
          <div>
            <div class="title-overheader Pretendard-700">
              글로벌 평가기관이 요구하는 핵심 지표를 선별한 i-ESG 진단 셋
            </div>
            <div class="entrance-title Pretendard-700">i-ESG 신년 특별 프로모션</div>
          </div>
          <div class="content Pretendard-700">
            5분 투자로 ESG 진단하고, <br />
            100% 무료<span class="green-content"> 기업 ESG 분석 리포트 </span>와 <br />
            1,800만원 상당의<span class="green-content"> 50% 할인 바우처 </span> 받아보세요
          </div>
          <router-link to="/promotions">
            <button @click="goToPromotion" class="promotion-button">
              <span class="promotion-button-text Pretendard-700"> 지금 시작하기</span>
            </button>
          </router-link>
        </div>
        <div class="entrance-view" v-if="language == 'en'">
          <div>
            <div class="title-overheader Pretendard-700">
              Explore Essential Indicators to Align with Global ESG Standards
            </div>
            <div class="entrance-title Pretendard-700">2024 New Year Promo</div>
          </div>
          <div class="content Pretendard-700">
            Take 5min ESG Assessment, <br />
            Get a Free <span class="green-content"> ESG Analysis Report</span><br />
            and a<span class="green-content"> 50% Off Voucher </span>
          </div>
          <router-link to="/promotions">
            <button @click="goToPromotion" class="promotion-button">
              <span class="promotion-button-text Pretendard-700"> Check Now</span>
            </button>
          </router-link>
        </div>
        <div class="assessment-background"></div>
      </Slide> -->
      <Slide class="main-page">
        <video
          src="../../assets/video/Homevideo_ver2.mp4"
          type="video/mp4"
          autoplay
          loop
          muted
        ></video>
        <div class="section" id="page1">
          <div class="inner">
            <section v-if="language == 'ko'" class="page1_title">
              <div class="Pretendard-700 home-title">
                <span
                  v-for="(t, index) in homeText1"
                  :key="index"
                  class="item"
                  :style="{ animationDelay: 100 + index * 400 + 'ms' }"
                  v-text="t"
                /><br />
                <span
                  v-for="(t, index) in textArray"
                  :key="index"
                  class="item"
                  :class="{
                    'home-title-color':
                      t === 'E' ||
                      t === 'S' ||
                      t === 'G' ||
                      t === '디' ||
                      t === '지' ||
                      t === '털' ||
                      t === '관' ||
                      t === '리'
                  }"
                  :style="{ animationDelay: 100 + index * 100 + 'ms' }"
                  v-text="t"
                /><br />
                <span
                  v-for="(t, index) in homeText3"
                  :key="index"
                  class="item"
                  :style="{ animationDelay: 100 + index * 200 + 'ms' }"
                  v-text="t"
                />
              </div>
              <div v-if="language == 'ko'" class="demo-button-contain">
                <button type="button" @click="modalshow = true" class="Pretendard-700 button">
                  데모 요청
                </button>
              </div>
            </section>

            <section v-if="language == 'en'" class="page1_title">
              <h1 class="Pretendard-700 home-title" data-aos="fade-up">
                Ultimate <span class="home-title-color">ESG digital solutions</span> <br />that
                assist your ESG journey
              </h1>
              <div data-aos="fade-right" data-aos-delay="200">
                <button type="button" @click="modalshow = true" class="Pretendard-700 button">
                  Request a demo
                </button>
              </div>
            </section>

            <div v-if="language == 'ko'" class="flex_row">
              <section class="page1_bottom">
                <div
                  class="home-box Pretendard-600"
                  v-for="(index, i) in 4"
                  :key="i"
                  :style="{ animationDelay: 100 + i * 300 + 'ms' }"
                >
                  <img class="box-img" :src="homeBox2.box.img[i]" alt="" />
                  <p class="box-title" v-html="homeBox2.box.title[i]"></p>
                </div>
              </section>

              <!-- max-width: 780px page1_bottom -->
              <section class="page1_bottom-T">
                <div class="squareT Pretendard-500" v-for="(index, i) in 4" :key="i">
                  <p v-if="windowWidth > 700" class="text1" v-html="homeBox2.box.titleTablet[i]" />
                  <p
                    v-if="windowWidth < 700"
                    class="text1 mobile"
                    v-html="homeBox2.box.titleMobile[i]"
                  />
                </div>
              </section>
            </div>

            <div v-if="language == 'en'" class="flex_row" data-aos="fade-up" data-aos-delay="300">
              <section class="page1_bottom">
                <div
                  class="home-box Pretendard-600"
                  v-for="(index, i) in 4"
                  :key="i"
                  :style="{ animationDelay: 100 + i * 300 + 'ms' }"
                >
                  <img class="box-img" :src="homeBox2.box.img[i]" alt="" />
                  <p class="box-titleEn" v-html="homeBox2.box.titleEng[i]"></p>
                </div>
              </section>

              <!-- max-width: 780px page1_bottom -->
              <section class="page1_bottom-T">
                <div class="squareT">
                  <p class="text1">
                    Efficient ESG management<br />without any expertise and experience
                  </p>
                </div>
                <div class="squareT">
                  <p class="text2">Easy & fast collaboration<br />for ESG data management</p>
                </div>
                <div class="squareT">
                  <p class="text3">
                    Optimized solutions<br />
                    with AI-driven technology
                  </p>
                </div>
                <div class="squareT">
                  <p class="text4">Enhanced security and transparency with blockchain technology</p>
                </div>
              </section>
            </div>
            <div v-if="windowWidth > 700" class="home-arrow-down">
              <img src="../../assets/imgs/home-arrow-down.svg" alt="" />
            </div>
          </div>
        </div>
      </Slide>
      <template #addons="{ slidesCount }">
        <Navigation v-if="slidesCount > 1" />
      </template>
    </Carousel>
    <div class="section" id="page2">
      <div v-if="language == 'ko'" class="inner">
        <h5 class="color-title" data-aos="fade-right" data-aos-offset="300">i-ESG</h5>
        <h2 class="title" data-aos="fade-left" data-aos-offset="300">
          기업의 관점에서 생각하고<br />
          기업의 Pain Point에만<br class="mobile" />
          집중합니다.
        </h2>
        <p class="text" data-aos="fade-up" data-aos-offset="300">
          ESG 분야는 'Good To'에서 'Must' 시장으로 급격히 전환중이며, 기업들에게는 '생존'에 직결되는
          요소가 되었습니다.<br />
          반면, 기업들은 여전히 여러가지 제약으로 적절하게 대응하지 못하고 있습니다.<br />
          i-ESG는 막연했던 ESG 관리를 보다 편리하고 쉽게 관리할 수 있도록 돕습니다.
        </p>
        <p class="text-M">
          ESG 분야는 'Good To'에서 'Must' 시장으로 급격히 전환중이며, 기업들에게는 '생존'에 직결되는
          요소가 되었습니다. i-ESG는 막연했던 ESG 관리를 보다 편리하고 쉽게 관리할 수 있도록
          돕습니다.
        </p>
        <div class="box1" data-aos="fade-up" data-aos-offset="200">
          <section class="boxflex">
            <div>ESG 자가진단</div>
            <div>ESG 리포트자동화</div>
            <div>맞춤형 관리 대시보드</div>
          </section>
          <section class="boxflex">
            <div>최신 동향 및 비즈니스 사례</div>
            <div>공급망 관리</div>
            <div>온실가스 관리<span>(준비중)</span></div>
          </section>
          <section class="more">
            <a href="/about" class="click Pretendard-600">
              더 알아보기
              <hr />
            </a>
          </section>
        </div>
        <div class="box">
          <section class="boxflex">
            <div>ESG 자가진단</div>
            <div>ESG 리포트자동화</div>
          </section>
          <section class="boxflex">
            <div>맞춤형 관리 대시보드</div>
            <div>최신 동향 및 비즈니스 사례</div>
          </section>
          <section class="boxflex">
            <div>공급망 관리</div>
            <div>온실가스 관리<span>(준비중)</span></div>
          </section>
          <section class="more">
            <a href="/about" class="click Pretendard-600">
              더 알아보기
              <hr />
            </a>
          </section>
        </div>
      </div>

      <div v-if="language == 'en'" class="inner">
        <h5 class="color-title" data-aos="fade-right" data-aos-offset="300">i-ESG</h5>
        <h2 class="title" data-aos="fade-left" data-aos-offset="300">
          i-ESG focuses on the pain points<br />
          and comes up with tailored<br class="mobile" />
          solutions for businesses.
        </h2>
        <p class="text" data-aos="fade-up" data-aos-offset="300">
          <b style="font-family: 'Pretendard-SemiBold'">ESG is now a ‘MUST’.</b><br />
          ESG is a rapidly growing regulated sector, and many companies are not effectively dealing
          with the ESG requirements due to practical barriers.<br /><br />
          Dedicated to all companies dealing with ESG obscurity and complexity, i-ESG provides handy
          software tools for your convenience.
        </p>
        <p class="text-M">
          ESG 분야는 'Good To'에서 'Must' 시장으로 급격히 전환중이며, 기업들에게는 '생존'에 직결되는
          요소가 되었습니다. i-ESG는 막연했던 ESG 관리를 보다 편리하고 쉽게 관리할 수 있도록
          돕습니다.
        </p>
        <div class="box1" data-aos="fade-up" data-aos-offset="200">
          <section class="boxflex">
            <div>ESG Assessment</div>
            <div>ESG Reporting Automation</div>
            <div>Customized Dashboards</div>
          </section>
          <section class="boxflex">
            <div style="text-align: center">ESG Trends and Business Cases</div>
            <div style="text-align: center">Supply Chain Management</div>
            <div>GHG Management<span>(Soon)</span></div>
          </section>
          <section class="more">
            <a href="/about" class="click Pretendard-600">
              Learn More
              <hr />
            </a>
          </section>
        </div>
        <div class="box">
          <section class="boxflex">
            <div>ESG Assessment</div>
            <div>ESG Reporting Automation</div>
          </section>
          <section class="boxflex">
            <div>Customized Dashboards</div>
            <div>ESG Trends and Business Cases</div>
          </section>
          <section class="boxflex">
            <div>Supply Chain Management</div>
            <div>GHG Management<span>(Soon)</span></div>
          </section>
          <section class="more">
            <a href="/about" class="click Pretendard-600">
              Learn More
              <hr />
            </a>
          </section>
        </div>
      </div>
    </div>

    <div class="section" id="page3">
      <div v-if="language == 'ko'" class="inner">
        <p class="color-title" data-aos="fade-right" data-aos-offset="300">Integrated</p>
        <h1 class="title" data-aos="fade-left" data-aos-offset="300">
          i-ESG는 ESG에 특화된<br />
          데이터 기반 통합 디지털 관리<br class="mobile" />
          플랫폼 입니다.
        </h1>
        <div class="img-T" />
        <div class="flex_row">
          <div class="flex_cloumn" data-aos="fade-up" data-aos-offset="300">
            <h3 class="text_title">수작업 방식의 디지털 전환</h3>
            <p class="text">
              정보를 수집, 해석, 적용하고 관리하는<br />
              모든 복잡한 작업을 디지털로 통합 관리합니다.
            </p>
            <p class="text-T">
              정보를 수집, 해석, 적용하고 관리하는 모든 복잡한<br class="display-off" />
              작업을 디지털로 통합 관리합니다.
            </p>
            <section>
              <h3 class="text_title">직관적이고 효율적인 인터페이스</h3>
              <p class="text">
                ESG 지식이나 경험이 없더라도 클릭만으로<br class="display-off" />
                보고서를 생성하고 관리하여 보다 효과적으로 관리합니다.
              </p>
            </section>
            <section>
              <h3 class="text_title">기업고객의 현실을 고려한 합리적인 가격정책</h3>
              <p class="text">
                기존 대비 최대 95% 저렴한 비용으로 보다 체계적인 ESG 관리가 가능합니다.<br />
                <span>(니즈에 맞는 세분화된 가격정책 제공)</span>
              </p>
            </section>
          </div>
          <div class="img" />
        </div>
      </div>

      <div v-if="language == 'en'" class="inner">
        <p class="color-title" data-aos="fade-right" data-aos-offset="300">Integrated</p>
        <h1 class="title" data-aos="fade-left" data-aos-offset="300">
          i-ESG is a data-driven integrated digital management platform<br />
          specializing in ESG.
        </h1>
        <div class="img-T" />
        <div class="flex_row">
          <div class="flex_cloumn" data-aos="fade-up" data-aos-offset="300">
            <h3 class="text_title">Digital Transformation of ESG management</h3>
            <p class="text">
              i-ESG software turns all the inefficient manual work of collecting,<br />
              interpreting, and managing ESG data into collective digital workflows.
            </p>
            <section>
              <h3 class="text_title">Intuitive Interface Enhanced Efficiency</h3>
              <p class="text">
                i-ESG software creates and manages your sustainable ESG reports<br />and numerous
                data points regardless of your ESG knowledge,<br />
                with just a few clicks.
              </p>
            </section>
            <section>
              <h3 class="text_title">Reasonable Pricing to Lower Your Burden</h3>
              <p class="text">
                i-ESG software lowers the cost of ESG management by up to 95%<br />
                as we introduce reasonable pricing policies that fit our client’s needs.
              </p>
            </section>
          </div>
          <div class="img" />
        </div>
      </div>
    </div>
    <div class="section" id="page4">
      <div v-if="language == 'ko'" class="inner">
        <p class="color-title" data-aos="fade-right" data-aos-offset="300">Innovative Solution</p>
        <h1 class="title" data-aos="fade-left" data-aos-offset="300">
          최신 기술을 활용하여<br class="mobile" />가장 효율적이고<br />
          <br class="mobile" />
          신뢰할만한 솔루션을 제공합니다.
        </h1>
        <div class="img-T" />
        <div class="flex_row">
          <div class="flex_cloumn" data-aos="fade-up" data-aos-offset="300">
            <h3 class="text_title">인공지능을 통한 최적 관리</h3>
            <p class="text">
              자체 고안된 AI 알고리즘은 국내외 수많은 기준/동향/규제와<br />
              관련한 DB를 구축,학습하여 최적화된 솔루션을 제공합니다.
            </p>
            <p class="text-T">
              국내외 수많은 기준/동향/규제와 관련한 DB를 구축, 학습하여 최적화된 솔루션을
              제공합니다.
            </p>
            <section>
              <h3 class="text_title">블록체인을 통한 정보 보안</h3>
              <p class="text">
                블록체인 기술을 적용하여 회사의 민감한 정보를 보안하고<br />
                주요 이해관계자들과의 소통에 신뢰성 및 투명성을 제공합니다.
              </p>
              <p class="text-T">
                블록체인 기술을 적용하여 회사의 민감한 정보를 보안하고, 소통에 신뢰성을 제공합니다.
              </p>
            </section>
          </div>
          <div class="img" />
        </div>
      </div>

      <div v-if="language == 'en'" class="inner">
        <p class="color-title" data-aos="fade-right" data-aos-offset="300">Innovative Solution</p>
        <h1 class="title" data-aos="fade-left" data-aos-offset="300">
          i-ESG offers the most efficient and reliable ESG solutions<br />
          with<br class="mobile" />
          the latest AI and big-data technology.
        </h1>
        <div class="img-T" />
        <div class="flex_row">
          <div class="flex_cloumn" data-aos="fade-up" data-aos-offset="300">
            <h3 class="text_title">
              Optimal Management <br />
              Driven by AI & Big-data Technology
            </h3>
            <p class="text">
              Based on global standards, trends, and regulations,<br />
              the i-ESG AI algorithm updates database<br />
              and offers optimized solutions tailored to your business.
            </p>
            <p class="text-T">
              Based on global standards, trends, and regulations,<br />
              the i-ESG AI algorithm updates database<br />
              and offers optimized solutions tailored to your business.
            </p>
            <section>
              <h3 class="text_title">
                Data Security Strengthened<br />
                with Blockchain Technology
              </h3>
              <p class="text">
                i-ESG secures sensitive data<br />
                while building reliability and transparency among stakeholders<br />
                via blockchain technology.
              </p>
              <p class="text-T">
                i-ESG secures sensitive data<br />
                while building reliability and transparency among stakeholders<br />
                via blockchain technology.
              </p>
            </section>
          </div>
          <div class="img_en" />
        </div>
      </div>
    </div>
    <div class="section" id="page5">
      <div v-if="language == 'ko'" class="inner">
        <p class="color-title" data-aos="fade-right" data-aos-offset="300">Intelligence</p>
        <h2 class="title" data-aos="fade-left" data-aos-offset="300">
          지속적인 인사이트를 공유함으로써<br />
          더 나은 생태계를 육성합니다.
        </h2>
      </div>

      <div v-if="language == 'en'" class="inner">
        <p class="color-title" data-aos="fade-right" data-aos-offset="300">Intelligence</p>
        <h2 class="title" data-aos="fade-left" data-aos-offset="300">
          i-ESG creates a supportive ecosystem<br />
          by sharing market insights and trends.
        </h2>
      </div>

      <MyCarousel
        v-if="language == 'ko'"
        :ko_new_length="ko_new_length"
        :ext="ext"
        :ko="ko"
        :semi="semi"
        :tab="tab"
        :EduData="EduData"
        :imgSrc="imgSrc"
        :checking="checking"
        class="display-off"
        data-aos="fade-up"
        data-aos-offset="300"
        @moveTab="$emit('moveTab', 'ESG Education')"
        :Cnt="Cnt"
      />

      <MyCarousel_EN
        v-else-if="language == 'en' && glo_new_length > 0"
        :ko_new_length="glo_new_length"
        :ext="ext"
        :ko="glo"
        :semi="semi"
        :tab="tab"
        :EduData="EduData"
        :imgSrc="imgSrc"
        :checking="checkingEn"
        class="display-off"
        data-aos="fade-up"
        data-aos-offset="300"
        @moveTab="$emit('moveTab', 'ESG Education')"
        :Cnt="CntEn"
      />

      <CarouselT
        :ko_new_length="ko_new_length"
        :ext="ext"
        :imgSrc="imgSrc"
        :ko="ko"
        :semi="semi"
        :tab="tab"
        :Cnt="Cnt"
        :EduData="EduData"
        @moveTab="$emit('moveTab', 'ESG Education')"
      />

      <!-- 
        <MyCarousel_EN v-if="language == 'en'" :ext="ext" :ko="ko" :glo="glo" :semi="semi" :tab="tab" :EduData="EduData" :imgSrc="imgSrc" :checking="checking" class="display-off" data-aos="fade-up" data-aos-offset="300" @moveTab="$emit('moveTab', 'ESG Education')" :Cnt="Cnt" />
        <CarouselT :ext="ext" :imgSrc="imgSrc" :ko="ko" :semi="semi" :tab="tab" :Cnt="Cnt" :EduData="EduData" @moveTab="$emit('moveTab', 'ESG Education')" /> -->
    </div>
    <div class="section" id="page6">
      <div v-if="language == 'ko'" class="cover">
        <h1>효율적인 ESG 대응 및 관리, 지금 시작해보세요</h1>
        <button @click="modalshow = true" class="Pretendard-700 button">데모 신청</button>
      </div>

      <div v-if="language == 'en'" class="cover">
        <h1>Start your efficient ESG journey, right now.</h1>
        <button @click="modalshow = true" class="Pretendard-700 button">Request a demo</button>
      </div>
      <ContactModal
        v-if="modalshow && language == 'ko'"
        :modalshow="modalshow"
        @closemodal="modalshow = false"
        :modaldata="modaldata"
      />
      <ContactModal
        v-if="modalshow && language == 'en'"
        :modalshow="modalshow"
        @closemodal="modalshow = false"
        :modaldata="modaldataEN"
        :language="language"
      />
    </div>
    <!-- <div class="section" id="page6">
        <div class="img-shadow">
          <transition name="fade">
            <img v-show="iesg[iesgNum].num == iesgNum" :src="iesg[iesgNum].image_url" alt="">
          </transition>
        </div>
        <a :href="iesg[iesgNum].link" target="_blank">
          <div class="cover">
              <div v-if="iesg[iesgNum].num == iesgNum" class="newsTextInner">
                <h1>{{iesg[iesgNum].title}}</h1>
                <h5>" {{iesg[iesgNum].preview}} "</h5>
                <p>- {{iesg[iesgNum].date}} {{iesg[iesgNum].news}} -</p>
              </div>
          </div>
        </a>
      </div> -->
  </div>
</template>

<script>
import MyCarousel from '../Carousel/MyCarousel.vue'
import MyCarousel_EN from '../Carousel/MyCarousel_EN.vue'
import CarouselT from '../Carousel/CarouselT.vue'
import iesgs from '../../assets/js/iesg.json'
import iesg from '../../assets/js/iesg.json'
import ContactModal from '../../components/Modal/ContactModal.vue'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Home',
  components: {
    Carousel,
    Slide,
    Navigation,
    MyCarousel,
    CarouselT,
    ContactModal,
    MyCarousel_EN
  },
  props: {
    glo: Array,
    ko: Array,
    ext: Array,
    semi: Array,
    tab: String,
    EduData: Object,
    imgSrc: String,
    language: Array,
    ko_new_length: String,
    glo_new_length: String,
    news_en: Array
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      homeText1: '효과적인',
      homeText2: 'ESG 디지털 관리를 위한',
      textArray: [],
      homeText3: '최적의 솔루션',
      checking: [this.ko, this.semi, this.EduData, this.ko, this.semi, this.EduData],
      checkingEn: [this.ko, this.EduData, this.ko, this.EduData, this.ko, this.EduData],
      name: '',
      email: '',
      corporate: '',
      content: '',
      check: false,
      mouse: [false, true, true, true, true, true],
      Cnt: [3, 3, 1, 3, 3, 1],
      CntEn: [3, 1, 3, 1, 3, 1],
      iesgs: iesgs,
      iesg: iesg,
      iesgNum: 0,
      intervalTiming: 9000,
      modalshow: false,
      modaldata: {
        Mtitle: '데모 요청',
        Mname: '담당자 이름 / 직급 (이름과 직급 사이에 슬래쉬(/) 기호 입력 필요)',
        Mposition: '담당자 직급',
        Mcompany: '회사명',
        Mdepartment: '담당 부서',
        Mphone: '연락처',
        Memail: '이메일 주소',
        Mquestions: '문의사항',
        Mbutton: '문의하기',
        Mtype: '데모 요청'
      },
      modaldataEN: {
        Mtitle: 'Request a demo',
        Mname: 'Name / Position',
        Mposition: 'Position',
        Mcompany: 'Company Name',
        Mdepartment: 'Department',
        Mphone: 'Phone',
        Memail: 'Business Email',
        Mquestions: 'Message',
        Mbutton: 'Submit',
        Mtype: '홈페이지 문의사항(EN)'
      },
      homeBox: {
        box1: {
          title: '전문지식과 경험이 없어도<br>효율적인 ESG 정보 관리',
          img: require('../../assets/imgs/Home_img/box1-img.svg')
        },
        box2: {
          title: '협업을 통해 간단해지는<br>데이터 수집 및 관리',
          img: require('../../assets/imgs/Home_img/box2-img.svg')
        },
        box3: {
          title: '인공지능을 활용한<br>최적의 ESG 관리 솔루션',
          img: require('../../assets/imgs/Home_img/box3-img.svg')
        },
        box4: {
          title: '블록체인 기술을 활용한<br>보안, 신뢰성, 투명성 강화',
          img: require('../../assets/imgs/Home_img/box4-img.svg')
        }
      },
      homeBox2: {
        box: {
          title: [
            '전문지식과 경험이 없어도<br>효율적인 ESG 정보 관리',
            '협업을 통해 간단해지는<br>데이터 수집 및 관리',
            '인공지능을 활용한<br>최적의 ESG 관리 솔루션',
            '블록체인 기술을 활용한<br>보안, 신뢰성, 투명성 강화'
          ],
          img: [
            require('../../assets/imgs/Home_img/box1-img.svg'),
            require('../../assets/imgs/Home_img/box2-img.svg'),
            require('../../assets/imgs/Home_img/box3-img.svg'),
            require('../../assets/imgs/Home_img/box4-img.svg')
          ],
          titleTablet: [
            '전문지식과 경험이 없어도 효율적인 ESG 정보 관리',
            '협업을 통해 간단해지는 데이터 수집 및 관리',
            '전문지식과 경험이 없어도 효율적인 ESG 정보 관리',
            '블록체인 기술을 활용한 정보 보안, 신뢰성, 투명성 강화'
          ],
          titleMobile: [
            '전문지식과 경험이 없어도<br>효율적인 ESG 정보 관리',
            '협업을 통해 간단해지는<br>데이터 수집 및 관리',
            '전문지식과 경험이 없어도<br>효율적인 ESG 정보 관리',
            '블록체인 기술을 활용한 정보 보안,<br>신뢰성, 투명성 강화'
          ],
          titleEng: [
            'Efficient ESG management<br> without any expertise and experience',
            'Easy & fast collaboration<br> for ESG data management',
            'Optimized solutions<br>with AI-driven technology',
            'Enhanced security and transparency<br> with blockchain technology'
          ]
        }
      }
    }
  },
  methods: {
    ImgNum() {
      if (this.iesgNum < this.iesg.length) {
        this.iesgNum++
      }
      if (this.iesgNum == this.iesg.length) {
        this.iesgNum = 0
      }
    },
    console(e) {
      console.log(e)
    },
    handleResize() {
      this.windowWidth = window.innerWidth
    }
  },
  created() {
    // this.loading = setInterval(this.ImgNum, this.intervalTiming)
    this.textArray = this.homeText2.split('')
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>
<style scoped src="./home.css"></style>
