export default {
  "HomeMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embark on the ESG journey with our ESG ultimate digital solutions"])},
  "Demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a Demo"])},
  "HomeSol1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficient ESG Data management with our intuitive solution, no experience needed"])},
  "HomeSol2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple and easy collaborative data collection"])},
  "HomeSol3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamlining your ESG management with our AI-based solution"])},
  "HomeSol4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhancing security, reliability, and transparency through blockchain technology"])},
  "iESGUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At i-ESG, we concentrate solely on your(the) pain points from a client perspective"])},
  "iESGMid1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investors are now seeing ESG as a must-have, not just good to have, and are focusing more on companies with responsible business practicies"])},
  "iESGMid2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despite ongoing efforts, companies fail to respond to the changing demands"])},
  "iESGMid3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG simplifies ESG management, making it convenient and easy to navigate"])},
  "Function1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Assessment"])},
  "Function2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Reporting Automation"])},
  "Function3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized Management Dashboards"])},
  "Function4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The newest business trends and business examples/ Current ESG Trends and Case Studies"])},
  "Function5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supply Chain Management Module"])},
  "Function6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GHG Management (Soon to be)"])},
  "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information/Learn More"])},
  "IntegratedUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG is a specialized, data-driven platform for integrated digital management of ESG."])},
  "IntegratedMid1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automating manual tasks"])},
  "IntegratedMid2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With our digital tools, we unify and manage the complex tasks of collecting, interpreting, applying, and managing information."])},
  "IntegratedMid3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intuitive and simplified Interface"])},
  "IntegratedMid4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate ESG reports with a single click, no prior knowledge needed."])},
  "IntegratedMid5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasonable pricing that takes into account the circumstances of our corporate clients."])},
  "IntegratedMid6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieve more systematic ESG management at up to 95% lower cost than existing solutions"])},
  "IntegratedMid7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailored Pricing"])},
  "ISUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our solution utilizes the cutting-edge technologies of AI and blockchain to ensure maximum efficiency and security"])},
  "ISMid1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieve optimal management with the power of artificial intelligence."])},
  "ISMid2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimized solutions provided using our AI algorithm with a global standards database."])},
  "ISMid3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain for Information Security"])},
  "ISMid4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using blockchain secures sensitive information and fosters transparent communication with stakeholders. "])},
  "Inteliigience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We encourage the cultivation of sustainable ideas through the sharing of knowledge"])},
  "Homefin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin your journey towards efficient ESG management today."])},
  "Demofin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a Demo"])},
  "AboutusMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why ESG matters for everyone?"])},
  "Background1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Climate change, a central pillar of ESG, is far more serious than we may realize."])},
  "Background2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Climate crises like droughts, floods, earthquakes, and fires are happening globally and can be easily observed."])},
  "Background3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An UN IPCC report warns that the climate change risks are more severe than we thought, with melting of Arctic ice even with carbon neutrality by 2050. It emphasizes that to overcome the climate crisis, we need to focus on immediate reduction of GHG emissions instead of gradual improvements."])},
  "Background4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a growing sense of urgency, companies are feeling the pressure of increased regulation and demands."])},
  "Background5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The importance of ESG for a business has been brought a step closer to reality."])},
  "Background6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company A, a domestic exporting firm, recently received a letter from an European client."])},
  "Background7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The letter states that the evaluation of transactions will involve considering ESG factors in addition to price and quality."])},
  "Background8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG scores increasingly impact transaction, delivery, and investment decisions."])},
  "Background9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-world examples show a clear connection between ESG and improved financial performance."])},
  "Background10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't overlook this huge market."])},
  "Background11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investors worldwide, along with rating agencies and consumers, are increasingly backing corporate ESG management and taking practical steps to promote it."])},
  "Background12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG investment is predicted to triple to $12.15 trillion (KRW 14.7 quadrillion) by 2030, according to experts."])},
  "Background13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies cannot afford to ignore the demands of the capital market, given its sheer size and influence."])},
  "Background14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source: Global Sustainable Investment Alliance, Deutsche Bank, etc."])},
  "ProblemMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despite its importance, implementing effective ESG management can be a daunting task for companies."])},
  "ProblemMainbt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(The data collected has been compiled with responses from i-ESG clients and multiple enterprises)"])},
  "Problem1up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vagueness/Uncertainty"])},
  "Problem1down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigating ESG can be challenging due to its complex and multifaceted nature."])},
  "Problem2up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lack of skilled ESG professionals"])},
  "Problem2down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the early market stages, finding experts and acquiring professional capabilities can be challenging."])},
  "Problem3up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High cost and time-consuming"])},
  "Problem3down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report preparation alone can take 4-6 months, with consulting fees up to 300 million KRW."])},
  "Problem3bt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The average cost for large corportations"])},
  "Problem4up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lack of standards"])},
  "Problem4down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["300+ standards, frameworks, initiatives, and regulations"])},
  "Problem5up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inefficient manual practicies"])},
  "Problem5down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All ESG disclosures are manually collected, categorized, and analyzed"])},
  "VisionMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We envision a future where all companies adopt sustainable management practices."])},
  "MissionMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG provides ESG digital solutions by providing data-based support to companies in understanding, managing, and reporting on ESG."])},
  "DT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We enhance the limitations of manual work through digital means."])},
  "DT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With our digital tools, we unify and manage the complex tasks of collecting, interpreting, applying, and managing information."])},
  "AI1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our AI technology collects and analyzes vast amounts of standard, trend, and regulation databases to deliver the best solution for your needs."])},
  "AI2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our blockchain technology ensures secure and reliable management of sensitive information"])},
  "allinone1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG streamlines scattered ESG data management, providing a comprehensive solution."])},
  "allinone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG allows for effective and integrated management, from diagnosis to ongoing monitoring."])},
  "Socialimpactmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We strive to contribute to social issues by reflecting and growing with greater responsibility."])},
  "Socialimpactbody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We help companies address various global issues critical to their survival by alleviating ESG management limitations arising from information and resource imbalances. Furthermore, we support their growth towards becoming better companies by promoting ESG internalization."])},
  "DNA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate DNA"])},
  "DNAmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We deliver services customized to meet companies' specific needs and realities, from their perspective."])},
  "DNAbody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG was launched as an in-house venture of POSCO International, a leading trading company."])},
  "DNAbody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With over 50 years of diverse domestic and international business experience, understanding corporate perspectives and providing tailored solutions is ingrained in our DNA."])},
  "DNAbody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improving as a company goes beyond mere regulation and supervision."])},
  "DNAbody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG approaches ESG issues from the perspective of corporate clients, without evaluating our customers."])},
  "Ourteammain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At i-ESG, our strengths include understanding the pain points of corporate clients, data analysis capabilities, global networks, and IT development skills."])},
  "Media1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
  "Media2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG on media"])},
  "PartnersMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration is key to creating a better world and better companies."])},
  "PartnersSub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We want to express our gratitude to all our partners who have contributed to making the world a better place in their places."])},
  "PartnersSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving forward, we will continue to create a leap towards the future we all envision through excellent partnerships."])},
  "Parnersfin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us on our journey to create a better world with the help of more companies."])},
  "Partnersbt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership Inquiries"])},
  "SolutionMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our ESG data platform is built with a corporate perspective in mind."])},
  "SolutionSub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG management should not burden companies."])},
  "SolutionSub1fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At i-ESG, effective ESG management can be accomplished with no prior knowledge. We provide customized solutions for every stage."])},
  "SolutionSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG management is more than just promoting a High-performance"])},
  "SolutionSub2fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have implemented services that cover all aspects of ESG management. You can manage all ESG activities more effectively, efficiently, and affordably."])},
  "ESGDBMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG dashboard that enables continous improvements and management on your ESG goals"])},
  "DB1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated Management Dashboard"])},
  "DB1ft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An intuitive dashboard that displays detailed ESG history at a glance and enables easy management"])},
  "DB2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our ESG Scores"])},
  "DB2ft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our ESG Performance Data (By category)"])},
  "DB3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History Management and AI-based Improvement Feedback"])},
  "DB3ft1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Diagnosis, Management of Report History and Status of improvements"])},
  "DB3ft2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-driven feedback for improvement based on company information."])},
  "DB4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time Trend Analysis"])},
  "DB4ft1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizing Crawling and RPA techniques"])},
  "DB4ft2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domestic and international ESG news and regulatory information."])},
  "DB5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big data insight analysis"])},
  "DB5ft1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time collection of various open data from domestic and foreign sources"])},
  "DB5ft2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Trends and materiality issues"])},
  "DB6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good and bad examples"])},
  "DB6ft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive and Negative Sentiment Analysis using NLP with Text Analytics"])},
  "SolEv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Evaluation"])},
  "SolEvMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate your company's current ESG level with our quick and accurate diagnostic assessment."])},
  "Solex1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our diagnostic system incorporates the key components of major global reporting standards and is regularly updated."])},
  "Solex2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can effectively manage ESG using these criteria, regardless of prior knowledge."])},
  "Evcrit1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifying environmental, social, and governance (ESG) factors"])},
  "Evcrit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The biggest challenges facing our industry/ Major issues facing our industry/ the key issues of utmost importance for our industry"])},
  "Evcrit3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our company's ESG rating relative to industry peers"])},
  "Evcrit4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The specific areas that should be given more emphasis for future ESG management"])},
  "SolReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Automation/Automated Reporting"])},
  "SolReportMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's no need to spend a lot of time and money."])},
  "Re1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get all your ESG tasks done at once with i-ESG, eliminating the need for manual labor."])},
  "Re2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG uses AI algorithms to create a database of ESG relevant standards, trends, and policies from around the world, since thet are programmed to constantly learn via Maching Learning technology."])},
  "Re3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Say goodbye to manual ESG tasks and streamline everything with i-ESG"])},
  "Re4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG supports global ESG disclosure standards with writing guides and examples for easy reporting by anyone."])},
  "Memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We support various global ESG disclosure standards, including GRI, SASB, TCFD, UN SDGs, K-ESG and K-ESG for SMEs. Fact Sheet - These standards are updated regularly. (2022.12)"])},
  "CustomizedDB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalized/Customized Management Dashboards"])},
  "CustomizedDBMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To achieve better growth as a company, continuous ESG management is essential"])},
  "CustomziedDB1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide an integrated management dashboard for companies to manage ESG more easily and effectively."])},
  "CustomizedDB2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG aims to effectively address various ESG demands that companies may have in different fields, as and when they arise"])},
  "CustomizedDB3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["However, i-ESG has even more goals beyond that."])},
  "CustomizedDB4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We aim to support customers throughout their lifecycle, helping them overcome limitations and grow into better companies."])},
  "CustomizedDB5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our dashboard offers real-time tracking and monitoring of our company's status and history, along with a wide range of domestic and international trends in ESG-related issues."])},
  "CustomizedDB6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG adopts cutting-edge technologies, including AI and big data, to deliver objective and meaningful information."])},
  "SCM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supply Chain Management Module"])},
  "SCMMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We skillfully assess and manage the sustainability of our suppliers."])},
  "SCM1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG management is not only required for our company, but also for the entire supply chain."])},
  "SCM2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despite the need, companies often struggle to identify ESG risks and opportunities in their supply chains."])},
  "SCM3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We integrate and monitor our suppliers' sustainability with our ESG management through i-ESG software."])},
  "SCM4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantifying the risks and opportunities of our suppliers enables us to make quick and effective decisions regarding sustainability for the future"])},
  "SCM5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your supply chain portfolio in real-time with i-ESG software that reflects changing requirements."])},
  "ESGMan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized ESG management solutions optimized for your unique business environment"])},
  "ESGMan1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Data Management"])},
  "ESGMan1fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG collects and analyzes a vast amount of domestic and international data to provide ESG solutions optimized for a company's business environment."])},
  "ESGMan2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple data collection through collaboration"])},
  "ESGMan2fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG simplifies data collection and monitoring, freeing up your time for other responsibilities."])},
  "ESGMan3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An efficient ESG management environment"])},
  "ESGMan3fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work from anywhere, anytime with i-ESG, as long as you're connected to the internet."])},
  "ESGMan4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimize your ESG management with the latest technology"])},
  "ESGMan4fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We proficiently handle diverse ESG reporting requirements, stay up-to-date with trends and regulations, and manage sensitive information disclosures."])},
  "Solfin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start managing your ESG complexities now with i-ESG solution"])},
  "Finbt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Demo"])},
  "PricingMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the best ESG solution package for your company."])},
  "Essential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Core functions"])},
  "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top features that boost productivity"])},
  "Enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise-Targeted"])},
  "Enterpriseplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All advanced functions"])},
  "PricingESGDB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Dashboard"])},
  "PricingESGDBfun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Management System including ESG Management Status + Big Data Trends + Domestic and International ESG Trends + AI-Based Business Analytics"])},
  "Pricingtest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Assessment"])},
  "Pricingtestfun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data-based ESG Diagnostic Set + Dashboard + History Data Management + PDF Report"])},
  "Pricingmt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indentification of Material Issues"])},
  "Pricingmtfun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material Issue Management/Survey Solutions + Dashboard+History Data Management + PDF Report"])},
  "Pricingreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automating ESG Disclosure"])},
  "Pricingreportfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global ESG Disclosure Standards + AI-Based Recommendation + Automated Reporting (both Korean and English)"])},
  "PricingESGMg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Management"])},
  "PricingESGMgfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Data collection and Management + KPI Management + AI and Big Data Analytics + PDF Report"])},
  "PricingGHG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GHG Management"])},
  "PricingGHGfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GHG Data Collection and Management (Scope1~3) + Setting GHG Reduction Targets and its management"])},
  "PricingSCM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Supply Chain Management Module"])},
  "PricingSCMfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A diagnostic package(moddable) + Supply chain diagnostic +Dashboard+Administrative Reports"])},
  "DataINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Integration Services"])},
  "Support1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A networking event invitation"])},
  "Support2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trend Reports"])},
  "Support3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing free access to our specialized ESG paid course(both online and offline)"])},
  "Support4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-site due diligence"])},
  "Support5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Consulting"])},
  "Support6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Disclosure & Consulting"])},
  "Support7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution Utilization Consulting"])},
  "PromotionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion"])},
  "Promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take advantage of our promotion and get at least 10% off on additional Partner and Supply Chain solutions."])},
  "Promquestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We would be happy to assist you with any further questions."])},
  "PricingVeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Those are customers who have shown interest, and agreed to participate in beta testing,"])},
  "Pricinglist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The list will continue to be updated."])},
  "ClientsMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our customers, who are building a better world"])},
  "ClientsSub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe you're good companies that prioritize ESG practices."])},
  "ClientsSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your commitment to creating a better world and improving its business practices is commendable."])},
  "ClientsSub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We actively support and encourage companies in their proactive ESG management here at i-ESG."])},
  "Clientsmemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Those are customers who showed interest, and agreed to participate in beta testing. The list will continue to be updated."])},
  "Clientsbt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast-track the growth of your company with i-ESG."])},
  "iesgdemobox1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inflow Path"])},
  "iesgdemobox2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Articles"])},
  "iesgdemobox3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion Emails"])},
  "iesgdemobox4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
  "iesgdemobox5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
  "iesgdemobox6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking submit below, you consent to allow i-ESG to store and process the personal information submitted above to provide you with the content requested."])},
  "iesgdemobox7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job title"])},
  "iesgdemobox8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organization"])},
  "iesgdemobox9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your department"])},
  "iesgdemobox10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
  "iesgdemobox11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your telephone number"])},
  "iesgdemobox12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiries"])},
  "iesgbt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG is a digital service platform that leverages data to effectively manage the diverse needs of the rapidly expanding ESG sector."])},
  "iesgbt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG utilizes cutting-edge technologies to offer reliable and objective services that cater to the needs of businesses."])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["168, Yeoksam-ro, Gangnam-gu, Seoul, Republic of Korea"])}
}