<template>
  <div>
    <!-- <div class="about-p1">
      <div class="inner">
        <hr />
        <h4 class="Pretendard-600">
          Who we are
        </h4>
      </div>
    </div> -->
    <pageSection1 v-show="this.$route.name === 'About'" :language="language" />
    <div class="about-p2">
      <div v-if="language == 'ko'" class="inner">
        <section class="about-p2__title">
          <p class="color-title" data-aos="fade-right" data-aos-offset="200">Background</p>
          <h2 class="Pretendard-700" data-aos="fade-left" data-aos-offset="200">
            <span class="mobile-dis-off">한번 쯤은 들어봤을 ESG,<br /></span>
            왜 모두가 ESG를<br class="mobile" />
            이야기할까요?
          </h2>
          <section class="content-1" data-aos="fade-left" data-aos-offset="300">
            <div class="flex__column content-box">
              <h2 class="title">
                ESG의 대표적인 범주 ‘기후변화’,<br />
                우리가 생각하는 것 보다 훨씬 심각합니다.
              </h2>
              <p class="text">
                이미 전 세계 곳곳에서 가뭄, 홍수, 지진, 화재 등 기후변화의 위기들을 쉽게 찾아볼 수
                있습니다.<br />
                UN 산하 정부간 협의체 IPCC는 8년만에 발간한 보고서에서 2050년 탄소중립을
                달성하더라도<br />
                북극 의 빙하가 한번은 전부 녹을 것이며, 기후변화 위기를 극복하기 위해서 단계별
                개선이 아닌<br />
                ‘즉각적인 온실가스의 감축’이 반드시 필요하다고 강조했습니다.<br />
                이러한 시급성으로 기업들을 향한 요구와 규제가 확대되고 있습니다.
              </p>
            </div>
          </section>
          <section class="content-1-tablet">
            <div class="flex__column content-box">
              <h2 class="title">
                ESG의 대표적인 범주 ‘기후변화’,<br />
                우리가 생각하는 것 보다 훨씬 심각합니다.
              </h2>
              <p class="text">
                UN 산하 정부간 협의체 IPCC는 8년만에 발간한 보고서에서 2050년 탄소중립을
                달성하더라도 북극의 빙하가 한번은 전부 녹을 것이며, ‘즉각적인 온실가스의 감축’이
                반드시 필요하다고 강조했습니다. 이러한 시급성으로 기업들을 향한 요구와 규제가
                확대되고 있습니다.
              </p>
            </div>
          </section>
          <section class="content-mobile">
            <div class="flex__column">
              <h2 class="title">
                ESG의 대표적인 범주 ‘기후변화’,<br />
                우리가 생각하는 것 보다 훨씬 심각합니다.
              </h2>
              <p class="text">
                IPCC는 8년만에 발간한 보고서에서 2050년 탄소중립을 달성하더라도 북극의 빙하가 한번은
                전부 녹을 것이며, ‘즉각적인 온실가스의 감축’을 강조했습니다. 이러한 시급성으로
                기업들을 향한 요구와 규제가 확대되고 있습니다.
              </p>
            </div>
          </section>
          <section class="content-2" data-aos="fade-right" data-aos-offset="300">
            <div class="flex__column content-box">
              <h2 class="title">이미 우리 기업들의 현실이 되었습니다.</h2>
              <p class="text">
                최근 국내 수출기업 A사는 유럽의 고객사로부터 레터를 받았습니다.<br />
                기존 가격, 품질과 더불어 ESG 요소로 거래 여부를 평가하겠다는 내용이 담겨있습니다.<br />
                ESG 결과에 따라 거래가 중단되고, 납품이 배제되고, 투자가 철회되는 사례들.<br />
                ESG 경영과 기업의 수익성이 직결되는 요소라는 다양한 실 사례는 이미 너무나 많습니다.
              </p>
              <p class="text-tablet">
                최근 국내 수출기업 A사는 유럽의 고객사로부터 레터를 받았습니다. 기존 가격, 품질과
                더불어 ESG 요소로 거래 여부를 평가하겠다는 내용이 담겨있습니다. ESG 결과에 따라
                거래가 중단되고, 납품이 배제되고, 투자가 철회되는 사례들. ESG 경영은 기업의 수익성이
                직결되는 요소라는 다양한 실 사례는 이미 너무나 많습니다.
              </p>
            </div>
          </section>
          <section class="content-mobile">
            <div class="flex__column">
              <h2 class="title">이미 우리 기업들의 현실이 되었습니다.</h2>
              <p class="text">
                최근 국내 수출기업 A사는 유럽의 고객사로부터 레터를 받았습니다. 기존 가격, 품질과
                더불어 ESG 요소로 거래 여부를 평가하겠다는 내용이 담겨있습니다. ESG 결과에 따라
                거래가 중단되고, 납품이 배제되고, 투자가 철회되는 사례들. ESG 경영은 기업의 수익성이
                직결되는 요소입니다.
              </p>
            </div>
          </section>
          <section class="content-3" data-aos="fade-left" data-aos-offset="300">
            <div class="flex__column content-box">
              <h2 class="title">절대 간과해서는 안 될, 큰 시장입니다.</h2>
              <p class="text">
                전 세계 많은 투자사, 평가사, 소비자들은 기업들의 ESG 경영을 지지하며, 이를 토대로
                실제적인 행동을 취하고 있습니다. '20년 기준 ESG 투자 규모는 약 40조 5천억달러 (한화
                약 4.9경원)으로 '30년까지 3배 이상 성장할 것이라는 전문가들의 전망이 있었습니다.
                자본시장의 요구, 이미 너무나 큰 시장 규모는 기업들이 결코 간과해서는 안되는
                시장입니다.<br /><span>출처: 글로벌지속가능투자연합, 도이치 뱅크 등</span>
              </p>
              <p class="text-tablet">
                전 세계 많은 투자사, 평가사, 소비자들은 기업들의 ESG 경영을 지지하며, 이를 토대로
                실제적인 행동을 취하고 있습니다. '20년 기준 ESG 투자 규모는 약 40조 5천억달러 (한화
                약 4.9경원)으로 '30년까지 3배 이상 성장할 것이라는 전문가들의 전망이 있었습니다.
                기업들이 결코 간과해서는 안될 규모의 시장입니다.<br />
                <span>출처: 글로벌지속가능투자연합, 도이치 뱅크 등</span>
              </p>
            </div>
          </section>
          <section class="content-mobile">
            <div class="flex__column">
              <h2 class="title">절대 간과해서는 안 될, 큰 시장입니다.</h2>
              <p class="text">
                전 세계 많은 투자사, 평가사, 소비자들은 기업들의 ESG 경영을 지지하며, 이를 토대로
                실제적인 행동을 취하고 있습니다. '20년 기준 ESG 투자 규모는 약 40조 5천억달러 (한화
                약 4.9경원)으로 '30년까지 3배 이상 성장할 것이라는 전문가들의 전망이 있었습니다.
              </p>
            </div>
          </section>
        </section>
      </div>

      <div v-if="language == 'en'" class="inner">
        <section class="about-p2__title">
          <p class="color-title" data-aos="fade-right" data-aos-offset="200">Background</p>
          <h2 class="Pretendard-700" data-aos="fade-left" data-aos-offset="200">
            Why should we care about ESG?
          </h2>
          <section class="content-1" data-aos="fade-left" data-aos-offset="300">
            <div class="text_display content-box">
              <h2 class="title">
                The climate crisis is one of the pressing threats<br />
                that humanity faces
              </h2>
              <p class="textEn">
                Climate change like wildfires, floods, and earthquakes has already brought
                disastrous impacts all over the world,<br />
                followed by expanding immediate action required over businesses.<br /><br />

                The IPCC report stressed immediate GHG reduction is crucial,<br />
                and with consistent conditions, the Arctic glaciers will melt away even under carbon
                neutrality status by 2050.<br />
                In this context, we should care about ESG more, and quickly seek the right
                solutions.
              </p>
            </div>
          </section>
          <section class="content-1-tablet">
            <div class="flex__column content-box">
              <h2 class="title">
                The climate crisis is one of the pressing threats<br />
                that humanity faces
              </h2>
              <p class="textEn">
                Climate change like wildfires, floods, and earthquakes has already brought
                disastrous impacts all over the world, followed by expanding immediate action
                required over businesses. The IPCC report stressed immediate GHG reduction is
                crucial, and with consistent conditions, the Arctic glaciers will melt away even
                under carbon neutrality status by 2050. In this context, we should care about ESG
                more, and quickly seek the right solutions.
              </p>
            </div>
          </section>
          <section class="content-mobile">
            <div class="flex__column content-box">
              <h2 class="title">
                The climate crisis is one of the pressing<br />
                threats that humanity faces
              </h2>
              <p class="textEn">
                Climate change like wildfires, floods, and earthquakes has already brought
                disastrous impacts all over the world, followed by expanding immediate action
                required over businesses. The IPCC report stressed immediate GHG reduction is
                crucial, and with consistent conditions, the Arctic glaciers will melt away even
                under carbon neutrality status by 2050. In this context, we should care about ESG
                more, and quickly seek the right solutions.
              </p>
            </div>
          </section>
          <section class="content-2" data-aos="fade-right" data-aos-offset="300">
            <div class="flex__column content-box">
              <h2 class="title">
                ESG performance directly impacts <br />
                a company's profitability
              </h2>
              <p class="textEn">
                Recently, one of our clients received a letter from a European company,<br />
                requesting the ESG status to decide on partnership subsist.<br />
                There are increasing cases of investment withdrawal, or transaction suspension<br />
                due to a lack of impact on ESG preparedness.
              </p>
              <p class="text-tablet">
                Recently, one of our clients received a letter from a European company requesting
                the ESG status to decide on partnership subsist. There are increasing cases of
                investment withdrawal, or transaction suspension due to a lack of impact on ESG
                preparedness.
              </p>
            </div>
          </section>
          <section class="content-mobile">
            <div class="flex__column">
              <h2 class="title">
                ESG performance directly impacts <br />
                a company's profitability
              </h2>
              <p class="text">
                Recently, one of our clients received a letter from a European company requesting
                the ESG status to decide on partnership subsist. There are increasing cases of
                investment withdrawal, or transaction suspension due to a lack of impact on ESG
                preparedness.
              </p>
            </div>
          </section>
          <section class="content-3" data-aos="fade-left" data-aos-offset="300">
            <div class="flex__column content-box">
              <h2 class="title">
                ESG performance emerges <br />
                as a key indicator in capital markets.
              </h2>
              <p class="textEn">
                Many investors, rating agencies, and consumers support ESG management<br />
                and are taking practical action in earnest.<br />
                As of 2020, ESG investment reached 40.5 trillion dollars, and experts predicted<br />
                a triple times increase in investment by 2030.<br /><br />

                ESG management should no longer be overlooked,<br />
                and companies should adapt to the rapid change of new business environment.
                <!-- <span>
                    Source: Global Sustainable Investment Alliance, Deutsche Bank
                  </span> -->
              </p>
              <p class="text-tablet">
                Many investors, rating agencies, and consumers support ESG management and are taking
                practical action in earnest. As of 2020, ESG investment reached 40.5 trillion
                dollars, and experts predicted a triple times increase in investment by 2030. ESG
                management should no longer be overlooked, and companies should adapt to the rapid
                change of new business environment.<br />
                <span> Source: Global Sustainable Investment Alliance, Deutsche Bank </span>
              </p>
            </div>
          </section>
          <section class="content-mobile">
            <div class="flex__column">
              <h2 class="title">
                ESG performance emerges as a key<br />
                indicator in capital markets.
              </h2>
              <p class="text">
                Many investors, rating agencies, and consumers support ESG management and are taking
                practical action in earnest. As of 2020, ESG investment reached 40.5 trillion
                dollars, and experts predicted a triple times increase in investment by 2030. ESG
                management should no longer be overlooked, and companies should adapt to the rapid
                change of new business environment.<br />
                <span> Source: Global Sustainable Investment Alliance, Deutsche Bank </span>
              </p>
            </div>
          </section>
        </section>
      </div>
    </div>
    <div class="about-p3">
      <div v-if="language == 'ko'" class="inner">
        <section class="flex_column">
          <h5 class="color-title" data-aos="fade-right">Problem</h5>
          <h2 class="Pretendard-700 mobile-dis-off tablet-display-off" data-aos="fade-left">
            이러한 중요성에도 불구하고<br />
            ESG 관리 및 대응은 여전히 기업들에게 어려운 과제입니다.<br />
            <span>(i-ESG 고객인터뷰 포함 다수 기업 ESG 대응 설문조사 결과 취합)</span>
          </h2>
          <h2 class="Pretendard-700 tablet">
            이러한 중요성에도 불구하고<br />
            ESG 관리는 여전히 기업들에게 어려운 과제입니다.
          </h2>
          <h2 class="Pretendard-700 mobile">
            기업들에게 ESG 관리는<br />
            여전히 어려운 과제입니다.
          </h2>
          <div class="p3content" data-aos="fade-up" data-aos-offset="300">
            <div class="flex_cloumn_left">
              <div class="content-p3">
                <h3 class="title">막연함</h3>
                <p class="text">
                  너무 광범위한 분야,<br />
                  아는 것 만으로도 버거운 ESG
                </p>
              </div>
              <div class="content-p3">
                <h3 class="title">전문 역량의 부재</h3>
                <p class="text">
                  시장의 태동기, 전문가를 찾기도<br />
                  전문 역량을 갖추기도 쉽지 않은 현실
                </p>
              </div>
              <div class="content-p3">
                <h3 class="title">장시간 소요, 높은 비용</h3>
                <p class="text">
                  리포트 준비 작성 기간 4~6개월,<br />
                  컨설팅사 자문 비용 2억~3억<br />
                  <span class="mobile-dis-off">(대기업 평균)</span>
                </p>
              </div>
            </div>
            <div class="flex_cloumn_right">
              <div class="content-p3">
                <h3 class="title">표준의 부재</h3>
                <p class="text">
                  300여개가 넘는<br />
                  기준, 프레임워크, 이니셔티브, 규제
                </p>
              </div>
              <div class="content-p3">
                <h3 class="title">수작업의 비효율</h3>
                <p class="text">
                  ESG 공시를 위한<br />
                  자료수집, 분류, 분석 모두 수작업
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div v-if="language == 'en'" class="inner">
        <section class="flex_column">
          <h5 class="color-title" data-aos="fade-right">Problem</h5>
          <h2 class="Pretendard-700 mobile-dis-off tablet-display-off" data-aos="fade-left">
            Companies are still facing practical challenges<br />
            in ESG management
          </h2>
          <h2 class="Pretendard-700 tablet">
            Although the necessity of ESG management is increasing, companies are still facing
            practical challenges in ESG management
          </h2>
          <h2 class="Pretendard-700 mobile">
            There are many huddles to effective<br />
            ESG managment
          </h2>
          <div class="p3content" data-aos="fade-up" data-aos-offset="300">
            <div class="flex_cloumn_left">
              <div class="content-p3">
                <h3 class="title">Complexity</h3>
                <p class="textEn">Hard to define/identify ESG</p>
              </div>
              <div class="content-p3">
                <h3 class="title">Not enough Expertise</h3>
                <p class="textEn">
                  Hard to equip with appropriate expertise<br />
                  in a newly developing ESG market.
                </p>
              </div>
              <div class="content-p3">
                <h3 class="title">Time-Consuming and Too Expensive</h3>
                <p class="textEn">
                  Hard to afford lengthy and expensive manual processes<br />
                  of ESG management.
                </p>
              </div>
            </div>
            <div class="flex_cloumn_right">
              <div class="content-p3">
                <h3 class="title">No Standard</h3>
                <p class="textEn">
                  Hard to find an applicable standard<br />
                  among over 300 frameworks, initiatives, and regulations.
                </p>
              </div>
              <div class="content-p3">
                <h3 class="title">Inefficiency</h3>
                <p class="textEn">
                  Hard to deal with the fatigue of manual work<br />
                  from data collection to classification and analysis.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="about-p4">
      <div v-if="language == 'ko'" class="inner">
        <div class="color-title" data-aos="fade-up">Vision</div>
        <h3 class="title mobile-dis-off" data-aos="fade-up" data-aos-offset="300">
          모든 기업들이
          <span
            >지속가능한 전략을<br />
            수립하고, 실행하고, 효과적으로 알리는</span
          ><br />
          경영활동을 하는 모습을 상상합니다.
        </h3>
        <h3 class="title mobile">
          ESG를 보다 잘 알고, 잘 관리하고,<br />
          알릴 수 있도록 돕는 데이터 기반 <br />
          ESG 특화 통합 디지털 솔루션입니다.<br />
        </h3>
        <hr />
        <div class="color-title mobile-dis-off" data-aos="fade-up">Mission</div>
        <h5 class="title display-off" data-aos="fade-up" data-aos-offset="300">
          i-ESG는 기업들이 ESG를 보다 잘 알고, 잘 관리하고, 알릴 수 있도록 돕는<br />
          데이터 기반 ESG 특화 통합 디지털 솔루션 입니다.
        </h5>
        <h5 class="title tablet">
          i-ESG는 기업들이 ESG를<br />
          보다 잘 알고, 잘 관리하고, 알릴 수 있도록 돕는<br />
          데이터 기반 ESG 특화 통합 디지털 솔루션 입니다.
        </h5>
        <div class="text-contents" data-aos="fade-right" data-aos-offset="300">
          <h5 class="green-title">Digital Transformation</h5>
          <p class="text">
            수작업의 한계를 디지털로 보완합니다. 정보를 수집,<br class="mobile" />
            해석, 적용하고<br class="mobile-dis-off" />
            관리하는 모든 복잡한 작업을 디지털로 통합 관리합니다
          </p>
        </div>
        <div class="text-contents" data-aos="fade-left" data-aos-offset="300">
          <h5 class="green-title">AI/Blockchain Technology</h5>
          <p class="text">
            AI 기술로 수많은 기준/동향/규제 DB를 수집/분석하여<br class="mobile" />
            최적 솔루션을 제공합니다.<br />
            블록체인 기술로 민감한 정보를 보안하고, 신뢰성을 제공합니다.
          </p>
        </div>
        <div class="text-contents" data-aos="fade-right" data-aos-offset="300">
          <h5 class="green-title">Integrated All-in-One Solution</h5>
          <p class="text">
            흩어져있던 ESG 관리, i-ESG에서 한번에 해결합니다.<br />
            i-ESG 솔루션으로 진단부터 관리에 이르는<br class="mobile" />
            효과적인 통합 관리가 가능해집니다.
          </p>
        </div>
      </div>

      <div v-if="language == 'en'" class="inner">
        <div class="color-title" data-aos="fade-up">Vision</div>
        <h3 class="titleEn mobile-dis-off" data-aos="fade-up" data-aos-offset="300">
          Enable company customers<br />
          <span> to know, manage, and deliver their ESG better </span>
          for sustainable growth
        </h3>
        <h3 class="title mobile">
          i-ESG aims to empower every company
          <span>
            to set,<br />
            practice and market their ESG strategy </span
          ><br />
          ESG management.
        </h3>
        <hr />
        <div class="color-title mobile-dis-off" data-aos="fade-up">Mission</div>
        <h5 class="titleEn display-off" data-aos="fade-up" data-aos-offset="300">
          To become a customer-centric company,<br />
          where customers can derive sustainable growth <br />
          through our reasonable, realistic, and data-driven services
        </h5>
        <h5 class="title tablet">
          i-ESG provides an all-in-one solution specialized in ESG data-based technology for
          companies to know, manage, and deliver ESG better. i-ESG helps effective ESG management
          throughout your ESG journey from beginning to end.
        </h5>
        <div class="text-contents" data-aos="fade-right" data-aos-offset="300">
          <h5 class="green-title">Digital Transformation</h5>
          <p class="text">
            i-ESG helps you collect, interpret, and apply ESG simply from<br />
            a digital setting and improve the inefficiency of manual work.
          </p>
        </div>
        <div class="text-contents" data-aos="fade-left" data-aos-offset="300">
          <h5 class="green-title">AI/Blockchain Technology</h5>
          <p class="text">
            i-ESG helps you monitor numerous ESG regulations/trends<br />
            with AI/big-data technologies to offer optimal sustainability solutions.<br /><br />
            Blockchain technology improves transparency<br />
            and protects sensitive data.
          </p>
        </div>
        <div class="text-contents" data-aos="fade-right" data-aos-offset="300">
          <h5 class="green-title">Integrated All-in-One Solution</h5>
          <p class="text">
            i-ESG helps you gather scattered ESG fragments for your efficient management,<br />
            from ESG assessment to building sustainable ESG strategies.
          </p>
        </div>
      </div>
    </div>

    <div class="about-p4-1">
      <div v-if="language == 'ko'" class="inner">
        <h5 class="color-title" data-aos="fade-right">Social Impact</h5>
        <h2 class="Pretendard-700" data-aos="fade-left">
          <span class="display-off">우리는</span> 더 큰 책임감을 가지고<br class="mobile" />
          <span class="mobile-dis-off"
            >끊임없이<br class="tablet" />
            고민하고<br class="tablet-display-off" />
            준비하여</span
          >
          사회적 문제에<br class="tablet" />
          기여하는<br class="mobile" />
          회사로의 성장을 지향합니다.
        </h2>
        <p class="text Pretendard-400" data-aos="fade-in" data-aos-offset="300">
          정보와 자원 등의 불균형에 기인한 기업들의 ESG 대응한계를 해소하여,<br
            class="mobile-dis-off"
          />
          기업생존에 직결된 다양한 글로벌 이슈를 해결하고 나아가 ESG의 내재화를 도와<br
            class="tablet"
          />
          더 나은 기업으로의 성장을 지원합니다.
        </p>
        <div class="esg-image">
          <transition-group name="fade" mode="out-in">
            <img
              data-aos="fade-up"
              data-aos-offset="200"
              v-if="isOpen"
              :src="imgArray[imgCnt]"
              alt="UN SDG 글로벌 목표 4"
              id="img_id"
            />
            <img
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="200"
              v-if="isOpen"
              :src="imgArray[imgCnt + 1]"
              alt="UN SDG 글로벌 목표 10"
              id="img_id"
            />
            <img
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="400"
              v-if="isOpen"
              :src="imgArray[imgCnt + 2]"
              alt="UN SDG 글로벌 목표 12"
              id="img_id"
            />
            <img
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="600"
              v-if="isOpen"
              :src="imgArray[imgCnt + 3]"
              alt="UN SDG 글로벌 목표 17"
              id="img_id"
            />
          </transition-group>
        </div>
        <p class="subtext Pretendard-400" data-aos="fade-in" data-aos-delay="500">
          [i-ESG 실천 UN SDGs 핵심가치]
        </p>
      </div>

      <div v-if="language == 'en'" class="inner">
        <h5 class="color-title" data-aos="fade-right">Social Impact</h5>
        <h2 class="Pretendard-700" data-aos="fade-left">
          We support responsible growth<br />
          that constantly makes changes to a better society
        </h2>
        <p class="text Pretendard-400" data-aos="fade-in" data-aos-offset="300">
          Resolving limitations of information and resources imbalance for ESG response,<br />
          we contribute to dissolving global issues linked to business survival and help internalize
          ESG for becoming a better company.
        </p>
        <div class="esg-image">
          <transition-group name="fade" mode="out-in">
            <img
              data-aos="fade-up"
              data-aos-offset="200"
              v-if="isOpen"
              :src="imgArray_en[imgCnt]"
              alt="UN SDG 글로벌 목표 4"
              id="img_id"
            />
            <img
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="200"
              v-if="isOpen"
              :src="imgArray_en[imgCnt + 1]"
              alt="UN SDG 글로벌 목표 10"
              id="img_id"
            />
            <img
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="400"
              v-if="isOpen"
              :src="imgArray_en[imgCnt + 2]"
              alt="UN SDG 글로벌 목표 12"
              id="img_id"
            />
            <img
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="600"
              v-if="isOpen"
              :src="imgArray_en[imgCnt + 3]"
              alt="UN SDG 글로벌 목표 17"
              id="img_id"
            />
          </transition-group>
        </div>
        <p class="subtext Pretendard-400" data-aos="fade-in" data-aos-delay="500">
          [Core social values of i-ESG based on UN SDGs]
        </p>
      </div>
    </div>

    <div class="about-p5">
      <div v-if="language == 'ko'" class="inner">
        <div class="color-title">기업 관점 DNA</div>
        <h2 class="title">기업의 관점에서, 기업 현실에 맞는 서비스에 집중합니다.</h2>
        <div class="p5-contents">
          <div>
            <img
              src="../../assets/imgs/About_img/posco-logo.png"
              alt="posco logo"
              class="posco-logo"
            />
          </div>
          <div class="text display-off">
            <p>
              i-ESG는 대표종합상사인 포스코인터내셔널의 사내벤처로 출범되었습니다. <br />50여년 이상
              국내외 다양한 비즈니스 경험하며 기업들의 입장에서 현상을<br />
              바라보고 이에 맞는 솔루션을 찾는 것은 우리의 DNA가 되었습니다. <br /><br />
              더 나은 기업이 되는 것은 규제와 감시로 결코 도달할 수 없습니다. <br />i-ESG는 기업을
              평가하지 않습니다. 오직 기업들의 입장에서 생각합니다.
            </p>
          </div>
          <div class="text tablet">
            <p>
              i-ESG는 대표종합상사인 포스코인터내셔널의 사내벤처로 출범되었습니다. 50여년 이상
              국내외 다양한 비즈니스 경험하며 기업들의 입장에서 현상을 바라보고 이에 맞는 솔루션을
              찾는 것은 우리의 DNA가 되었습니다. 더 나은 기업이 되는 것은 규제와 감시로 결코 도달할
              수 없습니다. i-ESG는 기업을 평가하지 않습니다. 오직 기업들의 입장에서 생각합니다.
            </p>
          </div>
          <div class="text mobile">
            <p>
              i-ESG는 대표종합상사인 포스코인터내셔널의 사내벤처로 출범되었습니다. 50여년 이상의
              국내외 다양한 비즈니스 경험을 바탕으로 기업들의 입장에서 현상을 바라봅니다. 더 나은
              기업이 되는 것은 규제와 감시로 결코 도달할 수 없습니다. i-ESG는 오직 기업들의 입장에서
              생각합니다.
            </p>
          </div>
        </div>
      </div>

      <div v-if="language == 'en'" class="inner">
        <div class="color-title">Our DNA - Business-centered</div>
        <h2 class="title">
          We are on your side<br />
          We care what you need
        </h2>
        <div class="p5-contents">
          <div>
            <img
              src="../../assets/imgs/About_img/posco-logo.png"
              alt="posco logo"
              class="posco-logo"
            />
          </div>
          <div class="text display-off">
            <p>
              As an in-house venture under a Fortune 500 company,<br />
              i-ESG, with its extended ecosystem, clearly understands the businesses<br />
              and it has become our DNA to focus on the business side<br />
              and find solutions accordingly.<br /><br />
              Understanding your concerns,<br />
              we think beyond mere regulations and policies to advance sustainability<br />
              in the long-term value.
              <br /><br />
              i-ESG never tries to judge you, yet we listen to your voice of needs.<br />
              Improve ESG resilience with i-ESG
            </p>
          </div>
          <div class="text tablet">
            <p>
              As an in-house venture under a Fortune 500 company, i-ESG, with its extended
              ecosystem, clearly understands the businesses and it has become our DNA to focus on
              the business side and find solutions accordingly. Understanding your concerns, we
              think beyond mere regulations and policies to advance sustainability in the long-term
              value.
            </p>
          </div>
          <div class="text mobile">
            <p>
              As an in-house venture under a Fortune 500 company, i-ESG, with its extended
              ecosystem, clearly understands the businesses and it has become our DNA to focus on
              the business side and find solutions accordingly. Understanding your concerns, we
              think beyond mere regulations and policies to advance sustainability in the long-term
              value.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="about-p5-1">
      <div v-if="language == 'ko'" class="inner">
        <div class="color-title">About Company</div>
        <h2 class="title">
          i-ESG는 기업들이 직면한 ESG 대응한계를 데이터에 기반하여 해소하는 <br />
          ESG 특화 디지털 솔루션입니다.
        </h2>
        <div class="img-container">
          <img
            src="../../assets/imgs/About_img/AboutCompany.png"
            alt="AboutCompany"
            class="about-company-img"
          />
        </div>
        <!-- <div class="img-container">
          <img src="../../assets/imgs/About_img/AboutCompanyTable.png" alt="AboutCompany" class="about-company-img">
        </div> -->
      </div>
      <div v-if="language == 'en'" class="inner">
        <div class="color-title">About Company</div>
        <h2 class="title">
          i-ESG is an ESG-specific data-driven digital solution<br />
          to ease ESG challenges that businesses are facing.
        </h2>
        <div class="img-container">
          <img
            src="../../assets/imgs/About_img/AboutCompanyEn.png"
            alt="AboutCompany"
            class="about-company-img"
          />
        </div>
        <!-- <div class="img-container">
          <img src="../../assets/imgs/About_img/AboutCompanyTableEn.png" alt="AboutCompany" class="about-company-img">
        </div> -->
      </div>
    </div>

    <div class="about-p6">
      <div v-if="language == 'ko'" class="inner">
        <div class="color-title">Team i-ESG</div>
        <h2 class="title">풍부한 ESG 지식과 경험으로 구성된 i-ESG 팀</h2>
        <h2 class="subTitle Pretendard-500">
          ESG 분야 데이터 분석, SW 개발에 이르는 핵심 역량을 내재화 하였습니다
        </h2>
        <div class="intro-title">
          <h5 class="title">i-ESG <span class="greenText">대표</span></h5>
          <hr />
        </div>
        <div class="intro-profile">
          <div class="profile-img" />
          <div class="text">
            <div class="CEO_name">
              <p class="Pretendard-600 ceo-title">김종웅 (Bell Kim)<br /><br /></p>
              <!-- <a href="https://www.linkedin.com/in/bellkim/" target="__blank"><i class="fab fa-linkedin fa-lg link" /></a> -->
            </div>
            <p>현 i-ESG 대표 (포스코그룹 정식 사내벤처)</p>
            <p>
              국내대표 종합상사 실무 경력 15년 (35개국 내 대규모 B2B, B2G 인프라 투자 프로젝트 담당)
            </p>
            <p>
              MIT 슬론 경영대학원 MBA / MIT Sustainability Initiative 정식멤버 (관련 Certi 보유)
            </p>
            <p>미국 Climate Interactive 기후변화 앰버서더 활동</p>
            <p>국제 민관프로젝트 관리 공인 자격 (CP3P-F), 국제 프로젝트 관리 자격 (PMP) 보유</p>
            <p>핀테크 플랫폼 ‘the Riches’ 창업 경험</p>
            <div class="svgContain">
              <img src="../../assets/imgs/About_img/ceo-1.svg" alt="" />
              <img src="../../assets/imgs/About_img/ceo-2.svg" alt="" />
              <img src="../../assets/imgs/About_img/ceo-3.svg" alt="" />
              <img src="../../assets/imgs/About_img/ceo-4.svg" alt="" />
              <img src="../../assets/imgs/About_img/ceo-5.svg" alt="" />
            </div>
          </div>
          <div class="mobile-text">
            <span> i-ESG 대표 </span>
            <p>MIT 슬론경영대학원 석사(MBA)</p>
            <p>MIT Sustainability 커뮤니티 정식 멤버</p>
            <p>ESG/에너지 분야 사업개발 경력 10여년</p>
            <p>플랫폼/Sustainability 분야 사업 기획자문</p>
          </div>
        </div>
        <!-- <div class="intro-title">
          <h5 class="title">i-ESG <span class="greenText">CDO</span></h5>
          <hr>
        </div>
        <div class="intro-profile">
          <div class="cdo_img"></div>
          <div class="text">
            <div class="CEO_name">
              <p class="Pretendard-700 ceo-title">
                박용준 (Yongjun Park)<br><br>
              </p>
              <a href="https://www.linkedin.com/in/bellkim/" target="__blank"><i class="fab fa-linkedin fa-lg link" /></a>
            </div>
            <p>
              서울대학교 에너지공학 학/석사
            </p>
            <p>
              ESG/탄소배출권 등 사업개발 경력 7년
            </p>
            <p>
              에너지분야 리서치 경력 (미국주재)
            </p>
            <p>
              에너지/탄소배출권 분야 산학 초청 강사
            </p>
          </div>
          <div class="mobile-text">
            <span>
              i-ESG 대표
            </span>
            <p>
              MIT 슬론경영대학원 석사(MBA)
            </p>
            <p>
              MIT Sustainability 커뮤니티 정식 멤버
            </p>
            <p>
              ESG/에너지 분야 사업개발 경력 10여년
            </p>
            <p>
              플랫폼/Sustainability 분야 사업 기획자문
            </p>
          </div>
        </div> -->
        <div class="intro-title">
          <h5 class="title">Our <span class="greenText">Talented Team</span></h5>
          <hr />
        </div>
        <div class="subText">
          <img src="../../assets/imgs/About_img/simbol1.svg" alt="" />
          <h5 class="Pretendard-600">Our Our Core Team across the domains</h5>
        </div>
        <img class="ourCoreTeam" src="../../assets/imgs/About_img/OurCoreTeam.png" alt="" />
        <div class="intro-title">
          <h5 class="title">Our <span class="greenText">Partners & Advisors</span></h5>
          <hr />
        </div>
        <div class="cardContainer">
          <div class="card">
            <h2 class="Pretendard-700 cardTitle">Partners</h2>
            <p class="Pretendard-500 cardContents">
              ICT 분야 기업 및 기관<br />
              검증, 교육, 컨설팅 기관<br />
              주요 협회 및 지자체
            </p>
          </div>
          <div class="card">
            <h2 class="Pretendard-700 cardTitle">Advisors</h2>
            <p class="Pretendard-500 cardContents">
              ESG 분야 연구원 및 ESG 컨설턴트<br />
              AI/빅데이터 분야 교수진<br />
              비즈니스 개발 및 투자 전문가
            </p>
          </div>
        </div>
        <div class="intro-title">
          <h5 class="title">Our <span class="greenText">Achievement</span></h5>
          <hr />
        </div>
        <img class="ourCoreTeam" src="../../assets/imgs/About_img/ourAchievement.png" alt="" />
      </div>

      <div v-if="language == 'en'" class="inner">
        <div class="color-title">Team i-ESG</div>
        <h2 class="title">A Talented Team with rich experience and knowledge across the domains</h2>
        <h2 class="subTitle Pretendard-500">
          And a group of skilled partners and advisors engaged in meaningful work supporting
          businesses in achieving sustainable growth
        </h2>
        <div class="intro-title">
          <h5 class="title">i-ESG <span class="greenText">CEO</span></h5>
          <hr />
        </div>
        <div class="intro-profile">
          <div class="profile-img" />
          <div class="text">
            <div class="CEO_name">
              <p class="Pretendard-600 ceo-title">Bell (Jongwoong) Kim<br /><br /></p>
              <!-- <a href="https://www.linkedin.com/in/bellkim/" target="__blank"><i class="fab fa-linkedin fa-lg link" /></a> -->
            </div>
            <p>15+ year Professional Exp in Global Biz Development (ESG/Energy/Investment)</p>
            <p>Biz Development Exp in 35 countries (with Gov’t, IBs, International Orgs, etc)</p>
            <p>MIT Sloan School of Business - MBA</p>
            <p>A member of MIT / KAIST Sustainability community (Sustainability Certi)</p>
            <p>Climate Interactive (NGO, USA) Ambassador</p>
            <p>
              Certified Public Private Partnership Professional (CP3P-F), Certified Project
              Management Professional (PMP)
            </p>
            <p>ex-Founder of fintech platform ‘the Riches’</p>
            <div class="svgContain">
              <img src="../../assets/imgs/About_img/ceo-1.svg" alt="" />
              <img src="../../assets/imgs/About_img/ceo-2.svg" alt="" />
              <img src="../../assets/imgs/About_img/ceo-3.svg" alt="" />
              <img src="../../assets/imgs/About_img/ceo-4.svg" alt="" />
              <img src="../../assets/imgs/About_img/ceo-5.svg" alt="" />
            </div>
          </div>
        </div>
        <!-- <div class="intro-title">
          <h5 class="title">i-ESG <span class="greenText">CDO</span></h5>
          <hr>
        </div>
        <div class="intro-profile">
          <div class="cdo_img"></div>
          <div class="text">
            <div class="CEO_name">
              <p class="Pretendard-700 ceo-title">
                박용준 (Yongjun Park)<br><br>
              </p>
              <a href="https://www.linkedin.com/in/bellkim/" target="__blank"><i class="fab fa-linkedin fa-lg link" /></a>
            </div>
            <p>
              서울대학교 에너지공학 학/석사
            </p>
            <p>
              ESG/탄소배출권 등 사업개발 경력 7년
            </p>
            <p>
              에너지분야 리서치 경력 (미국주재)
            </p>
            <p>
              에너지/탄소배출권 분야 산학 초청 강사
            </p>
          </div>
          <div class="mobile-text">
            <span>
              i-ESG 대표
            </span>
            <p>
              MIT 슬론경영대학원 석사(MBA)
            </p>
            <p>
              MIT Sustainability 커뮤니티 정식 멤버
            </p>
            <p>
              ESG/에너지 분야 사업개발 경력 10여년
            </p>
            <p>
              플랫폼/Sustainability 분야 사업 기획자문
            </p>
          </div>
        </div> -->
        <div class="intro-title">
          <h5 class="title">Our <span class="greenText">Talented Team</span></h5>
          <hr />
        </div>
        <div class="subText">
          <img src="../../assets/imgs/About_img/simbol1.svg" alt="" />
          <h5 class="Pretendard-600">Our Our Core Team across the domains</h5>
        </div>
        <img class="ourCoreTeam" src="../../assets/imgs/About_img/OurCoreTeamEn.png" alt="" />
        <div class="intro-title">
          <h5 class="title">Our <span class="greenText">Partners & Advisors</span></h5>
          <hr />
        </div>
        <div class="cardContainer">
          <div class="card">
            <h2 class="Pretendard-700 cardTitle">Partners</h2>
            <p class="Pretendard-500 cardContents">
              ICT/Could Companies <br />
              Partners in verification, education & consulting <br />
              SW Full stack Development Team
            </p>
          </div>
          <div class="card">
            <h2 class="Pretendard-700 cardTitle">Advisors</h2>
            <p class="Pretendard-500 cardContents">
              Researchers and consultants in the field of ESG <br />
              Professors in AI / Big data, <br />
              Experts in Business Development, Investment, etc
            </p>
          </div>
        </div>
        <div class="intro-title">
          <h5 class="title">Our <span class="greenText">Achievement</span></h5>
          <hr />
        </div>
        <img class="ourCoreTeam" src="../../assets/imgs/About_img/ourAchievementEn.png" alt="" />
      </div>
    </div>
    <div class="about-p7">
      <div v-if="language == 'ko'" class="inner">
        <h1 class="title">
          i-ESG는<br class="tablet" />
          우리와 함께 성장해 갈 인재를 모집합니다.
        </h1>
        <button class="button" @click="openInNewTab">지원 방법 바로가기</button>
      </div>

      <div v-if="language == 'en'" class="inner">
        <h1 class="title">i-ESG is seeking talents to grow together with us</h1>
        <button class="button" @click="openInNewTab">Apply Here</button>
      </div>
    </div>
  </div>
</template>

<script>
import setMeta from '@/utils/setMeta';
import pageSection1 from '../pageSection1.vue';

export default {
  name: 'About',
  components: {
    pageSection1
  },
  beforeCreate() {
    setMeta({
      title: 'i-ESG | About Us',
      description: 'i-ESG를 통해 우리회사의 ESG를 체계적으로 관리하고 다양한 컨텐츠를 만나보세요',
      path: this.$route.path,
      keywords: 'ESG, ESG 관리, ESG 솔루션'
    });
  },
  props: {
    language: Array
  },
  data() {
    return {
      team: {
        cdo: {
          title: 'CDO',
          profile:
            '서울대학교 에너지공학 학/석사<br>ESG/탄소배출권 등 사업개발 경력 7년<br>에너지분야 리서치 경력 (미국주재)<br>에너지/탄소배출권 분야 산학 초청 강사<br>',
          profileEn:
            '• Master of Energy Engineering<br>• Vast Project Experience of<br>&nbsp;&nbsp;&nbsp;ESG business(Energy / Carbon &nbsp;&nbsp;&nbsp;Emission)<br>• AI based Data Analyst/ESG &nbsp;&nbsp;&nbsp;Researcher',
          img: require('../../assets/imgs/About_img/team-img/CDO.png')
        },
        anna: {
          title: 'ESG Researcher',
          profile:
            '카이스트 녹색경영정책 석사<br>ESG 분야 리서치 경력<br>국제기구 친환경 분야 리서치 (Mexico)',
          profileEn:
            '• Master of Green Business and Policy<br>• Formal Environmental International &nbsp;&nbsp;&nbsp;Org. Researcher<br>• Highly Experienced ESG Analyst',
          img: require('../..//assets/imgs/About_img/team-img/anna.png')
        },
        lee: {
          title: 'ESG Researcher',
          profile: '자유전공(사회과학)/환경공학 학사<br> ESG(환경) 분야 컨설팅 및 리서치 경력',
          profileEn:
            '• Bachelor of Enviromental &nbsp;&nbsp;&nbsp;Science/Engineering<br>• Work Experience in ESG Consulting &nbsp;&nbsp;&nbsp;& Research',
          img: require('../..//assets/imgs/About_img/team-img/Lee.png')
        },
        choi: {
          title: 'Software Engineer',
          profile: 'UC Berkeley, 컴퓨터 공학<br>software engineer (미국 Bellevue)',
          profileEn:
            '• Major in Computer Science<br>• International Work Experience in &nbsp;&nbsp;&nbsp;Software Engineering<br>• Highly Experienced Backend Expert',
          img: require('../..//assets/imgs/About_img/team-img/choi.png')
        },
        shin: {
          title: 'Software Engineer',
          profile: 'AI/ML 과정 이수 및 프로젝트 진행경험<br>AI 프로젝트 PM 경력',
          profileEn:
            '• Certified AI/ML Professional<br>• Highly Experienced PM <br>• Vast Experience of AI Projects',
          img: require('../..//assets/imgs/About_img/team-img/shin.png')
        },
        kim: {
          title: 'UX/UI Designer',
          profile:
            '한양대학교 디자인대학 학사<br>UIUX / 3D / KIA  전시 아티스트<br>다수 국내외 기업 프로젝트 참여',
          profileEn:
            '• Bachelor of Design<br>• Formal UIUX / 3D / KIA Artist<br>• Vast Experience of Global Projects',
          img: require('../..//assets/imgs/About_img/team-img/kim.png')
        },
        Jongeon: {
          title: 'Marketing Team Leader',
          profile:
            '스타트업 CMO 등 마케팅 실무경험 17년<br>후후앤컴퍼니 마케팅 총괄 및 서비스 기획 <br>KT 신규 서비스 기획 및 제휴/마케팅 담당<br>MBC 컨텐츠 기획 및 마케팅 기획',
          profileEn:
            '• 17+ years Marketing Strategy<br>• Formal CMO in the Blockchain &nbsp;&nbsp;&nbsp;Industry<br>• Vast Experience as Marketing Lead &nbsp;&nbsp;&nbsp;for Major Businesses(KT / MBC)',
          img: require('../..//assets/imgs/About_img/team-img/Jongeon.png'),
          class: 'marketing-text'
        },
        Hyeon: {
          title: 'Marketing & Sales Manager',
          profile:
            '고려대 경영학 전공<br>ESG 연구원 주관 융복합 연구 참여<br>UNEP 환경교육 멘토<br>국내외 브랜드 마케팅 경력',
          profileEn:
            '• Major in Psychology<br>• Certified Junior Survey Analyst<br>• Environmental Governance Project &nbsp;&nbsp;&nbsp;Formal Vice President',
          img: require('../..//assets/imgs/About_img/team-img/Hyeon.png'),
          class: 'marketing-text'
        },
        a: {}
      },
      imgArray: [
        require('../../assets/imgs/About_img/esg-img/UNSDG4.png'),
        require('../../assets/imgs/About_img/esg-img/UNSDG10.png'),
        require('../../assets/imgs/About_img/esg-img/UNSDG12.png'),
        require('../../assets/imgs/About_img/esg-img/UNSDG17.png'),
        require('../../assets/imgs/About_img/esg-img/esg12.png'),
        require('../../assets/imgs/About_img/esg-img/esg17.png')
      ],
      imgArray_en: [
        require('../../assets/imgs/About_img/esg-img/E_WEB_04_en.png'),
        require('../../assets/imgs/About_img/esg-img/E_WEB_10_en.png'),
        require('../../assets/imgs/About_img/esg-img/E_WEB_12_en.png'),
        require('../../assets/imgs/About_img/esg-img/E_WEB_17_en.png')
      ],
      imgCnt: 0,
      isOpen: true
    };
  },

  created() {
    // this.makeImage()
  },
  unmounted() {
    // clearInterval(this.makeImage())
  },

  methods: {
    openInNewTab() {
      window.open(
        'https://noiseless-sock-3f3.notion.site/i-ESG-a2239716812e4620819a8a693cb0f786?pvs=4',
        '_blank'
      );
    },
    makeImage: function () {
      var self = this;
      setInterval(function () {
        self.isOpen = true;
        if (self.imgCnt < 3) {
          self.imgCnt++;
        }
        if (self.imgCnt >= 3) {
          self.imgCnt = 0;
        }
      }, 1000);
    }
  }
};
</script>

<style scoped src="./about.css"></style>
