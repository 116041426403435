<template>
  <div>
    <ContactModal
      v-if="modalshow && language == 'ko'"
      :modalshow="modalshow"
      @closemodal="modalshow = false"
      :modaldata="modaldata"
    />
    <ContactModal
      v-if="modalshow && language == 'en'"
      :modalshow="modalshow"
      @closemodal="modalshow = false"
      :modaldata="modaldataEN"
      :language="language"
    />
    <div v-if="language == 'ko'">
      <section class="client-p1">
        <div class="inner">
          <hr />
          <h1 class="display-off Pretendard-600">
            Client - 더 나은 세상을 만들어가는 우리의 고객들
          </h1>
          <h1 class="Pretendard-600 tablet">Client</h1>
        </div>
      </section>
      <section class="client-p2">
        <div class="inner">
          <h1 class="Pretendard-600 title">여러분은 좋은 기업입니다.</h1>
          <p class="Pretendard-500 text">
            더 나은 세상을 위해, 더 나은 기업이 되기 위해, 한 걸음의 도약을 하는 여러분은 좋은
            기업입니다. i-ESG는 이러한 기업들의 적극적인 ESG 경영을 응원하며, 적극 지원합니다.<br />
            <span>(베타참여 의향 전달 기업들이며, 리스트는 지속 업데이트 예정입니다)</span>
          </p>
          <section class="client-contain">
            <PartnersCarousel :itemsToShow="5" :height="'auto'" :mypartnerList="clientImages" />
            <!-- <card v-for="(myClient,i) in myClients" :key="myClient" class="client-card">
            <div class="client-card-inner">
              <img :src="require(`@/assets/imgs/Client/client_img/${myClient.img_url}`)" alt="client-logo" class="logo">
              <h1 class="title Pretendard-700">{{myClient.id}}</h1>
              <p class="text Pretendard-400">{{myClient.content}}</p>
              <button type="button" @click="onClickRedirect(i)" class="report-button Pretendard-600">리포트 보기 -></button>
            </div>
          </card> -->
          </section>
        </div>
      </section>
      <div class="client-p3">
        <section class="inner">
          <h1 class="title">더 나은 기업으로의 성장, 지금 시작하세요.</h1>
          <button class="button" @click="modalshow = true">데모 요청</button>
        </section>
      </div>
    </div>

    <!-- English ver -->

    <div v-if="language == 'en'">
      <section class="client-p1">
        <div class="inner">
          <hr />
          <h1 class="display-off Pretendard-600">Client - a big step to a sustainable future</h1>
          <h1 class="Pretendard-600 tablet">Client</h1>
        </div>
      </section>
      <section class="client-p2">
        <div class="inner">
          <h1 class="Pretendard-600 title">To our customers</h1>
          <p class="Pretendard-500 text">
            You are now taking a big step toward a better enterprise and a better society.<br />
            i-ESG sincerely supports your sustainable ESG management by all means. <br />
            <!-- <span>(Following are the companies that request the i-ESG software beta test. We are continuously updating the list.)</span> -->
          </p>
          <section class="client-contain">
            <PartnersCarousel :itemsToShow="3" :height="'auto'" :mypartnerList="clientImages" />
            <!-- <card v-for="(myClient,i) in myClients" :key="myClient" class="client-card">
            <div class="client-card-inner">
              <img :src="require(`@/assets/imgs/Client/client_img/${myClient.img_url}`)" alt="client-logo" class="logo">
              <h1 class="title Pretendard-700">{{myClient.id}}</h1>
              <p class="text Pretendard-400">{{myClient.content}}</p>
              <button type="button" @click="onClickRedirect(i)" class="report-button Pretendard-600">리포트 보기 -></button>
            </div>
          </card> -->
          </section>
        </div>
      </section>
      <div class="client-p3">
        <section class="inner">
          <h1 class="title">Start your ESG journey right now</h1>
          <button class="button" @click="modalshow = true">Request a Demo</button>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import myClients from '../../assets/js/client.json'
import setMeta from '@/utils/setMeta'
import ContactModal from '../../components/Modal/ContactModal.vue'
import PartnersCarousel from '../Partners/PartnersCarousel/PartnersCarousel.vue'

export default {
  name: 'Clients',
  props: {
    language: String
  },
  beforeCreate() {
    setMeta({
      title: 'i-ESG | Clients',
      description: 'i-ESG와 함께하는 기업들',
      path: this.$route.path,
      keywords: 'ESG 기업, ESG 통합관리, ESG, ESG 관리, ESG 솔루션'
    })
  },
  components: {
    ContactModal,
    PartnersCarousel
  },
  data() {
    return {
      myClients: myClients,
      modalshow: false,
      modaldata: {
        Mtitle: '데모 요청',
        Mname: '담당자 이름 / 직급 (이름과 직급 사이에 슬래쉬(/) 기호 입력 필요)',
        Mposition: '담당자 직급',
        Mcompany: '회사명',
        Mdepartment: '담당 부서',
        Mphone: '연락처',
        Memail: '이메일 주소',
        Mquestions: '문의사항',
        Mbutton: '문의하기',
        Mtype: '데모 요청'
      },
      modaldataEN: {
        Mtitle: 'Request a demo',
        Mname: 'Name / Position',
        Mposition: 'Position',
        Mcompany: 'Company Name',
        Mdepartment: 'Department',
        Mphone: 'Phone',
        Memail: 'Business Email',
        Mquestions: 'Message',
        Mbutton: 'Submit',
        Mtype: '데모 요청(EN)'
      }
    }
  },
  methods: {
    onClickRedirect: function (i) {
      window.open(this.myClients[i].link, '_blank')
    }
  },
  computed: {
    clientImages() {
      return this.myClients.map((client) =>
        require(`@/assets/imgs/Client/client_img/${client.img_url}`)
      )
    }
  }
}
</script>

<style scoped src="./clients.css"></style>
