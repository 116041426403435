<template>
  <div>
    <header
      class="navBar"
      :class="[scrolled || isPricing || isPromotion ? 'headroom--unpinned' : '']"
      v-on="handleScroll"
    >
      <div class="inner">
        <router-link to="/"
          ><img
            src="../../assets/logo/Login_001_LogoG.png"
            alt="i-ESG"
            @click="menuNuminit"
            id="iesg_logo"
        /></router-link>
        <h2 class="hide">대매뉴</h2>
        <ul class="nav_el">
          <li
            v-on:mouseover="showDropdown = true"
            v-on:mouseout="showDropdown = false"
            class="nav1"
          >
            About
            <img src="../../assets/imgs/dropdown.png" alt="" id="dropdown-icon" />
            <ul v-show="showDropdown" class="dropmenus">
              <router-link
                :to="dropmenu.menusLink"
                v-for="dropmenu in filteredDropMenus"
                :key="dropmenu.menutypes"
                class="dropmenu dropmenu-background"
                @click="showDropdown = false"
              >
                <li
                  :class="[
                    scrolled || isPricing || isPromotion ? 'dropmenu-scroll-background' : ''
                  ]"
                >
                  {{ dropmenu.menutypes }}
                </li>
              </router-link>
            </ul>
          </li>
          <router-link :to="menuss[i].menusLink" v-for="(menu, i) in menuss" :key="i" class="nav1">
            {{ menuss[i].menutypes }}
          </router-link>
        </ul>
        <ul class="nav_el2">
          <router-link v-if="language == 'ko'" to="#contact" class="demo-button"
            >데모 요청</router-link
          >
          <router-link v-if="language == 'en'" to="#contact" class="demo-button">Demo</router-link>
          <router-link to="/Junbi" class="dashboard-button">Dashboard</router-link>
          <h2 class="hide">Dashborad</h2>
          <select
            :class="[scrolled || isPricing || isPromotion ? 'selectColor' : '']"
            v-model="value"
            @change="onLanguageChange"
          >
            <option value="ko">KR</option>
            <option value="en">EN</option>
          </select>
        </ul>
      </div>
    </header>

    <HamburgerMenu
      :menuss="menuss"
      :dropmenus="dropmenus"
      @closemodal="modalshow = false"
      :modaldata="modaldata"
    />

    <ContactModal
      v-if="modalshow"
      :modalshow="modalshow"
      @closemodal="modalshow = false"
      :modaldata="modaldata"
    />
  </div>
</template>

<script>
import ContactModal from '../Modal/ContactModal.vue'
import HamburgerMenu from './HamburgerMenu.vue'

export default {
  name: 'Header',
  components: {
    ContactModal,
    HamburgerMenu
  },
  data() {
    return {
      showDropdown: false,
      hamburgercheck: false,
      show: false,
      limitPosition: 100,
      scrolled: false,
      lastPosition: 0,
      menuss: [
        {
          menutypes: 'Solutions',
          menusLink: '/solutions'
        },
        {
          menutypes: 'Pricing',
          menusLink: '/pricing'
        },
        {
          menutypes: 'Insights',
          menusLink: '/insights'
        },
        {
          menutypes: 'Clients',
          menusLink: '/clients'
        }
      ],
      dropmenus: [
        {
          menutypes: 'About us',
          menusLink: '/about',
          class: 'dropmenu-background1',
          scrollBack: "{'dropmenu-scroll-background1' : scrolled}"
        },
        {
          menutypes: 'Media',
          menusLink: '/esg-media',
          class: 'dropmenu-background2',
          scrollBack: "{'dropmenu-scroll-background2' : scrolled}"
        },
        {
          menutypes: 'Partners',
          menusLink: '/partners',
          class: 'dropmenu-background1',
          scrollBack: "{'dropmenu-scroll-background1' : scrolled}"
        }
      ],

      menusNum: [false, false, false, false, false],
      value: sessionStorage.getItem('selectedLang') || 'ko',
      modalshow: false,
      modaldata: {
        Mtitle: '데모 요청',
        Mname: '담당자 이름 / 직급 (이름과 직급 사이에 슬래쉬(/) 기호 입력 필요)',
        Mposition: '담당자 직급',
        Mcompany: '회사명',
        Mdepartment: '담당 부서',
        Mphone: '연락처',
        Memail: '이메일 주소',
        Mquestions: '문의사항',
        Mbutton: '문의하기',
        Mtype: '데모 요청'
      }
    }
  },
  props: {
    language: String
  },
  methods: {
    handleScroll() {
      if (this.limitPosition < window.scrollY) {
        this.scrolled = true
        // move up!
      }
      if (this.limitPosition > window.scrollY) {
        this.scrolled = false
        // move down
      }
      this.lastPosition = window.scrollY
      // this.scrolled = window.scrollY > 250;
    },
    onLanguageChange() {
      sessionStorage.setItem('selectedLang', this.value)
      this.$emit('selectLang', this.value)
    }
  },
  mounted() {
    if (sessionStorage.getItem('selectedLang') == undefined) {
      this.value = window.navigator.language.slice(0, 2)
      sessionStorage.setItem('selectedLang', this.value)
    }
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    isPricing() {
      return this.$route.name === 'Pricing'
    },
    isPromotion() {
      return this.$route.name === 'Promotion'
    },
    filteredDropMenus() {
      if (this.language === 'en') {
        return this.dropmenus.filter((menu) => menu.menutypes !== 'Media')
      }
      return this.dropmenus
    }
  }
}
</script>
<style scoped src="./header.css"></style>
