<template>
  <div>
    <div class="lendingpage">
      <section class="inner">
        <h1 class="lending-title" data-aos="fade-up">
          새로운 상생의 길<br />
          <span
            ><span style="color: #145371" data-aos="fade-up" data-aos-delay="300">협력사의</span>
            <span style="color: #f9aa25" data-aos="fade-up" data-aos-delay="300"
              >ESG 대비</span
            ></span
          >에<br />
          함께해주세요
        </h1>
        <h2 class="lending-text" data-aos="fade-up" data-aos-delay="200">
          공급망 리스크를 빠르게 식별하는 동시에,<br />
          협력사들의 ESG 경영 구축을 돕고 싶다면?<br />
          <span style=""
            >모두 가능한 <span style="">i-ESG 상생협력 패키지</span>를 알아보세요!</span
          >
        </h2>
        <img
          data-aos="fade-up"
          src="../assets/imgs/Lending_img/lending_img1.png"
          alt="i-esg"
          class="landing-img"
        />
        <h3 data-aos="fade-right" data-aos-delay="100" class="lending-text">
          i-ESG는 글로벌 빅데이터에 기반한<br />
          <span style="font-family: GmarketSans-Bold">ESG 특화 All-in-One 솔루션</span>입니다.
        </h3>
        <p class="lending-grayfont" data-aos="fade-up" data-aos-delay="100">
          ※ i-ESG 상생협력 패키지는 협력사/공급업체의 ESG 관리를 위해 패키지를 구매하는 대기업에
          더하여
          <span style="font-family: GmarketSans-Bold; color: #333134"
            >최대 100%만큼 i-ESG가 무상 Credit을 추가 지원</span
          >하여, 더 많은 협력사가 혜택을 받을 수 있도록 지원하는 프로모션 행사로 별도의 예고없이
          종료될 수 있습니다.
        </p>
        <!-- <div style="display:flex; align-items:center; justify-content: center; margin: 5rem auto 0 auto;"><a href="#info"><button>더 알아보기</button></a></div> -->
        <h1 class="lending-title">
          상생협력 패키지<br />
          <span data-aos="fade-up" data-aos-offset="300" style="color: #145371"
            >이런 점이 좋습니다!</span
          >
        </h1>
        <div style="width: 100%">
          <p class="for">FOR 협력사</p>
        </div>
        <ol style="width: 100%">
          <li>
            <h1 class="title2" style="margin-top: 2.5rem">
              1. 중소기업향 맞춤형 통합 관리 서비스로
              <span style="color: #00ae66">ESG 경영 시작</span>
            </h1>
            <p class="text">
              국내 대표 종합상사의 사내벤처로 출범한 i-ESG는 풍부한 현업 경험에 기반하여 예산과
              인력이 부족한 중소기업들이 ESG 현황을 진단하고, 관리, 개선할 수 있는 클라우드 기반
              솔루션을 제공합니다.
            </p>
            <img
              data-aos="fade-up"
              data-aos-delay="200"
              src="../assets/imgs/Lending_img/lending_img2.png"
              alt="i-ESG"
              style="position: absolute; left: 50%; transform: translate(-50%, 0)"
              class="absolute-img"
            />
            <div class="space"></div>
          </li>

          <li>
            <h1 class="title2">
              2. 실시간 업데이트되는 SaaS로
              <span style="color: #00ae66">모든 ESG 요구 효과적 대응</span>
            </h1>
            <p class="text">
              i-ESG는 차별화된 데이터 기술에 기반하여 글로벌 ESG 동향을 실시간으로 파악하고
              업데이트합니다. 중소기업들은 배경 지식 없이도 지속 상향되는 ESG 관련 요구에 효과적으로
              대응할 수 있습니다.
            </p>
            <div class="two-imgs">
              <figure data-aos="fade-up" data-aos-delay="200">
                <figcaption>(AI 기반 실시간 국내외 ESG 트렌드 분석)</figcaption>
                <img
                  src="../assets/imgs/Lending_img/lending_img3-1.png"
                  alt=""
                  width="93%"
                  style="margin: auto"
                />
              </figure>
              <figure data-aos="fade-up" data-aos-delay="350">
                <figcaption>(글로벌 기준을 반영한 ESG 리포팅 자동화)</figcaption>
                <img
                  src="../assets/imgs/Lending_img/lending_img3-2.jpg"
                  alt=""
                  width="93%"
                  style="margin: auto"
                />
              </figure>
            </div>
          </li>

          <li>
            <h1 class="title2">
              3. 최소한의 비용으로 가능한 <span style="color: #00ae66">최적의 ESG 관리</span>
            </h1>
            <p class="text">
              일회성에 그치거나 비싼 비용을 지불해야 하는 컨설팅과 달리, i-ESG는 글로벌 빅데이터에
              기반한 클라우드 서비스로 합리적인 비용으로 지속적인 데이터 관리 및 개선까지
              가능합니다.
            </p>
            <figure data-aos-offset="200" data-aos="fade-up" class="figure-layout">
              <img
                src="../assets/imgs/Lending_img/lending_img4.png"
                alt="i-ESG"
                class="img-size-width-60"
              />
            </figure>
          </li>
        </ol>

        <div style="width: 100%">
          <p class="for">FOR 대기업</p>
        </div>
        <ol style="width: 100%">
          <li>
            <h1 class="title2" style="margin-top: 2.5rem">
              1. 손쉽고 빠른 디지털 관리로
              <span style="color: #00ae66">공급망 리스크 최소화 및 개선 </span>
            </h1>
            <p class="text">
              EU 공급망 실사법, SEC Scope 3 공시 등 대기업들에게 공급망 관리의 중요성이 어느때보다
              커지고 있습니다.<br />
              i-ESG 솔루션으로 필요할 때 언제든, 우리회사의 목적에 부합한 진단항목에 맞추어
              (커스터마이제이션 기능 제공), 협력사/파트너사의 ESG 현황을 손쉽게 파악(대시보드
              제공)하고 보다 현실적인 개선을 지원할 수 있습니다.
            </p>
            <div class="two-imgs">
              <figure data-aos="fade-up" data-aos-delay="200" class="img-size-50">
                <figcaption>(공급망 관리 대시보드)</figcaption>
                <img
                  src="../assets/imgs/Lending_img/lending_img5-1.png"
                  alt="i-ESG"
                  class="img-size-100"
                  style="
                    margin: auto;
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                  "
                />
              </figure>
              <figure data-aos="fade-up" data-aos-delay="350" class="img-size-50">
                <figcaption>(내부 공유용 리포트 제공)</figcaption>
                <img
                  src="../assets/imgs/Lending_img/lending_img6.png"
                  alt="i-ESG"
                  class="img-size-60"
                  style="
                    margin: 0 auto 0 auto;
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                  "
                />
              </figure>
            </div>
          </li>

          <li>
            <h1 class="title2">
              2. 협력사 ESG 지원으로
              <span style="color: #00ae66">동반성장 기틀 마련 및 홍보 효과</span>
            </h1>
            <p class="text">
              자체적으로 ESG 대응이 어려운 중소 기업들과의 동반성장이 사회적으로 요구되고
              있습니다.<br />
              i-ESG와 함께 협력사을 위한 ESG 지원으로 동반성장을 도모하고 홍보효과를 누릴 수
              있습니다.
            </p>
            <figure class="figure-layout">
              <img
                src="../assets/imgs/Lending_img/lending_img7-1.png"
                alt="i-ESG"
                class="img-size-85"
                style="margin: auto"
              />
            </figure>
          </li>
        </ol>
        <section class="contact" data-aos="fade-up" data-aos-offset="200">
          <h1>모두를 위한 ESG 상생협력, 지금 시작해보세요</h1>
          <p>
            ※ 상담신청을 남겨주시면, 개별 연락드리고 솔루션 시연 및 상세 상담을 진행할 예정입니다.
          </p>
          <div class="two-buttons">
            <button type="button" @click="modalshow = true">상담 신청하기</button>
            <a href="https://i-esg.io" target="_blank"><button>홈페이지 바로가기</button></a>
          </div>
        </section>
      </section>
      <section id="info">
        <p style="font-family: GmarketSans-Bold; font-size: 1.875rem; color: #f9aa25">
          i-ESG 상생협력패키지
        </p>
        <h1
          style="
            font-family: GmarketSans-Bold;
            font-size: 3.125rem;
            color: #fafffe;
            margin: 3.125rem 0;
          "
        >
          상세 정보
        </h1>
        <div class="info-inner">
          <p>
            우리의 많은 협력사들이 외부의 도움없이 스스로 ESG를 내재화하기란 결코 쉽지 않습니다.<br />
            i-ESG는 보다 많은 기업들이 ESG를 더 잘 이해하고, 관리하길 바랍니다. <br />
            <span class="border-font"
              >i-ESG는 상생협력 패키지 구매금액의 최대 100%까지 무상 Credit을 추가 제공합니다.</span
            >
          </p>
          <div class="benefit-info">
            <h1>상세 혜택</h1>
            <ul>
              <li>중소기업용 Basic 솔루션 구독료 : 1000만원 이하/년 (별도 협의)</li>
            </ul>
            <table>
              <tbody border="1" align="center">
                <tr>
                  <td style="background-color: #d5e3db; width: 15%; border-left: none">2억 이하</td>
                  <td style="text-align: left; padding: 1.875rem">
                    : 지불금액 + 50% 추가 크레딧 제공<br />
                    <span class="small-font"
                      >(ex. 1억 지불 시, 지정된 협력사들이 총 1억 5000만원에 준하는 크레딧 이용
                      가능)</span
                    >
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #7cb0be; border-left: none">2억 이상</td>
                  <td style="text-align: left; padding: 1.875rem">
                    : 지불금액 + 100% 추가 크레딧 제공<br />
                    + 구매기업향 공급망 ESG 진단 관리 소프트웨어 무상 제공
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button
          style="background-color: #fafffe; color: #000000; margin-top: 5rem"
          type="button"
          @click="modalshow = true"
        >
          상담 신청하기
        </button>
      </section>
    </div>
    <ContactModal :modalshow="modalshow" @closemodal="modalshow = false" :modaldata="modaldata" />
  </div>
</template>

<script>
import ContactModal from '../components/Modal/ContactModal.vue'
import setMeta from '@/utils/setMeta'

export default {
  name: 'Lending',
  beforeCreate() {
    setMeta({
      title: 'i-ESG Promotion',
      description: '새로운 상생의 길 협력사의 ESG 대비에 함께해주세요.',
      path: this.$route.path,
      keywords: 'ESG Trends, ESG Seminars, ESG Education, ESG, ESG 관리, ESG 솔루션'
    })
  },
  components: {
    ContactModal
  },
  data() {
    return {
      modalshow: false,
      modaldata: {
        Mtitle: '상생협력 패키지 문의',
        Mname: '담당자 이름 / 직급 (이름과 직급 사이에 슬래쉬(/) 기호 입력 필요)',
        Mposition: '담당자 직급',
        Mcompany: '회사명',
        Mdepartment: '담당 부서',
        Mphone: '연락처',
        Memail: '이메일 주소',
        Mquestions: '문의사항',
        Mbutton: '문의하기',
        Mtype: '상생협력 패키지 문의'
      }
    }
  }
}
</script>

<style scoped>
.lendingpage {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: auto;
  background-color: #fafffe;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.inner {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lending-title {
  font-family: 'GmarketSans-Bold';
  font-size: 4.6rem;
  text-align: center;
  color: #333134;
  line-height: 6.8rem;
  margin-top: 8rem;
}
.lending-title span {
  font-size: 5.8rem;
}
.img-size-50 {
  width: 50%;
}
.img-size-60 {
  width: 60%;
  height: 85%;
}
.img-size-width-60 {
  width: 60%;
}
.img-size-65 {
  width: 65%;
}
.img-size-85 {
  width: 85%;
}
.img-size-100 {
  width: 100%;
  height: 57%;
}
.lending-text {
  font-family: 'GmarketSans-Medium';
  font-size: 2.1rem;
  text-align: center;
  line-height: 4rem;
  margin-top: 5rem;
  color: #333134;
}
.lending-text span {
  font-size: 2.35rem;
  line-height: 4.7rem;
}
.lending-text span span {
  font-family: 'GmarketSans-Bold';
  font-size: 2.85rem;
  line-height: 6rem;
}
.lending-grayfont {
  font-family: 'GmarketSans-Light';
  font-size: 0.95rem;
  margin: 4rem auto 0 auto;
  width: 44rem;
  color: #838383;
  line-height: 1.8rem;
  word-break: keep-all;
  text-align: center;
}
.landing-img {
  width: 81.25rem;
  height: auto;
  margin-top: 7rem;
}
button {
  padding: 12px 40px;
  width: auto;
  height: 3.438rem;
  background: #343b5e;
  border-radius: 30px;
  color: #fafffe;
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-family: 'GmarketSans-Bold';
  border: 0;
  cursor: pointer;
  transition: all ease-in-out 0.15s;
  display: inline-block;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
button:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(1.02);
}
.for {
  padding: 0.75rem 2.5rem;
  width: 16rem;
  height: 2.5rem;
  background: #f9aa25;
  border-radius: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  color: #333134;
  font-family: 'GmarketSans-Bold';
  font-size: 1.563rem;
  line-height: 1.875rem;
}
.title2 {
  font-family: 'GmarketSans-Bold';
  font-size: 2.625rem;
  line-height: 5rem;
  color: #333134;
  margin-top: 13rem;
}
.text {
  width: 77rem;
  font-family: 'GmarketSans-Medium';
  font-size: 1.563rem;
  line-height: 2.5rem;
  color: #333134;
  margin: 1.6rem 0 0 2.9rem;
  word-break: keep-all;
}
.absolute-img {
  width: 105rem;
  height: 28.859rem;
}
.space {
  height: 35rem;
}
.figure-layout {
  display: flex;
  justify-content: center;
  margin: 5rem auto 0 auto;
}
.two-imgs {
  display: flex;
  justify-content: space-between;
}
.two-imgs figure {
  margin: 6rem auto 0 auto;
  display: flex;
  flex-direction: column;
}
.two-imgs figure figcaption {
  font-family: 'GmarketSans-Bold';
  font-size: 1.25rem;
  line-height: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}
.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15rem auto 0 auto;
}
.contact h1 {
  margin: auto;
  font-family: 'GmarketSans-Bold';
  font-size: 3.125rem;
  line-height: 180%;
}
.contact p {
  margin: auto;
  font-family: 'GmarketSans-Medium';
  font-size: 1.25rem;
  line-height: 200%;
}
.contact .two-buttons {
  margin: 3rem auto 15rem auto;
  font-family: 'GmarketSans-Bold';
  font-size: 1.25rem;
  line-height: 200%;
  width: 60%;
  display: flex;
  justify-content: space-evenly;
}
#info {
  width: 100%;
  height: auto;
  background-color: #343b5e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0 400px 0;
}
#info .info-inner {
  width: 81.25rem;
  height: auto;
  padding: 3.125rem;
  background-color: #fafffe;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#info .info-inner p {
  text-align: center;
  font-family: 'GmarketSans-Medium';
  font-size: 1.75rem;
  line-height: 300%;
  margin-top: 3.125rem;
}
#info .benefit-info {
  margin-top: 9.375rem;
  width: 100%;
}
#info .benefit-info h1 {
  text-align: left;
  font-family: 'GmarketSans-Bold';
  font-size: 3.125rem;
}
#info li {
  width: auto;
  list-style-type: disc;
  margin: 3.125rem 0 0 1.25rem;
  font-family: 'GmarketSans-Medium';
  font-size: 1.875rem;
}
#info table {
  margin-top: 3.125rem;
  width: 100%;
  border: 2px solid #444444;
  border-right: none;
  border-left: none;
  margin-bottom: 6.25rem;
}
#info td {
  border: 2px solid #444444;
  font-family: 'GmarketSans-Medium';
  font-size: 1.875rem;
  line-height: 160%;
  margin: auto;
  border-right: none;
}
#info .small-font {
  font-size: 1.5rem;
}
#info .border-font {
  font-family: 'GmarketSans-Bold';
  font-size: 2.1rem;
}

/* @media screen and (max-width: 2200px) {
    .absolute-img {
      width: 100%;
      height: auto;
      top:159.5rem;
    }
  }
  @media screen and (max-width: 1600px) {
    .absolute-img {
      width: 100%;
      height: auto;
      top:159.5rem;
    }
  }
  @media screen and (max-width: 1300px) {
    .absolute-img {
      width: 100%;
      height: auto;
      top:159.5rem;
    }
  }
  @media screen and (max-width: 1080px) {
    .absolute-img {
      width: 100%;
      height: auto;
      top:132.5rem;
    }
    .lendingpage {
    padding: 2rem 0 0 0;
    }
    .inner {
      width: 94%;
    }
    .lending-title {
      font-size: 3.6rem;
      text-align: center;
      color: #333134;
      line-height: 5.5rem;
    }
    .lending-title span{
      font-size: 4.6rem;
    }
    .lending-text {
      font-size: 1.5rem;
      line-height: 3rem;
      margin-top: 5rem;
    }
    .lending-text span{
    font-size: 1.7rem; line-height:3.5rem;
    }
    .lending-text span span{
      font-family: 'GmarketSans-Bold'; font-size : 2.1rem; line-height:3.5rem
    }
    .landing-img {
        width: 85%;
        height: auto;
        margin-top:5rem 
    }
    .lending-grayfont{
      font-size:0.9rem;
      margin: 2.5rem auto 0 auto;
      width: 40rem;
      line-height: 1.5rem;
    }
    
    button {
      padding: 12px 30px;
      height: 3.2rem;
      font-size: 1.1rem;
    }
    button:hover {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      transform: scale(1.02);
    }
    .for {
      padding: 0.5rem 2rem; width: 12.5rem; height: 2rem;
      margin-top: 6.5rem;
      font-size: 1.3rem; line-height: 1.875rem;
    }
    .title2 {
      font-size: 2rem;
      line-height: 5rem;
    }
    .text {
      width: 80%;
      font-size: 1.3rem;
      line-height: 2rem;
      margin: 1rem 0 0 2.2rem;
    }
    .space{
      height: 24rem;;
    }
    .two-imgs figure{
      margin: 5rem auto 0 auto;
    }
    .two-imgs figure figcaption{
      font-family: "GmarketSans-Bold";
      font-size: 1.05rem;
      line-height: 2rem;
      margin-bottom: 0.5rem;
    }
    .contact {
      margin: 12rem auto 0 auto;
    }
    .contact h1{
      font-size: 2.7rem;
      line-height: 170%;
    }
    .contact p{
      font-size: 1.1rem;
      line-height: 190%;
    }
    .contact .two-buttons{
      margin: 2.5rem auto 12rem auto;
      width: 50%;
    }
    #info {
      padding: 6rem 0 20rem 0;
    }
    #info .info-inner{
      word-break: keep-all;
      width: 80%;
      height: auto;
      padding : 3rem;
    }
    #info .info-inner p{
      font-size: 1.4rem;
      line-height: 250%;
      margin-top: 2rem;
      width: 90%;
    }
    #info .benefit-info{
      margin-top: 7rem;
      width: 100%;
    }
    #info .benefit-info h1{
      font-size: 2.7rem;
    }
    #info li{
      margin: 3rem 0 0 1rem;
      font-size: 1.3rem;
    }
    #info table{
      margin-top: 2.5rem;
      margin-bottom: 5rem;
    }
    #info td{
      font-size: 1.4rem;
      line-height: 150%;
    }
    #info .small-font{
      font-size: 1.1rem;
    }
    #info .border-font {
      font-family: 'GmarketSans-Bold'; font-size : 1.6rem
    }
  }





  @media screen and (max-width: 540px) {
    .absolute-img {
      top:93.5rem;
    }
    .lendingpage {
    padding: 3.5rem 0 0 0;
    }
    .inner {
      width: 90%;
    }
    .lending-title {
      font-size: 2.3rem;
      line-height: 150%;
      margin-top: 5rem;
    }
    .lending-title span{
      font-size: 2.5rem;
    }
    .lending-title span span{
      font-size: 2.7rem;
    }
    .lending-text {
      font-size: 1.15rem;
      line-height: 140%;
      margin-top: 4rem;
    }
    .lending-text span{
    font-size: 1.15rem; line-height:140%;
    }
    .lending-text span span{
      font-family: 'GmarketSans-Bold'; font-size : 1.3rem; line-height:150%
    }
    .img-size-50 {
      width: 100%;
    }
    .img-size-60 {
    width: 70%;
    height: auto;
    }
    .img-size-65 {
      width: 70%;
    }
    .img-size-width-60 {
      width: 95%;
    }
    .img-size-85 {
      width: 100%;
    }
    .img-size-100 {
    width: 95%;
    height: auto;
  }
    .landing-img {
        width: 100%;
        margin-top:4rem 
    }
    .lending-grayfont{
      font-size:0.8rem;
      margin: 2rem auto 0 auto;
      width: 90%;
      line-height: 1.2rem;
    }
    
    button {
      padding: 12px 25px;
      height: 3.2rem;
      font-size: 1rem;
      line-height: 100%;
    }
    .for {
      padding: 0.5rem 2rem; width: 10rem; height: 1.5rem;
      margin-top: 4rem;
      font-size: 1.1rem;
    }
    .title2 {
      word-break: keep-all;
      font-size: 1.7rem;
      line-height: 150%;
      width: 90%;
      margin-top: 6rem;
    }
    .text {
      width: 100%;
      font-size: 1.1rem;
      line-height: 150%;
      margin: 1.5rem 0 0 0;
    }
    .space{
      height: 12rem;;
    }
    .figure-layout{
      display:flex; justify-content:center; margin: 3rem auto 0 auto;
    }
    .two-imgs{
      flex-wrap: wrap;
    }
    .two-imgs figure{
      margin: 3rem auto 0 auto;
    }
    .two-imgs figure figcaption{
      font-size: 1rem;
      line-height: 120%;
      margin-bottom: 0.5rem;
    }
    .contact {
      margin: 10rem auto 0 auto;
    }
    .contact h1{
      word-break: keep-all;
      text-align: center;
      font-size: 1.9rem;
      line-height: 150%;
      width: 90%;
    }
    .contact p{
      word-break: keep-all;
      margin-top: 1rem;
      text-align: center;
      font-size: 1rem;
      line-height: 150%;
    }
    .contact .two-buttons{
      margin: 2rem auto 10rem auto;
      width: 100%;
    }
    #info {
      padding: 6rem 0 15rem 0;
    }
    #info .info-inner{
      padding : 1rem;
      width: 90%;
    }
    #info .info-inner p{
      font-size: 1.15rem;
      line-height: 190%;
      margin-top: 2rem;
      word-break: keep-all;
    }
    #info .benefit-info{
      margin-top: 4rem;
      width: 100%;
    }
    #info .benefit-info h1{
      font-size: 2rem;
    }
    #info li{
      word-break: keep-all;
      margin: 2rem 0 0 1rem;
      font-size: 1.1rem;
      line-height: 140%;
    }
    #info table{
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
    #info td{
      word-break: keep-all;
      font-size: 1.1rem;
      line-height: 150%;
    }
    #info .small-font{
      font-size: 0.9rem;
    }
    #info .border-font {
      word-break: keep-all;
      font-family: 'GmarketSans-Bold'; font-size : 1.35rem;
    }
  }


  @media screen and (max-width: 360px) {
    .absolute-img {
      top:98.5rem;
    }
  }
  @media screen and (max-width: 300px) {
    .absolute-img {
      top:104rem;
    }
    .lending-title {
      font-size: 2.1rem;
      line-height: 150%;
      margin-top: 5rem;
    }
    .lending-title span{
      font-size: 2.4rem;
    }
    .lending-title span span{
      font-size: 2.5rem;
    }
  } */
</style>
