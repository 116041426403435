<template>
  <table class="table" v-if="language === 'ko'">
    <thead>
      <tr>
        <th>수집 목적</th>
        <th>수집 항목</th>
        <th>수집 근거</th>
        <th>보유 기간</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="esg">
        <td>
          1도입 관련 상담/문의,<br />
          바우처 전송에 대한 처리
        </td>
        <td>이메일, 연락처, 기업명</td>
        <td>정보 주체의 동의</td>
        <td>동의 후 1년</td>
      </tr>
    </tbody>
  </table>
  <table class="table" v-if="language === 'en'">
    <thead>
      <tr>
        <th>Purpose of Collection</th>
        <th>Items of Collection</th>
        <th>Basis for Collection</th>
        <th>Period</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="esg">
        <td>
          User Identification & Engagement
        </td>
        <td>
          Email, Contact info,<br /> Company name
        </td>
        <td>
          Consent of <br /> 
          the data subject
        </td>
        <td>1 year</td>
      </tr>
    </tbody>
  </table>
</template>
  
<script>

export default {
  name : "PrivacyConsentTable",
  props: {
    language: String
  },
}
</script>

<style scoped>

  .table {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    margin-top: 1.3rem;
  }
  .table thead th{
    border: 0.5px solid rgb(139, 135, 135);
    font-family: "Pretendard-SemiBold";
    font-weight: 600;
    font-style: normal;
    font-size: 0.9rem;
    padding: 1rem 0 1rem 0;
    background-color: rgb(203, 203, 203)
  }

  .table tbody td{
    border: 0.5px solid rgb(139, 135, 135);
    padding: 1rem 0 1rem 0;
    font-size: 0.9rem;
    font-family: "Pretendard-Regular";
    font-weight: 400;
    background-color: #e3e3e3;
    text-align: center;
    vertical-align: middle
  }
  .table tbody td a{text-decoration: none; color: #FBFFFD;}
  .table tbody td a:hover{text-decoration: underline;}
</style>