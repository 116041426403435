<template>
  <div>
    <ContactModal
      v-if="modalshow && language == 'ko'"
      :modalshow="modalshow"
      @closemodal="modalshow = false"
      :modaldata="modaldata"
    />
    <ContactModal
      v-if="modalshow && language == 'en'"
      :modalshow="modalshow"
      @closemodal="modalshow = false"
      :modaldata="modaldataEN"
      :language="language"
    />
    <pageSection1 :language="language" v-show="this.$route.name == 'Partners'" />
    <div v-if="language == 'ko'">
      <div class="partner-p2">
        <div class="inner">
          <h1 class="Pretendard-600 title">
            우리의 협력은 더 나은 세상,<br />
            더 좋은 기업을 만들어가는 데<br class="mobile" />
            가장 중요한 양분입니다.
          </h1>
          <p class="Pretendard-500 text">
            더 나은 세상을 위해 각자의 분야에서 힘을 더해주신 파트너사들에게 감사합니다.<br
              class="display-off"
            />
            앞으로도 우수한 파트너십을 통해 우리 모두가 바라는 모습으로의 도약을 만들어내겠습니다.
          </p>
          <PartnersCarousel :height="'auto'" :itemsToShow="5" :mypartnerList="mypartnerList" />
          <!-- <PartnerList class="mt-100" :mypartnerList="mypartnerList" /> -->
          <section class="partners-contain">
            <div class="partners">
              <hr class="partners-hr" />
              <h1 class="partners-title Pretendard-600">
                i-ESG supports major sustainability communities and ESG standards, frameworks, and
                ratings
              </h1>
              <PartnersCarousel
                class="mt-100"
                :mypartnerList="partnerImages"
                :height="'auto'"
                :itemsToShow="5"
              />
              <div class="partners-logos">
                <!-- <div v-for="myPartner in myPartners" :key="myPartner" class="partners-logo">
                <img :src="require(`@/assets/imgs/Partners/partner_img/${myPartner.img_url}`)" alt="partner-logo" />
              </div> -->
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="partner-p3 display_none-680">
        <h3 class="display_none-880">
          더 많은 기업들과, 더 나은 세상을 만들어가는 여정에 함께해 주세요.
        </h3>
        <h3 class="display-880">더 나은 세상을 만들어가는 여정에 함께해 주세요.</h3>
        <button @click="modalshow = true">파트너 제휴 문의</button>
      </div>
    </div>

    <!-- EN ver -->

    <div v-if="language == 'en'">
      <div class="partner-p2">
        <div class="inner">
          <h1 class="Pretendard-600 title">
            We, with our reliable partners,<br />
            are fully committed to support for a better impact
          </h1>
          <p class="Pretendard-500 text">
            Great thanks to our partners working for a better world.<br />
            We promise to leap forward in a way everyone hopes with strong relationships with
            partners.
          </p>
          <!-- <PartnerList class="mt-100" :mypartnerList="mypartnerList" /> -->
          <PartnersCarousel :height="'auto'" :itemsToShow="5" :mypartnerList="mypartnerList" />
          <section class="partners-contain">
            <div class="partners">
              <hr class="partners-hr" />
              <h1 class="partners-title Pretendard-600">
                i-ESG supports major sustainability communities and ESG standards, frameworks, and
                ratings
              </h1>
              <PartnersCarousel
                class="mt-100"
                :mypartnerList="partnerImages"
                :height="'auto'"
                :itemsToShow="5"
              />
              <div class="partners-logos">
                <!-- <div v-for="myPartner in myPartners" :key="myPartner" class="partners-logo">
                <img :src="require(`@/assets/imgs/Partners/partner_img/${myPartner.img_url}`)" alt="partner-logo" />
              </div> -->
              </div>
            </div>
          </section>
          <!-- <section class="partner-contain">
          <card v-for="my_partner in my_partners" :key="my_partner" class="partner-card">
            <div class="partner-card-inner">
              <a href="" class="logo" />
              <h4 class="title Pretendard-700">기업명</h4>
              <p class="text Pretendard-400">렌드는... MSCI 선정 2022년 ESG 10대 트렌드는... MSCI 선정 2022년 ESG 10대 트렌드는...</p>
              <button class="report-button Pretendard-600">웹사이트  -></button>
            </div>
          </card>
        </section> -->
        </div>
      </div>
      <div class="partner-p3 display_none-680">
        <h3 class="display_none-880">Become a Partner</h3>
        <h3 class="display-880">Become a Partner</h3>
        <button @click="modalshow = true">Contact Us</button>
      </div>
    </div>
  </div>
</template>

<script>
import ContactModal from '../../components/Modal/ContactModal.vue'
import myPartners from '../../assets/js/partner.json'
import setMeta from '@/utils/setMeta'
import pageSection1 from '../pageSection1.vue'
import PartnersCarousel from './PartnersCarousel/PartnersCarousel.vue'

export default {
  name: 'partners',
  props: {
    language: String
  },
  beforeCreate() {
    setMeta({
      title: 'i-ESG | Partners',
      description: 'i-ESG와 함께하는 기업들',
      path: this.$route.path,
      keywords: 'ESG Partners,ESG 기업, ESG 통합관리, ESG, ESG 관리, ESG 솔루션'
    })
  },
  components: {
    ContactModal,
    pageSection1,
    PartnersCarousel
  },
  data() {
    return {
      mypartnerList: [
        require('../../assets/imgs/Partners/partner_img/posco-logo.png'),
        require('../../assets/imgs/Partners/partner_img/poscoint-logo.png'),
        require('../../assets/imgs/Partners/partner_img/kfq-logo.png'),
        require('../../assets/imgs/Partners/partner_img/shinkim-logo.png'),
        require('../../assets/imgs/Partners/partner_img/ncloud-logo.png'),
        require('../../assets/imgs/Partners/partner_img/springcamp-logo.png'),
        require('../../assets/imgs/Partners/partner_img/KoERI-logo.png'),
        require('../../assets/imgs/Partners/partner_img/albatroz-logo.png'),
        require('../../assets/imgs/Partners/partner_img/allganize-logo.png')
      ],
      myPartners: myPartners,
      modalshow: false,
      modaldata: {
        Mtitle: '파트너 제휴 문의',
        Mname: '담당자 이름 / 직급 (이름과 직급 사이에 슬래쉬(/) 기호 입력 필요)',
        Mposition: '담당자 직급',
        Mcompany: '회사명',
        Mdepartment: '담당 부서',
        Mphone: '연락처',
        Memail: '이메일 주소',
        Mquestions: '문의사항',
        Mbutton: '문의하기',
        Mtype: '파트너 제휴 문의'
      },
      modaldataEN: {
        Mtitle: 'Become a Partner',
        Mname: 'Name / Position',
        Mposition: 'Position',
        Mcompany: 'Company Name',
        Mdepartment: 'Department',
        Mphone: 'Phone',
        Memail: 'Business Email',
        Mquestions: 'Message',
        Mbutton: 'Submit',
        Mtype: '파트너 제휴 문의(EN)'
      }
    }
  },
  computed: {
    partnerImages() {
      return this.myPartners.map((partner) =>
        require(`@/assets/imgs/Partners/partner_img/${partner.img_url}`)
      )
    }
  }
}
</script>

<style scoped src="./partners.css"></style>
