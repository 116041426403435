<template>
  <div>
    <section id="section1">
      <div id="div1">
        <img src="../assets/imgs/iesgDV_img/DV_back1.png" alt="i-ESG 로고" id="iesg-back-logo" />
        <img src="../assets/imgs/iesgDV_img/DV_back2.png" alt="" id="iesg-back-img1" />
        <div class="inner">
          <h1 class="lending-title">
            막연한 ESG 관리,<br />
            <img src="../assets/imgs/iesgDV_img/iesg_logo.png" alt="i-ESG 로고" id="iesg-logo" />
            <img src="../assets/imgs/iesgDV_img/+.png" alt="" id="plus" />
            <span class="title-linear">클라우드 서비스 보급·확산 사업</span>으로<br />
            부담 없이 시작하세요.
          </h1>
          <button
            class="contact-button"
            id="contact1"
            type="button"
            @click="modalshow = true"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="600"
          >
            문의 신청 버튼
          </button>
          <p
            class="lending-text"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="600"
          >
            AI • 빅데이터 기반 ESG 특화 통합관리 SaaS 솔루션 기업 i-ESG는<br />
            <span class="content-linear">‘2023 중소기업 클라우드 서비스 보급·확산 사업’</span>
            <span id="green-span">공급기업</span>으로 선정되었습니다.
          </p>
          <h2 class="lending-sub-title">
            <a href="https://www.cloudsup.or.kr/" target="_blank" class="lending-a-tag"
              ><span class="to-right-underline">중소기업 클라우드 서비스 보급·확산 사업</span></a
            >이란?
          </h2>
          <span class="sub-text">*구 클라우드바우처 지원사업</span>
          <p class="lending-sub-text">
            중소기업 클라우드 서비스 보급·확산 사업은 국내 중소기업 업무 환경의 디지털 전환을
            위해<br />
            클라우드 서비스 도입 컨설팅 및 이용료를 지원하는 정부사업입니다.
          </p>
          <p class="text-ex">
            <span>전담기관: 정보통신산업진흥원</span><br />
            가공 서비스 각각의 세부 진행절차가 상이하니 참고바랍니다.
          </p>
          <div class="flex-row w1128">
            <div class="flex-column mlmt">
              <h3 class="box-title">지원자격</h3>
              <img src="../assets/imgs/iesgCloud_img/support2.png" alt="" class="data-img" />
            </div>
            <div class="flex-column">
              <h3 class="box-title">
                지원기간<span class="box-title-span mobile"
                  >(사전 신청 시, i-ESG에서 상세일정을 안내해드립니다.)</span
                >
              </h3>
              <img src="../assets/imgs/iesgCloud_img/support1.png" alt="" class="data-img" />
            </div>
          </div>
          <h2
            class="box-title mlmt"
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-duration="600"
          >
            지원절차
            <!-- <span id="side-span">(*22년 기준, 추후 상세 일정 업로드 예정)</span> -->
          </h2>
          <img
            src="../assets/imgs/iesgCloud_img/support3.png"
            alt=""
            class="ml"
            id="julcha-img"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="600"
          />
          <div id="gradient1">
            <hr />
            <h1 class="title2">
              클라우드 보급·확산 사업을<br />
              i-ESG와 함께해야 하는 이유
            </h1>
            <p class="sub-title2">
              급속도로 증가하는 ESG 규제와 의무, <span class="greenfont">ESG</span>는 이제 기업
              생존을 위한 <span class="greenfont">필수 요소</span>입니다.
            </p>
            <img
              src="../assets/imgs/iesgDV_img/img3.png"
              alt=""
              id="img3"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-offset="500"
              data-aos-duration="600"
            />
          </div>
        </div>
      </div>
      <div id="img4">
        <h3 class="title3">
          그러나,<br />
          시시각각으로 변하는 요구사항과 규제 •••
        </h3>
        <h3 class="title4">
          자체적으로 ESG를 대응해나가는 것은 <span class="goldfont">너무나도 어렵습니다.</span>
        </h3>
        <img src="../assets/imgs/iesgDV_img/img5.png" alt="" id="img5" />
      </div>
      <section id="section2">
        <img
          src="../assets/imgs/iesgDV_img/simbol.png"
          alt=""
          id="simbol"
          data-aos="example-anim3"
        />
        <h2 class="title5">
          i-ESG는 국내 대표그룹의 사내벤처로서 축적한 비즈니스 노하우와 ESG 전문성을 바탕으로<br />
          기업들의 어려움을 해소하는 <span class="greenfont">ESG 특화 통합관리 SaaS 솔루션</span>을
          제공합니다.
        </h2>
        <img src="../assets/imgs/iesgCloud_img/support4.png" alt="" id="img6" />
        <img src="../assets/imgs/iesgDV_img/img7.png" alt="" id="img7" />
        <img src="../assets/imgs/iesgDV_img/img8.png" alt="" id="img8" />
        <div class="flex-row w1130 mt94">
          <div class="flex-cloumn w398">
            <h3 id="AS-IS">AS-IS</h3>
            <img src="../assets/imgs/iesgDV_img/img9.png" alt="" id="img9" />
          </div>
          <img src="../assets/imgs/iesgDV_img/arrow.png" alt="" id="arrow-img" />
          <div class="flex-cloumn w631">
            <h3 id="TO-BE">TO-BE</h3>
            <img src="../assets/imgs/iesgCloud_img/support5.png" alt="" id="img10" />
          </div>
        </div>
      </section>
      <section id="section3">
        <h1 class="title6">
          <span class="title-linear">클라우드 서비스 보급·확산 사업,</span> 어떤기업에게 필요한가요?
        </h1>
        <div class="flex-row" id="img11s">
          <img
            src="../assets/imgs/iesgDV_img/img11-1.png"
            alt=""
            class="img11"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
          />
          <img
            src="../assets/imgs/iesgDV_img/img11-2.png"
            alt=""
            class="img11"
            data-aos="fade-up"
            data-aos-delay="150"
            data-aos-offset="200"
            data-aos-duration="600"
          />
          <img
            src="../assets/imgs/iesgDV_img/img11-3.png"
            alt=""
            class="img11"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-offset="200"
            data-aos-duration="600"
          />
        </div>
        <div class="gradation-box">
          <h3 id="title7">
            i-ESG 클라우드 보급·확산 사업 이용 시,
            <span class="green-font">최대 80%까지 지원 가능합니다.</span>
          </h3>
        </div>
        <img src="../assets/imgs/iesgCloud_img/support6.png" alt="" id="img12" />
        <h1 id="title9">기업생존의 필수, <span class="green-gradation">ESG 대응 및 관리</span></h1>
        <img src="../assets/imgs/iesgCloud_img/support7.png" alt="" id="img14" />
        <h1 id="title10">이제 시작하세요.</h1>
        <div id="button-box">
          <a href="https://i-esg.io" id="hp-bnt" target="_blank">i-ESG 홈페이지 이동</a>
          <button id="contact-bnt" type="button" @click="modalshow = true">문의 신청</button>
        </div>
        <p id="footer-text">AI • 빅데이터 기반 ESG 특화 통합관리 SaaS 솔루션 기업 i-ESG</p>
        <img src="../assets/imgs/iesgDV_img/img15.png" alt="" id="img15" />
        <img src="" alt="" />
      </section>
    </section>
    <ContactModal :modalshow="modalshow" @closemodal="modalshow = false" :modaldata="modaldata" />
  </div>
</template>

<script>
import ContactModal from '../components/Modal/ContactModal.vue'
import setMeta from '@/utils/setMeta'

export default {
  name: 'iesgCloud',
  beforeCreate() {
    setMeta({
      title: 'i-ESG Promotion',
      description:
        '막연한 ESG 관리, i-ESG X 클라우드 서비스 보급·확산 사업으로 부담없이 시작하세요.',
      path: this.$route.path,
      keywords:
        'ESG Trends, ESG Education, ESG, ESG 관리, ESG 솔루션, 클라우드 서비스 보급·확산 사업, 클라우드 바우처'
    })
  },
  components: {
    ContactModal
  },
  data() {
    return {
      modalshow: false,
      modaldata: {
        Mtitle: '클라우드 서비스 보급·확산 사업 문의 사항',
        Mname: '담당자 이름 / 직급 (이름과 직급 사이에 슬래쉬(/) 기호 입력 필요)',
        Mposition: '담당자 직급',
        Mcompany: '회사명',
        Mdepartment: '담당 부서',
        Mphone: '연락처',
        Memail: '이메일 주소',
        Mquestions: '문의사항',
        Mbutton: '문의하기',
        Mtype: '클라우드 문의 사항'
      }
    }
  }
}
</script>

<style scoped>
[data-aos='example-anim3'] {
  transform: rotate(180deg);
  opacity: 0;
  transition-property: transform, opacity;
}
.aos-animate {
  transform: rotate(0);
  opacity: 1;
}
#section1 {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: auto;
  background-color: #ffffff;
}
#div1 {
  width: 100%;
  height: 212.2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(0deg, #b3f3cc -29.2%, rgba(158, 239, 189, 0) 69.27%);
}
.inner {
  display: flex;
  flex-direction: column;
  width: 84%;
  height: auto;
}
.lending-title {
  font-family: 'Pretendard-Bold';
  font-size: 62px;
  line-height: 150%;
  color: #252636;
  margin-left: 2.8vw;
  margin-top: 10.1vw;
  letter-spacing: -0.02em;
}
#iesg-logo {
  width: 13vw;
}
#iesg-back-img1 {
  width: 41.9vw;
  position: absolute;
  left: 55.6vw;
  top: 21.8vw;
}
#plus {
  width: 2.5vw;
  margin: 0 1.5vw 0.2vw 1.5vw;
}
.title-linear {
  background: linear-gradient(89.98deg, #189bfa 18.16%, #001626 96.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  letter-spacing: -0.03em;
}
.content-linear {
  background: linear-gradient(89.98deg, #189bfa 18.16%, #001626 166.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
#iesg-back-logo {
  width: 43.5vw;
  position: absolute;
  left: 61.4vw;
  top: 6.5vw;
}
.contact-button {
  border: none;
  box-shadow: 0.2vw 0.3vw 0.7vw rgba(149, 196, 164, 0.72);
  border-radius: 3.5vw;
  cursor: pointer;
}
#contact1 {
  font-family: 'Pretendard-Bold';
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(100deg, #26ca7b -3.76%, #59dd8c 64.13%, #47e484 88.44%);
  width: 18.2vw;
  height: 6vw;
  margin-top: 11.5vw;
  margin-left: 2.8vw;
  font-size: 2.1vw;
  color: #ffffff;
}
.lending-text {
  font-family: 'Pretendard-Bold';
  font-size: 2.1vw;
  line-height: 150%;
  color: #252636;
  margin-left: 2.8vw;
  margin-top: 6.2vw;
  word-break: keep-all;
}
#green-span {
  color: #0fba51;
  line-height: 150%;
  font-family: 'Pretendard-Bold';
  font-size: 2.1vw;
}
.lending-sub-title {
  font-family: 'Pretendard-ExtraBold';
  margin-left: 2.8vw;
  font-size: 3.5vw;
  margin-top: 13.7vw;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #252636;
}
.sub-text {
  font-family: 'Pretendard-SemiBold';
  margin-left: 2.8vw;
  margin-top: 0.5vw;
  font-size: 20px;
  line-height: 150%;
  color: #707872;
}
.lending-a-tag {
  font-family: 'Pretendard-ExtraBold';
  font-size: 3.5vw;
  line-height: 130%;
  color: #252636;
}
.to-right-underline {
  position: relative;
}
.to-right-underline:after {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: -0.7vw;
  left: 0;
  max-height: 5px;
  width: 0px;
  height: 0.3vw;
  margin: 0.2vw 0 0;
  transition: all 0.25s ease-in-out;
  transition-duration: 0.3s;
  opacity: 0;
  background-color: #0fba51;
  border-radius: 0.3vw;
}
.to-right-underline:hover:after {
  width: 100%;
  opacity: 1;
}
.lending-sub-text {
  font-family: 'Pretendard-Medium';
  margin-left: 2.8vw;
  margin-top: 2.4vw;
  line-height: 150%;
  font-size: 1.8vw;
}
.text-ex {
  font-family: 'Pretendard-Medium';
  font-size: 1.4vw;
  margin-left: 2.8vw;
  margin-top: 1.5vw;
  line-height: 150%;
  color: #707872;
}
.text-ex span {
  font-family: 'Pretendard-SemiBold';
}
.flex-row {
  display: flex;
  justify-content: space-between;
}
.w1128 {
  width: 81vw;
}
.flex-column {
  display: flex;
  flex-direction: column;
  margin-top: 5vw;
}
.data-img {
  width: 37.7vw;
  box-shadow: 0px 0px 30px rgba(191, 206, 196, 0.5);
  border-radius: 1.4vw;
  margin-top: 1.3vw;
}
.box-title {
  font-family: 'Pretendard-Bold';
  font-size: 2.1vw;
  line-height: 130%;
  display: flex;
  align-items: flex-end;
  color: #252636;
}
#side-span {
  font-family: 'Pretendard-Medium';
  font-size: 1.4vw;
  line-height: 130%;
  display: flex;
  align-items: flex-end;
  color: #707872;
  margin-left: 0.7vw;
}
.box-title-span {
  font-family: 'Pretendard-Bold';
  font-size: 1.24vw;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  color: #3f9f6b;
  margin-left: 0.6vw;
}
.mlmt {
  margin-left: 2.8vw;
  margin-top: 5vw;
  box-sizing: border-box;
}
.box {
  margin-top: 1.3vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.9vw 3.1vw 10.6vw;
  gap: 1.3vw;
  width: 37.7vw;
  height: 14.7vw;
  background: #ffffff;
  box-shadow: 0 0 30px rgba(191, 206, 196, 0.5);
  border-radius: 20px;
  box-sizing: border-box;
}
.box-text {
  font-family: 'Pretendard-SemiBold';
  line-height: 150%;
  font-size: 1.5vw;
  color: #404046;
  flex: none;
}
.ml {
  margin-left: 2.8vw;
}
.box-span {
  font-family: 'Pretendard-SemiBold';
  line-height: 150%;
  font-size: 1.1vw;
  color: #404046;
  flex: none;
}
#julcha-img {
  width: 78.4vw;
  margin-top: 1.3vw;
}
#gradient1 {
  position: absolute;
  width: 100%;
  height: 102.2vw;
  left: 0;
  top: 134.4vw;
  background: linear-gradient(
    180deg,
    rgba(163, 163, 167, 0) 0%,
    rgba(59, 59, 68, 0.44) 7.81%,
    #282829 17.19%,
    #282829 73.96%,
    rgba(40, 40, 41, 0.08) 100%
  );
  display: flex;
  flex-direction: column;
}
hr {
  margin: 0 auto;
  width: 0.1vw;
  height: 23.3vw;
  border: 0.1vw solid #e8fbf0;
  background: #e8fbf0;
}
.title2 {
  font-family: 'Pretendard-Bold';
  font-size: 4.3vw;
  line-height: 130%;
  text-align: center;
  color: #cbf7dc;
  margin-top: 3.1vw;
}
.sub-title2 {
  font-family: 'Pretendard-Bold';
  font-size: 1.8vw;
  line-height: 150%;
  color: #bacfc4;
  text-align: center;
  margin-top: 2.8vw;
}
.greenfont {
  color: #43d384;
}
#img3 {
  width: 85vw;
  margin: -10vw auto;
}
#img4 {
  width: 100%;
  height: 56vw;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -3vw;
  background-image: url(../assets/imgs/iesgDV_img/img4.png);
  display: flex;
  flex-direction: column;
}
.title3 {
  font-family: 'Pretendard-Medium';
  line-height: 150%;
  text-align: center;
  color: #eeeeee;
  font-size: 2.5vw;
  position: absolute;
  top: 218.1vw;
  left: 31.1vw;
}
.title4 {
  font-family: 'Pretendard-Medium';
  line-height: 150%;
  text-align: center;
  color: #eeeeee;
  font-size: 2.5vw;
  position: absolute;
  top: 230.3vw;
  left: 22.1vw;
}
.goldfont {
  color: #be9e68;
  font-family: 'Pretendard-ExtraBold';
}
#img5 {
  width: 78.5vw;
  margin: 32.3vw auto;
}
#section2 {
  width: 100%;
  height: 104.2vw;
  background: linear-gradient(180deg, #eff8f2 17.71%, rgba(255, 255, 255, 0) 77.13%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
#simbol {
  width: 5.5vw;
  margin-top: 9.7vw;
}
.title5 {
  font-family: 'Pretendard-Bold';
  font-size: 2.1vw;
  line-height: 150%;
  text-align: center;
  color: #252636;
  margin-top: 3.8vw;
}
#img6 {
  width: 38.8vw;
  margin-top: 4.9vw;
}
#img7 {
  width: 52.6vw;
  position: absolute;
  left: 47.6vw;
  top: 311.7vw;
  z-index: -1;
}
#img8 {
  width: 26.9vw;
  position: absolute;
  left: 11.5vw;
  top: 333.5vw;
  z-index: -1;
}
#AS-IS {
  font-family: 'Pretendard-Bold';
  font-size: 2.8vw;
  line-height: 150%;
  text-align: center;
  color: #808080;
}
#TO-BE {
  background: linear-gradient(90deg, #7de7a7 -63.52%, #009d65 110.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: 'Pretendard-Bold';
  font-size: 2.8vw;
  line-height: 150%;
  text-align: center;
}
.w1130 {
  width: 78.5vw;
}
.w398 {
  width: 27.6vw;
}
.w631 {
  width: 43.8vw;
}
.mt94 {
  margin-top: 6.5vw;
}
#img9 {
  width: 27.6vw;
  margin-top: 2.4vw;
}
#arrow-img {
  width: 19.75vw;
  position: absolute;
  left: 38.5vw;
  top: 306.8vw;
}
#img10 {
  width: 43.8vw;
}
#tobe-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.1vw 2.8vw;
  gap: 0.7vw;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(191, 206, 196, 0.5);
  border-radius: 48px;
  width: 43.8vw;
  height: 23.8vw;
  margin-top: 2.4vw;
  box-sizing: border-box;
}
#tobe-title {
  font-family: 'Pretendard-Bold';
  font-size: 2.4vw;
  line-height: 2.9vw;
  text-align: center;
  background: linear-gradient(90deg, #7de7a7 -14.51%, #009d65 110.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
#section3 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title6 {
  font-family: 'Pretendard-Bold';
  font-size: 3.1vw;
  line-height: 130%;
  text-align: center;
  margin-top: 4vw;
}
.green-gradation {
  background: linear-gradient(90deg, #7de7a7 -14.51%, #009d65 49.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.img11 {
  width: 25.4vw;
}
#img11s {
  width: 78.8vw;
  margin-top: 3.5vw;
}
.gradation-box {
  background: linear-gradient(90deg, #fefffe 0%, #ffffff 75.45%, #f5fff9 100%);
  box-shadow: 0px 0px 30px rgba(191, 206, 196, 0.5);
  width: 78.5vw;
  height: 6.1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.2vw;
  border-radius: 20px;
}
#title7 {
  font-family: 'Pretendard-Bold';
  font-size: 2.2vw;
  line-height: 130%;
  text-align: center;
  color: #000000;
}
#title8 {
  font-family: 'Pretendard-Bold';
  font-weight: 700;
  font-size: 2.2vw;
  line-height: 160%;
  text-align: center;
  color: #000;
  margin-top: 5.5vw;
}
.green-font {
  color: #009644;
}
#img12 {
  width: 49.9vw;
  margin-top: 5.5vw;
}
#img13 {
  position: absolute;
  width: 8.5vw;
  left: 51.1vw;
  top: 424.15vw;
  z-index: 4;
}
#title9 {
  font-family: 'Pretendard-Bold';
  font-size: 4.5vw;
  line-height: 130%;
  text-align: center;
  color: #252636;
  margin-top: 19.4vw;
}
#title10 {
  font-family: 'Pretendard-Bold';
  font-size: 4.5vw;
  line-height: 130%;
  text-align: center;
  color: #252636;
  margin-top: 1.1vw;
}
#img14 {
  width: 57.4vw;
  margin-top: 1.7vw;
}
#button-box {
  width: 42.9vw;
  display: flex;
  justify-content: space-between;
  margin-top: 10.6vw;
}
#hp-bnt {
  border: 0.3vw solid transparent;
  width: 22.6vw;
  height: 6.6vw;
  border-radius: 50px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(278.64deg, #008666 -3.76%, #11c785 64.13%, #008a71 88.44%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  cursor: pointer;
  font-family: 'Pretendard-Bold';
  font-size: 2.1vw;
  color: #008f6d;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: keep-all;
  margin: auto;
  text-align: center;
}
#contact-bnt {
  background: linear-gradient(278.64deg, #26ca7b -3.76%, #59dd8c 64.13%, #47e484 88.44%);
  border-radius: 50px;
  width: 16vw;
  height: 6.6vw;
  border: none;
  font-family: 'Pretendard-Bold';
  font-size: 2.1vw;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#footer-text {
  font-family: 'Pretendard-Medium';
  font-size: 1.4vw;
  line-height: 150%;
  text-align: center;
  color: #737373;
  margin-top: 8.6vw;
  margin-bottom: 4.9vw;
}
#img15 {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 97.8vw;
  top: 371vw;
}

/* @media screen and (max-width: 500px) {
        .mobile{
          display: none;
        }
        #contact1 {
        width: 80px;
        }
        .box-text{
          width: 100%;
          line-height: 130%;
          transform: translate(-5%, 5%) scale(0.7);
          display: inline-block;
        }
        #gradient1{
        height: 91.2vw;
        top: 145.5vw;
        background: linear-gradient(180deg, rgba(163, 163, 167, 0) 0%, rgba(59, 59, 68, 0.44) 7.81%, #282829 17.19%, #282829 73.96%, rgba(40, 40, 41, 0.08) 100%);
        display: flex;
        flex-direction: column;
        }
        .gradation-box {
          width: 91vw;
        }
        #button-box{
          width: 46vw;
        }
        #hp-bnt {
          width: 24.8vw;
        }
        #side-span{
          transform: translate(-12.5%, -5%) scale(0.73);
          display: inline-block;
        }
    
      } */
</style>
