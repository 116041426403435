<template>
<div>
  <div class="inner">
    <div class="title">
      <hr>
      <h1>
        i-ESG EDUCATION PROGRAM
      </h1>
      <hr>
    </div>
  </div>
  <div class="inner">
    <div class="subtitle">
      <p>
        * i-ESG education program is currently available in Korea.
      </p>
    </div>
  </div>
  <Carousel :wrapAround="true">
    <Slide v-for="(edu) in edus" :key="edu">
      <div v-show="edu.img.length > 3" class="carousel__item" :style="{ backgroundImage : `url('${edu.img}')`}"></div>
      <div v-show="edu.img.length < 3" class="carousel__item" :style="{ backgroundImage : `url(${require('@/assets/imgs/Insights_img/Edu_img/edu_1.jpg')}`}">
        <iframe src="https://www.youtube.com/embed/x9xRwhFNMyc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      
    </Slide>
    <template #addons>
      <Pagination />
      <navigation>
      </navigation>
    </template>
  </Carousel>
  <!-- <div class="button">
    <button type="button" class="contact" @click="questionsdata(), modalshow = true">교육 문의하기 ></button>
      <button type="button" class="download" @click="modalshow = true, downdata()">
        제안서 다운로드 >
      </button>
  </div> -->
  <!-- <div class="inner">
    <div class="title">
      <hr>
      <h2>CONTENTS</h2>
      <hr>
    </div>
  </div>
  <div class="card_inner">
    <a v-for="post in 3" :key ="post">
      <article class="card">
        <img :src='imgSrc + post' class="img"/>
        <div class="description">
          <h3 class="Pretendard-600 title">준비중 입니다.</h3>
          <p class="Pretendard-400">
            준비중
          </p>
        </div>
      </article>
    </a>
  </div> -->
  <DownloadModal v-if="modalshow" :modaldata="modaldata" :modalshow="modalshow" @closemodal="modalshow = false" />
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import DownloadModal from "../Modal/ContactModal"

// import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'Basic',
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
    DownloadModal,
  },
  data() {
    return {
      imgSrc : 'https://placeimg.com/640/480/tech?random=',
      edus : [
        {img: 'b'},
        {img: require("@/assets/imgs/Insights_img/Edu_img/edu_2.jpg")},
        {img: require("@/assets/imgs/Insights_img/Edu_img/edu_3.jpg")},
        {img: require("@/assets/imgs/Insights_img/Edu_img/edu_4.jpg")}
      ],
      a : 0,
      modalshow : false,
      modaldata : {
        Mtitle : '',
        Mname : '',
        Mposition: '',
        Mcompany: '',
        Memail : '',
        Mdepartment : '',
        Mdownload : '',
        Mbutton : '',
        Mphone : '',
        Mquestions : '',
        Mtype : ''
      }
    }
  },
  methods : {
    downdata() {
      this.modaldata.Mtitle = '제안서 다운로드',
      this.modaldata.Mname = '담당자 이름 / 직급 (이름과 직급 사이에 슬래쉬(/) 기호 입력 필요)',
      this.modaldata.Mcompany= '회사명',
      this.modaldata.Memail = '이메일 주소',
      this.modaldata.Mdepartment = '담당 부서',
      this.modaldata.Mdownload = '제안서 다운로드',
      this.modaldata.Mbutton = false,
      this.modaldata.Mphone = '연락처'
      this.modaldata.Mposition = '담당자 직급'
      this.modaldata.Mtype = '제안서 다운로드'
    },
    questionsdata() {
      this.modaldata.Mtitle = '교육 문의',
      this.modaldata.Mname = '담당자 이름 / 직급 (이름과 직급 사이에 슬래쉬(/) 기호 입력 필요)',
      this.modaldata.Mposition = '담당자 직급',
      this.modaldata.Mcompany= '회사명',
      this.modaldata.Memail = '이메일 주소',
      this.modaldata.Mphone = '연락처'
      this.modaldata.Mdepartment = '담당 부서',
      this.modaldata.Mdownload = false,
      this.modaldata.Mquestions = '문의사항',
      this.modaldata.Mbutton = '문의하기'
      this.modaldata.Mtype = '교육 문의'
    }
  }
});
</script>

<style scoped>
  .inner {
    margin: 0 auto 0 auto;
    width: 75vw;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
  }
  .title {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 8rem;
    font-family: "Pretendard-Bold";
    font-weight: 700;
    font-style: normal;
    font-size: 2.3rem;
    color: rgb(82, 82, 82);
  }

  .subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    font-family: "Pretendard-Medium";
    font-size: 0.75rem;
    color: rgb(167, 167, 167);
    line-height: 120%;
  }
  .title h1 {
    width: 35vw;
    text-align: center;
  }
  .title h2 {
    width: 25vw;
    text-align: center;
  }
  iFrame {
    width: 29.3vw;
    height: 19.36vw;
    position: absolute;
    top: 11vw;
    right: 24.38vw;
  }
  .button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 37rem;
    margin: auto;
    padding-bottom: 5rem;
  }
  .button .contact {
    margin-top: 0;
    width: 12rem;
    height: 3.3rem;
    border: 0;
    font-size: 1.2rem;
    font-family: "Pretendard-Bold";
    font-weight: 700;
    font-style: normal;
    background: #000000;
    color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 5px;
  }
  .button .contact:hover {
  background-color: #D3E4DD;
  transition-duration:0.3s;
  color: rgb(0, 0, 0);
  }
  .button .download {
    width: 12rem;
    height: 3.3rem;
    border: 0;
    font-size: 1.2rem;
    font-family: "Pretendard-Bold";
    font-weight: 700;
    font-style: normal;
    background: #000000;
    color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 5px;
    margin: 0;
  }
  .button .download:hover {
  background-color: #D3E4DD;
  transition-duration:0.3s;
  color: rgb(0, 0, 0);
  }

  .carousel {
  padding-top: 6rem;
  }
  
  .carousel__item {
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
    min-height: 42.2vw;
    width: 75vw;
    border-radius: 8px;
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .carousel__slide {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

:deep(.carousel__prev),
:deep(.carousel__next) {
  border: 1px solid #B3B3B3;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 0;
  /* margin : 0 5vw; */
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.25);
  
}

:deep(.carousel__prev) {
  top: 50%;
  left: 8vw;
  transform: translate(-50%, -50%);
}

:deep(.carousel__next) {
  top: 50%;
  right: 8vw;
  transform: translate(50%, -50%);
}

:deep(.carousel__pagination-button) {
  background-color: rgb(156, 156, 156);
}

:deep(.carousel__pagination-button--active) {
  background-color: rgb(73, 73, 73);
}
:deep(.carousel__pagination) {
  padding-bottom: 6rem;
}
hr {
  margin: 0 auto 0 auto;
  height: 2px;
  width: 75vw;
  background: rgb(0, 0, 0);
  border: 0;
  opacity: 0.5;
  
}
.card {
  width: clamp(220px, 18.75vw, 375px);
  height: clamp(300px, 21.8rem, 500px);
  border: 0.5px solid #e0e0e0;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.card_inner{
  margin: 6rem auto 100px auto;
  width: clamp(700px ,59.72vw, 1200px);
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-content: space-between */
}
a {
  width: clamp(220px, 18.75vw, 375px);
  height: clamp(300px, 21.8rem, 500px);
  margin-bottom: 4.688rem;
}
.card .img{
  width: 100%;
  height: 8.75rem;
  background-size: cover;
}
.card .description {
  height: calc(100% - 8.75rem - 44px);
  padding: 22px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000;
}
.description h3 {
  text-align: center;
  margin: 0;
  padding-top: 0;
  font-size: clamp(14px ,1.25rem ,28px);
  font-family: "Pretendard-Bold";
  font-weight: 700;
  font-style: normal;
  line-height: 130%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3 ;
  -webkit-box-orient: vertical;
}
.description p {
  padding-bottom: 3.5rem;
  text-align: center;
  font-size: clamp(13px, 1rem, 19px);
  line-height: 130%;
  color: #888888;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3 ;
  -webkit-box-orient: vertical;
}


/* @media screen and (max-width: 1050px) {
  .card_inner{
    width: clamp(685px ,59.72vw, 1000px);
  }
  .card .description {
    height: calc(100% - 8.75rem - 38px);
    padding: 19px;
  }
  .description h3 {
    font-size: clamp(18px ,1.25rem ,24px);
  }
  .description p {
    font-size: clamp(16px, 1rem, 19px);
  }
  .inner {
    width: 85vw;
  }
  .title h1 {
    width: 45vw;
    text-align: center;
  }
  .title h2 {
    width: 35vw;
  }
  .title {
    padding-top: 4rem;
    font-size: 2rem;
  }
  iFrame {
    width: 29.3vw;
    height: 19.36vw;
    position: absolute;
    top: 11.35vw;
    right: 24.38vw;
  }
  :deep(.carousel__pagination) {
  padding-bottom: 3.5rem;
}

  :deep(.carousel__prev),
  :deep(.carousel__next) {
    width: 35px;
    height: 35px;
  }
}
@media screen and (max-width: 700px) {
  .card_inner{
    width: clamp(450px ,59.72vw, 700px);
  }
  .card .description {
    height: calc(100% - 8.75rem - 28px);
    padding: 14px;
  }
  .description h3 {
    font-size: 14px
  }
  .description p {
    font-size: 13px
  }
  .button {
    width: 100%;
  }
  .inner {
    width: 85vw;
  }
  .title h1 {
    width: 45vw;
    text-align: center;
  }
  .title h2 {
    width: 35vw;
  }
  .title {
    padding-top: 4rem;
    font-size: 2rem;
  }
  iFrame {
    top: 12vw;
  }
  :deep(.carousel__pagination) {
  padding-bottom: 3.5rem;
}

  :deep(.carousel__prev),
  :deep(.carousel__next) {
    width: 35px;
    height: 35px;
  }
  .card {
    width: clamp(120px, 45vw, 210px);
    height: clamp(240px, 18rem, 500px);
  }
  a {
    width: clamp(120px, 45vw, 210px);
    height: clamp(240px, 18rem, 500px);
    margin-bottom: 3.8rem;
  }
}

@media screen and (max-width: 480px) {

  .card .description {
    height: calc(100% - 8.75rem - 24px);
    padding: 12px;
  }
  .card .description h3 {
    -webkit-line-clamp: 2 ;
  }
  .card_inner{
    width: clamp(260px ,93.75vw, 450px);
  }
  .inner {
    width: 85vw;
  }
  .title h1 {
    width: 45vw;
    text-align: center;
  }
  .title h2 {
    width: 35vw;
  }
  .title {
    padding-top: 4rem;
    font-size: 1.5rem;
  }
  iFrame {
    top: 12.7vw;
  }
  :deep(.carousel__pagination) {
  padding-bottom: 3.5rem;
}

  :deep(.carousel__prev),
  :deep(.carousel__next) {
    width: 28px;
    height: 28px;
  }
  :deep(.carousel__prev) {
    left: 6.6vw;
  }

  :deep(.carousel__next) {
    right: 6.6vw;
  }
}

@media screen and (max-width: 395px) {
  iFrame {
    width: 29.4vw;
    height: 19.5vw;
    position: absolute;
    top: 13.2vw;
    right: 24.5vw;
  }
} */
</style>