<template>
  <div class="pagination-container">
    <div class="previous-button-container">
      <button class="first-button" @click="goToFirst()">&lt;&lt;</button>
      <button class="previous-button" @click="goToPrevious()">&lt;</button>
    </div>
    <ul class="pagination">
      <li v-for="page in pages" :key="page" :class="{ 'active': page === currentPage }" @click="goToPage(page)">
        {{ page }}
      </li>
    </ul>
    <div class="back-button-container">
      <button class="next-button" @click="goToNext()">></button>
      <button class="last-button" @click="goToLast()">>></button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props : {
    currentPage : Number,
    totalPages : Number
  },
  computed: {
    pages() {
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }
  },
  methods: {
    goToPage(page) {
      this.$emit('changePage', page);
      // You can add your custom logic here to handle pagination
    },
    goToFirst() {
      this.$emit('changePage', 1);
    },
    goToLast() {
      this.$emit('changePage', this.totalPages);
    },
    goToPrevious() {
      this.$emit('changePage', Math.max(1, this.currentPage - 1));
      // this.currentPage = Math.max(1, this.currentPage - 1);
    },
    goToNext() {
      this.$emit('changePage', Math.min(this.totalPages, this.currentPage + 1));
      // this.currentPage = Math.min(this.totalPages, this.currentPage + 1);
    }
  }
}
</script>

<style>
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
}

.previous-button-container{
  display: flex;
  margin-right: 11px;
}

.back-button-container {
  display: flex;
  margin-left: 11px;
}

.first-button,
.previous-button,
.next-button,
.last-button {
  width: 20px;
  height: 20px;
  padding: 6px;
  border: 1px solid #D9D9D9;
  font-size: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:transparent;
  box-sizing: border-box;
  color: #5F5F5F;
}

.first-button {
  border-right: none;
}
.last-button {
  border-left: none;
}

.first-button:hover,
.previous-button:hover,
.next-button:hover,
.last-button:hover {
  background-color: none;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  background-color:transparent;
  border: 1px solid #D9D9D9;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #999999;
  margin: 0 2px;
  box-sizing: border-box;
}

.pagination li.active {
  border: 1px solid #5F5F5F;
  color: #5F5F5F;
}


/* .pagination li:hover {
  background-color: #ccc;
} */
</style>
