<template>
  <div>
    <div class="insights-p1">
      <div class="inner">
        <hr />
        <h4 class="Pretendard-600">ESG Intelligence</h4>
      </div>
    </div>
    <div class="tab">
      <section class="insight-tab">
        <div
          :class="{ tab1: tab == 'ESG Trend' }"
          @click="$emit('moveTab', 'ESG Trend'), (posts = 9)"
          class="Pretendard-700"
        >
          ESG Trends
        </div>
        <hr />
        <div
          :class="{ tab1: tab == 'ESG Education' }"
          @click="$emit('moveTab', 'ESG Education'), (posts = 9)"
          class="Pretendard-700"
        >
          ESG Educations
        </div>
        <!-- <div :class="{tab1 : tab == 'ESG Template'}" @click="$emit('moveTab', 'ESG Template') ,posts = 9" class="Pretendard-700 ">ESG Template</div> -->
      </section>
      <section class="mobile">
        <div
          @click="$emit('moveTab', 'ESG Trend'), (posts = 9)"
          :class="{ tab1: tab == 'ESG Trend' }"
          class="Pretendard-700"
        >
          Trends
        </div>
        <hr />
        <div
          @click="$emit('moveTab', 'ESG Education'), (posts = 9)"
          :class="{ tab1: tab == 'ESG Education' }"
          class="Pretendard-700"
        >
          Educations
        </div>
        <!-- <div @click="$emit('moveTab', 'ESG Template')" :class="{tab1 : tab == 'ESG Template'}" class="Pretendard-700 ">Template</div> -->
      </section>
    </div>
    <div v-if="tab == 'ESG Trend'">
      <div class="more-p1">
        <Post
          :language="language"
          :imgSrc="imgSrc"
          :ko_new_length="ko_new_length"
          :ko="ko"
          :glo="glo"
          :posts="posts"
          @PostInit="posts = $event"
        />
        <!-- <PostEN :glo="glo" :news="news" :posts="posts" /> -->
        <button v-if="language === 'ko'"  @click="more(ko)" class="more_button Pretendard-500">더보기</button>
        <button v-if="language === 'en'"  @click="more(ko)" class="more_button Pretendard-500">More</button>
      </div>
    </div>
    <div v-if="tab == 'ESG Seminar'">
      <div class="more-p1">
        <!-- <input placeholder="?" v-model="findWord" /> -->
        <PostSemi
          :language="language"
          :semi="semi"
          :imgSrc="imgSrc"
          :posts="posts"
          :findWord="findWord"
        />
        <button @click="more(semi)" class="more_button Pretendard-500">더보기</button>
      </div>
    </div>
    <div v-if="tab == 'ESG Education'">
      <div class="more-edu">
        <PostEdu :language="language" />
      </div>
    </div>
    <div v-if="tab == 'ESG Template'">
      <div class="more-edu">
        <PostTemp :language="language" :temp="temp" />
      </div>
    </div>
  </div>
</template>

<script>
import Post from './post/Post.vue';
import PostSemi from './post/Post_semi.vue';
import PostEdu from './post/Post_edu.vue';
import PostTemp from './post/Post_temp.vue';
import setMeta from '@/utils/setMeta';

export default {
  name: 'Insights_more',
  beforeCreate() {
    setMeta({
      title: 'i-ESG | ESG Trends',
      description: 'i-ESG를 통해 우리회사의 ESG를 체계적으로 관리하고 다양한 컨텐츠를 만나보세요',
      path: this.$route.path,
      keywords: 'ESG Trends ,ESG, ESG 관리, ESG 솔루션'
    });
  },
  components: {
    Post,
    PostSemi,
    PostEdu,
    PostTemp
  },
  props: {
    tab: String,
    semi: Array,
    temp: Array,
    ko: Array,
    glo: Array,
    imgSrc: String,
    ko_new_length: String,
    language: String
  },
  data() {
    return {
      더보기: 0,
      posts: 9,
      findWord: ''
    };
  },

  // mounted() {
  //   this.emitter.on('moveTab', (a)=>{
  //     this.isAtive = a
  //   })
  // },
  // setup() {
  //   let follower = ref([]);
  //   function search(검색어){
  //     let newFollower = follower.value.filter((a)=>{
  //         return a.name.indexOf(검색어) != -1
  //     });
  //   follower.value = [...newFollower]
  //   }

  // return {follower, search}
  // },

  methods: {
    more(s) {
      if (s.length <= this.posts) {
        // pass
      }
      if (s.length - 6 > this.posts) {
        this.posts = this.posts + 6;
      } else {
        this.posts = this.posts + (s.length - this.posts);
      }
    }
    // more() {
    //   axios.get(`http://223.130.162.200:8003/API/dataset/${20 + this.더보기}`)
    //   .then(result => {
    //     this.emitter.emit('new_more', result)
    //     this.emiiter.emit('더보기', 6)
    //     })
    //   }
  }
};
</script>
<style scoded src="./insights_more.css"></style>