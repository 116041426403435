<template>
  <div v-if="temp">
    <div class="insights-p1">
      <div class="inner">
        <hr />
        <h4 class="Pretendard-600">ESG Intelligence</h4>
      </div>
    </div>
    <section>
      <div class="inner">
        <article>
          <h1 class="main-title Pretendard-700">ESG Template</h1>
          <table class="table">
            <colgroup>
              <col width="125px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>제목</th>
                <td class="title Pretendard-600">
                  {{ temp.results[temp.results[0].properties.태그.number - $route.params.id].properties.제목.title[0].text.content }}
                </td>
              </tr>
              <tr>
                <th>작성일</th>
                <td class="Pretendard-400">{{temp.results[temp.results[0].properties.태그.number - $route.params.id].properties.등록일.date.start}}</td>
              </tr>
              <tr>
                <th>첨부파일</th>
                <td class="Pretendard-400">
                  <a :href="temp.results[temp.results[0].properties.태그.number - $route.params.id].properties.템플릿.files[0]?.file.url">
                    {{temp.results[temp.results[0].properties.태그.number - $route.params.id].properties.템플릿.files[0]?.name}}
                  </a>
                </td>
              </tr>
              <tr class="contents">
                <td class="content Pretendard-400" colspan="2" name="contn">
                  <img v-if="imgtrue()" :src="temp.results[temp.results[0].properties.태그.number - $route.params.id].properties.이미지.files[0]?.file.url" alt="">
                  <p class="text" v-html="tempText[temp.results[0].properties.태그.number - $route.params.id]"></p>
                </td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "TempDetail",
  props : {
    temp : Array,
    tempText : Array,
  },
  methods: {
    imgtrue() {
      if (this.temp.results[this.temp.results[0].properties.태그.number - this.$route.params.id].properties.이미지.files[0]?.file.url != null) {
        return true
      } else {
        return false
      }
    }
  },
};
</script>

<style scoped>
  section .inner {
    margin: 0 auto 0 auto;
    padding: 10rem 0 10rem 0;
    width: 1350px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .inner .main-title {
    margin-right: auto;
    margin-left: 3rem;
    padding-bottom: 10rem;
    font-size: 3rem;
  }
  .table{
    border-collapse: collapse; 
    width: 90%; 
    margin: auto;
  }
  .table tbody th{
    align-items: center;
    border: 0.5px solid rgb(17, 17, 17);
    border-width: 0.5px 0; 
    font-family: "Pretendard-SemiBold";
    font-weight: 600;
    font-style: normal;
    font-size: 1.1rem;
    padding: 1rem 0 1rem 0;
    background-color: #e7fcf1;
  }

  .table tbody td{
    border: 0.5px solid rgb(17, 17, 17);
    padding: 1.8rem;
    font-size: 1.1rem;
    color: #000;
    border-width: 0.5px 0 0 0.5px; 
    line-height: 130%;
  }
  .table tbody .content {
    padding: 2rem;
    border-width: 0 ;
    font-size: 1rem;
  }
  .table tbody .content img {
    width: 80%;
    height: 80%;
    margin: 6rem 7rem 4rem 7rem;
    object-fit: contain;
  }
  .table tbody .content .text {
    font-size: 1rem;
    margin: 4rem 3rem 8rem 3rem;
    line-height: 160%;
  }
  .table tbody tr{
    border-bottom: 1px solid rgb(201, 201, 201);
  }
  .table td:first-child, .table th:first-child{width: 20%;}

  .table tbody td a{text-decoration: none; color: black;}
  .table tbody td a:hover{text-decoration: underline;}
</style>