import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import router from './router.js';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'aos/dist/aos.css';
import mitt from 'mitt';
import VueGtag from 'vue-gtag-next';
import { createMetaManager } from 'vue-meta';
import dayjs from 'dayjs';
import i18n from './i18n';
// import ChannelService from './channel-service';
// import config from './config.js';

library.add(fas);

let emitter = mitt();
let app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$dayjs = dayjs;

app
  .use(router)
  .use(VueSweetalert2)
  .use(VueGtag, {
    property: {
      id: "G-1E2453XS7S",
      params: {
        send_page_view: false,
      },
    },
  })
  .use(createMetaManager())
  .use(i18n).provide('i18n', i18n)
  .component('fa', FontAwesomeIcon)
  .mount('#app');

// ChannelService.loadScript();
// ChannelService.boot({
//   "pluginKey": config.channelTalk,
//   "zIndex": 2,
// });
