<template>
  <div class="flex-wrap justify-around w-100">
    <!-- https://ismail9k.github.io/vue3-carousel/ -->
    <Carousel :itemsToShow="itemsToShow" :wrapAround="true" :autoplay="2000" class="mb">
      <Slide class="img-box" v-for="p in mypartnerList" :key="p">
        <div class="img-container">
          <img :src="p" :style="{ height: height }" alt="" class="carousel__item" />
        </div>
      </Slide>
      <template #addons="{ slidesCount }">
        <Navigation v-if="slidesCount > 1" />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'PartnerList',
  components: { Carousel, Slide },
  props: {
    mypartnerList: Array,
    height: String,
    width: String,
    itemsToShow: Number
  }
}
</script>

<style scoped>
.img-container {
  width: 'auto';
}
.w-100 {
  width: 100%;
}
.flex {
  display: flex;
}
.flex-cloumn {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.carousel__item {
  width: 80%;
}
.justify-around {
  justify-content: space-around;
}
.img-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
:deep(.carousel__slide) {
  margin-top: 80px;
}
/* img {
  height: 124px;
} */
</style>
