<template>
<div>
  <div class="end" :class="{ modal_back : modalshow}" @click.self="$emit('closemodal')">
    <div class="end" :class="{ modal : modalshow}">
      <fa @click="$emit('closemodal')" icon="xmark" size="2xl" class="xicon" />
      <div class="inner-right">
        <Submitform :modalshow="modalshow" :modaldata="modaldata" :language="language"/> 
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Submitform from './Submitform.vue';

export default {
  name : "DownloadModal",
  components : {
    Submitform,
  },
  props : {
    modalshow : String,
    modaldata : Object,
    language : String,
  },
  data() {
    return {
      selectedOption: '',
      option1Selected: false,
      option2Selected: false,
      option3Selected: false,
      option4Selected: false,
      option5Selected: false,
      otherInput: '',
      publicPath : process.env.BASE_URL,
      form: {
        name: '',
        position : '',
        // department :'',
        email: '',
        funnel: '',
        company: '',
        phone:'',
        questions: '',
        type: this.modaldata.Mtitle,
      },
      infochecked: false,
      submit : false,
    }
  },
}
</script>

<style scoped src="./contactModal.css">
</style>
