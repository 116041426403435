<template>
  <div>
    <pageSection1 v-if="this.$route.name == 'Pricing'" :language="language" />
    <section v-if="language === 'ko'" class="section2">
      <div class="inner">
        <pricingTable
          :language="language"
          :pricingLevel="pricingLevel"
          :serviceContents="serviceContents"
        />
        <div class="promotion-box">
          <p class="Pretendard-400">
            <span class="color-white Pretendard-500">프로모션:</span> 협력사 / 공급망 솔루션 추가 시
            최소 10% 할인혜택 적용
          </p>
        </div>
        <div class="contact">
          <h3 class="contact-text Pretendard-400">
            더 궁금한 점이 있으신가요? 저희가 도와드릴게요.
            <span class="Pretendard-500 contact-span" @click="modalshow = true">문의하기</span>
          </h3>
        </div>
      </div>
      <div class="clients-box">
        <h1 class="clients-title Pretendard-600">
          베타참여 의향 전달 기업들이며, <br />
          리스트는 지속 업데이트 예정입니다.
        </h1>
        <img class="clients-img" src="../../assets/imgs/pricingCompany.png" alt="Clients" />
        <button class="clients-bnt Pretendard-700" @click="modalshow = true">도입 문의</button>
      </div>
    </section>
    <section v-if="language === 'en'" class="section2">
      <div class="inner">
        <pricingTable
          :language="language"
          :pricingLevel="pricingLevel"
          :serviceContents="serviceContents"
        />
        <div class="promotion-box">
          <p class="Pretendard-400">
            <span class="color-white Pretendard-500">Promotion :</span> Take advantage of our
            promotion and get at least 10% off on additional Partner and Supply Chain solutions.
          </p>
        </div>
        <div class="contact">
          <h3 class="contact-text Pretendard-400">
            We would be happy to assist you with any further questions.
            <span class="Pretendard-500 contact-span" @click="modalshow = true">Contact Us</span>
          </h3>
        </div>
      </div>
      <!-- <div class="clients-box">
        <h1 class="clients-title Pretendard-600">
          Those are customers who have shown interest, <br>and agreed to participate in beta testing, <br>
          The list will continue to be updated.
        </h1>
        <img class="clients-img" src="../../assets/imgs/pricingCompany.png" alt="Clients">
        <button class="clients-bnt Pretendard-700" @click="modalshow = true">Contact Us</button>
      </div> -->
    </section>
    <ContactModal
      v-if="modalshow && language == 'ko'"
      :modalshow="modalshow"
      @closemodal="modalshow = false"
      :modaldata="modaldata"
    />
    <ContactModal
      v-if="modalshow && language == 'en'"
      :modalshow="modalshow"
      @closemodal="modalshow = false"
      :modaldata="modaldataEN"
      :language="language"
    />
  </div>
</template>

<script>
import pageSection1 from '../pageSection1.vue'
import pricingTable from '../pricingTable.vue'
import ContactModal from '../Modal/ContactModal.vue'

export default {
  components: {
    pageSection1,
    pricingTable,
    ContactModal
  },
  props: {
    language: String
  },
  data() {
    return {
      modalshow: false,
      modaldata: {
        Mtitle: '도입 문의',
        Mname: '담당자 이름 / 직급 (이름과 직급 사이에 슬래쉬(/) 기호 입력 필요)',
        Mposition: '담당자 직급',
        Mcompany: '회사명',
        Mdepartment: '담당 부서',
        Mphone: '연락처',
        Memail: '이메일 주소',
        Mquestions: '문의사항',
        Mbutton: '문의하기',
        Mtype: '도입 문의'
      },
      modaldataEN: {
        Mtitle: 'Request a demo',
        Mname: 'Name / Position',
        Mposition: 'Position',
        Mcompany: 'Company Name',
        Mdepartment: 'Department',
        Mphone: 'Phone',
        Memail: 'Business Email',
        Mquestions: 'Message',
        Mbutton: 'Submit',
        Mtype: '도입 문의(EN)'
      },
      pricingLevel: {
        None: {
          title: ' ',
          subTitle: ' '
        },
        Essential: {
          title: 'Essential',
          subTitle: '최소한의 필수 핵심 기능'
        },
        Advanced: {
          title: 'Advanced',
          subTitle: '생산성을 높이는 기능'
        },
        Enterprise: {
          title: 'Enterprise',
          subTitle: '엔터프라이즈를 위한'
        },
        EnterprisePlus: {
          title: 'Enterprise',
          subTitle: '모든 ESG 고급 기능 지원'
        }
      },
      serviceContents: {
        None: {
          title: ' ',
          check_icn: ''
        },
        dashboard: {
          title: 'ESG 대시보드',
          check_icn: [
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg')
          ]
        },
        self: {
          title: 'ESG 자가진단',
          check_icn: [
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg')
          ]
        },
        import: {
          title: '중요성 이슈파악',
          check_icn: [
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg')
          ]
        },
        report: {
          title: 'ESG 공시 자동화',
          check_icn: [
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg')
          ]
        },
        MGT: {
          title: 'ESG 관리',
          check_icn: [
            '',
            'Add-on',
            require('../../assets/imgs/icn_check.svg'),
            require('../../assets/imgs/icn_check.svg')
          ]
        },
        GHG: {
          title: 'GHG 관리',
          check_icn: ['', '', 'Add-on', require('../../assets/imgs/icn_check.svg')]
        },
        supply: {
          title: 'ESG 공급망 관리',
          check_icn: ['', '', 'Add-on', require('../../assets/imgs/icn_check.svg')]
        }
      }
    }
  }
}
</script>

<style scoped>
.section2 {
  width: 100%;
  height: auto;
  background-color: #f8faf9;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.inner {
  width: 75vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.promotion-box {
  height: 2.7rem;
  background: rgb(77, 209, 139, 0.8);
  box-shadow: 0px 2px 30px rgba(215, 206, 229, 0.4);
  border-radius: 10px;
  margin-top: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #29463d;
  font-size: 1rem;
}
.color-white {
  color: #ffffff;
}
.contact {
  margin-top: 4.375rem;
  margin-bottom: 4.375rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.contact-text {
  color: #4d4d4d;
  font-size: 1.25rem;
}
.contact-span {
  margin-left: 0.3rem;
  cursor: pointer;
  color: #005e41;
}
.contact-span:hover {
  color: #4dd18b;
}
.clients-box {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4.375rem 0;
}
.clients-title {
  text-align: center;
  color: #000000;
  font-size: 2rem;
  line-height: 130%;
}
.clients-img {
  width: 75vw;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.clients-bnt {
  width: 14.125rem;
  height: 3rem;
  background: #d3e4dd;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
}
.clients-bnt:hover {
  background: #4dd18b;
}
/* @media screen and (max-width: 1040px) {
  .inner {
  width: 89.74vw;
  }
}

@media screen and (max-width: 720px) {
  .inner {
    width: 93.75vw;
  }
  .contact-text {
    font-size: clamp(10px, 3vw, 13.5px);
  }
  .promotion-box{
    font-size: 1rem;
    font-size: clamp(10px, 3vw, 13.5px);
  }
  .clients-title{
    font-size: clamp(10px, 3.5vw, 15px);
    font-family: 'Pretendard-Medium';
  }
  .clients-box {
    padding: 2.25rem 0;
  }
  .clients-bnt {
    width: 26.25vw;
    height: 2.1rem;
    font-size:1.25rem;
    font-size: clamp(10px, 2.8vw, 12.5px);
  }
} */
</style>
