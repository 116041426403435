<template>
  <div>
    <header class="hamburger">
    <div>
      <nav>
        <div class="navbar"  v-on="handleScroll">
          <div class="container nav-container">
            <div class="logo" @click="hamburgercheck = false">
              <router-link to="/"><img src="../../assets/logo/Login_001_LogoG.png" alt=""></router-link>
            </div>
            <input @click="viewmenu = true" class="checkbox" type="checkbox" v-model="hamburgercheck" id="check" />
            <div class="hamburger-lines">
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>  
            <div class="menu-items">
              <li @click="toggleBox" class="hamburger-nav-about" :class="{ 'box-open': showBox }">
                <div class="about-li">
                  About
                  <img src="../../assets/imgs/dropdown.png" alt="" id="dropdown-icon">
                </div>
                <transition name="toggle-box">
                <div v-if="showBox" class="toggle-box">
                  <router-link :to="dropmenu.menusLink" v-for="dropmenu in dropmenus" :key="dropmenu" class="tooglemenu dropmenu-background" @click="hamburgercheck = false"><li>{{dropmenu.menutypes}}</li></router-link>
                </div>
                </transition>
              </li>
              <router-link :to="menu.menusLink" v-for="menu in menuss" :key = menu @click="hamburgercheck = false" class="hamburger-menu-name"><li>{{menu.menutypes}}</li></router-link>
              <li @click="modalshow = true, hamburgercheck = false" class="li-contact"><a class="contact">Contact</a></li>
              <router-link to="/junbi"> <li class="login" >고객 로그인</li> </router-link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
  <ContactModal v-if="modalshow" :modalshow="modalshow" @closemodal="modalshow = false" :modaldata="modaldata" />
</div>  
</template>

<script>
import ContactModal from '../Modal/ContactModal.vue';


export default {
  components: {
    ContactModal
  },
  data() {
    return {
      hamburgercheck : false,
      showBox : false,
      modalshow : false,
      
    }
  },
  props : {
    menuss : Array,
    dropmenus : Array,
    modaldata : Array,
  },
  methods: {
    toggleBox() {
      this.showBox = !this.showBox;
    },
  },

}
</script>

<style scoped src="./header.css">

</style>