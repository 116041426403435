<template>
  <!-- https://ismail9k.github.io/vue3-carousel/ -->
  <div>
    <Carousel
      :itemsToShow="1.7"
      :wrapAround="true"
      :breakpoints="breakpoints"
      :autoplay="13000"
      class="mb"
    >
      <Slide class="a" v-for="(slide, b) in checking" :key="slide">
        <router-link to="/insights_more" class="router" @click="fire(b)">
          <div class="carousel_title">
            <h1 class="h1">{{ ESG[b] }}</h1>
          </div>
        </router-link>
        <div v-for="(c, i) in Cnt[b]" :key="c" class="carousel__item" :style="ChangeWidth(b)">
          <a
            v-show="ESG[b] == 'ESG Trend'"
            :href="ko[ko_new_length - i - 1]?.site_source"
            class="card"
            target="_blank"
          >
            <img
              :src="ko[ko_new_length - i - 1]?.site_image"
              class="card-img-top"
              alt="..."
              :style="ChangeImgHeight(b)"
            />
            <div v-show="Cnt[b] != 1" class="card-body">
              <h1 class="card-title Pretendard-600">
                {{ ko[ko_new_length - i - 1]?.site_subject }}
              </h1>
              <p class="card-text Pretendard-400">
                {{ ko[ko_new_length - i - 1]?.site_content }}
              </p>
            </div>
          </a>
          <a
            v-show="ESG[b] == 'ESG Seminar'"
            :href="semi[i]?.site_source"
            class="card"
            target="_blank"
          >
            <img
              :src="semi[i]?.site_image"
              @error="replaceByDefault"
              class="card-img-top"
              alt="ESG 세미나"
              :style="ChangeImgHeight(b)"
            />
            <div v-show="Cnt[b] != 1" class="card-body">
              <h1 class="card-title Pretendard-600">
                {{ semi[i]?.site_subject }}
              </h1>
              <p class="card-text Pretendard-400">
                일시 : {{ $dayjs(semi[i]?.created_at).format('YYYY-MM-DD') }} <br />
                행사 주최/주관 : {{ semi[i]?.site_organizer }}
              </p>
            </div>
          </a>
          <router-link
            to="/insights_more"
            v-show="ESG[b] == 'ESG Education'"
            class="card"
            @click="$emit('moveTab', 'ESG Education')"
          >
            <img
              :src="EduData[i]?.site_image"
              class="card-img-top"
              alt="..."
              :style="ChangeImgHeight(b)"
            />
          </router-link>
        </div>
      </Slide>
      <template #addons="{ slidesCount }">
        <Navigation v-if="slidesCount > 1" />
      </template>
    </Carousel>
  </div>
</template>
<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'MyCarousel',
  components: { Carousel, Slide, Navigation },
  props: {
    ext: Array,
    semi: Array,
    tab: String,
    EduData: Object,
    Cnt: Array,
    checking: Array,
    ko: Array,
    imgSrc: String,
    ko_new_length: String
  },
  methods: {
    replaceByDefault(e) {
      const randNum = Math.floor(Math.random() * 10);
      e.target.src = this.imgSrc + randNum;
    },
    fire(b) {
      this.emitter.emit('movetab', this.ESG[b]);
    },
    ChangeWidth(i) {
      if (this.Cnt[i] === 1) {
        return {
          width: '93%'
        };
      }
    },
    ChangeImgHeight(i) {
      if (this.Cnt[i] === 1) {
        return {
          height: '100%'
        };
      }
    }
  },
  data() {
    return {
      ESG: [
        'ESG Trend',
        'ESG Seminar',
        'ESG Education',
        'ESG Trend',
        'ESG Seminar',
        'ESG Education'
      ],
      breakpoints: {
        // 700px and up
        931: {
          itemsToShow: 1.3
        },
        1340: {
          itemsToShow: 1.7
        },
        // 1024 and up
        1780: {
          itemsToShow: 1.8
        }
      }
    };
  }
};
</script>

<style scoped src="./mycarousel.css"></style>
