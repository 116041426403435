<template>
  <div class="more-p1">
    <div v-if="language === 'ko'" class="post-lang">
      <a href="#" @click="setKr(true)">
        <p :class="{ click: kr }">국내</p>
      </a>
      <hr />
      <a href="#" @click="setKr(false)">
        <p :class="{ click: !kr }">해외</p>
      </a>
    </div>
    <div v-else-if="language === 'en'" class="post-langEn">
      <a href="#" @click="setKr(true)">
        <p :class="{ click: kr }">Domestic</p>
      </a>
      <hr />
      <a href="#" @click="setKr(false)">
        <p :class="{ click: !kr }">Global</p>
      </a>
    </div>
    <div v-if="kr" class="inner">
      <card v-for="(post, index) in posts" :key="`domestic-${index}`">
        <a v-if="ko[ko_new_length - post]" :href="ko[ko_new_length - post]?.site_source" target="_blank">
          <article class="card">
            <img :src="ko[ko_new_length - post]?.site_image" @error="replaceByDefault($event)" class="img" />
            <div class="description">
              <h1 class="Pretendard-600 title">{{ ko[ko_new_length - post]?.site_subject }}</h1>
              <p class="Pretendard-400">{{ ko[ko_new_length - post]?.site_content }}</p>
            </div>
          </article>
        </a>
      </card>
    </div>
    <div v-if="!kr" class="inner">
      <card v-for="(post, i) in posts" :key="`global-${i}`">
        <a v-if="glo[i]" :href="glo[i]?.site_source" target="_blank">
          <article class="card">
            <div class="description">
              <h1 class="Pretendard-600">{{ glo[i]?.site_subject }}</h1>
              <p class="Pretendard-400">{{ glo[i]?.site_content }}</p>
            </div>
          </article>
        </a>
      </card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Post',
  props: {
    posts: Number,
    ko: Array,
    glo: Array,
    imgSrc: String,
    ko_new_length: String,
    language: {
      type: String,
      default: 'ko'
    }
  },
  data() {
    return {
      kr: this.language === 'ko'
    };
  },
  methods: {
    setKr(value) {
      this.kr = value;
      this.$emit('PostInit', 9);
    },
    replaceByDefault(e) {
      const randNum = Math.floor(Math.random() * 50);
      e.target.src = this.imgSrc + randNum;
    }
  }
};
</script>

<style src="./post.css"></style>
