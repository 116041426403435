<template>
  <div class=end :class="{ modal_back : viewmodal != 0}" @click.self="$emit('closemodal')">
    <div class=end :class="{ modal : viewmodal != 0}">
      <fa @click="$emit('closemodal')" icon=xmark size=2xl class=xicon />
      <div v-if="viewmodal == 1" class=inner-right id=contact>
          <h1 class=title>개인정보처리방침</h1>
          <p class=text>
(‘https://i-ESG.io’이하 ‘'i-ESG'’) 은(는) 정보주체들의 개인정보보호를 매우 중요시하며, 개인정보의 보호와 관련하여 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법 등
정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정 및 방송통신위원회가 제정한 개인정보보호지침을 준수하고 있습니다.<br>
'i-ESG'는 개인정보 처리방침을 통하여 정보주체들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
<br><br>
i-ESG'는 개인정보 처리방침을 홈페이지 첫 화면에 공개함으로써 정보주체들이 언제나 용이하게 보실 수 있도록 조치하고 있습니다.
<br><br>
i-ESG'의 개인정보 처리방침은 정부의 법률 및 지침 변경이나 'i-ESG'의 내부 방침 변경 등으로 인하여 수시로 변경될 수 있고, <br>
이에 따른 개인정보 처리방침의 지속적인 개선을 위하여 필요한 절차를 정하고 있습니다. 그리고 개인정보 처리방침을 개정하는 경우 <br>
'i-ESG'는 개인정보 처리방침 변경 시행 7일전부터 사이트 공지사항을 통하여 공지하고 버전번호 및 개정일자 등을 부여하여 개정된 사항을 정보주체들이 쉽게 알아볼 수 있도록 하고 있습니다.
<br><br>
01. 개인정보 수집에 대한 동의<br>
02. 개인정보의 수집목적 및 이용목적<br>
03. 수집하는 개인정보 항목 및 수집방법<br>
04. 수집하는 개인정보의 보유 및 이용기간<br>
05. 개인정보의 제3자 제공<br>
06. 개인정보 자동 수집 장치의 설치ㆍ운영 및 그 거부에 관한사항<br>
07. 개인정보의 위탁처리<br>
08. 정보주체의 권리, 의무 및 행사방법<br>
09. 개인정보의 파기절차 및 방법<br>
10. 개인정보관련 기술적-관리적 대책<br>
11. 개인정보 보호 책임자 및 담당자<br>
12. 개인정보 처리방침 변경 및 고지의 의무<br>
<br><br>
1. 개인정보 수집에 관한 동의<br>
<br>
① 'i-ESG'는 정보주체들이 'i-ESG'의 개인정보 수집동의 및 이용약관의 내용에 대하여 「동의」버튼 또는 「취소」버튼을 클릭할 수 있는 절차 또는 체크박스 유무를 마련하여, 「동의」버튼 또는 체크박스 체크 시 개인정보 수집에 대해 동의한 것으로 봅니다.
<br><br>
2. 개인정보의 수집목적 및 이용 목적
<br><br>
① "개인정보"라 함은 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 생년월일 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다.
<br><br>
② 'i-ESG'는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다. 'i-ESG' 연계 서비스를 활용하기 위한 경우,<br>
금융감독원 규정에 따라 개인정보수집을 동의하는 절차를 진행하도록 파트너사향 공지하고 있으며, 이때 수집된 개인정보 및 수집과 관련된 사항은 <br>
'i-ESG'와는 별도로 각 서비스를 제공하는 파트너사로 문의하시기 바라며 이들 기관의 개인정보취급 관리 및 개인정보활용 제공과 같은 내용은 'i-ESG'와 무관합니다.
<br><br>
③ 'i-ESG'가 수집하는 개인정보 항목과 이용목적은 다음과 같습니다.<br>
- ID, 생년월일 : 연령제한 서비스 이용제공 및 불량회원의 부정한 이용 재발 방지<br>
- 이메일주소, 전화번호, 휴대폰번호 : 오프라인 상담 등 고지사항 전달, 본인 의사확인, 불만처리 등 의사소통 경로의 확보, 새로운 서비스나 신상품, 이벤트 정보 등 최신 정보안내,상담서비스와 향후 상품활용에 대한 안내/통보<br>
- 생년월일, 주소 : 인구통계학적 분석<br>
- IP Address, 방문 일시 : 불량회원의 부정 이용 방지와 비인가 사용 방지<br>
- 계좌정보, 신용카드정보 : 유료서비스 이용, 환불 처리 시<br>
- 그 외 선택항목 : 개인 맞춤서비스를 제공하기 위한 자료 및 인구통계학적 분석
<br><br>
④ 정보주체는 위의 내용에 대한 동의를 거부할 수 있습니다. 다만, 이에 대한 동의를 하시지 않을 경우에는 재테크 상담 및 향후 관리 서비스, 신규 상품에 대한 안내를 받지 못할 수 있음을 알려 드립니다.
<br><br>
3. 수집하는 개인정보 항목 및 수집방법
<br><br>
가. 수집하는 개인정보의 항목<br>
① 'i-ESG'는 회원가입, 오프라인 상담 요청 시, 상품 가입 시 등 아래와 같은 개인정보를 수집하여 처리하고 있습니다.
<br><br>
&lt; 일반 회원가입 시 &gt;<br>
- 필수항목 : 아이디, 비밀번호, 비밀번호 질문과 답, 이름, 생년월일, 성별, 이메일, 전화번호, 휴대폰, 주소, 회사명 (페이스북, 네이버, 구글 등으로 가입 시, 동 사 규정에 준수한 제한적인 정보만을 수집)<br>
- 선택항목 : 직장주소, 직장전화번호, 회사직책, 직업<br>
<br>
i-ESG'는 어떤 경우에도 회원의 개인 계좌, 주민번호, 은행 정보 등 금융관련 정보를 수집하지 않으며, 'i-ESG'와는 별도로 각 파트너사 서비스를 이용하는 경우에는 <br>
파트너사에서 요청하는 개인정보 수집과 관련된 사항은 해당 기관로 문의하시기 바라며 이들 기관의 개인정보취급 관리 및 개인정보활용 제공과 같은 내용은 'i-ESG'와 무관합니다.<br>
<br>
② 기타 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.<br>
- IP Address, 방문일시, 서비스 이용 기록 : 부정 이용 방지, 비인가 사용 방지 등<br>
<br>
③ 'i-ESG'는 이용자의 개인정보를 수집할 경우 반드시 이용자의 동의를 얻어 수집하며, 이용자의 기본적 인권을 침해할 우려가 있는 인종, 출신지, 본적지, 사상, 정치적 성향, 범죄기록, 건강상태 등의 
정보는 이용자의 동의 또는 법령의 규정에 의한 경우 이외에는 수집하지 않습니다.
<br><br>
④ 'i-ESG'는 회원 가입을 만 14세 이상인 경우에 가능하도록 하며 개인정보의 수집•이용에 법정대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다. <br>
단, 법정대리인의 동의를 얻은 경우에는 만 14세 미만 이용자의 개인정보를 수집•이용할 수 있습니다.<br>
<br>
나. 개인정보 수집방법<br>
<br>
① 'i-ESG'는 다음과 같은 방법으로 개인정보를 수집합니다.<br>
홈페이지, 서면양식, 팩스, 전화(인 콜/아웃 콜), 상담 게시판, 이메일, 이벤트 응모 등 'i-ESG'는 정보주체의 사전 동의 없이는 정보주체의 개인 정보를 공개하지 않으며, 수집된 정보는 아래와 같이 이용하고 있습니다.<br>
<br>
4. 수집하는 개인정보의 보유 및 이용기간<br>
<br>
① 'i-ESG'는 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유.이용기간 또는 법령에 따른 개인정보 보유.이용기간 내에서 개인정보를 처리.보유합니다.<br>
<br>
② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
- 고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만 채권.채무관계 잔존시에는 해당 채권.채무관계 정산시까지<br>
<br>
③ 수집하는 개인정보 항목 및 수집방법에서와 같이 일시적인 목적 (설문조사, 이벤트, 본인확인 등)으로 입력 받은 개인정보는 그 목적이 달성된 이후에는 동일한 방법으로 사후 재생이 불가능한 상태로 처리됩니다.<br>
<br>
④ 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 'i-ESG'는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. <br>
이 경우 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br>
<br>
- 계약 또는 청약철회 등에 관한 기록:<br>
▶ 보존근거 : 전자상거래 등에서의 소비자보호에 관한 법률 제 6조 및 시행령 제 6조:<br>
▶ 보존기간 : 5년<br>
- 대금결제 및 재화 등의 공급에 관한 기록:<br>
▶ 보존근거 : 전자상거래 등에서의 소비자보호에 관한 법률 제 6조 및 시행령 제 6조:<br>
▶ 보존기간 : 5년<br>
- 소비자의 불만 또는 분쟁처리에 관한 기록 :<br>
▶ 보존근거 : 전자상거래 등에서의 소비자보호에 관한 법률 제 6조 및 시행령 제 6조<br>
▶ 보존기간 : 3년<br>
- 본인확인에 관한 기록:<br>
▶ 보존근거 : 정보통신망 이용촉진 및 정보보호에 관한 법률 제44조의 5 및 시행령 제 29조<br>
▶ 보존기간 : 6월<br>
- 접속에 관한 기록:<br>
▶ 보존근거 : 통신비밀보호법 제 15조의 2 및 시행령 제 41조<br>
▶ 보존기간 : 3개월<br>
- 부정이용기록 :<br>
▶ 보존근거 : 부정이용의 배제 등 'i-ESG' 방침에 의한 보존<br>
▶ 보존기간 : 1년<br>
- 신용정보의 수집, 처리 및 이용 등에 관한 기록:<br>
▶ 보존근거 : 신용정보의 이용 및 보호에 관한 법률<br>
▶ 보존기간 : 3년<br>
- 표시•광고에 관한 기록:<br>
▶ 보존근거 : 전자상거래 등에서의 소비자보호에 관한 법률<br>
▶ 보존기간 : 6월<br>
<br>
5. 개인정보의 제3자 제공<br>
<br>
① 'i-ESG'는 정보주체의 별도 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우 외에는 개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br>
<br>
- 정보주체들이 사전에 공개에 동의한 경우<br>
- 홈페이지에 게시한 서비스 이용 약관 및 기타 회원 서비스 등의 이용약관 또는 운영원칙을 위반한 경우<br>
- 서비스를 이용하여 타인에게 정신적, 물질적 피해를 줌으로써 그에 대한 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우<br>
- 기타 법에 의해 요구된다고 선의로 판단되는 경우 (ex. 관련법에 의거 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우 등)<br>
- 통계작성, 학술연구나 시장조사를 위하여 특정개인을 식별할 수 없는 형태로 연구단체, 협력업체나 광고주 등에 제공하는 경우<br>
- 정보주체의 서비스 이용에 따른 불만사항 및 문의사항(민원사항)의 처리를 위하여 관련있는 협력 전문업체에 해당 민원사항의 처리에 필요한 개인정보를 제공하는 경우<br>
<br>
6. 개인정보 자동 수집 장치의 설치ㆍ운영 및 그 거부에 관한 사항<br>
① 쿠키란?<br>
- 'i-ESG'는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.<br>
- 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.<br>
이후 이용자가 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.<br>
- 쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.<br>
<br>
② 'i-ESG'의 쿠키 사용 목적<br>
회원과 비회원의 접속 빈도나 방문 시간 등의 분석, 이용자의 취향과 관심분야 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅, 기타 개인 맞춤 서비스 제공을 위해 사용합니다.<br>
<br>
③ 쿠키의 설치ㆍ운영 및 거부<br>
- 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다.<br>
- 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 'i-ESG' 일부 서비스는 이용에 제한이 있을 수 있습니다.<br>
- 쿠키 설치 허용 여부를 지정하는 방법은 다음과 같습니다.<br>
- Microsoft Edge<br>
▶ [설정] 메뉴에서 [쿠키 및 사이트 권한]을 선택합니다.<br>
▶ [쿠키 및 사이트 데이터 관리 및 삭제] 클릭합니다.<br>
▶ [사이트에서 쿠키 데이터를 저장하고 읽도록 허용]을 설정하시면 됩니다.<br>
- Chrome<br>
▶ [설정] 메뉴에서 [개인정보 및 보안]을 선택합니다.<br>
▶ [쿠키 및 기타 사이트 데이터]를 클릭합니다.<br>
▶ [모든 쿠키 허용]을 설정하시면 됩니다.<br>
<br>
7. 개인정보의 위탁 처리<br>
<br>
① 'i-ESG'는 원활한 개인정보 업무처리를 위하여 향후 개인정부 처리업무를 외부전문업체에 위탁하여 처리할 수 있으며 현재 위탁하고 있지 않습니다.<br>
<br>
② 'i-ESG'는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적.관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리.감독, 손해배상 등 <br>
책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br>
<br>
③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.<br>
<br>
8. 정보주체의 권리, 의무 및 행사방법<br>
<br>
정보주체는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.<br>
<br>
① 정보주체는 (‘사이트URL’이하 ‘사이트명) 에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.<br>
1. 개인정보 열람요구<br>
2. 오류 등이 있을 경우 정정 요구<br>
3. 삭제요구<br>
4. 처리정지 요구<br>
<br>
② 정보주체는 언제든지 등록되어 있는 본인의 개인정보를 열람하거나 정정 하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 『회원정보』를 클릭하여 직접 열람 또는 정정하거나, <br>
개인정보관리책임자 및 담당자에게 서면, 전화 또는 E-mail로 연락하시면 지체없이 조치하겠습니다. 정보주체가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다.<br>
잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정하도록 조치하겠습니다. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 경우는 
개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br>
<br>
③ 정보주체는 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 정보주체가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 자신에게 있으며 
타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다. 정보주체는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. <br>
비밀번호를 포함한 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. <br>
정보주체가 위 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 등 관련 법률에 의해 처벌받을 수 있습니다.<br>
<br>
9. 개인정보의 파기절차 및 방법<br>
<br>
① 'i-ESG'가 보유기간을 미리 고지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 동의를 받은 경우에는 약정한 기간 동안 보존합니다.<br>
<br>
② 수집된 개인정보의 보유•이용기간은 서비스이용계약체결(회원가입)시부터 서비스이용계약해지(탈퇴신청, 직권탈퇴 포함)입니다. <br>
또한 동의 해지 시 'i-ESG'는 이용자의 개인정보를 상기 명시한 정보보유 사유에 따라 일정 기간 저장하는 자료를 제외하고는 지체 없이 파기하며 개인정보취급이 제3자에게 위탁된 경우에는 수탁자에게도 파기하도록 지시합니다.<br>
<br>
③ 파기절차: 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. <br>
이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.<br>
<br>
④ 파기기한: 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 
그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.<br>
<br>
⑤ 파기방법: 귀중한 회원의 개인정보를 안전하게 처리하며, 유출의 방지를 위하여 다음과 같은 방법을 통하여 개인정보를 파기합니다.<br>
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br>
- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br>
<br>
⑥ 'i-ESG'는 [4조 수집하는 개인정보의 보유 및 이용기간]에 명시된 사항을 제외하고 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 개인정보를 지체 없이 파기합니다.<br>
<br>
10.개인정보관련 기술적-관리적 대책<br>
개인정보의 안전성 확보 조치 (''i-ESG'')은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.<br>
<br>
① 정기적인 자체 감사 실시<br>
개인정보 취급 관련 안정성 확보를 위해 정기적으로 자체 감사를 실시하고 있습니다.<br>
<br>
② 개인정보 취급 직원의 최소화 및 교육<br>
개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br>
<br>
③ 내부관리계획의 수립 및 시행<br>
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br>
<br>
④ 해킹 등에 대비한 기술적 대책<br>
i-ESG'는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.<br>
<br>
⑤ 개인정보의 암호화<br>
이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br>
<br>
⑥ 접속기록의 보관 및 위변조 방지<br>
개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.<br>
<br>
⑦ 개인정보에 대한 접근 제한<br>
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br>
<br>
⑧ 문서보안을 위한 잠금장치 사용<br>
개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br>
<br>
⑨ 비인가자에 대한 출입 통제<br>
개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.<br>
<br>
11. 개인정보 보호 책임자 및 담당자<br>
<br>
① 'i-ESG'는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br>
<br>
- 개인정보 보호책임자<br>
▶ 성명 : 박용준<br>
▶ 직책 : i-ESG 운영기획 총괄<br>
▶ 직급 : 이사<br>
▶ 연락처 : +82-2-3211-4374, info@i-esg.io<br>
<br>
- 개인정보 보호 담당부서<br>
▶ 부서명 : i-ESG 운영기획 파트<br>
▶ 담당자 : 박용준<br>
▶ 연락처 : +82-2-3211-4374, info@i-esg.io<br>
※ 개인정보 보호 담당부서로 연결됩니다.<br>
<br>
기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br>
- 개인정보침해신고센터 (www.118.or.kr / 118)<br>
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)<br>
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)<br>
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)<br>
<br>
② 정보주체께서는 'i-ESG'의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. <br>
'i-ESG'는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br>
<br>
12. 개인정보 처리방침 변경 및 고지의 의무<br>
<br>
①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.<br>
<br>
<br>
부칙<br>
본 방침은 2022년 6월 1일부터 적용됩니다.
</p>
</div>


















        
      <div v-if="viewmodal == 2" class=inner-right id=contact>
          <h1 class=title>서비스 이용 약관</h1>
          <p class=text>
            제 1 장 총칙<br>
<br>
제1조 (목적)<br>
<br>
본 약관은 회사(이하 ‘회사’)가 운영하는 ESG 특화 플랫폼 i-ESG(https://i-esg.io, 이하 ‘i-ESG’)에서 제공하는 제반 서비스 이용과 관련하여 회사와 고객의 권리·의무 및 책임사항 등을 규정함을 목적으로 합니다.<br>
<br>
제2조 (용어의 정의)<br>
<br>
본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.<br>
<br>
1) 서비스 배경<br>
회사가 온라인 플랫폼을 통해 제공하는 서비스는 빠르게 규제화되고 있는 ESG의 다양한 요구에 보다 쉽고, 효율적으로 관리/대응할 수 있도록 돕는 ESG 특화 디지털 솔루션입니다.<br>
2) 서비스 :<br>
  ① i-ESG 홈페이지에서 기업고객으로하여금 원하는 서비스 또는 정보를 검색하여 이를 웹상에서 활용할 수 있도록 하는 웹상에서의 일련의 유료/무료 서비스<br>
  ② i-ESG 홈페이지에서 유료고객 가입 후, 고객사의 ESG 관리를 원할하게 하는 데 도움이 되는 웹상에서의 ESG 특화 모듈 (진단, 공시자동화, 대시보드, 공급망 관리 등) 및 고객의 별도 요청에 따른 온/오프라인의 연계 서비스  <br>
  ③ i-ESG 홈페이지에서 ESG 관련 유용한 정보 제공 등 추가 서비스 등<br>
  ④ 기타 회사가 정한 서비스 배경에 부합하여 회사가 추가로 제공 또는 제공 예정인 서비스<br>
3) 이용자 : i-ESG 서비스를 이용하는 모든 고객 및 비고객<br>
4) 고객 : ‘회사’의 서비스에 접속하여 본 약관에 따라 ‘회사’와 이용계약을 체결하고 ‘회사’가 제공하는 서비스를 이용하는 개인 또는 법인, 공공기관 등<br>
5) 아이디(ID) : 고객의 식별과 서비스 이용을 위하여 고객이 정하고 회사가 승인하는 문자와 숫자의 조합 또는 고객의 이메일 주소를 의미<br>
6) 비밀번호 : 고객이 설정한 아이디(ID)와 일치되는 고객임을 확인하고 비밀보호를 위해 고객 자신이 정한 문자 또는 숫자의 조합을 의미<br>
7) 크레딧 : 회사가 프로모션 등을 위하여 조건을 정해 특정 상품에 적용할 수 있도록 하는 요금제도<br>
8) 고객의 정보 : 고객이 회사의 서비스에 저장하는 정보(『국가정보화기본법』 제 3 조 제 1호에 따른 정보, 개인인 경우에는 개인정보와 신용정보를 포함합니다.)로써 고객이 소유 또는 관리하는 정보<br>
9) 영업일 : 대한민국 내 위치한 시중 은행이 실제 영업하는 날을 의미합니다.<br>
10) 파트너 : 고객의 요청에 의하여 아이이에스지 서비스에 연계하여 추가적인 서비스를 제공하거나, 아이이에스지의 서비스 판매를 대행하는 사업자로써 고객에 대한 기술지원, 장애대응, 과금관리, 계약관리 등 고객이 서비스를 원활히 이용할 수 있도록 전반적인 운영・관리를 수행하는 사업자를 말합니다. 다만, “파트너”와 “고객”간에 별도로 체결하는 계약이나 약정이 있는 경우, 그 계약관계에는 본 약관이 적용되지 않습니다.
본 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계법령, 개별서비스에 대한 별도약관 및 이용규정에서 정하는 바에 의합니다.<br>
<br>
제3조 (약관의 효력 및 변경)<br>
<br>
1) 약관은 회사의 서비스 웹사이트(https://i-esg.io)에서 확인 가능합니다. 약관은 고객이 회사의 서비스 웹사이트에 접속 또는 이용할 때 그리고 서비스 계약(아래 제 5 조 제 1 항에서 정의한 바에 따름) 성립에 따라 제공되는 서비스에 적용됩니다. 고객이 회사 서비스 웹사이트에 접속 또는 이용하기 위해서는 약관에 동의해야 합니다.<br>
2) 회사는 ‘약관의 규제에 관한 법률’, ‘정보통신망이용촉진 및 정보보호 등에 관한 법률’, ‘클라우드 컴퓨팅 발전 및 이용자보호에 관한 법률’ 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다. <br>
3)  회사는 회사의 서비스 웹사이트 내 수정 약관의 게시 등 합리적인 방법을 통해 약관을 수정할 수 있습니다. 이 때 회사는 약관 수정 사항의 효력이 발생하기 최소 7 일 전에 효력 발생일과 자세한 내용을 명시하여 회사 서비스 웹사이트를 통해 이를 공지합니다. 단, 수정 사항이 고객의 권리 및 의무와 관련해 불리하게 작용하는 경우 회사는 제7조 제1항에 명시된 바에 따라 효력 발생일 최소 30 일 이전에 해당 고객에게 이를 통지합니다.<br>
4) 회사가 전항에 따라 개정약관을 공지 또는 통지하면서 고객에게 30 일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 고객이 명시적으로 거부의 의사표시를 하지 아니한 경우, 고객이 개정 약관에 동의한 것으로 봅니다.<br>
5) 고객이 개정약관의 적용에 동의하지 않는 경우 회사 또는 고객은 이용계약을 해지할 수 있습니다. 이 경우 회사는 고객에게 서면, 전자우편 또는 이에 준하는 방법으로 해지 사유, 해지일, 환급 비용을 통지합니다.<br>
<br>
제4조 (약관 외 명시되지 않은 사항)<br>
<br>
1) 본 약관에서 정하지 아니한 사항은 준거법, 규정, 상관습 및 서비스 웹사이트 내 개별서비스 운영정책(해당 시)에 따릅니다. 오해의 소지를 피하기 위해 부연하자면, 회사는 각각의 개별서비스에 대한 별도 운영정책을 실시할 수 있습니다.<br>
2) 회사는 개별서비스에 적용될 사항을 정하여 개별 약관으로 운영할 수 있습니다. 개별 약관의 내용이 본 약관과 상충할 경우 별도로 정한 바가 없다면 개별 약관이 우선 적용됩니다.<br>
3) 개별 약관에 규정이 없는 경우 본 약관에 따릅니다.<br>
4) 본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 정보통신윤리위원회 심의규정, 정보통신 윤리강령, 컴퓨터프로그램보호법 등 기타 대한민국의 관련법령 및 상관행을 따릅니다.<br>
<br>
제 2 장 서비스 이용<br>
<br>
제5조 (서비스 이용의 신청)<br>
<br>
1) 서비스 이용 신청자(“신청인”)가 약관에 동의한 후 서비스 이용을 신청하고, 회사가 이에 승인을 함으로써 서비스 이용계약(“서비스 계약”)이 성립합니다.<br>
2) 서비스 이용 신청 시 신청인은 실명과 실제 정보를 제공해야 합니다. 신청인이 불법적으로 가명을 사용하거나 거짓 정보를 제공한 경우에는 약관에서 정한 권리를 누리거나 주장할 수 없으며, 회사는 서비스 계약을 해지할 수 있습니다.<br>
3) 신청인이 서비스 신청 시 제공한 개인 정보는 준거법 및 규정, 회사의 개인정보보호 정책에 따라 보호됩니다.<br>
4) 신청인이 민법상 미성년자인 경우 법정대리인(보호자)의 동의가 있어도 서비스 이용이 불가하며, 회사는 미성년자의 서비스 신청을 거절할 수 있습니다. 고객은 서비스를 이용함으로써 미성년자가 아님을 회사에 확인합니다.<br>
<br>
제6조 (서비스 계약의 성립)<br>
<br>
1) 신청인이 상기 제 5 조에서 명시한 정보를 성실하고 정확하게 작성하면 회사는 특별한 사유가 없는 한 신청을 승인합니다.<br>
2) 회사는 다음 각 호에 해당하는 신청에 대해서는 승낙하지 않습니다.<br>
  ① 타인 동의 없이 타인명의로 서비스를 신청한 경우<br>
  ② 정보를 허위로 기재하였거나 허위의 서류를 제출한 경우<br>
  ③ 회사가 정한 이용신청 요건이 미비 되었을 경우(필수항목 누락, 오기 등)<br>
  ④ 법령 또는 약관을 위반하여 이용계약이 해지 된 적이 있는 이용자가 신청한 경우<br>
  ⑤ 사회의 안녕질서 혹은 미풍양속을 저해할 목적으로 신청한 경우<br>
  ⑥ 기타 부정한 용도로 본 서비스를 이용하고자 하는 경우<br>
3) 그밖에 제 1 호에서 제 6 호까지에 준하는 사유로써, 승낙하는 것이 상당히 부적절하다고 판단되는 경우<br>
  ①  회사가 서비스 이용 신청을 거부하거나 연기할 것을 결정한 경우 신청인에게 이를 통지합니다.<br>
  <br>
제 7 조 (고객에 대한 통지)<br>
1) 회사는 고객에 대한 개별 통지를 원칙으로 하며 회사가 고객에게 통지해야 하는 경우, 회사는 약관에서 별도로 명시하지 않는 한 서비스 이용 신청 시 고객이 등록한 이메일 주소를 이용하여 통지합니다.<br>
2) 고객 전체에 통지해야 하는 경우, 회사는 상기 제 1 항에서 명시한 방식 대신 서비스 웹사이트에 7 일 이상 공지함으로써 통지를 대신할 수 있습니다. 단, 고객의 권리 및 의무와 관련해 불리한 변경 사항을 통지하는 경우에는 본 조항이 적용되지 않습니다.<br>
3) 고객은 회사의 통지 사항을 수신할 수 있도록 연락처 정보(이메일 주소, 휴대폰 번호, 유선 전화번호 등)를 항상 최신 상태로 유지할 의무가 있습니다. 연락처 정보가 업데이트 되어 있지 않아 고객에게 발생하는 불이익에 대해 회사는 어떠한 책임도 지지 않습니다.<br>
<br>
제 8 조 (개별서비스의 유형 및 변경)<br>
1) 회사는 개별서비스의 종류, 세부 사항 및 가격뿐만 아니라 개별서비스의 이용약관, 개별서비스 운영 규칙 등을 포함한 세부 사항을 서비스 웹 사이트에 게시합니다. 고객은 서비스 웹사이트에 게재된 세부 사항을 충분히 숙지하고 관련 정보를 바탕으로 서비스를 신청해야 합니다.<br>
2) 서비스 웹사이트 상에 새로운 정보를 게재하거나 제 7 조에 따라 고객에게 통지하는 경우에 한해, 회사는 개별서비스의 형태 또는 가격을 추가하거나 변경할 수 있습니다. 오해의 소지를 피하기 위해 부연하자면, 변경사항이 고객의 권리 및 의무에 불리하게 작용할 수 있는 경우 고객은 제 7 조에 명시된 조건에 따라 이를 통지 받으며 제 3 조 제 5 항에 명시된 권리를 보유합니다.<br>
3) 서비스가 종료되는 경우 회사는 서비스가 종료되기 60 일 이전에 만료 또는 종료 사실을 통지합니다.<br>
<br>
9 조 (서비스 제공)<br>
1) 원칙적으로 회사는 하루 24 시간, 주 7 일의 서비스를 제공합니다. 단, 설비의 점검 등 회사가 필요한 경우 또는 설비의 장애, 서비스 이용의 폭주 등 불가항력 사항으로 인하여 서비스 이용에 지장이 있는 경우 예외적으로 서비스 이용의 전부 또는 일부를 제한할 수 있습니다.<br>
2) 서비스 제공에 필수적인 경우 회사는 정기점검을 실시할 수 있고 그 일정은 고객에게 통지하거나 서비스 웹사이트에 공지한 바에 따릅니다.<br>
3) 회사가 제공하는 서비스를 이용하여 고객이 저장하는 정보 또는 데이터는 고객이 소유 또는 관리하며 회사는 고객의 동의 없이 이에 접근하지 않습니다.<br>
<br>
제 10 조 (고객 계정 관리)<br>
<br>
1) 고객은 자신의 ID 및 비밀번호를 관리하고 제 3 자가 이용하지 못하도록 방지할 책임이 있습니다. <br>
2) 고객은 자신의 ID 및 비밀번호가 도용된 사실 또는 제 3 자가 이용한 사실을 알게 된 경우, 해당 사실을 회사에 즉시 이를 알리고 회사의 가이드라인에 따릅니다.<br>
3) 회사는 개인정보가 무단으로 공개 또는 유출될 위험이 있는 경우 또는 ID 가 회사 또는 회사 운영자와 연관되어 있는 것처럼 오해되는 경우 ID 이용을 제한할 수 있습니다.<br>
4) 본 조의 의무를 위반하여 발생하는 모든 책임은 고객에게 있습니다. 고객이 본 조의 의무를 위반하거나 회사 지침 또는 가이드라인을 따르지 않아 발생하는 불이익에 대하여 회사는 책임을 지지 않습니다.<br>
5) 회사는 개별 고객이 회사의 정책에 따라 보유할 수 있는 계정의 수를 제한할 수 있습니다. 상세한 내용은 서비스 홈페이지에 공지한 바에 따릅니다.<br>
<br>
제 11 조 (고객 개인정보의 변경)<br>
<br>
1) 고객은 서비스 웹사이트의 개인정보 관리 페이지에서 언제든 개인정보를 확인하고 수정할 수 있습니다. 그러나, 기업 고객의 이름, 개인 고객의 실명, 사업자 등록번호 또는 고유 ID 번호 등을 포함하되 이에 한정되지 않는 서비스 관리 상의 필수 정보는 임의 수정이 불가합니다.
2) 서비스 이용 신청이 제출된 이후 고객 정보가 바뀌면 고객은 서비스 웹사이트 상에서 정보를 수정하고 이메일 또는 기타 방법을 통해 이를 회사에 알려야 합니다.<br>
3) 고객이 회사에 제공한 개인정보가 정확하지 않거나 제 2 항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.<br>
<br>
제 12 조 (양도 및 관련 고객 정보 변경)<br>
<br>
1) 어떠한 고객도 임의로 서비스 계약 상의 권리 및 의무 또는 본 계약에서 이용하는 기타 권리를 타인에게 이전하거나 양도, 담보 설정 등으로 처분할 수 없습니다.<br>
2) 고객에게 상속, 합병, 분할의 사유가 발생하여 고객이 아닌 제 3 자(이하 “양수인”)가 고객이 회사와 맺은 서비스 계약에 따른 법적 지위를 승계하는 경우 고객 및 양수인은 즉시 회사에 지위 승계를 입증할 수 있는 서류를 첨부하여 회사가 지정한 방법과 절차에 따라 통지하여야 합니다.<br>
3) 상기 제 2 항에 따른 고객 정보 변경의 경우, 양수인은 승계 이전에 약관 및 서비스 계약의 조건들을 완전히 이행할 책임이 있습니다. 승계와 관련하여 문제가 발생하는 경우, 고객과 양수인이 연대하여 책임을 집니다.<br>
<br>
제 13조 (고객정보 사용에 대한 동의)<br>
<br>
홈페이지의 고객정보는 다음과 같이 수집, 사용, 관리, 보호되며, 고객이 본 약관에 따라 이용신청을 하는 것은 회사가 신청서에 기재된 고객정보를 수집, 이용하는 것에 동의하는 것으로 간주됩니다.<br>
1) 고객정보의 수집 : 회사는 서비스 가입 시 고객이 제공하는 정보를 통해서 고객의 정보를 수집합니다.<br>
2) 고객정보의 사용 : 회사는 서비스 제공과 관련해서 수집된 고객의 신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 정보통신윤리위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우, 고객이 홈페이지에 제공한 고객정보를 스스로 공개한 경우는 제외합니다.<br>
3) 고객정보의 관리 : 고객은 고객정보의 보호 및 관리를 위하여 수시로 귀하의 고객정보를 수정 또는 삭제할 수 있습니다. 수신되는 정보 중 불필요하다고 생각되는 부분도 변경 또는 조정 할 수 있습니다.<br>
4) 고객정보의 보호 : 고객정보는 오직 고객만이 열람•수정•삭제 할 수 있으며, 이는 전적으로 고객의 ID와 비밀번호에 의해 관리되고 있습니다. 따라서 타인에게 고객의 ID와 비밀번호를 알려주어서는 아니 되며, 작업 종료 시에는 반드시 로그아웃 하고, 웹 브라우저의 창을 닫아야 합니다.<br>
5) 고객정보의 수집 및 이용에 대해서는 관련 법령에 준수하여 i-ESG 홈페이지에 게재한 개인정보 취급방침에 의거 활용되며, 제3자향 정보 제공에 대한 사항은 이용자의 "동의함" 의사표시에 의거 향후 수집, 활용될 수 있습니다.<br>
<br>
제 3 장 서비스 제공 및 이용<br>
<br>
제 14 조 (회사에 의한 서비스 이용의 정지 또는 종료)<br>
<br>
1)  회사는 다음 각호의 사유에 해당하는 경우 사전 통지 없이 고객의 서비스 이용을 정지할 수 있습니다.<br>
  ① 고객이 요금을 납입하지 않은 경우(고객이 파트너에게 요금을 납입하지 않는 경우 포함)<br>
  ② 안정된 서비스 운용을 저해할 수 있는 다량의 정보 전송, 광고성 정보를 전송 또는 매개하는 행위를 하거나 이러한 행위가 발생하는 경우<br>
  ③ 고객 서버에서 실행중인 프로그램으로 인해 다른 고객 시스템의 운영에 피해가 발생하거나, 회사의 서비스 운영에 지장 또는 장애가 발생한 경우 또는 그러한 피해 내지 지장, 장애가 발생할 위험이 있는 경우<br>
  ④ 고객이 서비스를 이용하여 운영중인 시스템과 관련하여 비정상 과다 트래픽이 발생하여 네트워크에 영향을 끼치는 경우<br>
  ⑤ 고객이 보안 업데이트를 서버에 적절히 설치하지 않음으로 말미암아 회사의 서비스 운영에 위험요소로 판단되는 경우<br>
  ⑥ 고객이 국가적 이익이나 공익에 반하는 목적을 위해 서비스를 이용하는 경우<br>
  ⑦ 고객의 서비스 이용이 관련 법령을 위반하거나 공공 윤리나 질서에 반하는 경우<br>
  ⑧ 고객의 행위가 타인의 명예를 손상시키거나 불이익을 초래하는 경우<br>
  ⑨ 고객이 이용중인 서버가 바이러스에 감염 또는 해킹되었거나 그러한 것으로 의심되는 경우<br>
  ⑩ 서비스를 통해 고객이 처리하는 고객의 정보에 대해 제 3 자가 권리 침해를 주장하는 등 (이를 포함하되 이에 한정 짓지 않음) 고객의 서비스 이용을 정지시킬 만한 법적 근거가 있는 경우<br>
  ⑪ 법적 절차에 따라 정부기관이 서비스의 일시적 이용 정지를 요청 또는 명령하는 경우<br>
  ⑫ 고객이 회사가 서비스 이용을 위해 정한 조건, 서비스 이용과 관련하여 고지한 제약 또는 제한 사항을 회피하는 방식(예를 들면, 무료로 서비스를 이용하거나 특가 혜택을 받기 위하여 복수의 회사 계정을 생성하거나 필수 설치 프로그램을 무단 삭제하는 등)으로 본건 서비스에 접근하거나 사용하는 경우<br>
  ⑬ 기타 관련 법령이나 회사가 정한 이용약관, 이용조건에 위배되는 경우<br>
2) 상기 제 1 항에 따른 사유로 인해 고객의 서비스 이용을 정지시키는 경우, 회사는 해당 고객에게 정지 사유, 이용 정지 기간 및 공식적인 이의 제기 수단을 통지합니다. 회사는 제 1 항과 관련한 사유가 제거되거나 더 이상 유효하지 않은 경우 지체 없이 고객의 서비스 이용 권리를 회복시킵니다.<br>
3) 이용 정지 사유가 계속해서 존재하는 경우 회사는 해당 서비스 계약을 해지할 수 있습니다. 이 경우 회사는 제 7 조에 명시된 방법에 따라 고객에게 통지합니다. 고객이 파트너에게 계속하여 요금을 납입하지 않는 경우에는 파트너가 증빙을 갖추어 계약 해지를 요청할 경우에도 동일합니다.<br>
4)  회사는 본 조에서 기술된 사유로 인해 서비스가 정지된 기간 동안 발생한 요금을 청구할 수 있습니다.<br>
<br>
제 15 조 (서비스 중단)<br>
<br>
1) 다음에 해당하는 경우 회사는 전 통지 없이 해당 서비스를 일시적으로 중단할 수 있습니다. 이 경우, 사전 또는 사후 고객에게 중지사유 및 기간을 공지합니다. <br>
  ① 서비스 제공과 관련된 회사와 제 3 자간의 계약의 종료/해지, 설비 수리 및 기타 유지보수 작업 등 불가피한 사유가 발생한 경우<br>
  ② 국가적 비상사태, 설비 결함 또는 서비스 이용 급증 등으로 정상적인 서비스 운영에 장애가 발생할 위험이 있는 경우<br>
  ③ 천재지변 등 불가피한 사유로 인해 회사가 실질적으로 안정적인 서비스를 제공할 수 없는 경우<br>
  ④ 전기통신 서비스 제공업체(전기통신사업법에 명시)가 통신 서비스를 중단한 경우 <br>
2)  회사는 상기 제 1 항에 명시된 사항에 해당하는 경우 고객에게 사전 통지하며 서비스 접속화면이나 서비스 웹사이트상에 게재함으로써 서비스 중단을 공지할 수 있습니다. 단, 회사가 통제할 수 없는 사유로 인한 서비스의 중단(회사의 고의, 과실이 없는 설비 장애, 시스템 장애 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다<br>
3) 고객은 홈페이지에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, i-ESG 관리범위 외의 서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실에 대해 회사는 관련 책임을 부담하지 않습니다.<br>
4) 회사가 정상적인 서비스 제공의 어려움으로 인하여 일시적으로 서비스를 중지하여야 할 경우에는 서비스 중지 1주일 전에 고지 후 서비스를 중지할 수 있으며, 이 기간 동안 고객이 고지 내용을 인지하지 못한데 대하여 회사는 책임을 부담하지 않습니다. 상당한 이유가 있을 경우 위 사전 고지기간은 감축되거나 생략될 수 있습니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에도 회사는 책임을 부담하지 않습니다.<br>
5) 회사는 사전 공지 후 서비스를 일시적으로 중단할 수 있으며, 이에 대하여 고객 또는 제3자에 어떠한 책임도 부담하지 않습니다.<br>
6) 회사는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고 없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.<br>
7) 회사가 통제할 수 없는 사유로 인한 서비스 중단의 경우(시스템관리자의 고의, 과실 없는 디스크 장애, 시스템 다운 등)에 사전통지가 불가능 하며, 타인(PC통신회사, 기간통신사업자 등)의 고의, 과실로 인한 시스템 중단 등의 경우에는 통지하지 않습니다.<br>
8) 1년 이상 장기간 휴면 고객인 경우 안내 메일 또는 공지사항 발표 후 1주일간의 통지기간을 거쳐 서비스 사용을 중지할 수 있습니다.<br>
<br>
제 16 조 (고객에 의한 서비스의 중지 또는 해지)<br>
<br>
1) 고객이 서비스 계약을 중지하거나 해지하기 원하는 경우, 서비스 웹사이트에 게시한 절차와 방법을 통해 회사에게 이를 통지해야 합니다. 회사는 정상적인 조건 하에서 고객이 요청한 서비스의 해지와 관련하여, 별도로 정한 환불정책에 의거 서비스를 중지시키거나 서비스 계약을 종료합니다. 그러나 고객이 계약 해지를 요청한 날 납부해야 할 이용 요금이 남아 있다면, 해당 고객이 이용 요금을 납부 완료한 후에야 해지 절차를 진행할 수 있습니다.<br>
2) 서비스 이용 기간 중에 고객이 잠정적으로 서비스 계약을 중지하고자 하는 경우, 허용 가능한 중지 기간, 중지 기간 중 이용 요금, 중지 조건, 그리고 중지의 효력 등의 문제는 서비스 웹사이트에 게시된 세부 사항에 따릅니다. <br>
  ① 정액 상품을 해지하는 경우, 계정은 현재 결제 주기가 종료될 때 자동으로 상품 사용이 종료됩니다. 단, 부득이한 사유로 인한 해지의 경우 정액 상품의 사용 비율 또는 사용한 기간(월할계산) 비율로 환불 금액을 산정하여 환불이 진행됩니다.<br>
  ② 상품의 사용 비율 또는 사용한 기간에 따라 현금이 아닌 i-ESG 솔루션에서 사용이 가능한 Credit의 형태로 제공될 수 있습니다. <br>
3) 고객이 상기 제 2 항에 명시된 서비스 이용 중지 사유의 일부에 해당하여 서버 이용을 중지하는 경우(“서버 중지”), 서비스는 한번에 최대 90 일까지, 12 개월 동안 누적 일수 180 일까지 중지될 수 있습니다. 고객이 서버를 이용하지 않은 상태로 회사의 명시적 동의 없이 최대 기간을 초과한 경우, 회사는 고객에게 통지한 후 서버를 반납 처리(회수)할 수 있습니다. 이 경우 서버에 저장된 고객 데이터는 회사에 30 일 간 백업된 후 삭제됩니다. 고객은 앞서 언급한 저장 기간 이내에 한하여 서버 및 데이터 복구 재사용을 요청할 수 있습니다. 서버 중지가 가능한 서버 타입 및 중지 기간 동안 발생하는 이용 요금에 대한 정보는 서비스 웹사이트에 게시된 바에 따릅니다.<br>
4) 고객은 서비스 이용 기간 동안 사용해온 서버에 저장된 데이터를 서비스 계약 해지 이전에 직접 백업해야 합니다. 회사는 서비스 계약 해지와 동시에 고객의 모든 자원(서버 등) 및 자료를 삭제합니다. 삭제된 자원과 자료는 어떠한 이유로든 복구되지 않습니다.<br>
<br>
제17조 (서비스 이용제한)<br>
<br>
1) 고객이 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가 있다고 의심할 만한 합리적인 사유가 발생할 경우 고객의 본 서비스 사용을 일부 또는 전부 이용 정지할 수 있으며, 이로 인해 발생하는 불이익에 대해 회사는 책임을 부담하지 않습니다.<br>
2) 고객이 본 약관 제16조(고객의 의무) 등 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지할 수 있습니다. 이 경우 회사는 고객의 접속을 금지할 수 있습니다.<br>
<br>
제18조 (정보제공 및 광고의 게재)<br>
<br>
1) 회사는 고객이 서비스 이용 중 필요하다고 인정되는 다양한 정보 및 광고에 대해서는 전자우편이나 서신우편, SMS(핸드폰 문자메시지), DM(Direct Mail), 메신저 등의 방법으로 고객에게 제공할 수 있으며, 만약 원치 않는 정보를 수신한 경우 고객은 이를 수신거부 할 수 있습니다.<br>
2) 회사는 서비스의 운용과 관련하여 서비스화면, 홈페이지, 전자우편 등에 광고 등을 게재할 수 있으며, 서비스를 이용하고자 하는 고객이 이 광고게재에 대하여 동의하는 것으로 간주합니다.<br>
3) 회사는 서비스 상에 게재되어 있거나 서비스를 통한 광고주와의 판촉활동에 고객이 참여하거나 교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 책임을 지지 않습니다.<br>
<br>
<br>
제 4 장 이용요금 및 관리<br>
<br>
제 19 조 (서비스 이용요금)<br>
<br>
1) 회사는 이용요금 및 그 변경사항을 서비스 웹사이트상에 게시합니다.<br>
2) 서비스는 기본적으로 월간, 연간구독료(약정제)로 정의되며, 별도로 회사가 정한 바에 따라 프로모션 요금이 있을 수 있습니다.<br>
3) 특별한 사유가 있지 않는 한 서비스 계약 기간 동안 이용요금이 변경되더라도 변경된 이용요금은 서비스 계약 기간 전체에 걸쳐 소급 적용되지 않습니다.<br>
4) 회사는 서비스 이용 시 활용할 수 있는 크레딧 (이하 “크레딧 등”)을 고객 상대로 발행할 수 있습니다. 이때 “크레딧 등”의 활용 조건 및 기타 운영 정책 등 “크레딧 등”에 대한 세부사항은 서비스 웹사이트에 게시한 바에 따릅니다.<br>
<br>
제 20 조 (서비스 이용 요금 산정 및 정산)<br>
<br>
1) 서비스 이용요금은 유료서비스를 이용하는 시점에 선납하여야 하며, 달리 정하지 않는 한 매월 또는 매년 갱신됩니다. <br>
<br>
제 21 조 (해지 수수료)<br>
<br>
1) 합의된 계약 기간이 있는 서비스 계약이 제 13 조 제 3 항, 제 15 조 또는 제 16 조, 또는 기타 고객의 사유로 해지되는 경우, 고객은 해지 수수료를 회사에 납부해야 합니다. 해지 수수료는 개별서비스의 이용약관 또는 운영 정책 등에서 별도로 안내됩니다.<br>
2) 다음에 해당하는 경우, 고객은 전항의 해지 수수료를 납부하지 않고 서비스 계약을 해지할 수 있습니다.<br>
  ① 회사의 귀책 사유로 인해 누적 서비스 장애가 한 달 동안 72 시간을 초과한 경우<br>
  ② 회사의 귀책 사유로 인해 1 시간 이상 지속되는 서비스 장애가 한 달 동안 5 회 이상 발생한 경우<br>
  <br>
제 22 조 (이용요금 납부 및 청구)<br>
1) 회사(파트너 포함, 본 조에서는 이하 동일)는 이용요금 발생 해당 월의 다음 달에 고객에게 청구서를 발행하며, 고객은 회사가 사전에 명시한 지급기일 당일 또는 그 이전에 청구된 금액을 납부해야 합니다.<br>
2) 서비스 이용에 대한 이용요금 납입 수단은 다음 각 호와 같습니다.<br>
  ① 회원 가입 유형이 국내(대한민국) 사업자인 경우 : 계좌이체, 전용가상계좌, 신용카드 중 선택.<br>
  ② 제 1 호 외의 회원 가입 유형인 경우 : 신용카드.<br>
3) 회사는 고객이 이용요금 지급기일 최소 5 일 전에 서비스 이용요금 청구서를 받을 수 있도록 사전에 발송합니다.<br>
4) 고객이 지급기일 내에 이용요금을 납부하지 않는 경우, 회사는 이메일을 통해 미납 이용요금에 대한 통지를 합니다. 미납 이용요금에 대해서는 가산금(매월 미납 금액의 1.5%)이 추가되어 재청구됩니다.<br>
5) 고객이 이용요금을 2회 까지 납부 하지 않는 경우, 회사는 약관에 명시되고 서비스 웹사이트에 게시된 절차와 방법에 따라 서비스를 해지할 수 있습니다.<br>
6) 기존 납부 방식에서 요금제 및 사용자를 변경할 경우 변경일 전까지 사용한 요금 및 할인반환금을 제외한 사용료를 정산하여 환불 처리한 후 변경된 시점부터 새로운 선납으로 결제가 진행되어 결제 주기가 변경됩니다.<br>
  ① 요금제 납부 방식이 변경될 경우 (월간 → 연간 또는 연간 → 월간)<br>
7) 연간 결제 만료 전 중도에 월간 요금 납부 방식을 변경하거나 서비스의 변경, 해지하게 될 경우 할인반환금이 발생됩니다. 할인반환금은 월간 요금제 대비 할인받은 금액으로, 계산 공식은 아래와 같습니다.<br>
  ① 할인반환금 ={사용기간 월 X (월간 요금제 단가 - 연간 요금제 단가)}<br>
8) 월간 결제와 연간 결제 중도 변경 시 계산 공식은 아래와 같습니다.<br>
  ① 월간 결제 서비스 중도 변경 시 환불 요금 계산법 : 기존 결제금액 - 변경일 전날까지 일할 계산된 사용요금<br>
  ② 연간 결제 서비스 중도 변경 시 환불 요금 계산법 : 기존 결제금액 - 변경일 전날까지 일할 계산된 사용요금 - 할인반환금<br>
  <br>
제 23 조 (이용요금 납입의 책임)<br>
고객은 서비스 이용요금을 납입할 책임이 있습니다. 단, 고객이 이용요금 납입 책임자를 지정한 경우 고객과 이용요금 납입 책임자가 이용요금 납입에 대해 연대책임을 집니다. 이 경우 고객은 납입 책임자의 책임 확인서를 제공하여야 합니다.<br>
<br>
제 24 조 (청구서에 대한 이의제기)<br>
1) 고객이 청구된 이용요금에 동의하지 않는 경우, 고객은 서면 또는 이메일을 통해 회사 또는 파트너에 대해 공식적으로 이의를 제기할 수 있습니다.<br>
2) 회사 또는 파트너는 제 1 항의 공식 이의제기 문서를 접수한 이후 7 영업일 이내에 검토하여 고객에게 그 결과를 알립니다. 상기 언급한 이의제기와 관련한 결과를 정해진 기간 내에 고객에게 통지하지 못하는 경우, 회사 또는 파트너는 고객에게 지연사유를 통지합니다.<br>
<br>
제 25 조 (이용요금의 환불)<br>
<br>
1) 회사 또는 파트너는 고객이 납부한 이용요금에 과오납이 있는 경우 과오납된 요금액을 고객의 다음 달 이용 요금에서 상계할 수 있습니다.<br>
2) 제 1 항의 상계 이전에 고객이 과오납금액에 대하여 환불을 요구하는 경우 회사 또는 파트너는 고객에게 해당 금액을 반환합니다.<br>
3) 회사는 다음과 같은 환불정책을 의거 고객에게 환불 절차를 진행합니다. <br>
  ① 고객은 서비스 수수료를 결제한 후 서비스를 사용하지 않은 상태에서 2주일내에 신청을 철회할 수 있으며 이 경우 전액 환불받을 수 있습니다. <br>
  ② 서비스를 사용하거나 사용하지 않은 상태에서 2주일이 경과한 경우에 환불을 신청하는 경우, 아래와 같이 환불을 진행 합니다.<br>
- 월간 결제 서비스 이용 중 환불 요금 계산법 : 기존 결제금액 - 해당일 전날까지 일할 계산된 사용요금<br>
- 연간 결제 서비스 이용 중 환불 요금 계산법 : 기존 결제금액 - 해당일 전날까지 일할 계산된 사용요금 - 할인반환금<br>
- 환불받을 금액은 0원보다 클 경우에 지급되며, 0원보다 작을 경우 고객이 반활할 추가 금액은 없는 것으로 간주합니다.<br>
  ③ i-ESG가 제공하는 솔루션의 제 4 항의 일부를 활용한 경우에는 해당기간이라 할지라도 취소된 구독 대해 환불을 요청할 수 없습니다.<br>
4) 사용여부의 기준은 아래와 같습니다. <br>
  ① 회사가 제공하는 어떠한 솔루션(자가진단 솔루션, 중요성이슈 식별 솔루션, 리포팅자동화 솔루션, ESG 관리솔루션, 온실가스 관리솔루션, 공급망 관리 솔루션 등 고객이 유료로 결제한 패키지 상품에 포함된 솔루션의 일부 또는 전체)을 진행, 완료하지 않은 경우 <br>
  <br>
제 5 장 의무 및 책임<br>
<br>
제26조 (회사의 의무)<br>
<br>
1) 회사는 안정적이고 지속적인 방식으로 고객이 요청하는 서비스를 제공하기 위해 노력합니다.<br>
2) 서비스의 정상 운영에 지장을 주는 장애가 발생하는 경우, 회사는 실행할 수 있는 최대한 빨리 정비 또는 복구하고 서비스를 안정적으로 운영하는데 최선을 다합니다.<br>
3) 회사는 고객이 제기한 의견이나 불만을 공정하게 즉시 또는 기간 내에 처리하며, 회사가 정한 절차에 따릅니다.<br>
4) 서비스를 원활히 운영하기 위하여 회사는 서비스 웹사이트에 게시된 개인정보보호정책에 따라 고객 개인정보를 수집하고 보관할 수 있습니다. 회사는 고객의 동의 없이 고객의 개인정보를 제 3 자에게 제공하지 않습니다. 단, 관련법 및 규정에 따라 조사의 목적 등으로 법원 또는 기타 사법 기관이 발행하는 영장 등을 통해 고객 개인정보 제공을 요청 받을 경우는 예외로 할 수 있습니다.<br>
5) 회사는 이용약관에 벗어난 목적으로 서비스와 관련한 고객의 정보에 접근하거나 데이터를 처리하지 않습니다. 다만 장애 처리, 고객의 정보 보호 등 원활한 서비스 제공을 위하여 접근이 필요한 경우 고객의 정보에 접근, 내용을 파악할 수 있습니다.<br>
6) 회사는 제 5 항에 따라 파악한 고객의 정보에 대하여 원활한 서비스 제공을 위하여 삭제, 변경 등 데이터 처리가 필요한 경우 고객의 동의를 받습니다. 다만, 고객의 동의가 없거나 동의를 하지 않더라도 회사의 서비스 운영 및 다른 고객의 서비스 이용에 방해가 되는 경우 회사는 해당 고객의 서비스 이용을 정지할 수 있으며, 그 절차는 제13조 제 2항 이하에서 정한 바에 따릅니다.<br>
<br>
제27조 (회사의 소유권)<br>
<br>
1) 회사가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스 명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 회사에게 있습니다.<br>
2) 회사의 서비스 웹사이트에 포함된 모든 콘텐츠[텍스트, 그래픽, 로고, 버튼 아이콘, 이미지, 오디오 클립, 디지털 다운로드, 데이터 컴필레이션(data compilations) 및 소프트웨어 등]는 회사 또는 그 콘텐츠 제공자의 자산이며, 저작권법 등 관련 법령에 의해 보호됩니다. 고객은 회사와의 별도 합의가 있거나 회사의 명시적인 서면 동의가 있는 경우를 제외하고, 콘텐츠의 전부 또는 일부를 상업적 목적으로 복제, 복사, 사용할 수 없으며, 다운로드 또는 변경하거나 재판매를 하여서는 안됩니다. 또한, 고객의 콘텐츠 사용권한에는 데이터 마이닝, 로봇이나 이와 유사한 데이터 수집 및 추출 도구의 사용이 포함되지 않습니다.<br>
3) 고객은 회사가 명시적으로 승인한 경우를 제외하고는 제1항 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권설정행위, 상업적 이용행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.<br>
<br>
제28조 (개인정보보호정책)<br>
<br>
1) 회사는 이용신청 시 고객이 제공하는 정보를 통하여 고객에 관한 정보를 수집하며, 고객의 개인정보는 본 이용계약의 이행과 본 이용계약상의 서비스 제공을 위한 목적으로만 이용합니다.<br>
2) 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 고객의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보취급방침이 적용됩니다.<br>
3) 회사는 서비스 제공과 관련하여 취득한 고객의 정보를 본인의 승낙 없이 제3자에게 누설 또는 배포할 수 없으며 상업적 목적으로 사용할 수 없습니다. 다만, 다음의 각 호의 경우는 제외합니다.<br>
  ① 관계 법령에 의하여 수사상의 목적으로 관계기관으로부터 요구가 있는 경우<br>
  ② 정보통신윤리위원회의 요청이 있는 경우<br>
  ③ 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우<br>
  <br>
제29조 (i-ESG 제휴 서비스 활용 및 관리에 대한 운영)<br>
<br>
1) 다음 각 호에 해당하는 경우 회사는 해당 서비스에 대해 검토해볼 권리가 있습니다.<br>
  ① 기업고객이 i-ESG 연계 서비스 활용 시, 서비스 진행에 대한 현황 확인<br>
  ② 다른 고객의 신고가 접수된 경우<br>
  ③ 기타 부정행위로 의심할만한 근거가 발견된 경우<br>
2) 부정 행위 적발 시 기업고객에게 내용 통보 후 제휴 파트너사를 퇴출할 수 있습니다.<br>
  ① 이러한 경우, 회사는 기업고객의 ESG 관리가 용이하도록 타 제휴사를 소개하는 데 최선을 다할 의무가 있습니다.<br>
  <br>
제30조 (고객의 의무)<br>
<br>
1) 고객은 서비스 이용을 위해 가입할 경우 현재의 사실과 일치하는 완전한 정보(이하 ‘가입정보’)를 제공해야 합니다. 또한 가입정보에 변경이 발생할 경우 즉시 갱신해야 합니다.<br>
2) 고객에게 부여되는 ID, 비밀번호는 다음 각 호와 같이 관리해야 합니다.<br>
  ① 고객의 승인 없이 ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 회사에 신고해야 합니다.<br>
  ② 매 접속 종료 시에는 반드시 로그아웃 하고, 웹 브라우저의 창을 닫아야 합니다.<br>
3) 고객은 서비스를 이용하면서 다음과 같은 행위를 하지 않기로 동의합니다.<br>
  ① 타인(소수를 포함)에게 위해를 가하는 행위<br>
- 타인의 ID, 비밀번호, 명의 도용 및 타인으로 가장하는 행위<br>
- 타인과의 관계를 허위로 명시하는 행위<br>
- 타인을 비방할 목적으로 사실 또는 허위의 사실을 적시하여 명예를 훼손하는 행위<br>
- 본인 또는 타인에게 재산상의 이익을 주거나 손해를 가할 목적으로 허위의 정보를 유통시키는 행위<br>
- 수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 해 상대방의 일상적 생활을 방해하는 행위<br>
- 회사의 사전 승낙 없이 서비스를 이용한 영리 행위<br>
  ② 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 행위<br>
  ③ 정크 메일(junk mail), 스팸(spam), 도배글, 피라미드 조직 등을 권유하거나 게시, 게재 또는 전자우편으로 보내는 행위<br>
  ④ 저속 또는 음란한 데이터, 텍스트, 소프트웨어, 음악, 사진, 그래픽, 비디오 메시지 등(이하 ‘컨텐츠’)을 게시, 게재 또는 전자우편으로 보내는 행위<br>
  ⑤ 지적재산권을 포함한 모든 권리가 없는 컨텐츠를 게시, 게재 또는 전자우편으로 보내는 행위<br>
  ⑥ 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비를 파괴, 방해 또는 기능을 제한하기 위한 소프트웨어 바이러스를 게시, 게재 또는 전자우편으로 보내는 행위<br>
  ⑦ 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시, 게재, 전자우편으로 보내는 행위 등 다른 사용자의 개인정보를 수집 또는 저장하는 행위<br>
  ⑧ 재물을 걸고 도박하거나 사행 행위를 하는 행위<br>
  ⑨ 윤락 행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위<br>
  ⑩ 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는 행위<br>
  ⑪ 기업고객 및 관련인이 거짓으로 위장하여 본인의 콘테스트에 참여하는 행위<br>
  ⑫ 기타 불법적이거나 부당한 행위<br>
4) 고객은 준거법, 약관, 회사의 서비스 웹사이트와 지침에 명시되어 있는 가이드라인과 예방조치 등을 완벽하게 숙지하고 체득하여 준수할 의무가 있습니다. 고객은 회사의 업무에 방해되는 행위를 하여서는 안됩니다.<br>
5) 고객은 서비스를 이용하면서 취급하는 데이터를 백업하고 저장할 의무가 있으며 데이터 관리 소홀로 인한 손실이 발생한 경우 이에 대한 책임을 져야 합니다.<br>
6) 고객은 회사의 동의없이 제 3 자에게 서비스를 재판매 혹은 재임대할 수 없습니다.<br>
7) 본 약관 및 관계법령에서 규정한 사항을 준수하지 않음으로 인해 발생하는 서비스 이용 상의 손해 또는 제3자에 의한 부정 이용 등에 대한 책임은 고객에게 있으며, 회사는 그에 대해 책임을 지지 않습니다.<br>
<br>
제 6장 손해배상<br>
<br>
제 31 조 (회사의 손해배상)<br>
<br>
1) 회사는 회사의 귀책사유로 인하여 서비스를 제공하지 못한 때에, 고객의 요청에 의거하여 전액 또는 부분 환불을 진행할 수 있습니다. <br>
2) 고객이 “개별 서비스”를 이용하지 못함으로 인한 회사의 배상책임은 상기 1항에 한정되며, 고객의 기대이익과 같은 간접적/부수적인 손실에 대하여는 회사가 책임을 부담하지 않습니다.<br>
3) 본 조항은 회사의 서비스를 유료로 이용하는 고객에게만 적용되며, 무료 서비스 또는 이벤트 등으로 서비스를 무료로 이용하는 고객에게 발생한 손해에 대해서는 회사가 책임지지 않습니다.<br>
<br>
제 32 조 (고객의 손해배상)<br>
<br>
1) 고객은 다음과 같은 사유로 분쟁이 발생하는 경우 회사, 계열사, 각각에 해당하는 임직원, 대리인, 협력사 및 라이선스
사용허가권자를 면책시키고 방어하며, 이들이 피해를 입지 않도록 하는데 동의합니다.<br>
  ① 고객이 약관, 준거법 및 규정 위반 또는 침해<br>
  ② 회사가 약관 위반으로 의심되는 것을 조사하거나 약관 위반이 발생한 것으로 판단하여 취하는 조치<br>
2) 고객이 회사에 대하여 손해를 배상하게 되는 경우 회사의 법률 비용, 경비 그리고/또는 상기 명시한 청구에서 비롯되는 손해배상액을 포함하되 이에 한정되지 않습니다.<br>
3) 회사가 고객에 대해 손해배상을 청구하는 경우의 절차에 관해서는 제 27 조의 규정을 준용합니다.<br>
<br>
제 33 조 (법적 책임 제한) <br>
<br>
1) 다른 규정에도 불구하고, 다음에 해당하는 경우 그것이 예측 가능한 상황이라 할지라도 회사는 계약 상이든 불법 행위(과실 포함) 상이든 이와 관련하여 발생하는 어떠한 손실이나 손해에 대하여 준거법이 허용하는 최대한의 범위 내에서 책임을 지지 않습니다.<br>
   ① 천재지변이나 전쟁 등 불가항력에 의한 경우<br>
   ② 고객의 고의(미필적 고의 포함), 부작위 또는 과실에 의한 경우<br>
   ③ 회사 이외의 서비스 제공업체가 제공한 통신 서비스에 대한 장애<br>
   ④ 회사가 고의 또는 중과실로 서비스를 중단한 경우를 제외하고, 서비스 진단 등 부득이한 사정으로 인해 사전 공지 후 서비스를 중단한 경우<br>
   ⑤ 회사가 제 9 조에 따라 사전 통지 또는 공지된 정기 점검 실행을 위해 서비스를 중단한 경우<br>
   ⑥ 고객의 이용이 서비스 계약에서 정의하는 서비스 범위를 초과하는 경우<br>
   ⑦ 고객이 서비스에 게시한 정보의 부정확, 부적법함 등으로 인해 고객 자신 또는 제 3 자에게 손해가 발생한 경우<br>
   ⑧ 고객의 시스템 보안 관리 소홀로 인하여 침해가 발생한 경우<br>
   ⑨ 국가적 비상사태, 전국적 네트워크 장애, 또는 이에 상응하는 불가항력으로 인해 서비스가 중단된 경우<br>
   ⑩ 서비스를 이용하는 고객의 시스템에서 발생한 사고의 확산을 방지하기 위해 서비스가 중단되는 경우<br>
   ⑪ 회사가 관련 법령에 따른 보호조치를 취하였음에도 외부로부터의 불법적 침해로 인해 서비스 장애가 발생한 경우<br>
   ⑫ 고객의 정보시스템에서 발생한 사고의 확산을 방지하기 위해 서비스가 중단되는 경우<br>
   ⑬ 회사 서비스 상에 고객이 임의로 설치한 장비, 소프트웨어, 애플리케이션 또는 OS 에서 장애가 발생하는 경우<br>
   ⑭ 고객이 무료 서비스를 이용하는 경우<br>
2) 계약 또는 불법행위(과실 포함), 기타에서 비롯되는 모든 손해, 손실 및 소송 사유에 대하여 회사가 책임지는 최대 누적 배상 금액은 (A) 본 조건 하에서 발생한 소송 사유의 요인이 되는 상황의 발생일 직전 6 개월 내 서비스 이용을 위해 고객이 회사에 납부한 총 합계 금액, 그리고 (B) 미화 1 천 달러 중에서 큰 금액입니다. 법적 책임 제한은 어떠한 종류이든(본 약관 상의 본 조항 및 기타 조항 포함) 회사와 계열사 그리고 이들의 승계인 및 양수인의 이익에 부합하기 위함입니다.<br>
3) 회사는 특별, 직접, 간접, 징벌적, 부수적, 결과적 손해 또는 이익 손실, 저축 상의 손실, 영업 방해, 정보의 손실 등을 포함하되 이에 한정 짓지 않는 사유로 인한 어떠한 손해(계약 또는 불법 행위 또는 기타 서비스와 관련하여 발생한 모든 기타 손해)에 대하여 책임을 지지 않습니다.<br>
<br>
제34조 (재판관할)<br>
<br>
1) 서비스 이용과 관련하여 회사와 고객 사이에 분쟁이 발생한 경우, 회사와 고객은 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 해야 합니다.<br>
2) 제1항의 규정에도 불구하고 서비스 이용으로 발생한 분쟁에 대하여 소송이 제기될 경우, 회사 소재지를 관할하는 법원을 관할법원으로 합니다.<br>
3) 고객과 회사는 서울중앙지방법원의 독점적 사법권과 재판관할권에 영구히 동의하며, 이에 대한 사법권, 재판관할권의 불편함에 대한 이의권을 포기합니다. 전항에도 불구하고, 서울중앙지방법원의 판결 및 판시 사항은 고객 또는 회사 소재지의 어느 법원에서나 집행이 가능합니다.<br>
<br>
제 35 조 (책임 면제 조항) <br>
<br>
1) 준거법에서 허용하는 최대 한도 내에서, 서비스는 “있는 그대로,” “유효한” 범위에서 제공되며, 회사는 조건, 품질, 내구성, 수행능력, 정확성, 신뢰성, 상업성 또는 특별 목적에 대한 적합성, 그리고 침해 방지, 또는 원활한 서비스, 오류 방지, 유해 요소 방지, 보안, 또는 기능이나 데이터의 손해나 손실 유발 방지 등을 포함하되 이에 한정 짓지 않는 내용에 대하여 암시적으로나 명시적으로 일체의 보장을 하지 않습니다.<br>
2) 회사는 준거법에서 허용하는 최대 한도 내에서 서비스가 제공하는 정보의 유효성, 정확성, 신뢰성, 품질, 안정성, 완전성 또는 현재성을 대표 또는 보장하지 않습니다. 일부 관할지에서는 묵시적 보증의 배제나 묵시적 보증의 유효기간 제한을 허용하지 않으므로, 위 배제나 제한이 고객에게 적용되지 않을 수 있습니다.<br>
<br>
제 36 조 (분리 규정)<br>
<br>
만약 어떤 조항이 법원 또는 관할 재판소에 의해 무효 또는 집행 불가하다고 판단되더라도, 그 나머지 조항들의 집행 가능성은 이에 영향 받지 않고 여전히 유효하며 강제력을 갖습니다.<br>
<br>
<br>
제 37 조 (권리 불포기 조항)<br>
<br>
고객의 약관 위반과 관련하여 회사가 권리 실행이나 조치를 취하지 않았다고 하더라도, 이후 결과적으로 또는 유사하게 발생하는 행위에 대해 회사가 약관의 권리 및 해당 권리 실행을 포기하는 것으로 간주되지는 않습니다.<br>
<br>
<br>
제 38 조 (무역 법규 준수)<br>
<br>
고객이 서비스를 구매 및/또는 이용하기 위한 조건으로서, 고객은 EU Dual Use Export Controls, US Export Administration Regulations, International Traffic in Arms Regulations, 국제 및 국가별 경제 제재 프로그램을 포함하여 해당되는 모든 데이터의 보호, 수입, 재수입, 제재, 안티 보이콧, 수출 통제 법규 및 국가별 수출 통제 제도를 준수하기로 합의합니다. 오해의 소지를 피하기 위하여 부연하자면, 고객(해당되는 경우에는 그 최종 사용자)은 고객의 정보의 전송 및 처리, 앞서 언급한바 있는 회사의 최종 사용자를 대상으로 한 고객의 정보의 활용을 포함하여, 고객이 서비스를 사용하기로 선택하는 방식과 관련된 준수에 전적으로 책임이 있습니다. 고객이나 최종 사용자가 업로드한 모든 고객의 정보는 회사의 전세계에 위치한 서버에서 호스팅 될 수 있으므로, 고객은 관련된 무역 제한 목록에 나열된 정보를 업로드할 수 없으며 최종 사용자가 금지된 정보를 업로드 하지 못하게 할 책임이 있습니다. 고객은 UN 안정보장이사회, 미국 국가 안전보장 회의, 미국 정부(예: 미국 정부기관의 유럽연합 특별 지정 국가 목록 및 기타 해당 국가의 해외 제재 조치 회원국 목록) 및 이에 국한되지 아니한 기관의 제재 대상 목록에 명시되지 않았음을 확인하고 보증합니다.<br>
<br>
제 39 조 (언어)<br>
<br>
본 약관 상 회사가 게시하거나 통지하는 모든 의사교환 수단은 국문으로 합니다. 이 경우 회사는 이용자의 편의를 위하여 국문과 함께 영문 번역문을 제공할 수 있습니다. 단, 회사가 본 약관에 대한 영문 번역문을 제공하거나 게시 또는 통지를 함에 있어 영문 번역문을 제공하는 경우, 국문본과 영문 번역문 사이의 여하한 불일치 사항에 대해서는 국문본이 우선합니다. 그럼에도 불구하고 고객은 회사에 대한 의사교환 수단으로써 국문 또는 영문을 선택하여 진행할 수 있습니다.<br>
<br>
* 부 칙<br>
1. 이 약관은 2022년 6월 1일부터 적용됩니다.<br>
<br>
<br>
<br>
&lt;서비스 약관 부록 &gt;<br>
<br>
본 약관은 회사가 제공하는 클라우드 기반의 ESG 특화 관리 플랫폼 서비스 'i-ESG'(https://.i-ESG.io)을 이용함에 있어 제반 권리 의무와 관련 절차 등을 규정함을 목적으로 합니다.<br>
<br>
1. 서비스 개요<br>
<br>
회사가 운영하는 i-ESG는 온라인(i-ESG.io)상에서 기업고객을 대상으로 ESG 관리를 위한 관련 서비스를 운영하는 온라인 플랫폼입니다. i-ESG가 제공하는 서비스를 통해 원하는 서비스를 직접 검색하고 무료/유료로 서비스를 활용할 수 있습니다. 유료서비스의 경우 기업이 저장하고 관리하는 데이터를 i-ESG가 제공하는 데이터베이스에 등록, 향후 관리가 원할하게 이뤄질 수 있도록 합니다.<br>
<br>
2. 제공하는 서비스<br>
<br>
1) 기업고객은 i-ESG가 제공하는 아래 각호의 서비스를 무료로 이용할 수 있으며, 향후 일부 서비스의 유료 변경 등은 본 약관에 의거하여 이루어집니다.<br>
  ① 홈페이지상 게재된 각종 트렌드 정보의 열람<br>
  ② 홈페이지상 게재된 각종 세미나 정보의 열람<br>
  ③ 홈페이지상 게재된 각종 교육 정보의 열람 및 활용<br>
  ④ 기타 홈페이지상 게재된 각종 정보 열람<br>
  <br>
2) 유료 기업고객은 i-ESG가 제공하는 아래 각호의 서비스를 유료(월간, 연간구독료 기반)로 이용할 수 있으며, 이에 대한 대가를 i-ESG가 규정한 수수료 지불 규정에 의거 회사에 지급합니다.<br>
  ① i-ESG가 수집/분석한 데이터베이스를 활용한 ESG 간단진단 서비스 (진단, 피드백리포트, 보고서 다운로드 등)<br>
  ② i-ESG가 수집/분석한 데이터베이스를 활용한 ESG 리포팅 자동화 서비스 (기준 추천, 공시를 위한 기준 가이드, 자동완성 기능, 디자인템플릿, 외부연계 검증, 배포 등)<br>
  ③ i-ESG가 수집/분석한 데이터베이스를 활용한 ESG 관리 대시보드 서비스 (회사현황, AI 개선 피드백, 빅데이터분석, 긍/부정 사례 등)<br>
  ④ i-ESG가 수집/분석한 데이터베이스를 활용한 ESG 공급망 진단 서비스 (기본 진단 데이터, 추가 편집기능, 이메일발송, 결과 대시보드, 분석 리포트 등)<br>
  ⑤ i-ESG가 수집/분석한 데이터베이스를 활용한 중요성이슈 조사 서비스 (이해관계자 설정, 이메일발송, 결과 수집/분석, 분석 리포트 등)<br>
  ⑥ 상기 이외에 기업고객들의 요청에 따라 i-ESG는 유료 서비스를 추가로 제공할 수 있습니다.<br>
  ⑦ i-ESG가 웹상에서 제공하는 서비스 이외의 연계 파트너사에서 제공하는 서비스의 경우, 파트너사의 수수료 규정 및 약관에 따르며, i-ESG는 이와 관련하여 면책됩니다.<br>
  <br>
  <br>
4. i-ESG의 의무<br>
<br>
1) i-ESG는 기업고객들이 ESG 관련 대응/관리가 용이하도록 지속적인 서비스의 업그레이드에 최선을 다합니다.<br>
2) ESG 관련 트렌드, 유용한 Tip, 관련 세미나, 교육 등 효과적인 관리가 이뤄질 수 있도록 서비스를 개선, 확대하는 데 최선을 다합니다.<br>
3) 상품 가입고객의 데이타베이스 활용과 관련하여 회사는 회사가 정한 개인정보 취급방안에 의거 관련 법률을 준수하며, 사전 동의 없이 개인정보를 활용하지 않습니다.<br>
<br>
<br>
5. 기업고객의 의무<br>
<br>
본 조항은 고객이 i-ESG의 서비스를 이용하여 효과적인 자산관리를 하고자 하는 기업고객에게 적용됩니다.<br>
1) 기업고객은 i-ESG의 약관 외에 다른 요건이나 조건을 요구하지 않습니다.<br>
2) 기업고객은 ESG 관련 서비스 활용 전에 별도로 전반적인 부분에 대해 알아볼 의무가 있습니다. i-ESG는 데이터를 기반하여 최적의 솔루션을 제안하며, 이는 기업고객의 상황, 특성에 따라 유용하지 않을 수 있습니다. 이와 관련하여 발생하는 일체의 손해에 대해 책임지지 않습니다.<br>
3) i-ESG 서비스를 활용할 경우, 보다 효과적인 서비스 제공을 위해 i-ESG가 제공하는 데이터베이스에 해당 내용을 등록, 관리하는 데 동의합니다. 동의하지 않을 경우, 향후 i-ESG 서비스 활용에 제한이 있을 수 있으며, 서비스 이용간 발행한 어떠한 손실에 대해서도 회사를 상대로 책임을 물을 수 없습니다.<br>
<br>
<br>
6. 제휴 파트너사의 의무<br>
<br>
본 조항은 i-ESG의 서비스와 연계하여 기업고객에게 추가 서비스를 제공하고자 하는 제휴 파트너사에게 적용됩니다.<br>
1) 서비스 신청을 받은 경우, 기업고객이 요구하는 목적에 맞게 서비스를 제공하여야 하며, 그렇지 않는 경우 기업고개 또는 i-ESG에 의해 서비스 제공이 취소될 수 있습니다.<br>
2) 서비스를 제공함에 앞서, 제공하고자 하는 서비스에 대한 소개, 프로세스, 요금기준 등 상세한 서비스 제공방법에 대해 i-ESG향 공유하며, 서비스 방법은 i-ESG가 명시하는 방법을 따라야 합니다.<br>
3) 서비스를 제공하는 것은 i-ESG 보안정책에 동의하고, 다른 사람의 지적재산권을 침해하거나 특정 지적재산의 2차 저작물이 아님을 약속하고 보증한 것을 의미합니다.<br>
4) i-ESG에서 특별히 인정하는 경우가 아닌 이상 제3자의 지적재산권을 침해할 수 없습니다.<br>
5) 제휴 파트너사는 제3자의 지적재산권을 침해함으로 인한 기업고객 또는 회사의 손해에 대하여 책임을 부담해야 합니다. 다만, 제휴 파트너사의 책임 없는 사유로 인하여 발생한 경우에는 기업고객이 부담합니다.<br>
<br>
7. 제휴 파트너사의 서비스 중단<br>
<br>
1) 제휴 파트너사는 연계 서비스를 제공함에 있어, 전문기관(가)로서의 회사가 정한 바에 따라 최선의 노력을 다할 의무가 있습니다. 이와 관련하여 아래 각 호의 하나에 해당하는 사유가 발생하는 경우 제휴 파트너사는 기한의 이익을 상실하고 회사의 사전최고 등의 절차 없이 강제 서비스 중단이 될 수 있습니다. 이 경우 회사는 제휴 파트너사향 어떠한 법적 책임이 없으며, 이에 추가하여 회사에 손해가 발생하는 경우 이를 모두 배상하는 데 동의합니다.<br>
<br>
  ① 도덕성과 관련된 규정 위반으로 아래 중 하나에 해당하는 사항을 위반할 경우<br>
- 자격 허위 (경력, 소지자격 위조 또는 허위 기재 등)<br>
- 수수료 지급 관련 부정 (관련 비도덕적 행위)<br>
- 비합법적인 정보 이용 또는 남용<br>
<br>
  ② 전문성 결여와 관련하여 플랫폼 가치 위반을 누적 위반할 경우<br>
- 기업고객으로부터의 전문성 평가 클레임 (누적10회)<br>
- 서비스 제공에 대한 인위 거절 (누적10회)<br>
<br>
  ③ 효과적인 플랫폼 운영 및 서비스 질 향상을 위해, 회사는 제휴 파트너사의 서비스에 대한 모니터링 권리를 가지며, 제휴 파트너사는 회사의 요청을 이행하여야 합니다.<br>
  <br>
2) 제휴 파트너사는 언제든 원할 경우 임의 탈퇴할 수 있습니다.<br>
3) 본 조에 의하여 제휴 파트너사이 탈퇴하는 경우, 제휴 파트너사은 탈퇴 또는 탈퇴 시점까지 수행한 서비스의 제반자료, 성과물 등 일체의 관련 정보를 회사에 제출 또는 폐기하여야 하며, 동 자료 및 정보에 대한 일체의 권리는 회사에 귀속됩니다. 제휴 파트너사의 탈퇴 이후 어떠한 경우라도 회사에 귀속된 정보를 임의로 활용할 권리가 없으며, 이를 위반할 경우, 이를 통해 발생한 직, 간접적 손해에 대해 회사에 배상하여야 합니다.<br>
<br>
8. 지적재산권<br>
<br>
1) 회사가 i-ESG 서비스를 제공함에 있어 창출되는 일체의 서비스성과물 및 서비스 수행과정에서 창출된 고객정보를 포함한 유형 또는 무형물에 대한 일체의 권리는 창출과 동시에 회사에 귀속됩니다.<br>
2) i-ESG는 기업고객 또는 제휴 파트너사의 저지른 지적재산권 관련 침해, 위반 등에 법적인 책임을 지지 않습니다.<br>
3) 본 조 1항에서 정한 지적재산권과 관련하여 기업고객 또는 제휴 파트너사은 회사의 별도 사전 서면 동의 없이 어떠한 형태로 활용할 수 없으며, 이를 통해 발생한 회사의 직간접적 손실에 대해 손해 및 비용을 배상하여야 합니다.<br>
4) i-ESG는 홈페이지 상에서 제3자의 지적재산권을 침해한 것으로 판단되거나 침해 가능성이 존재하는 그 어떠한 콘텐츠를 자체적으로 삭제할 수 있습니다.<br>
5) 본 조에 정한 기업고객 및 제휴 파트너사의 의무는 이 서약기간 및 이 서약의 종료, 해지 또는 해제 후에도 유효합니다.<br>
<br>
<br>
9. 결제<br>
<br>
1) 기업고객은 회사가 제공하는 유료 서비스의 활용에 대한 대가로 월간, 연간구독료를 지불합니다.<br>
2) 월간, 연간구독료는 기업고객이 선택한 서비스의 범위에 따라 달라지며, 월 또는 년 단위 갱신형태로 진행됩니다.<br>
<br>
<br>
예금주: (주) 아이이에스지<br>
은행명: 신한은행<br>
계좌번호: 100-036-234496<br>
<br>
3) 제휴 파트너사가 회사에 지급하는 수수료 및 지급 기준, 계약해제, 손해배상 등의 상세한 조건은 당사자간 합의한 계약 내용에 준합니다.<br>
4) 회사에 대한 수수료 지급은 상품가입일 기준 익월말로 한하며, 지체가 있을 경우, 회사에 지급하여야 할 총액을 기준으로 일 [0.5]%의 지체상금을 지불하여야 합니다. 정해진 기한내 수수료가 지급되지 않을 경우, 회사는 제휴 파트너사가 약정한 보증금에서 해당 금액을 공제할 수 있으며, 제휴 파트너사은 보증금의 부족분을 공지일로부터 1주일 이내에 추가 입급해야 합니다. 동 규정을 준수하지 않을 경우, 회사가 약관 부록 7조에서 정한 규정에 의거 강제 탈퇴 등 불이익을 받을 수 있음에 동의합니다.<br>
<br>
제10조 (양도 및 담보제공 금지)<br>
<br>
제휴 파트너사난 회사의 사전 서면 동의 없이 이 서약에 의해 발생된 권리 및 의무사항에 대하여 전부 또는 일부를 제 3자에게 양도, 승계 또는 담보로 제공하지 못합니다.<br>
<br>
<br>
제11조 (서비스 범위 변경)<br>
<br>
회사가 약관 부록 제2조에서 정한 서비스에 대해 임의판단에 의거, 고객의 사전 서면동의 없이 서비스의 전부 또는 일부를 변경 또는 수정할 권리를 지니며, 이 서비스의 전부 또는 일부를 제 3자에게 이전할 수 있습니다.<br>
<br>
<br>
제12조 (서비스 종료)<br>
<br>
회사는 제2조에서 정한 서비스에 대해 임의판단에 의거, 고객의 사전 서면동의 및 고객에 대한 어떠한 손해배상 없이 서비스를 종료할 수 있습니다.<br>
<br>
<br>
제13조 (서약의 해석)<br>
<br>
이 이용약관의 각 조항에 관하여 해석 상의 이의가 있을 경우 및 이 약관에 명시되지 않은 사항은 고객과 회사가 합의하여 결정하되 합의가 되지 않을 경우에는 회사의 의견에 따릅니다.<br>
<br>
<br>
제15조 (효력)<br>
<br>
이 약관은 기업고객 또는 제휴 파트너사가 회사가 정한 일체의 내용을 이해하고 준수함을 동의함으로써 효력이 발생합니다.<br>
<br>
<br>
제16조 (관할법원)<br>
<br>
이 약관과 관련하여 분쟁이 발생하는 경우는 서울중앙지방법원을 제1심의 합의관할법원으로 한다.<br>
<br>
* 부 칙<br>
1. 이 약관은 2022년 6월 1일부터 적용됩니다.<br>
<br>
<br>
담당자<br>
e-mail : info@i-esg.io<br>
전화 : 02-3211-4374
          </p>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name : "Submodal",
  props : {
    viewmodal : Number,
  },
}
</script>

<style scoped src="./submodal.css">

</style>