<template>
  <div class="inner">
    <div class="t-head" v-if="windowWidth > 720 && language === 'ko'">
      <div
        class="pricing-level"
        v-for="(item, i) in tableHead"
        :key="item"
        :class="item.class"
        @mouseover="
          isHoveringEssential = i === 'Essential';
          isHoveringAdvanced = i === 'Advanced';
          isHoveringEnterprise = i === 'Enterprise';
          isHoveringEnterprisePlus = i === 'EnterprisePlus';
        "
        @mouseleave="
          isHoveringEssential = false;
          isHoveringAdvanced = false;
          isHoveringEnterprise = false;
          isHoveringEnterprisePlus = false;
        "
      >
        <div>
          {{ item.title }}
          <span v-if="i === 'EnterprisePlus'" :class="item.plusClass">{{ item.plus }}</span
          ><br />
        </div>
        <span class="title-sub">{{ item.subTitle }}</span>
      </div>
    </div>

    <div class="t-head" v-if="windowWidth > 720 && language === 'en'">
      <div
        class="pricing-level"
        v-for="(item, i) in tableHead"
        :key="item"
        :class="item.class"
        @mouseover="
          isHoveringEssential = i === 'Essential';
          isHoveringAdvanced = i === 'Advanced';
          isHoveringEnterprise = i === 'Enterprise';
          isHoveringEnterprisePlus = i === 'EnterprisePlus';
        "
        @mouseleave="
          isHoveringEssential = false;
          isHoveringAdvanced = false;
          isHoveringEnterprise = false;
          isHoveringEnterprisePlus = false;
        "
      >
        <div>
          {{ item.title }}
          <span v-if="i === 'EnterprisePlus'" :class="item.plusClass">{{ item.plus }}</span
          ><br />
        </div>
        <span class="title-sub">{{ item.subTitleEn }}</span>
      </div>
    </div>

    <!--720px mobile -->

    <div class="t-head" v-if="windowWidth < 721">
      <div
        class="pricing-level-mobile"
        v-for="(item, i) in tableHead"
        :key="item"
        :class="item.class"
        @mouseover="
          isHoveringEssential = i === 'Essential';
          isHoveringAdvanced = i === 'Advanced';
          isHoveringEnterprise = i === 'Enterprise';
          isHoveringEnterprisePlus = i === 'EnterprisePlus';
        "
        @mouseleave="
          isHoveringEssential = false;
          isHoveringAdvanced = false;
          isHoveringEnterprise = false;
          isHoveringEnterprisePlus = false;
        "
      >
        <div>{{ item.mobileTitle }}</div>
      </div>
    </div>
    <div class="t-body">
      <div class="service-title" v-if="language === 'ko'">
        <div v-for="(service, i) in tableBody.serviceDetail" :key="i" class="title">
          <P class="title-width">{{ service.title }}</P>
          <svg
            v-if="windowWidth > 721"
            class="pricing-info-svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @mouseover="showTooltip = i"
            @mouseout="showTooltip = null"
          >
            <path
              class="info-svg"
              d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 2.29875e-06 15.5228 2.78158e-06 10C3.2644e-06 4.47715 4.47716 5.50298e-07 10 1.03312e-06C15.5228 1.51594e-06 20 4.47715 20 10Z"
              fill="#CED5D1"
            />
            <rect x="9.0293" y="8" width="1.94444" height="7.77778" rx="0.972222" fill="white" />
            <path
              d="M9.02734 4.97295C9.02734 5.5099 9.46262 5.94518 9.99957 5.94518C10.5365 5.94518 10.9718 5.5099 10.9718 4.97295C10.9718 4.43601 10.5365 4.00073 9.99957 4.00073C9.46262 4.00073 9.02734 4.43601 9.02734 4.97295Z"
              fill="white"
            />
          </svg>
          <div class="tooltip Pretendard-400" v-if="showTooltip === i">
            {{ service.tooltip }}
          </div>
        </div>
      </div>

      <div class="service-title" v-if="language === 'en'">
        <div v-for="(service, i) in tableBody.serviceDetail" :key="i" class="title">
          <p class="title-width">{{ service.titleEn }}</p>
          <svg
            v-if="windowWidth > 721"
            class="pricing-info-svg"
            width="20"
            height="20"
            viewBox="20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @mouseover="showTooltip = i"
            @mouseout="showTooltip = null"
          >
            <path
              class="info-svg"
              d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 2.29875e-06 15.5228 2.78158e-06 10C3.2644e-06 4.47715 4.47716 5.50298e-07 10 1.03312e-06C15.5228 1.51594e-06 20 4.47715 20 10Z"
              fill="#CED5D1"
            />
            <rect x="9.0293" y="8" width="1.94444" height="7.77778" rx="0.972222" fill="white" />
            <path
              d="M9.02734 4.97295C9.02734 5.5099 9.46262 5.94518 9.99957 5.94518C10.5365 5.94518 10.9718 5.5099 10.9718 4.97295C10.9718 4.43601 10.5365 4.00073 9.99957 4.00073C9.46262 4.00073 9.02734 4.43601 9.02734 4.97295Z"
              fill="white"
            />
          </svg>
          <div class="tooltip Pretendard-400" v-if="showTooltip === i">
            {{ service.tooltipEn }}
          </div>
        </div>
      </div>

      <div
        v-for="(item, j) in tableBody.icn"
        :key="item"
        class="icns"
        :class="{
          pricingHover:
            (isHoveringEssential && j === 'essential_check_icn') ||
            (isHoveringAdvanced && j === 'advanced_check_icn') ||
            (isHoveringEnterprise && j === 'enterprise_check_icn') ||
            (isHoveringEnterprisePlus && j === 'enterprisePlus_check_icn')
        }"
        @mouseover="
          isHoveringEssential = j === 'essential_check_icn';
          isHoveringAdvanced = j === 'advanced_check_icn';
          isHoveringEnterprise = j === 'enterprise_check_icn';
          isHoveringEnterprisePlus = j === 'enterprisePlus_check_icn';
        "
        @mouseleave="
          isHoveringEssential = false;
          isHoveringAdvanced = false;
          isHoveringEnterprise = false;
          isHoveringEnterprisePlus = false;
        "
      >
        <div v-for="icn in item.check_icn" :key="icn" class="check_icn">
          <img v-if="icn !== 'Add-on'" :src="icn" class="check-icn" />
          <span v-if="icn === 'Add-on'" class="add-on"> Add-on </span>
        </div>
      </div>
    </div>
    <div class="integration-table">
      <table>
        <thead>
          <tr>
            <td class="table-title table-display intergation-color">DATA Integration</td>
            <th
              class="int-essential-color"
              @mouseover="isHoveringEssential = true"
              @mouseleave="isHoveringEssential = false"
              :class="{ pricingHover: isHoveringEssential }"
            >
              Essential
            </th>
            <th
              class="int-advanced-color"
              @mouseover="isHoveringAdvanced = true"
              @mouseleave="isHoveringAdvanced = false"
              :class="{ pricingHover: isHoveringAdvanced }"
            >
              Advanced
            </th>
            <th
              class="int-enterprise-color"
              @mouseover="isHoveringEnterprise = true"
              @mouseleave="isHoveringEnterprise = false"
              :class="{ pricingHover: isHoveringEnterprise }"
            >
              Enterprise
            </th>
            <th
              class="int-enterplus-color"
              @mouseover="isHoveringEnterprisePlus = true"
              @mouseleave="isHoveringEnterprisePlus = false"
              :class="{ pricingHover: isHoveringEnterprisePlus }"
            >
              Enterprise plus+
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-if="language === 'ko'" class="table-title table-display table-api">
              <p class="title-width title-start">자사 시스템 통합 API 제공</p>
              <svg
                v-if="windowWidth > 721"
                class="pricing-info-svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @mouseover="showIntTooltip = true"
                @mouseout="showIntTooltip = false"
              >
                <path
                  class="info-svg"
                  d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 2.29875e-06 15.5228 2.78158e-06 10C3.2644e-06 4.47715 4.47716 5.50298e-07 10 1.03312e-06C15.5228 1.51594e-06 20 4.47715 20 10Z"
                  fill="#CED5D1"
                />
                <rect
                  x="9.0293"
                  y="8"
                  width="1.94444"
                  height="7.77778"
                  rx="0.972222"
                  fill="white"
                />
                <path
                  d="M9.02734 4.97295C9.02734 5.5099 9.46262 5.94518 9.99957 5.94518C10.5365 5.94518 10.9718 5.5099 10.9718 4.97295C10.9718 4.43601 10.5365 4.00073 9.99957 4.00073C9.46262 4.00073 9.02734 4.43601 9.02734 4.97295Z"
                  fill="white"
                />
              </svg>
              <div class="tooltip Pretendard-400" v-if="showIntTooltip">
                자사 시스템 통합 가능 API 제공
              </div>
            </td>
            <td v-if="language === 'en'" class="table-title table-display table-api">
              <p class="title-width title-start">API Integration Services</p>
              <svg
                v-if="windowWidth > 721"
                class="pricing-info-svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @mouseover="showIntTooltip = true"
                @mouseout="showIntTooltip = false"
              >
                <path
                  class="info-svg"
                  d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 2.29875e-06 15.5228 2.78158e-06 10C3.2644e-06 4.47715 4.47716 5.50298e-07 10 1.03312e-06C15.5228 1.51594e-06 20 4.47715 20 10Z"
                  fill="#CED5D1"
                />
                <rect
                  x="9.0293"
                  y="8"
                  width="1.94444"
                  height="7.77778"
                  rx="0.972222"
                  fill="white"
                />
                <path
                  d="M9.02734 4.97295C9.02734 5.5099 9.46262 5.94518 9.99957 5.94518C10.5365 5.94518 10.9718 5.5099 10.9718 4.97295C10.9718 4.43601 10.5365 4.00073 9.99957 4.00073C9.46262 4.00073 9.02734 4.43601 9.02734 4.97295Z"
                  fill="white"
                />
              </svg>
              <div class="tooltip Pretendard-400" v-if="showIntTooltip">
                API Integration Services
              </div>
            </td>
            <td
              @mouseover="isHoveringEssential = true"
              @mouseleave="isHoveringEssential = false"
              :class="{ pricingHover: isHoveringEssential }"
            ></td>
            <td
              v-if="language === 'ko'"
              class="add-on"
              @mouseover="isHoveringAdvanced = true"
              @mouseleave="isHoveringAdvanced = false"
              :class="{ pricingHover: isHoveringAdvanced }"
            >
              별도비용
            </td>
            <td
              v-if="language === 'en'"
              class="add-on"
              @mouseover="isHoveringAdvanced = true"
              @mouseleave="isHoveringAdvanced = false"
              :class="{ pricingHover: isHoveringAdvanced }"
            >
              Additional Charges
            </td>
            <td
              @mouseover="isHoveringEnterprise = true"
              @mouseleave="isHoveringEnterprise = false"
              :class="{ pricingHover: isHoveringEnterprise }"
            >
              <img class="check-icn2" src="../assets/imgs/icn_check2.svg" />
            </td>
            <td
              @mouseover="isHoveringEnterprisePlus = true"
              @mouseleave="isHoveringEnterprisePlus = false"
              :class="{ pricingHover: isHoveringEnterprisePlus }"
            >
              <img class="check-icn2" src="../assets/imgs/icn_check2.svg" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="integration-table">
      <table v-if="windowWidth > 720">
        <thead>
          <tr>
            <th
              v-for="(item, p) in supportTable"
              :key="item"
              :class="[
                item.class,
                {
                  pricingHover:
                    (isHoveringEssential && p === 'Essential') ||
                    (isHoveringAdvanced && p === 'Advanced') ||
                    (isHoveringEnterprise && p === 'Enterprise') ||
                    (isHoveringEnterprisePlus && p === 'EnterprisePlus')
                }
              ]"
              @[`mouseover`]="() => (this['isHovering' + p] = true)"
              @[`mouseleave`]="() => (this['isHovering' + p] = false)"
            >
              {{ item.title }}
            </th>
          </tr>
        </thead>
        <tbody v-if="language === 'ko'">
          <tr class="bottom-line" v-for="tr in supportTbody" :key="tr">
            <td class="support-table-body" v-html="tr.title"></td>
            <td
              v-for="(icn, c) in tr.check_icn"
              :key="icn"
              :class="{
                pricingHover:
                  (isHoveringEssential && c === 0) ||
                  (isHoveringAdvanced && c === 1) ||
                  (isHoveringEnterprise && c === 2) ||
                  (isHoveringEnterprisePlus && c === 3)
              }"
              @mouseover="
                isHoveringEssential = c === 0;
                isHoveringAdvanced = c === 1;
                isHoveringEnterprise = c === 2;
                isHoveringEnterprisePlus = c === 3;
              "
              @mouseleave="
                isHoveringEssential = false;
                isHoveringAdvanced = false;
                isHoveringEnterprise = false;
                isHoveringEnterprisePlus = false;
              "
            >
              <img v-if="icn" :src="icn" class="check-icn2" />
            </td>
            <template v-if="tr.check_text && tr.check_text == '별도문의'">
              <td colspan="4" class="add-on" v-html="tr.check_text"></td>
            </template>
            <td
              v-else
              v-for="(text, t) in tr.check_text"
              :key="text"
              :class="{
                pricingHover:
                  (isHoveringEssential && t === 0) ||
                  (isHoveringAdvanced && t === 1) ||
                  (isHoveringEnterprise && t === 2) ||
                  (isHoveringEnterprisePlus && t === 3)
              }"
              @mouseover="
                isHoveringEssential = t === 0;
                isHoveringAdvanced = t === 1;
                isHoveringEnterprise = t === 2;
                isHoveringEnterprisePlus = t === 3;
              "
              @mouseleave="
                isHoveringEssential = false;
                isHoveringAdvanced = false;
                isHoveringEnterprise = false;
                isHoveringEnterprisePlus = false;
              "
            >
              <p v-if="text" class="add-on" v-html="text"></p>
            </td>
          </tr>
        </tbody>
        <tbody v-if="language === 'en'">
          <tr class="bottom-line" v-for="tr in supportTbody" :key="tr">
            <td class="support-table-body" v-html="tr.titleEn"></td>
            <td
              v-for="(icn, c) in tr.check_icn"
              :key="icn"
              :class="{
                pricingHover:
                  (isHoveringEssential && c === 0) ||
                  (isHoveringAdvanced && c === 1) ||
                  (isHoveringEnterprise && c === 2) ||
                  (isHoveringEnterprisePlus && c === 3)
              }"
              @mouseover="
                isHoveringEssential = c === 0;
                isHoveringAdvanced = c === 1;
                isHoveringEnterprise = c === 2;
                isHoveringEnterprisePlus = c === 3;
              "
              @mouseleave="
                isHoveringEssential = false;
                isHoveringAdvanced = false;
                isHoveringEnterprise = false;
                isHoveringEnterprisePlus = false;
              "
            >
              <img v-if="icn" :src="icn" class="check-icn2" />
            </td>
            <template v-if="tr.check_text && tr.check_text == '별도문의'">
              <td colspan="4" class="add-on" v-html="tr.check_textEn"></td>
            </template>
            <td
              v-else
              v-for="(text, t) in tr.check_textEn"
              :key="text"
              :class="{
                pricingHover:
                  (isHoveringEssential && t === 0) ||
                  (isHoveringAdvanced && t === 1) ||
                  (isHoveringEnterprise && t === 2) ||
                  (isHoveringEnterprisePlus && t === 3)
              }"
              @mouseover="
                isHoveringEssential = t === 0;
                isHoveringAdvanced = t === 1;
                isHoveringEnterprise = t === 2;
                isHoveringEnterprisePlus = t === 3;
              "
              @mouseleave="
                isHoveringEssential = false;
                isHoveringAdvanced = false;
                isHoveringEnterprise = false;
                isHoveringEnterprisePlus = false;
              "
            >
              <p v-if="text" class="add-on" v-html="text"></p>
            </td>
          </tr>
        </tbody>
      </table>

      <table v-if="windowWidth < 720">
        <thead>
          <tr>
            <th
              v-for="(item, p) in supportTable"
              :key="item"
              :class="[
                item.class,
                {
                  pricingHover:
                    (isHoveringEssential && p === 'Essential') ||
                    (isHoveringAdvanced && p === 'Advanced') ||
                    (isHoveringEnterprise && p === 'Enterprise') ||
                    (isHoveringEnterprisePlus && p === 'EnterprisePlus')
                }
              ]"
              @[`mouseover`]="() => (this['isHovering' + p] = true)"
              @[`mouseleave`]="() => (this['isHovering' + p] = false)"
            >
              {{ item.titleMobile }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bottom-line" v-for="tr in supportTbody" :key="tr">
            <td class="support-table-body" v-html="tr.titleMobile"></td>
            <td
              v-for="(icn, c) in tr.check_icn"
              :key="icn"
              :class="{
                pricingHover:
                  (isHoveringEssential && c === 0) ||
                  (isHoveringAdvanced && c === 1) ||
                  (isHoveringEnterprise && c === 2) ||
                  (isHoveringEnterprisePlus && c === 3)
              }"
              @mouseover="
                isHoveringEssential = c === 0;
                isHoveringAdvanced = c === 1;
                isHoveringEnterprise = c === 2;
                isHoveringEnterprisePlus = c === 3;
              "
              @mouseleave="
                isHoveringEssential = false;
                isHoveringAdvanced = false;
                isHoveringEnterprise = false;
                isHoveringEnterprisePlus = false;
              "
            >
              <img v-if="icn" :src="icn" class="check-icn2" />
            </td>
            <template v-if="tr.check_text && tr.check_text == '별도문의'">
              <td colspan="4" class="add-on" v-html="tr.check_text"></td>
            </template>
            <td
              v-else
              v-for="(text, t) in tr.checkTextMobile"
              :key="text"
              :class="{
                pricingHover:
                  (isHoveringEssential && t === 0) ||
                  (isHoveringAdvanced && t === 1) ||
                  (isHoveringEnterprise && t === 2) ||
                  (isHoveringEnterprisePlus && t === 3)
              }"
              @mouseover="
                isHoveringEssential = t === 0;
                isHoveringAdvanced = t === 1;
                isHoveringEnterprise = t === 2;
                isHoveringEnterprisePlus = t === 3;
              "
              @mouseleave="
                isHoveringEssential = false;
                isHoveringAdvanced = false;
                isHoveringEnterprise = false;
                isHoveringEnterprisePlus = false;
              "
            >
              <p v-if="text" class="add-on" v-html="text"></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      showIntTooltip: false,
      showTooltip: null,
      isHoveringEssential: false,
      isHoveringAdvanced: false,
      isHoveringEnterprise: false,
      isHoveringEnterprisePlus: false,
      tableHead: {
        None: {
          title: '',
          subTitle: ''
        },
        Essential: {
          title: 'Essential',
          mobileTitle: 'Essential',
          subTitle: '최소한의 필수 핵심 기능',
          subTitleEn: 'Core functions',
          check_icn: [
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            '',
            '',
            ''
          ],
          class: 'essential'
        },
        Advanced: {
          title: 'Advanced',
          mobileTitle: 'Advanced',
          subTitle: '생산성을 높이는 기능',
          subTitleEn: 'Top features that boost productivity',
          check_icn: [
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            '',
            '',
            'Add-on'
          ],
          class: 'advanced'
        },
        Enterprise: {
          title: 'Enterprise',
          mobileTitle: 'Enterprise',
          subTitle: '엔터프라이즈를 위한',
          subTitleEn: 'Enterprise-Targeted',
          check_icn: [
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            'Add-on',
            'Add-on',
            'Add-on'
          ],
          class: 'enterprise'
        },
        EnterprisePlus: {
          title: 'Enterprise',
          mobileTitle: 'Enterprise+',
          plus: 'Plus+',
          subTitle: '모든 ESG 고급 기능 지원',
          subTitleEn: 'All advanced functions',
          check_icn: [
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg'),
            require('../assets/imgs/icn_check.svg')
          ],
          class: 'enterprise-plus',
          plusClass: 'plusClass'
        }
      },

      tableBody: {
        service: {
          contents: [
            'ESG 대시보드',
            'ESG 자가진단',
            '중요성 이슈파악',
            'ESG 공시 자동화',
            'ESG 관리',
            'GHG 관리',
            'ESG 공급망 관리'
          ]
        },
        serviceDetail: {
          dashbord: {
            title: 'ESG 대시보드',
            titleEn: 'ESG Dashboard',
            tooltip:
              '기업진단현황 + 빅데이터 동향정보 + 국내외 트렌드 + AI 기반 비즈니스 사례 등 종합관리 대시보드',
            tooltipEn:
              'Integrated ESG management systems including Current State Diagnostics + Big Data Trends + Domestic and International ESG Trends + AI-Based Business Cases'
          },
          diagnosis: {
            title: 'ESG 자가진단',
            titleEn: 'ESG Self-assessment',
            tooltip: '데이터 기반 ESG 진단 Set 제공 + 대시보드 + 진단이력관리 + PDF리포트',
            tooltipEn: 'ESG Self-Assessment sets + Dashboard + History Data Management + PDF Report'
          },
          import: {
            title: '중요성 이슈파악',
            titleEn: 'Material Issues Identification',
            tooltip: '중요성이슈 관리/설문 솔루션 + 대시보드 + 이력관리 + PDF리포트',
            tooltipEn:
              'Material Issue identification + Stakeholder engagement (Survey) + Dashboard + History Data Management + PDF Report'
          },
          report: {
            title: 'ESG 공시 자동화',
            titleEn: 'ESG Disclosure automation',
            tooltip:
              '글로벌 공시기준 데이터 + AI 추천 + 국/영문 리포팅 자동화 + 대시보드 + (제3자 검증)',
            tooltipEn:
              'Global ESG Disclosure Regulations + AI-Recommendation + Reporting Automation + Dashboard + (Third Party Verification) + ESG report'
          },
          MGT: {
            title: 'ESG 관리',
            titleEn: 'ESG Management',
            tooltip: 'ESG 데이터관리 및 수집 + KPI 관리 + AI/빅데이터분석 + 대시보드 + PDF리포트',
            tooltipEn:
              'ESG Data Collection and Management + KPI management + AI and Big Data Analytics + Dashboard + PDF Report'
          },
          GHG: {
            title: 'GHG 관리',
            titleEn: 'GHG Management',
            tooltip:
              'GHG 데이터관리 및 수집(Scope1~3) + 감축목표 선정 및 관리 + 대시보드 + PDF리포트',
            tooltipEn:
              'Data collection + GHG KPI Management + Dashboard + AI and Big Data Analytics + GHG emission PDF Report '
          },
          supply: {
            title: 'ESG 공급망 관리',
            titleEn: 'ESG Supply Chain Management Module',
            tooltip: '진단 패키지(수정 가능) + 공급망 진단 + 대시보드 + 관리 리포트(PDF)',
            tooltipEn:
              'Diagnostic Package (modifiable) + Supply Chain Diagnostics + Dashboard + Supply Chain Management PDF Report'
          }
        },
        icn: {
          essential_check_icn: {
            check_icn: [
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              '',
              '',
              ''
            ]
          },
          advanced_check_icn: {
            check_icn: [
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              '',
              '',
              'Add-on'
            ]
          },
          enterprise_check_icn: {
            check_icn: [
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              'Add-on',
              'Add-on',
              'Add-on'
            ]
          },
          enterprisePlus_check_icn: {
            check_icn: [
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg'),
              require('../assets/imgs/icn_check.svg')
            ]
          }
        }
      },

      supportTable: {
        Support: {
          title: 'Support',
          titleMobile: 'Support',
          class: 'support-table intergation-color'
        },
        Essential: {
          title: 'Essential',
          titleMobile: 'Essential',
          class: 'int-essential-color'
        },
        Advanced: {
          title: 'Advanced',
          titleMobile: 'Advanced',
          class: 'int-advanced-color'
        },
        Enterprise: {
          title: 'Enterprise',
          titleMobile: 'Enterprise',
          class: 'int-enterprise-color'
        },
        EnterprisePlus: {
          title: 'Enterprise plus+',
          titleMobile: 'Enterprise+',
          class: 'int-enterplus-color'
        }
      },

      supportTbody: {
        email: {
          title: 'E-mail',
          titleEn: 'E-mail',
          titleMobile: 'E-mail',
          check_icn: [
            require('../assets/imgs/icn_check2.svg'),
            require('../assets/imgs/icn_check2.svg'),
            require('../assets/imgs/icn_check2.svg'),
            require('../assets/imgs/icn_check2.svg')
          ]
        },
        liveChat: {
          title: 'Live Chat',
          titleEn: 'Live Chat',
          titleMobile: 'Live Chat',
          check_icn: [
            require('../assets/imgs/icn_check2.svg'),
            require('../assets/imgs/icn_check2.svg'),
            require('../assets/imgs/icn_check2.svg'),
            require('../assets/imgs/icn_check2.svg')
          ]
        },
        phone: {
          title: 'Phone',
          titleEn: 'Phone',
          titleMobile: 'Phone',
          check_icn: [
            ' ',
            require('../assets/imgs/icn_check2.svg'),
            require('../assets/imgs/icn_check2.svg'),
            require('../assets/imgs/icn_check2.svg')
          ]
        },
        accountManager: {
          title: 'Account Manager',
          titleEn: 'Account Manager',
          titleMobile: 'Account Manager',
          check_icn: [
            ' ',
            ' ',
            require('../assets/imgs/icn_check2.svg'),
            require('../assets/imgs/icn_check2.svg')
          ]
        },
        network: {
          title: '네트워킹 행사 초청',
          titleEn: 'A networking event invitation',
          titleMobile: '네트워킹 행사 초청',
          check_icn: '',
          check_text: [' ', '', '초대', '초대'],
          check_textEn: [' ', '', 'Invited', 'Invited'],
          checkTextMobile: [' ', '', '초대', '초대']
        },
        trend: {
          title: '트렌드 리포트',
          titleEn: 'E-mail',
          titleMobile: 'Trend Reports',
          check_icn: [
            ' ',
            ' ',
            require('../assets/imgs/icn_check2.svg'),
            require('../assets/imgs/icn_check2.svg')
          ],
          check_text: '',
          checkTextMobile: ''
        },
        education: {
          title: 'ESG 특화 유료교육 무상 제공<br/>(온/오프라인)',
          titleEn:
            'Providing free access to our specialized ESG paid course<br/>(both online and offline)',
          titleMobile: 'ESG 특화 유료교육 무상 제공',
          check_icn: '',
          check_text: [
            '교육 1회/년 제공',
            '교육 3회/년 제공',
            '교육 5회/년 제공',
            '교육 5회/년 제공'
          ],
          check_textEn: [
            '1 session<br> per calendar year',
            '3 sessions<br> per calendar year',
            '5 sessions<br> per calendar year',
            '5 sessions<br> per calendar year'
          ],
          checkTextMobile: ['교육 1회/년', '교육 3회/년', '교육 5회/년', '교육 5회/년']
        },
        offline: {
          title: '오프라인 현장실사',
          titleEn: 'On-site due diligence',
          titleMobile: '오프라인 현장실사',
          check_icn: '',
          check_text: '별도문의',
          check_textEn: 'Contact us​'
        },
        consult: {
          title: '컨설팅',
          titleEn: 'ESG Consulting',
          titleMobile: '컨설팅',
          check_icn: '',
          check_text: '별도문의',
          check_textEn: 'Contact us​'
        },
        consult2: {
          title: '공시 컨설팅',
          titleEn: 'ESG Disclosure & Consulting',
          titleMobile: '공시 컨설팅',
          check_icn: '',
          check_text: '별도문의',
          check_textEn: 'Contact us​'
        },
        consult3: {
          title: '초기 솔루션 활용 컨설팅',
          titleEn: 'Solution Utilization Consulting',
          titleMobile: '초기 솔루션 활용<br> 컨설팅',
          check_icn: '',
          check_text: '별도문의',
          check_textEn: 'Contact us​'
        }
      }
    };
  },
  props: {
    // pricingLevel : Array,
    serviceContents: Array,
    language: String
  },
  computed: {
    formattedTitle() {
      return this.supportTbody.title.replace(/<br\/>/g, '<br>');
    }
    // isHoveringTitle() {
    // 	return (title) => 'isHovering' + title;
    // }
  },
  methods: {
    addClassHover() {
      this.isHovering = true;
    },
    console(j) {
      console.log(j);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.pricing-info-svg:hover .info-svg {
  fill: #8ea499;
}
.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-top: 5rem;
}
.t-head {
  display: flex;
}
.pricing-level {
  display: flex;
  width: 14.58vw;
  flex-direction: column;
  justify-content: center;
  height: 4.375rem;
  text-align: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-family: 'Pretendard-SemiBold';
  font-size: 1.5rem;
  margin: 0 2px;
  box-sizing: border-box;
}
.plusClass {
  font-family: 'Rum Raisin';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  color: #89f6d5;
  letter-spacing: 0.01em;
}
.pricing-level:nth-child(1) {
  width: 14.44vw;
  box-sizing: border-box;
  padding: 0.813rem 1.46vw;
  margin: 0;
}
.title-sub {
  margin-top: 0.375rem;
  font-size: 0.875rem;
  line-height: 100%;
  font-family: 'Pretendard-Medium';
}
.t-body {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 2px 30px rgba(215, 206, 229, 0.4);
  border-radius: 10px;
}
.service-title {
  display: flex;
  flex-direction: column;
}
.title {
  height: 1.25rem;
  width: 11.5vw;
  padding: 1.25rem 1.46vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #dedede;
  font-family: 'Pretendard-Medium';
  font-size: 1rem;
  color: #005e41;
  position: relative;
  /* box-sizing: border-box; */
}
.title-width {
  width: 90%;
}
.title-start {
  text-align: start;
}
.tooltip {
  position: absolute;
  z-index: 1;
  width: 17vw;
  padding: 0.625rem;
  font-size: 0.75rem;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 10px;
  left: 13.4vw;
  bottom: -1.3rem;
  filter: drop-shadow(2px 4px 20px rgba(149, 157, 163, 0.3));
  color: #7d7d7d;
  line-height: 140%;
}
.icns {
  display: flex;
  flex-direction: column;
}
.check_icn {
  width: 14.58vw;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
  border-bottom: 0.5px solid #dedede;
}
.essential {
  background: linear-gradient(0deg, #e2f7e5, #e2f7e5), #ffffff;
  color: #2f8b54;
  box-sizing: border-box;
}
.advanced {
  background: #c7f0cd;
  color: #2f8b54;
  box-sizing: border-box;
}
.enterprise {
  background: #70d69f;
  color: #ffffff;
  box-sizing: border-box;
}
.enterprise-plus {
  background: #08ac85;
  color: #ffffff;
  box-sizing: border-box;
}
.add-on {
  color: #627a68;
  font-size: 0.875rem;
  font-family: 'Pretendard-Medium';
}
.integration-table {
  margin-top: 0.75rem;
  background: #fbfefd;
  box-shadow: 0px 2px 30px rgba(215, 206, 229, 0.4);
  border-radius: 10px;
}
.pricing-info-svg {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.check-icn {
  height: 1.25rem;
}
.check-icn2 {
  height: 1.25rem;
}
table {
  display: table;
}
.table-title {
  width: 14.42vw;
  height: 2.25rem;
  padding: 0.625rem 1.39vw;
  box-sizing: border-box;
  position: relative;
}
.support-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 14.42vw;
  height: 2.25rem;
  padding: 0.625rem 1.39vw;
  box-sizing: border-box;
  text-align: start;
}
.support-table-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 14.42vw;
  height: auto;
  padding: 0.625rem 1.39vw;
  box-sizing: border-box;
  text-align: start;
  font-size: 0.875rem;
  font-family: 'Pretendard-Regular';
  color: #29463d;
}
.table-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
table tr th,
table tr td {
  width: 14.58vw;
  height: 2.25rem;
  padding: 0 2px;
}
td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
thead {
  border-bottom: 1px solid #d6d6d6;
}
.bottom-line {
  border-bottom: 0.5px solid #dedede;
}
.intergation-color {
  color: #005e41;
  font-size: 1rem;
  font-family: 'Pretendard-SemiBold';
}
.int-essential-color {
  color: #6bcc7c;
  font-family: 'Pretendard-SemiBold';
  font-size: 0.875rem;
}
.int-advanced-color {
  color: #00be2a;
  font-family: 'Pretendard-SemiBold';
  font-size: 0.875rem;
}
.int-enterprise-color {
  color: #089c3b;
  font-family: 'Pretendard-SemiBold';
  font-size: 0.875rem;
}
.int-enterplus-color {
  color: #005515;
  font-family: 'Pretendard-SemiBold';
  font-size: 0.875rem;
}
.table-api {
  color: #29463d;
  font-family: 'Pretendard-Medium';
  font-size: 0.875rem;
}
.charge-color {
  color: #627a68;
  font-family: 'Pretendard-SemiBold';
  font-size: 0.875rem;
}
.pricingHover {
  background: #f7fbf5;
}

/* @media screen and (max-width: 1040px) {
	.tooltip {
		width: 21vw;
		padding: 0.6rem;
		font-size: 0.85rem;
		left: 23vw;
		bottom: -0.5rem;
		line-height: 120%;
	}
	.inner{
	}
	.pricing-level{
		width: 15.5vw;
		font-size: 16px;
	}
	.title-sub{
		font-size: 10px;
	}
	.plusClass {
		font-size: 12px;
	}
	.check_icn{
		width: 15.5vw;
		height: 59.5px;
	}
	.pricing-level:nth-child(1) {
		width: clamp(184px, 14.44vw, 230px);
	}
	.title{
		width: clamp(160.8px, 14.44vw, 230px);
		font-size: 1.23rem;
		height: 27px;
	}
	.table-title {
		width: clamp(160.8px, 14.44vw, 230px);
		font-size: 1.23rem;
		box-sizing: content-box;
		padding: 0.395rem 1.46vw;
	}
	table tr th, table tr td {
		width: 15.5vw;
		height: 3rem;
		vertical-align: middle;
	}
	.support-table{
		width: clamp(160.8px, 14.44vw, 230px);
		font-size: 1.23rem;
		padding: 0.395rem 1.46vw;
		box-sizing: content-box;
	}
	.table-api{
	font-size: 1.05rem;
}
	.support-table-body{
	width: auto;
	font-size: 1.05rem;
	height: 39.5px;
	}
}

@media screen and (max-width: 720px) {
	.pricing-level-mobile {
		width: clamp(49px, 15.5vw, 180px);
		font-size: clamp(10px, 3vw, 13.5px);
		display: flex;
		justify-content: center;
		align-items: center;
		height: 32px;
		text-align: center;
		font-family: "Pretendard-SemiBold";
		margin: 0 2px;
		box-sizing: border-box;
		}
	.pricing-level-mobile:nth-child(1) {
		width: clamp(84px, 26.25vw, 200px);
		box-sizing: border-box;
		padding: 0.08rem 1.39vw;
		margin: 0;
	}
	.essential{
		background : transparent;
		color: #2F8B54;
		box-sizing: border-box;
	}
	.advanced {
		background : transparent;
		color: #167A3E;
		box-sizing: border-box;
	}
	.enterprise{
		background : transparent;
		color: #065A28;
		box-sizing: border-box;
	}
	.enterprise-plus{
		background : transparent;
		color: #003415;
		box-sizing: border-box;
	}
	.check_icn{
		width: clamp(50px, 15.63vw, 180px);
		height: 29.06px;
	}

	.title{
		width: clamp(52.4px, 23.33vw, 200px);
		font-size: clamp(10px, 3vw, 13.5px);
		padding: 0.08rem 1.39vw;
	}
	.table-title {
		width: clamp(52.4px, 23.33vw, 200px);
		font-size: clamp(10px, 3vw, 13.5px);
		padding: 0.03rem 1.39vw;
		text-align: start;
	}
	table tr th, table tr td {
		width: clamp(53px, 15.63vw, 150px);
		height: 32px;
		vertical-align: middle;
	}
	.support-table{
		width: clamp(52.4px, 23.33vw, 200px);
		font-size: clamp(10px, 3vw, 13.5px);
		padding: 0.03rem 1.39vw;
		height: 32px;
	}
	.support-table-body{
		font-size: clamp(10px, 2.9vw, 12px);
		width: clamp(52.4px, 23.33vw, 200px);
		height: 32px;
	}
	.intergation-color {
		font-size: clamp(10px, 3vw, 13.5px);
	}
	.int-essential-color {
		color: #2F8B54;
		font-size: clamp(10px, 3vw, 13.5px);
	}
	.int-advanced-color {
		color: #2F8B54;
		font-size: clamp(10px, 3vw, 13.5px);
	}
	.int-enterprise-color {
		color: #2F8B54;
		font-size: clamp(10px, 3vw, 13.5px);
	}
	.int-enterplus-color {
		color: #2F8B54;
		font-size: clamp(10px, 3vw, 13.5px);
	}
	.table-api{
		font-size: clamp(10px, 2.8vw, 12px);
	}
	.charge-color {
		font-size: clamp(10px, 2.8vw, 12px);
	}
	.add-on {
		font-size: clamp(10px, 2.8vw, 12px);
	}
} */
</style>
