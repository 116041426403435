<template>
  <div class="template">
    <div class="inner">
      <h1 class="main-title Pretendard-700">
        ESG Template
      </h1>
      <table class="table">
        <thead>
          <tr>
            <th>N0.</th>
            <th>제목</th>
            <th>등록일</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(esg,i) in temp.results[0].properties.title.number" :key="esg">
            <td>{{temp.results[i].properties.title.number}}</td>
            <td><router-link :to="{ name: 'TempDetail', params: { id: temp.results[i].properties.title.number}}">{{temp.results[i].properties.제목.title[0].text.content}}</router-link></td>
            <td>{{temp.results[i].properties.등록일.date.start}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name : "Template",
  props : {
    temp : Array,
    imgSrc : Array,
  },
  created() {
    console.log(this.temp.results[0].properties.title.number)
  },
  methods: {
    replaceByDefault(e) {
      const randNum = Math.floor(Math.random() * 100);
      e.target.src = this.imgSrc+randNum
    },
  },
}
</script>

<style scoped>
  .template {
    width: 100%;
    height: 1800px;
    background-color: #FBFFFD;
  }
  .inner {
    margin: 0 auto 0 auto;
    padding: 10rem 0 10rem 0;
    width: 1350px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .inner .main-title {
    margin-right: auto;
    margin-left: 3rem;
    padding-bottom: 10rem;
    font-size: 3rem;
  }
  .inner hr{
    border: 0;
    width: 100%;
    height: 1.3px;
    display: block;
    margin-bottom: 5rem;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    background-color: black;
  }
  .table{border-collapse: collapse; width: 100%; text-align: center;}
  .table thead th{
    border: 0.5px solid rgb(17, 17, 17);
    border-width: 1px 0; 
    font-family: "Pretendard-SemiBold";
    font-weight: 600;
    font-style: normal;
    font-size: 0.9rem;
    padding: 1rem 0 1rem 0;
    background-color: #FBFFFD;
  }

  .table td:first-child, .table th:first-child{width: 10%;}
  .table td:nth-child(3), .table th:nth-child(3){width: 30%;}
  .table td:nth-child(5), .table th:nth-child(4){width: 15%;}

  .table tbody td{
    padding: 2rem 0 2rem 0;
    font-size: 0.9rem;
    font-family: "Pretendard-Regular";
    font-weight: 400;
    font-style: normal;
  }
  .table tbody tr{
    border-bottom: 1px solid rgb(201, 201, 201);
  }
  .table tbody td a{text-decoration: none; color: black;}
  .table tbody td a:hover{text-decoration: underline;}

</style>