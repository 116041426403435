<template>
  <div class="junbipage">
    <div class="junbiimg"></div>
    <div>
      <h1 class="junbi-title">: : 서비스 <span>준비중</span> 입니다 : :</h1>
      <p class="junbi-text">
        보다 나은 서비스 제공을 위하여 페이지 준비중 입니다.<br>
        빠른 시일내에 준비하여 찾아뵙겠습니다.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name : "Junbi",

}
</script>

<style scoped >
  .junbipage {
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .junbiimg {
    background-image: url('../assets/logo/logo_simbol.png');
    width: 43rem;
    height: 43rem;
    background-size: contain;
    opacity: 0.08;
    position: fixed;
    background-repeat: no-repeat;
  }
  .junbi-title {
    font-family: "Pretendard-Regular";
    font-weight: 400;
    font-style: normal;
    font-size: 3.5rem;
    text-align: center;
    color: rgb(114, 131, 121);
  }
  .junbi-title span {
    font-family: "Pretendard-Bold";
    font-weight: 700;
    font-style: normal;
    color: rgb(136, 218, 170);
  }
  .junbi-text {
    font-family: "Pretendard-Regular";
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    text-align: center;
    line-height: 170%;
    margin-top: 2rem;
  }
  /* @media screen and (max-width: 540px) {
    .junbiimg {
    width: 26rem;
    height: 26rem;
    }
    .junbi-title {
      font-size: 2.42rem;
    }
    .junbi-text {
      font-size: 1.1rem;
      margin-top: 2rem;
    }
  } */
</style>