<template>
  <div>
  <pageSection1 :language="language"/>
  <div class="bg">
    <section class="inner">
        <div class="move-box">
          <p class="move-text Pretendard-400"><span @click="goHome">홈</span> > <span @click="goMedia">미디어</span> > <span class="color-change" @click="goMedia">주요공시</span></p>
        </div>
        <div class="container">
          <div class="title-container">
            <h1 class="detail-title Pretendard-700" @click="g">
              {{disclosures.results.find(item => item.id === $route.params.id).properties.title.title[0].text.content}}
            </h1>
            <div class="title-sub-box">
              <p class="type-text mobile-display-none-400">
                {{disclosures.results.find(item => item.id === $route.params.id).properties.type.rich_text[0].text.content}}
              </p>
              <p class="date-text mobile-display-none-400">
                {{disclosures.results.find(item => item.id === $route.params.id).properties.created_at.date.start}}
              </p>
            </div>
          </div>
          <div class="contents-box Pretendard-400">
            <p v-html="formatText(disclosures.results.find(item => item.id === $route.params.id).properties.contents.rich_text[0].plain_text)" />
            <img class="contents-img" :src="disclosures.results.find(item => item.id === $route.params.id).properties.img.url" alt="" />
          </div>
          <div class="title-sub-box-400">
            <p class="type-text mobile-display-block-400 display-none">
                {{disclosures.results.find(item => item.id === $route.params.id).properties.type.rich_text[0].text.content}}
              </p>
            <p class="date-text mobile-display-block-400 display-none">
                {{disclosures.results.find(item => item.id === $route.params.id).properties.created_at.date.start}}
            </p>
          </div>
          <div class="bottom-box Pretendard-500">
            <p class="ml-10"
            @mouseover="console(disclosures.results.find(item => item.id === $route.params.id).properties.file.files[0].external)">첨부파일</p>
            <div v-if="disclosures.results.find(item => item.id === $route.params.id).properties.file.files[0]">
              <a v-if="disclosures.results.find(item => item.id === $route.params.id).properties.file.files[0].file" :href="disclosures.results.find(item => item.id === $route.params.id).properties.file.files[0].file.url" class="ml-10" target="_blank" download>{{ disclosures.results.find(item => item.id === $route.params.id).properties.file.files[0].name }}</a>
              <a v-if="disclosures.results.find(item => item.id === $route.params.id).properties.file.files[0].external" :href="disclosures.results.find(item => item.id === $route.params.id).properties.file.files[0].external.url" class="ml-10" target="_blank">{{ disclosures.results.find(item => item.id === $route.params.id).properties.file.files[0].name }}</a>
            </div>
          </div>
          <div class="bottom-back">
            <svg class="back-svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" @click="goBack">
              <path class="leftArrow" d="M17.375 0.625H0.625V17.375H17.375V0.625Z" stroke="#D9D9D9" stroke-width="1.25" stroke-linejoin="round"/>
              <path class="leftArrow" d="M10.5 5L6 8.6L10.5 12.2" stroke="#D9D9D9" stroke-width="1.25" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <p class="goback Pretendard-500" @click="goBack">
              목록으로
            </p>
          </div>
        </div>
    </section>
  </div>
</div>
</template>

<script>


import pageSection1 from "../pageSection1.vue"
export default {
  data() {
    return {
      disclosures : ''
    }
  },
  components : { 
    pageSection1
  },
  created() {
    const storedData = sessionStorage.getItem('disclosures');
    const data = JSON.parse(storedData);
    this.disclosures = data;
  },

  methods : {
    formatText(text) {
      return text.replace(/\n/g, '<br>');
    },
    goFront() {
      this.$router.go(1);
    },
    goBack(){
      this.$router.go(-1);
    },
    goHome() {
      this.$router.push('/')
    },
    goMedia() {
      this.$router.push('/esg-media')
    },
    console(e){
      console.log(e)
    }
  },
  

}
</script>

<style scoped>
.display-none {
  display: none;
}
.bg {
  width: 100%;
  background: #F7F7F7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9.375rem 0 ;
}
.inner {
    width: 75.00vw;
    height: auto;
}
.container { 
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 20px 10px;
  margin-top: 1.25rem;
  box-sizing: border-box;
}
.move-box{
  display: flex;
  margin-left: 1.39vw;
}
.move-text .color-change{
  color: #5989D0
}
.move-text span{
  cursor: pointer;
}
.move-text{
  color: #757575;
  font-size: 14px;
}

.title-container{
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid #4A4A4A ;
  border-bottom: 1px solid #4A4A4A;
  padding: 1.25rem 0;
  justify-content: space-between;
}
.detail-title {
  color: #000000;
  font-size: 1.875rem;
  line-height: 130%;
  word-break: keep-all;
}
.title-sub-box {
  display: flex;
  align-content: flex-end;
  height: 100%;
  display: flex;
  color: #999999;
  font-size: 0.875rem;
  line-height: 130%;
  margin-top: auto;
  
}
.type-text{
  width: auto;
}
.date-text {
  margin-left: 1.5vw;
}
.contents-box{
  width: 100%;
  padding: 1.875rem 1rem 5rem 1rem ;
  color: #000000;
  font-size: 16px;
  line-height: 140%;
  box-sizing: border-box;
}

.contents-img {
  width: 100%;
  object-fit: contain;
  padding: 3rem 5vw 0 5vw;
  box-sizing: border-box;
}

.bottom-box {
  display: flex;
  border-top: 1px solid #4A4A4A ;
  border-bottom: 1px solid #4A4A4A;
  padding: 1.25rem 0;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
}
.bottom-box a{
  margin-left: 40px;
  color: #5989D0;
  cursor: pointer;
}
.bottom-back {
  display: flex;
  padding: 1.25rem 0 1.875rem 0;
  align-items: center;
}
.ml-10{
  margin-left: 10px;
}
.back-svg {
  cursor: pointer;
}
.back-svg:hover .leftArrow {
  stroke: #999999;
}
.goback{
  margin-left: 6px;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
  cursor: pointer;
}




/* @media screen and (max-width: 1040px) {
.inner {
    width: 88.2vw;
}
.detail-title {
  font-size: 1.8rem;
}
.contents-box{
  font-size: 1.15rem;
}
.move-text{
  font-size: 1rem;
}

}

@media screen and (max-width: 680px) {
.inner {
    width: 90vw;
}
.detail-title {
  font-size: 1.55rem;
}
.contents-box{
  font-size: 1.08rem;
}
.move-text{
  font-size: 0.9rem;
}
.bottom-box {
  font-size: 0.9rem;
}
.goback{

  font-size: 1rem;
}
.back-svg{
  height: 1.2rem;
  width: 1.2rem;
}

}


@media screen and (max-width: 500px) {

.detail-title {
font-size: 1.1rem;
}
.contents-box{
  font-size: 0.88rem;
}
.move-text{
  font-size: 0.88rem;
}
.bottom-box {
  font-size: 0.88rem;
}
.goback{
  font-size: 0.88rem;
}
.back-svg{
  height: 1rem;
  width: 1rem;
}

.type-text{
  font-size: 10px;
}
.date-text {
  font-size: 10px;
}


}

@media screen and (max-width: 400px) {
  .title-sub-box-400{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #999999;
    font-size: 0.875rem;
    line-height: 130%;
    margin-bottom: 1rem;
  }
  .mobile-display-block-400{
    display: block;
  }
  .mobile-display-none-400{
    display: none;
  }
  .detail-title {
  font-size: 1rem;
  }
  .contents-box{
  font-size: 0.95rem;
}
  .mobile-display-none {
    display: none;
  }
  .table-title {
    font-size: 11px;
  }
  .table-row {
    font-size: 11px;
  }
} */






</style>