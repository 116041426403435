<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content}` : `SITE_NAME` }}</template>
    </metainfo>
    <div class="box">
      <Header @selectLang="LangData" :language="language" />
      <router-view
        :mediaLen="mediaLen"
        :media="media"
        :temp="temp"
        :ext="ext"
        :ko="ko"
        :ko_new_length="ko_new_length"
        :glo_new_length="glo_new_length"
        :glo="glo"
        :news_en="news_en"
        :semi="semi"
        :edu="edu"
        :tab="tab"
        :EduData="EduData"
        :imgSrc="imgSrc"
        @moveTab="tab = $event"
        :language="language"
        :tempText="tempText"
        :disclosures="disclosures"
      ></router-view>
      <Footer
        v-if="this.$route.name !== 'VKlanding' && this.$route.name !== 'VKMlanding'"
        :language="language"
      />
      <Totop />
    </div>
  </div>
</template>

<script>
import Header from './components/Header/Header.vue'
import Footer from './components/Footer/Footer.vue'
import Totop from './components/Totopbutton.vue'
import news_en from './assets/js/newsCrawl_EN.json'
import ext from './assets/js/etc.json'
import edu from './assets/js/edu.json'
import AOS from 'aos'
import axios from 'axios'
import setMeta from '@/utils/setMeta'

export default {
  name: 'App',
  methods: {
    LangData(event) {
      this.language = event;
    }
  },
  components: {
    Header,
    Footer,
    Totop
  },
  data() {
    console.log('LANG', window.navigator.language.slice(0, 2));
    return {
      language: sessionStorage.getItem('selectedLang') || window.navigator.language.slice(0, 2),
      imgSrc: 'https://source.unsplash.com/500x500/?business=',
      news_en: news_en,
      ext: ext,
      edu: edu,
      tab: 'ESG Trend',
      EduData: [
        {
          site_source: '/insights_more',
          site_image: require('@/assets/imgs/Insights_img/Edu_img/edu_1.jpg')
        }
      ],
      ko: '',
      glo: '',
      semi: '',
      temp: '',
      tempText: [],
      media: '',
      mediaLen: '',
      ko_new_length: '',
      glo_new_length: '',
      disclosures: ''
    };
  },

  beforeCreate() {
    (function (w, d, a) {
      w.__beusablerumclient__ = {
        load: function (src) {
          var b = d.createElement('script');
          b.src = src;
          b.async = true;
          b.type = 'text/javascript';
          d.getElementsByTagName('head')[0].appendChild(b);
        }
      };
      w.__beusablerumclient__.load(a + '?url=' + encodeURIComponent(d.URL));
    })(window, document, '//rum.beusable.net/load/b240108e122753u694');

    axios
      .get('https://notiondbts.gudejr31.workers.dev/')
      .then((response) => {
        const data = response.data;
        for (var i = 0; i < data.results.length; i++) {
          this.tempText.push(
            data.results[i].properties.내용입력.rich_text[0]?.plain_text.replace(
              /(?:\r\n|\r|\n)/g,
              '<br />'
            )
          );
        }
        this.temp = data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get('https://solution.i-esg.io/API/collect')
      .then((response) => {
        const data = response.data;
        const crawl = [];
        const news = [];
        const semi = [];
        for (let key in data) {
          const crawling = data[key];
          crawling.id = key;
          crawl.push(crawling);
          if (crawl[key].site_type == 'news') {
            news.push(crawl[key]);
          } else {
            semi.push(crawl[key]);
          }
        }
        this.semi = semi;
        return news;
      })
      .then((news) => {
        const news_id = [];
        const ko = [];
        const glo = [];
        for (let key in news) {
          const news_data = news[key];
          news_data.id = key;
          news_id.push(news_data);
          if (news_id[key].site_location == 'glo') {
            glo.push(news_id[key]);
          } else {
            ko.push(news_id[key]);
          }
        }
        this.ko_new_length = ko.length;
        this.glo_new_length = glo.length;
        this.ko = ko;
        this.glo = glo;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  created() {
    this.emitter.on('movetab', (b) => {
      this.tab = b;
    });
  },

  beforeMount() {
    setMeta({
      title: 'i-ESG',
      description:
        'i-ESG는 기업들의 ESG 대응 한계를 해소하는 ESG 특화 B2B 디지털 관리 솔루션 입니다.',
      keywords: 'ESG, ESG 관리, ESG 솔루션, ESG 디지털 관리'
    }),
      AOS.init({
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 600, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: false, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom' // defines which position of the element regarding to window should trigger the animation
      });
  }
};
</script>

<style scoped>
@import './assets/css/reset.css';
</style>
