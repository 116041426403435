export default {
  "HomeMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효과적인 ESG 디지털 관리를 위한 최적의 솔루션"])},
  "Demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데모요청"])},
  "HomeSol1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전문지식과 경험이 없어도<br> 효율적인 ESG 정보 관리"])},
  "HomeSol2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["협업을 통해 간단해지는<br> 데이터 수집 및 관리"])},
  "HomeSol3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인공지능을 활용한<br> 최적의 ESG 관리 솔루션"])},
  "HomeSol4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록체인 기술을 활용한<br> 보안, 신뢰성, 투명성 강화"])},
  "iESGUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업의 관점에서 생각하고<br />기업의 Pain Point에만 집중합니다."])},
  "iESGMid1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 분야는 'Good To'에서 'Must' 시장으로 급격히 전환중이며, 기업들에게는 '생존'에 직결되는 요소가 되었습니다."])},
  "iESGMid2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반면, 기업들은 여전히 여러가지 제약으로 적절하게 대응하지 못하고 있습니다."])},
  "iESGMid3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG는 막연했던 ESG 관리를 보다 편리하고 쉽게 관리할 수 있도록 돕습니다."])},
  "Function1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 자가진단"])},
  "Function2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 리포트자동화"])},
  "Function3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맞춤형 관리 대시 보드"])},
  "Function4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신동향 및 비즈니스 사례"])},
  "Function5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공급망 관리"])},
  "Function6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["온실가스 관리(준비중)"])},
  "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 알아보기"])},
  "IntegratedUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG는 ESG에 특화된 데이터 기반 통합 디지털 관리 플랫폼 입니다."])},
  "IntegratedMid1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수작업 방식의 디지털 전환"])},
  "IntegratedMid2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보를 수집, 해석, 적용하고 관리하는 모든 복잡한 작업을 디지털로 통합 관리합니다."])},
  "IntegratedMid3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직관적이고 효율적인 인터페이스"])},
  "IntegratedMid4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 지식이나 경험이 없더라도 클릭만으로 보고서를 생성하고 관리하여 보다 효과적으로 관리합니다."])},
  "IntegratedMid5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업고객의 현실을 고려한 합리적인 가격정책"])},
  "IntegratedMid6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 대비 최대 95% 저렴한 비용으로 보다 체계적인 ESG 관리가 가능합니다."])},
  "IntegratedMid7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(니즈에 맞는 세분화된 가격정책 제공),"])},
  "ISUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인공지능과 블록체인을 활용하여 가장 효율적이고 안전한 솔루션을 제공합니다."])},
  "ISMid1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인공지능을 통한 최적 관리."])},
  "ISMid2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자체 고안된 AI 알고리즘은 국내외 수많은 기준/동향/규제와 관련한 DB를 구축,학습하여 최적화된 솔루션을 제공합니다."])},
  "ISMid3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록체인을 통한 정보 보안"])},
  "ISMid4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록체인 기술을 적용하여 회사의 민감한 정보를 보안하고 주요 이해관계자들과의 소통에 신뢰성 및 투명성을 제공합니다. "])},
  "Inteliigience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지식 공유를 통해 지속가능한 아이디어를 육성합니다."])},
  "Homefin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효율적인 ESG 대응 및 관리, 지금 시작해보세요."])},
  "Demofin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데모 신청"])},
  "AboutusMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한번 쯤은 들어봤을 ESG, 왜 모두가 ESG를 이야기할까요?"])},
  "Background1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG의 대표적인 범주 ‘기후변화’, 우리가 생각하는 것 보다 훨씬 심각합니다."])},
  "Background2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 전 세계 곳곳에서 가뭄, 홍수, 지진, 화재 등 기후변화의 위기들을 쉽게 찾아볼 수 있습니다."])},
  "Background3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UN 산하 정부간 협의체 IPCC는 8년만에 발간한 보고서에서 2050년 탄소중립을 달성하더라도 북극 의 빙하가 한번은 전부 녹을 것이며, 기후변화 위기를 극복하기 위해서 단계별 개선이 아닌 ‘즉각적인 온실가스의 감축’이 반드시 필요하다고 강조했습니다."])},
  "Background4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이러한 시급성으로 기업들을 향한 요구와 규제가 확대되고 있습니다."])},
  "Background5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 우리 기업들의 현실이 되었습니다."])},
  "Background6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 국내 수출기업 A사는 유럽의 고객사로부터 레터를 받았습니다."])},
  "Background7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 가격, 품질과 더불어 ESG 요소로 거래 여부를 평가하겠다는 내용이 담겨있습니다."])},
  "Background8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 결과에 따라 거래가 중단되고, 납품이 배제되고, 투자가 철회되는 사례들."])},
  "Background9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 경영과 기업의 수익성이 직결되는 요소라는 다양한 실 사례는 이미 너무나 많습니다."])},
  "Background10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["절대 간과해서는 안 될, 큰 시장입니다."])},
  "Background11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전 세계 많은 투자사, 평가사, 소비자들은 기업들의 ESG 경영을 지지하며, 이를 토대로 실제적인 행동을 취하고 있습니다."])},
  "Background12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20년 기준 ESG 투자 규모는 약 40조 5천억달러 (한화 약 4.9경원)으로 '30년까지 3배 이상 성장할 것이라는 전문가들의 전망이 있었습니다."])},
  "Background13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자본시장의 요구, 이미 너무나 큰 시장 규모는 기업들이 결코 간과해서는 안되는 시장입니다."])},
  "Background14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출처: 글로벌지속가능투자연합, 도이치 뱅크 등"])},
  "ProblemMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이러한 중요성에도 불구하고 ESG 관리 및 대응은 여전히 기업들에게 어려운 과제입니다."])},
  "ProblemMainbt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(i-ESG 고객인터뷰 포함 다수 기업 ESG 대응 설문조사 결과 취합)"])},
  "Problem1up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["막연함"])},
  "Problem1down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["너무 광범위한 분야, 아는 것 만으로도 버거운 ESG"])},
  "Problem2up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전문 역량의 부재"])},
  "Problem2down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장의 태동기, 전문가를 찾기도 전문 역량을 갖추기도 쉽지 않은 현실"])},
  "Problem3up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장시간 소요, 높은 비용"])},
  "Problem3down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리포트 준비 작성 기간 4~6개월, 컨설팅사 자문 비용 2억~3억"])},
  "Problem3bt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(대기업 평균)"])},
  "Problem4up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표준의 부재"])},
  "Problem4down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["300여개가 넘는 기준, 프레임워크, 이니셔티브, 규제"])},
  "Problem5up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수작업의 비효율"])},
  "Problem5down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 공시를 위한 자료수집, 분류, 분석 모두 수작업"])},
  "VisionMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 기업들이 지속가능한 전략을 수립하고, 실행하고, 효과적으로 알리는 경영활동을 하는 모습을 상상합니다."])},
  "MissionMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG는 기업들이 ESG를 보다 잘 알고 잘 관리하고 알릴 수 있도록 돕는 데이터 기반 ESG 특화 통합 디지털 솔루션 입니다."])},
  "DT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수작업의 한계를 디지털로 보완합니다. "])},
  "DT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보를 수집, 해석, 적용하고 관리하는 모든 복잡한 작업을 디지털로 통합 관리합니다."])},
  "AI1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI 기술로 수많은 기준/동향/규제 DB를 수집/분석하여 최적 솔루션을 제공합니다."])},
  "AI2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록체인 기술로 민감한 정보를 보안하고, 신뢰성을 제공합니다."])},
  "allinone1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["흩어져있던 ESG 관리, i-ESG에서 한번에 해결합니다."])},
  "allinone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG 솔루션으로 진단부터 관리에 이르는 효과적인 통합 관리가 가능해집니다."])},
  "Socialimpactmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우리는 더 큰 책임감을 가지고 끊임없이 고민하고 준비하여 사회적 문제에 기여하는 회사로의 성장을 지향합니다. "])},
  "Socialimpactbody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보와 자원 등의 불균형에 기인한 기업들의 ESG 대응한계를 해소하여 기업생존에 직결된 다양한 글로벌 이슈를 해결하고 나아가 ESG의 내재화를 도와 더 나은 기업으로의 성장을 지원합니다."])},
  "DNA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업 관점 DNA"])},
  "DNAmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업의 관점에서, 기업 현실에 맞는 서비스에 집중합니다."])},
  "DNAbody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG는 대표종합상사인 포스코인터내셔널의 사내벤처로 출범되었습니다."])},
  "DNAbody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50여년 이상 국내외 다양한 비즈니스 경험하며 기업들의 입장에서 현상을 바라보고 이에 맞는 솔루션을 찾는 것은 우리의 DNA가 되었습니다."])},
  "DNAbody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 나은 기업이 되는 것은 규제와 감시로 결코 도달할 수 없습니다."])},
  "DNAbody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG는 기업을 평가하지 않습니다. 오직 기업들의 입장에서 생각합니다."])},
  "Ourteammain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업 고객들의 Pain Point를 이해하는 사업경험/데이터 분석 능력 글로벌 네트워크/ IT 개발 역량은 i-ESG의 강점입니다."])},
  "Media1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주요공시"])},
  "Media2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언론 속의 i-ESG"])},
  "PartnersMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우리의 협력은 더 나은 세상, 더 좋은 기업을 만들어가는 데 가장 중요한 양분입니다."])},
  "PartnersSub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 나은 세상을 위해 각자의 분야에서 힘을 더해주신 파트너사들에게 감사합니다."])},
  "PartnersSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앞으로도 우수한 파트너십을 통해 우리 모두가 바라는 모습으로의 도약을 만들어내겠습니다."])},
  "Parnersfin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 많은 기업들과, 더 나은 세상을 만들어가는 여정에 함께해 주세요."])},
  "Partnersbt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파트너 제휴 문의"])},
  "SolutionMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업의 입장에서 기획하고 개발된 ESG 특화 데이터서비스 플랫폼"])},
  "SolutionSub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 경영은 회사에 부담이 되어서는 안됩니다."])},
  "SolutionSub1fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 경험과 지식이 없더라도 ESG 경영을 효과적으로 해낼 수 있습니다. 기업이 어떤 단계에 있더라도 적합한 맞춤형 해결책을 제공합니다."])},
  "SolutionSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG는 단순히 잘 알려서만 되는 것이 아닙니다"])},
  "SolutionSub2fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG를 잘 알고, 적용하고, 알리는 ESG 관리 전반에 대한 서비스를 구현했습니다. 보다 효과적이고, 빠르고, 저렴하게 ESG 관리하고, 소통할 수 있습니다."])},
  "ESGDBMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지속적인 ESG 관리/개선이 가능한 i-ESG 대시보드"])},
  "DB1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통합관리 대시보드"])},
  "DB1ft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세 ESG 이력을 한눈에 보여주고, 관리할 수 있는 직관적인 대시보드"])},
  "DB2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우리 회사 ESG 현황"])},
  "DB2ft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우리 회사의 각 ESG 분야별 세부 수치 확인"])},
  "DB3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이력 관리 및 AI 개선 피드백"])},
  "DB3ft1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 진단, 리포팅 내역 관리 및 개선 현황"])},
  "DB3ft2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 정보를 기반으로 AI 개선 피드백"])},
  "DB4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국내외 실시간 동향"])},
  "DB4ft1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["크롤링, RPA 기법 활용"])},
  "DB4ft2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국내외 ESG 기사,규제 정보 제공"])},
  "DB5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빅데이터 insight 분석"])},
  "DB5ft1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실시간 국내외 다양한 오픈 데이터 수집"])},
  "DB5ft2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 분야 트렌드, 중요성 이슈 제공"])},
  "DB6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good & Bad 케이스"])},
  "DB6ft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인공지능 감성분석 기반 국내외 다양한 비즈니스 사례를 긍정/부정 케이스로 분류"])},
  "SolEv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 진단"])},
  "SolEvMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우리 기업의 ESG 수준을 빠르고 정확하게 진단합니다."])},
  "Solex1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진단 시스템은 주요한 글로벌 평가기준의 공통 요소들을 토대로 구성되고 업데이트됩니다."])},
  "Solex2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 ESG에 대한 경험과 지식이 없더라도 아래의 사항들을 바탕으로 빠르게 정확한 ESG 관리를 지속할 수 있습니다."])},
  "Evcrit1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E(환경), S(사회), G(지배구조) 범주에 포함되는 사항이 무엇인지"])},
  "Evcrit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우리 산업분야에서 특별히 요구되는 중요성 이슈들이 무엇인지"])},
  "Evcrit3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우리 기업의 현황은 동종업계 대비, 국내/글로벌 대비 어떠한 수준인지"])},
  "Evcrit4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["향후 ESG 관리를 위해 보다 중점적으로 관리해야 하는 부분이 무엇인지"])},
  "SolReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리포트 자동화"])},
  "SolReportMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["많은 시간과 비용을 들일 필요가 없습니다."])},
  "Re1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수작업으로 진행하던 모든 ESG 업무를 i-ESG로 한 번에 해결하세요."])},
  "Re2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG는 AI 알고리즘에 기반하여, 국내외 수많은 기준/ 프레임워크/업계동향/ 규제정책과 관련한 데이터베이스를 생성하고, 머신러닝기법을 활용하여 지속적으로 학습하여 업데이트 합니다."])},
  "Re3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["클릭 몇 번만으로 최신 글로벌 기준에 부합하는 높은 품질의 ESG 리포트를 간편하고 작성할 수 있습니다."])},
  "Re4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG는 다양한 글로벌 ESG 공시 기준을 지원하며, 상세한 작성가이드와 예시로 기존 경험과 지식이 없더라도 수월하게 리포팅을 완성할 수 있습니다."])},
  "Memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["('22.12 기준 지원 글로벌 ESG 공시 기준: GRI, SASB, TCFD, UN SDGs, K-ESG, K-ESG (중소기업용), Fact Sheet - 기준은 시황에 따라 지속 업데이트 됩니다)"])},
  "CustomizedDB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맞춤형 관리 대시보드"])},
  "CustomizedDBMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 나은 기업으로의 성장, 지속적인 ESG 관리가 필요합니다."])},
  "CustomziedDB1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업들이 보다 쉽고, 효과적으로 ESG를 관리할 수 있는 통합관리 대쉬보드를 제공합니다."])},
  "CustomizedDB2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG는 기업들이 당장 필요한 ESG 분야 다양한 요구에 효과적으로 대응하길 원합니다."])},
  "CustomizedDB3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG의 목표는 여기에 그치지 않습니다."])},
  "CustomizedDB4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우리 고객들이 현실적인 대응한계를 해소하는 것을 넘어, 더 나은 기업으로 성장해 갈 수 있도록 전 생애주기에서 지원하는 서비스가 되고자 합니다."])},
  "CustomizedDB5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통합관리 대시보드를 통해 우리기업의 현황 및 이력 관리는 물론이고, 더 나은 기업으로 성장해 가는 데 필요한 다양한 국내외 정보를 제공합니다."])},
  "CustomizedDB6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보다 객관적이고 유의미한 정보를 제공하기 위해 i-ESG는 인공지능과 빅데이터와 같은 기술을 활용합니다."])},
  "SCM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공급망 관리"])},
  "SCMMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공급 업체의 지속 가능성을 효율적으로 파악하고 관리합니다."])},
  "SCM1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 경영은 우리 회사뿐 아니라 회사의 공급망 전체에 대해 요구됩니다."])},
  "SCM2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하지만 이러한 요구에도 불구하고 여전히 많은 기업들이 공급망 측면의 ESG 리스크와 기회요인을 적절히 식별하지 못하고 있습니다."])},
  "SCM3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG 소프트웨어를 통해 공급업체의 지속 가능성을 우리 기업의 ESG 관리와 통합하여 모니터링하고 관리합니다."])},
  "SCM4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공급업체의 리스크/기회의 정량화를 통해 향후 지속가능성에 대한 빠르고 효과적인 결정이 가능합니다."])},
  "SCM5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG 소프트웨어를 통해 시시각각 변하는 요구사항을 반영한 공급망 포트폴리오를 관리하세요."])},
  "ESGMan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우리 기업 환경에 맞는 최적화된 ESG 관리"])},
  "ESGMan1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 정보 관리"])},
  "ESGMan1fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" i-ESG는 ESG 경영에 필요한 국내외 수많은 데이터를 수집, 분석하여 기업의 경영환경에 최적화된 ESG 솔루션을 제공합니다."])},
  "ESGMan2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["협업으로 간단해지는 데이터 수집"])},
  "ESGMan2fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG를 사용하면 데이터 수집 및 모니터링에 대한 책임을 관리하는 동시에 기존의 수작업으로 진행했던 번거롭고 긴 수집과정을 최소화할 수 있습니다."])},
  "ESGMan3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효율적인 ESG 관리 환경"])},
  "ESGMan3fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복잡한 설치 절차나 이용절차 없이, 인터넷에 연결되어있기만 하면 언제든 어디에서든 작업을 진행할 수 있습니다."])},
  "ESGMan4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신 기술로 최적화된 ESG 관리"])},
  "ESGMan4fe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속해서 변하는 각종 규제, 동향은 물론 ESG 공시에 따른 필연적인 우리기업의 민감한 정보 공개 등도 효과적으로 관리합니다."])},
  "Solfin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복잡한 ESG 관리, i-ESG 솔루션으로 지금 시작해보세요."])},
  "Finbt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데모요청"])},
  "PricingMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우리회사에 꼭 맞는 ESG 솔루션 패키지를 선택하세요."])},
  "Essential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소한의 필수 핵심 기능"])},
  "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생산성을 높이는 기능"])},
  "Enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엔터프라이즈를 위한"])},
  "Enterpriseplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 ESG 고급 기능 지원"])},
  "PricingESGDB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 대시보드"])},
  "PricingESGDBfun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업진단현황+빅데이터 동향정보+국내외트렌드+AI 기반 비즈니스 사례 등 종합관리 대시보드"])},
  "Pricingtest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 자가진단"])},
  "Pricingtestfun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터 기반 ESG 진단 Set 제공 +대시보드+진단이력관리+PDF 리포트"])},
  "Pricingmt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중요성 이슈파악"])},
  "Pricingmtfun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중요성 이슈관리/설문 솔루션 + 대시보드+이력관리+PDF리포트"])},
  "Pricingreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 공시 자동화"])},
  "Pricingreportfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["글로벌 공시기준 데이터 +AI 추천 +국/영문 리포팅 자동화 + 대시보드 + (제3자 검증)"])},
  "PricingESGMg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 관리"])},
  "PricingESGMgfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 데이터관리 및 수집 + KPI 관리 + AI/빅데이터분석+대시보드+PDF리포트"])},
  "PricingGHG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GHG 관리"])},
  "PricingGHGfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GHG데이터관리 및 수집(Scope1~3) +감축목표 선정 및 관리"])},
  "PricingSCM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 공급망 관리 "])},
  "PricingSCMfe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진단패키지(수정가능)+공급망 진단 + 대시보드 + 관리 리포트(PDF)리"])},
  "DataINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자사 시스템 통합 API 제공"])},
  "Support1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["네트워킹 행사 초청"])},
  "Support2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["트렌드 리포트"])},
  "Support3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 특화 유료교육 무상 제공 (온/오프라인)"])},
  "Support4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오프라인 현장실사"])},
  "Support5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨설팅"])},
  "Support6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공시 컨설팅"])},
  "Support7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초기 솔루션 활용 컨설팅"])},
  "Promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로모션: 협력사 / 공급망 솔루션 추가 시 최소 10% 할인혜택 적용"])},
  "Promquestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 궁금한 점이 있으신가요? 저희가 도와드릴게요."])},
  "Pricinglist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["베타참여 의향 전달 기업들이며,리스트는 지속 업데이트 예정입니다."])},
  "ClientsMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 나은 세상을 만들어가는 우리의 고객들"])},
  "ClientsSub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여러분은 좋은 기업입니다."])},
  "ClientsSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 나은 세상을 위해, 더 나은 기업이 되기 위해, 한 걸음의 도약을 하는 여러분은 좋은 기업입니다."])},
  "ClientsSub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG는 이러한 기업들의 적극적인 ESG 경영을 응원하며, 적극 지원합니다."])},
  "Clientsmemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(베타참여 의향 전달 기업들이며, 리스트는 지속 업데이트 예정입니다)"])},
  "Clientsbt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 나은 기업으로의 성장, 지금 시작하세요."])},
  "iesgdemobox1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유입경로"])},
  "iesgdemobox2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뉴스기사"])},
  "iesgdemobox3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홍보메일"])},
  "iesgdemobox4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지인소개"])},
  "iesgdemobox5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])},
  "iesgdemobox6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요청된 사항을 처리하기 위해 i-ESG가 위에 제출된 개인정보를 저장 및 처리하는 데 동의합니다"])},
  "iesgdemobox7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자 성함/직급"])},
  "iesgdemobox8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사명"])},
  "iesgdemobox9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당부서"])},
  "iesgdemobox10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 주소"])},
  "iesgdemobox11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락처"])},
  "iesgdemobox12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의 사항"])},
  "iesgbt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i-ESG는 빠르게 확대되는 ESG 분야 다양한 요구를 효과적으로 관리하는 데이터기반 디지털 서비스 플랫폼입니다. "])},
  "iesgbt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업들에게 필요한 서비스를 제공하기 위해, i-ESG는 혁신적인 기술을 활용하여 객관성과 신뢰성에 기반한 서비스를 제공합니다."])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강남구 역삼로 168 팁스타운 S6"])}
}