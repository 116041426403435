<template>
  <div>
    <ContactModal
      v-if="modalshow && language == 'ko'"
      :modalshow="modalshow"
      @closemodal="modalshow = false"
      :modaldata="modaldata"
    />
    <ContactModal
      v-if="modalshow && language == 'en'"
      :modalshow="modalshow"
      @closemodal="modalshow = false"
      :modaldata="modaldataEN"
      :language="language"
    />
    <pageSection1 v-show="this.$route.name === 'Solutions'" :language="language" />
    <div class="solutions-p2">
      <div v-if="language == 'ko'" class="p2-title">
        <h5 class="color-title" data-aos="fade-right" data-aos-offset="200">Solutions</h5>
        <h4 class="title" data-aos="fade-left" data-aos-offset="200">
          가장 쉬운 ESG 디지털 솔루션 i-ESG
        </h4>
      </div>

      <div v-if="language == 'en'" class="p2-title">
        <h5 class="color-title" data-aos="fade-right" data-aos-offset="200">Solutions</h5>
        <h4 class="title" data-aos="fade-left" data-aos-offset="200">
          The Most User-friendly ESG Digital Solution
        </h4>
      </div>

      <div v-if="language == 'ko'" class="contents">
        <div class="image"></div>
        <div class="text">
          <div class="text-1">
            <h5 class="Pretendard-700">AI/빅데이터 기반의 객관적인 솔루션</h5>
            <p class="Pretendard-400">
              데이터 표집, 분석, 활용에 관련된 기술 특허 9+ 건 보유 <br />
              비표준화된 ESG 규제와 프레임워크의 비정형 ESG 데이터 정형화 기술 <br />
              기업에 필요한 ESG 데이터만 선별적으로 제공하는 AI 기술 <br />
            </p>
          </div>
          <div class="text-2">
            <h5 style="line-height: 150%" class="Pretendard-600">
              ESG 데이터는 디지털로 관리해야 합니다
            </h5>
            <p class="Pretendard-400">
              수작업 방식의 과도한 업무 부담과 비용 증가 문제를 해소할 수 있습니다. <br />
              ESG 연계 데이터 수집과 분석이 용이해 데이터 기반의 전략적 의사결정이 가능합니다.<br />
              데이터 이력관리를 통해 빈번하고 다양한 ESG 요구를 적기에 대응할 수 있습니다.<br />
            </p>
          </div>
        </div>
      </div>

      <div v-if="language == 'en'" class="contents">
        <div class="image"></div>
        <div class="text">
          <div class="text-1">
            <h5 class="Pretendard-700">Objective Solution based on AI/Big Data</h5>
            <p class="Pretendard-400">
              Unique 9+ patents in ESG data collection, classification, and analysis <br />
              Structuring unstructerd data from non-standardized regulations<br />
              Selective ESG insight for businesses powered by AI technology
            </p>
          </div>
          <div class="text-2">
            <h5 style="line-height: 150%" class="Pretendard-600">
              Effectiveness of Digital Management
            </h5>
            <p class="Pretendard-400">
              Resolving the excessive workload and cost from manual processes<br />
              Enabling strategic decision-making based on ESG-related data analysis <br />
              Managing data history and promptly respond to ESG demands
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="solutions-p2-1">
      <div v-if="language == 'ko'" class="inner">
        <h1 class="color-title" data-aos="fade-right" data-aos-offset="150">Solution</h1>
        <h2 class="title" data-aos="fade-left" data-aos-offset="150">
          지속적인 ESG 관리/개선이<br class="mobile" />
          가능한 i-ESG 대시보드
        </h2>
        <div class="top-box">
          <div class="box" data-aos="fade-up">
            <h1 class="box-title Pretendard-700">
              통합관리<br class="mobile" />
              대시보드
            </h1>
            <p class="box-text Pretendard-400 mobile_display_off">
              상세 ESG 이력을 <br class="mobile" /><span class="mobile_display_off"
                >한눈에 보여주고,</span
              ><br class="mobile_display_off" />
              관리할 수 있는<br class="mobile" />
              직관적인 대시보드
            </p>
            <div class="point-1">
              <hr class="line-1" />
              <div class="circle"></div>
            </div>
          </div>
          <div class="box break" data-aos="fade-up" data-aos-delay="150">
            <h1 class="box-title Pretendard-700">우리 회사 <br class="mobile" />ESG 현황</h1>
            <p class="box-text Pretendard-400 mobile_display_off">
              자사의 최근 진단결과를 직관적인
              <br />
              도표, 점수로 파악할 수 있는 그래프
            </p>
            <div class="point-2">
              <hr class="line-2" />
              <div class="circle"></div>
            </div>
          </div>
          <div class="box" data-aos="fade-up" data-aos-delay="300">
            <h1 class="box-title Pretendard-700">
              이력 관리 및 <br class="mobile" />AI 개선 피드백
            </h1>
            <p class="box-text Pretendard-400 mobile_display_off">
              진단 피드백을 주기적으로 요약된 형태로 <br />
              받으며 객관적인 상태 파악 가능
            </p>
            <div class="point-3">
              <hr class="line-3" />
              <div class="circle"></div>
            </div>
          </div>
        </div>
        <div class="img" />
        <div class="top-box">
          <div class="box" data-aos="fade-up" data-aos-delay="150" data-aos-offset="-500">
            <h1 class="box-title Pretendard-700">
              국내외<br class="mobile" />
              실시간 동향
            </h1>
            <p class="box-text Pretendard-400 mobile_display_off">
              크롤링, RPA 기법 활용<br />
              국내외 ESG 기사,<br class="mobile" />규제 정보 제공
            </p>
            <div class="point-4">
              <hr class="line-4" />
              <div class="circle"></div>
            </div>
          </div>
          <div class="box break" data-aos="fade-up" data-aos-delay="150" data-aos-offset="-500">
            <h1 class="box-title Pretendard-700">빅데이터/AI 통합 트렌드 분석</h1>
            <p class="box-text Pretendard-400 mobile_display_off">
              실시간 <span class="mobile_display_off">국내외 다양한</span> 오픈 데이터 수집<br />
              ESG 분야 트렌드,<br class="mobile" />
              중요성 이슈 제공
            </p>
            <div class="point-5">
              <hr class="line-5" />
              <div class="circle"></div>
            </div>
          </div>
          <div class="box" data-aos="fade-up" data-aos-delay="300" data-aos-offset="-500">
            <h1 class="box-title Pretendard-700">
              Good & Bad<br class="mobile" />
              케이스
            </h1>
            <p class="box-text Pretendard-400 mobile_display_off">
              빅데이터/AI 통합 트렌드 분석 <br />
              비즈니스 사례를 긍정/부정 케이스로 분류
            </p>
            <div class="point-6">
              <hr class="line-6" />
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="language == 'en'" class="inner">
        <h1 class="color-title" data-aos="fade-right" data-aos-offset="150">Solution</h1>
        <h2 class="title" data-aos="fade-left" data-aos-offset="150">
          Customized Big-data-enabled Dashboard for your needs
        </h2>
        <div class="top-box">
          <div class="box" data-aos="fade-up">
            <h1 class="box-titleEn Pretendard-700">
              Management<br class="mobile" />
              Dashboard
            </h1>
            <p class="box-textEn Pretendard-400 mobile_display_off">
              Intuitive ESG dashboard that<br />
              provides detailed ESG history at a glance
            </p>
            <div class="point-1">
              <hr class="line-1" />
              <div class="circle"></div>
            </div>
          </div>
          <div class="box" data-aos="fade-up" data-aos-delay="150">
            <h1 class="box-titleEn Pretendard-700">Company ESG Highlights</h1>
            <p class="box-textEn Pretendard-400 mobile_display_off">
              Specific data figures for each ESG sector
            </p>
            <div class="point-2">
              <hr class="line-2" />
              <div class="circle"></div>
            </div>
          </div>
          <div class="box" data-aos="fade-up" data-aos-delay="300">
            <h1 class="box-titleEn Pretendard-700">
              History Management<br />
              AI Improvement Feedback
            </h1>
            <p class="box-textEn Pretendard-400 mobile_display_off">
              ESG performance history management<br />
              AI feedback based on company info/data
            </p>
            <div class="point-3">
              <hr class="line-3" />
              <div class="circle"></div>
            </div>
          </div>
        </div>
        <div class="img_en" />
        <div class="top-box">
          <div class="box" data-aos="fade-up">
            <h1 class="box-titleEn Pretendard-700">
              Real-time<br class="mobile" />
              ESG Trends
            </h1>
            <p class="box-textEn Pretendard-400 mobile_display_off">
              Monitoring real-time ESG news<br />
              and regulation changes via<br />
              the latest technologies
            </p>
            <div class="point-4">
              <hr class="line-4" />
              <div class="circle"></div>
            </div>
          </div>
          <div class="box" data-aos="fade-up" data-aos-delay="150">
            <h1 class="box-titleEn Pretendard-700">
              Market Insight<br />
              via Big Data Analysis
            </h1>
            <p class="box-textEn Pretendard-400 mobile_display_off">
              Fine-tuned market insights and<br />
              statistics provided by data collection<br />
              from reliable sources
            </p>
            <div class="point-5">
              <hr class="line-5" />
              <div class="circle"></div>
            </div>
          </div>
          <div class="box" data-aos="fade-up" data-aos-delay="300">
            <h1 class="box-titleEn Pretendard-700">
              Positive & Negative<br />
              Business Cases
            </h1>
            <p class="box-textEn Pretendard-400 mobile_display_off">
              Business Cases Classified<br />
              via AI Sentiment Analysis
            </p>
            <div class="point-6">
              <hr class="line-6" />
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="solutions-interactive-tabs mobile_display_off">
      <div v-if="language == 'ko'" class="inner">
        <div class="tabs-header">
          <h1 class="color-title" data-aos="fade-right" data-aos-offset="150">i-ESG Solutions</h1>
          <h2 class="title" data-aos="fade-left" data-aos-offset="150">
            복잡한 ESG 대응/관리에 꼭 필요한 기능만 모은 <br />
            ESG 특화 통합 관리 솔루션입니다.
          </h2>
        </div>
        <div class="interactive-tab-group">
          <div
            v-for="(tab, index) in tabsKR"
            :key="index"
            :class="tab.class"
            @click="toggleInfo(index)"
          >
            <div class="box" data-aos="fade-up">
              <h1 class="corner-info Pretendard-400">{{ tab.title }}</h1>
              <h1 v-show="!tab.showInfo" class="interactive-tab-title Pretendard-600">
                {{ tab.defaultText }}
              </h1>
              <div v-show="tab.showInfo" class="interactive-tab-info">
                <div v-for="(info, i) in tab.information" :key="i" class="tab-item">
                  <div class="tab-icon"></div>
                  <div class="tab-text Pretendard-600">{{ info }}</div>
                </div>
              </div>
              <div class="img" :style="{ backgroundImage: 'url(' + tab.image + ')' }"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="language == 'en'" class="inner">
        <div class="tabs-header">
          <h1 class="color-title" data-aos="fade-right" data-aos-offset="150">i-ESG Solutions</h1>
          <h2 class="title" data-aos="fade-left" data-aos-offset="150">
            Integrated ESG-Specific Solution <br />
            Resolving Complexity of ESG Response/Management
          </h2>
        </div>
        <div class="interactive-tab-group">
          <div
            v-for="(tab, index) in tabsEN"
            :key="index"
            :class="tab.class"
            @click="toggleInfo(index)"
          >
            <div class="box" data-aos="fade-up">
              <h1 class="corner-info Pretendard-400">{{ tab.title }}</h1>
              <h1 v-show="!tab.showInfo" class="interactive-tab-title Pretendard-600">
                {{ tab.defaultText }}
              </h1>
              <div v-show="tab.showInfo" class="interactive-tab-info">
                <div v-for="(info, i) in tab.information" :key="i" class="tab-item">
                  <div class="tab-icon"></div>
                  <div class="tab-text Pretendard-600">{{ info }}</div>
                </div>
              </div>
              <div class="img" :style="{ backgroundImage: 'url(' + tab.image + ')' }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="badge">
      <div v-if="language == 'ko'" class="inner">
        <div class="badge-header">
          <h1 class="color-title" data-aos="fade-right" data-aos-offset="150">
            이 모든 데이터를 안전하게 관리하기 위해
          </h1>
          <h2 class="title" data-aos="fade-left" data-aos-offset="150">
            가장 안전한 데이터 관리를 위한 <br />세계적인 수준의 클라우드 보안
          </h2>
        </div>
        <div class="badge-content">
          <div class="badge-img-kr"></div>
          <div class="badge-background"></div>
        </div>
      </div>
      <div v-if="language == 'en'" class="inner">
        <div class="badge-header">
          <h1 class="color-title" data-aos="fade-right" data-aos-offset="150">
            Prioritizing Data Safeguard
          </h1>
          <h2 class="title" data-aos="fade-left" data-aos-offset="150">
            Premier Cloud Security <br />for Unmatched Data Protection
          </h2>
        </div>
        <div class="badge-content">
          <div class="badge-img"></div>
          <div class="badge-background"></div>
        </div>
      </div>
    </div>

    <div class="tab mobile_display_off">
      <section v-if="language == 'ko'">
        <div
          @click="(tab = 0), (isActive = 0)"
          :class="{ tab1: isActive == 0 }"
          class="Pretendard-600 tab2"
        >
          강력한 보안 <br class="tablet" />
          기능 제공
        </div>
        <div
          @click="(isActive = 1), (tab = 1)"
          :class="{ tab1: isActive == 1 }"
          class="Pretendard-600 tab2"
        >
          안전한 <br class="tablet" />
          클라우드 보안
        </div>
        <div
          @click="(tab = 2), (isActive = 2)"
          :class="{ tab1: isActive == 2 }"
          class="Pretendard-600 tab3"
        >
          데이터 보안
        </div>
      </section>

      <section v-if="language == 'en'">
        <div
          @click="(tab = 0), (isActive = 0)"
          :class="{ tab1: isActive == 0 }"
          class="Pretendard-600 tab2"
        >
          Advanced Security<br class="tablet" />
          Protocols
        </div>
        <div
          @click="(isActive = 1), (tab = 1)"
          :class="{ tab1: isActive == 1 }"
          class="Pretendard-600 tab2"
        >
          Cloud Security
        </div>
        <div
          @click="(tab = 2), (isActive = 2)"
          :class="{ tab1: isActive == 2 }"
          class="Pretendard-600 tab3"
        >
          Data Security
        </div>
      </section>
    </div>

    <div class="tab_slide">
      <div v-if="tab == 0" class="solutions-p3-1 mobile_display_off">
        <div v-if="language == 'ko'" class="p3inner flex__column">
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              01
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              신속한 추가 보안 조치
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700"> 보안 감사와 모니터링 <br /> </span>
            <span class="tab_text Pretendard-400">
              지속적인 보안 감사와 시스템 기록 모니터링을 통해 시스템의 안전성을 확보합니다.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              최소한의 접근 권한과 체계적인 인증 절차 <br />
            </span>
            <span class="tab_text Pretendard-400">
              직원 및 협력업체에 대한 접근 권한을 최소화하고, 체계적인 인증 절차를 통해 데이터
              접근을 관리합니다.
            </span>
          </p>
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              02
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              규정과 법률을 기반한 보안
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              전문 컨설턴트의 정보보호 감사 <br />
            </span>
            <span class="tab_text Pretendard-400">
              전문 보안 컨설턴트를 통해 지속적인 정보보호 감사를 수행하며 보안 수준을 최상으로
              유지합니다.
            </span>
          </p>
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              03
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              정보보호 교육을 받은 실무진
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700"> 보안이 고려된 개발<br /> </span>
            <span class="tab_text Pretendard-400">
              개발 단계부터 보안을 중요시하여 코드 리뷰, 분리된 환경에서의 테스트를 통해 보안을
              강화합니다.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700"> 취약점 점검과 관리 <br /> </span>
            <span class="tab_text Pretendard-400">
              정기적인 취약점 점검, 신속한 조치를 통해 시스템 보안을 유지합니다.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              정보보호 교육 및 비밀유지 계약<br />
            </span>
            <span class="tab_text Pretendard-400">
              모든 직원은 정보보호 교육을 이수하며 업무 수행 전 비밀유지 계약에 서명합니다.
            </span>
          </p>
        </div>
        <div v-if="language == 'en'" class="p3inner flex__column">
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              01
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              Additional Security Measures
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              Security Audit and Monitoring <br />
            </span>
            <span class="tab_text Pretendard-400">
              Ensuring system integrity through continuous security audits and system log
              monitoring.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              Systematic Verification Procedures <br />
            </span>
            <span class="tab_text Pretendard-400">
              Minimizing access rights for employees and collaborators, managing data access through
              systematic verification procedures.
            </span>
          </p>
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              02
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              Law and Regulations
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              Security Audits by Professional Consultants <br />
            </span>
            <span class="tab_text Pretendard-400">
              Maintaining the highest security levels through continuous audits conducted by
              professional security consultants.
            </span>
          </p>
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              03
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              Trained Staff
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              Security-Oriented Development<br />
            </span>
            <span class="tab_text Pretendard-400">
              Emphasizing security from the development stage of code reviews and testing in
              isolated environments.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700"> Vulnerability Checks <br /> </span>
            <span class="tab_text Pretendard-400">
              Maintaining system security through regular vulnerability checks and prompt response
              actions.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700"> Non-Disclosure Agreements <br /> </span>
            <span class="tab_text Pretendard-400">
              All employees undergo training session for non-disclosure agreements.
            </span>
          </p>
        </div>
      </div>

      <div v-if="tab == 1" class="solutions-p3-2 mobile_display_off">
        <div v-if="language == 'ko'" class="p3inner flex__column">
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              01
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              네이버 클라우드 플랫폼(NCP)의 보안
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              NCP의 완벽한 외부 네트워크 차단 <br />
            </span>
            <span class="tab_text Pretendard-400">
              i-ESG는 네이버 클라우드 플랫폼(NCP)으로 외부 네트워크와 차단되어 있어 우리 고객의
              정보를 안전하게 보호합니다.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              NCP의 실시간 서버 보안 감시 <br />
            </span>
            <span class="tab_text Pretendard-400">
              i-ESG는 NCP의 한층 강해진 보안 서버 설정과 무료 백신 제공으로 서버의 실시간 보안
              감시를 진행합니다.
            </span>
          </p>
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              02
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              마이크로소프트 클라우드(Azure)의 보안
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700"> Azure의 엄격한 보안 규정 <br /> </span>
            <span class="tab_text Pretendard-400">
              i-ESG는 Azure의 첨단 보안 기술과 엄격한 규정 준수를 기반으로 데이터를 안전하게
              보호합니다.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              Azure의 물리적 보안과 출처가 확실한 규정 준수 <br />
            </span>
            <span class="tab_text Pretendard-400">
              i-ESG는 Azure 데이터 센터의 물리적 보안, 비상 상황 대비, 철저한 보안 인증을 활용하여
              데이터 센터를 신뢰할 수 있는 표준,<br />
              ISO, CSA, SOC, K-ISMS 등을 준수하며 관리합니다.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700"> Azure의 고급 보안 관리 <br /> </span>
            <span class="tab_text Pretendard-400">
              i-ESG는 Azure의 고급 보안 관리 도구를 이용하여 클라우드 인프라의 보안 상태를
              지속적으로 모니터링하고, <br />
              위협을 신속하게 탐지, 대응합니다.
            </span>
          </p>
        </div>
        <div v-if="language == 'en'" class="p3inner flex__column">
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              01
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              Microsoft Azure Security
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              Stringent Security Regulations <br />
            </span>
            <span class="tab_text Pretendard-400">
              Safeguarding data securely based on Azure's state-of-the-art security technology and
              strict regulatory compliance.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              Physical Security based on Reliable Standards <br />
            </span>
            <span class="tab_text Pretendard-400">
              Managing data centers and utilizing physical security adhering to reliable standards
              such as ISO, CSA, SOC, K-ISMS.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              Advanced Security Management <br />
            </span>
            <span class="tab_text Pretendard-400">
              Continuously monitoring the security status of cloud infrastructure using advanced
              security management tools of Azure,<br />
              promptly detecting and responding to threats.
            </span>
          </p>
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              02
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              NCP(Naver Cloud Platform) Security
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              Complete External Network Blockade <br />
            </span>
            <span class="tab_text Pretendard-400">
              Securing our customer's information by blocking external network access through NCP.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              Real-time Server Security Monitoring <br />
            </span>
            <span class="tab_text Pretendard-400">
              Conducting real-time server security monitoring with enhanced security server
              configurations and free antivirus offerings.
            </span>
          </p>
        </div>
      </div>

      <div v-if="tab == 2" class="solutions-p3-3 mobile_display_off">
        <div v-if="language == 'ko'" class="p3inner flex__column">
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              01
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              가장 안전한 데이터 암호화
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700"> 데이터 암호화 <br /> </span>
            <span class="tab_text Pretendard-400">
              i-ESG는 강력한 단방향 암호화 알고리즘과 *HTTPS/TLS(TLS 1.2 이상) 프로토콜을 사용하여
              고객님의 중요한 정보를 <br />안전하게 저장 및 전송합니다.
            </span>
            <span class="tab_text_info Pretendard-400">
              *웹페이지를 안전하게 전송하기 위해 TLS 1.2 버전의 보안 프로토콜을 사용한다는 의미
            </span>
          </p>
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              02
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              비상 상황 대비와 방어
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              마이크로소프트 클라우드(Azure)의 비상 상황 대비 복구 <br />
            </span>
            <span class="tab_text Pretendard-400">
              i-ESG는 Azure를 활용해 다중 지역에 걸쳐 데이터를 복제하여 비상 상황과 장애에
              대비합니다.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">DDoS(디도스) 공격 방어 <br /> </span>
            <span class="tab_text Pretendard-400">
              i-ESG는 지속적인 모니터링과 첨단 기술을 활용하여 DDoS 공격 및 네트워크 위협으로부터
              신속하게 대응하고 효과적으로 <br />
              차단하는 방어 시스템을 운영합니다.
            </span>
          </p>
        </div>

        <div v-if="language == 'en'" class="p3inner flex__column">
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              01
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              The Utmost Data Encryption
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700"> Data Encryption <br /> </span>
            <span class="tab_text Pretendard-400">
              Ensuring the secure storage and transmission of vital information by employing robust
              one-way encryption algorithms and <br />
              the HTTPS/TLS (TLS 1.2 or higher) protocol.
            </span>
            <span class="tab_text_info Pretendard-400">
              *TLS 1.2 version of the security protocol is for securely transmitting webpages.
            </span>
          </p>
          <div class="tab_header">
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              02
            </h1>
            <h1 class="tab_subheader Pretendard-600" data-aos="fade-left" data-aos-offset="80">
              Emergency Preparedness and Defense
            </h1>
          </div>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700">
              Emergency recovery using Microsoft Cloud Azure <br />
            </span>
            <span class="tab_text Pretendard-400">
              Preparing for emergencies and disruptions by replicating data across multiple regions
              with Microsoft Azure.
            </span>
          </p>
          <p class="sol-p3-text2" data-aos="fade-left" data-aos-offset="80">
            <span class="tab_sub_subheader Pretendard-700"> DDoS Attack Defense <br /> </span>
            <span class="tab_text Pretendard-400">
              Operating a defense system that swiftly responds to and effectively blocks DDoS
              attacks and network threats.
            </span>
          </p>
        </div>
      </div>

      <div class="mobile">
        <div class="mobile_tab">
          <div class="mobile_tab_contents">
            <h5>ESG 진단</h5>
            <p>
              진단 시스템은 주요한 글로벌 평가기준의 공통 요소들을 토대로 구성되고 업데이트됩니다.
              기존 ESG에 대한 경험과 지식이 없더라도 아래의 사항들을 바탕으로 빠르게 정확한 ESG
              관리를 지속할 수 있습니다.
            </p>
          </div>
          <div class="mobile_tab_contents2">
            <h5>리포트 자동화</h5>
            <p>
              i-ESG는 AI 알고리즘에 기반하여, 국내외 수많은 기준/ 프레임워크/업계동향/ 규제정책와
              관련한 데이터베이스를 생성하고, 머신러닝기법을 활용하여 지속적으로 학습하여 업데이트
              합니다.
            </p>
          </div>
          <div class="mobile_tab_contents">
            <h5>공급망 관리</h5>
            <p>
              공급업체의 지속 가능성을 우리 기업의 ESG 관리와 통합하여 모니터링하고 관리합니다.
              공급업체의 리스크/기회의 정량화를 통해 향후 지속가능성에 대한 빠르고 효과적인 결정이
              가능합니다.
            </p>
          </div>
        </div>
      </div>

      <div class="company_slider">
        <div v-if="language == 'ko'" class="inner">
          <div class="company_slider_header">
            <h2 class="title" data-aos="fade-left" data-aos-offset="150">
              i-ESG는 <br />국내외 최고의 파트너사와 함께합니다.
            </h2>
          </div>
          <div class="company_slider_content" ref="sliderContent">
            <PartnersCarousel :mypartnerList="partnerImages" :height="'auto'" :itemsToShow="7" />
          </div>
        </div>
        <div v-if="language == 'en'" class="inner">
          <div class="company_slider_header">
            <h2 class="title" data-aos="fade-left" data-aos-offset="150">
              i-ESG Creates a Better Future <br />Together with Prestigious Partners.
            </h2>
          </div>
          <div class="company_slider_content" ref="sliderContent">
            <PartnersCarousel :mypartnerList="partnerImages" :height="'auto'" :itemsToShow="7" />
          </div>
        </div>
      </div>
    </div>

    <div class="solutions-p4">
      <div v-if="language == 'ko'" class="inner">
        <h1 class="title">
          수많은 데이터를 홀로 관리하고 계신가요? <br />
          <span class="color-text"> i-ESG 솔루션 </span>
          으로 <span class="color-text"> 함께 관리 </span>
          해보세요.
        </h1>
        <button class="button" @click="modalshow = true">데모 요청</button>
      </div>
      <div v-if="language == 'en'" class="inner">
        <h1 class="title">
          Drowning in Data Overload? <br />
          <span class="color-text"> i-ESG Solution </span>
          Offers <span class="color-text"> Decisive Support. </span>
        </h1>
        <button @click="modalshow = true" class="button Pretendard-700">Get a Demo</button>
      </div>
    </div>
  </div>
</template>

<script>
import setMeta from '@/utils/setMeta'
import ContactModal from '../../components/Modal/ContactModal.vue'
import pageSection1 from '../pageSection1.vue'
import PartnersCarousel from './PartnersCarousel/PartnersCarousel.vue'

export default {
  name: 'Solutions',
  props: {
    language: Array
  },
  components: {
    ContactModal,
    pageSection1,
    PartnersCarousel
  },
  beforeCreate() {
    setMeta({
      title: 'i-ESG | Solutions',
      description: 'i-ESG만의 손쉬운 ESG 통합 관리 솔루션',
      path: this.$route.path,
      keywords: 'ESG Solutions, ESG 통합관리, ESG, ESG 관리, ESG 솔루션'
    })
  },
  data() {
    return {
      isActive: 0,
      tab: 0,
      modalshow: false,
      partnerImages: [
        require('../../assets/imgs/Solutions_img/slider/1UNGC.png'),
        require('../../assets/imgs/Solutions_img/slider/2microsoft.png'),
        require('../../assets/imgs/Solutions_img/slider/3naver_cloud.png'),
        require('../../assets/imgs/Solutions_img/slider/4sustainability-initiative.png'),
        require('../../assets/imgs/Solutions_img/slider/5ci.png'),
        require('../../assets/imgs/Solutions_img/slider/6Kaist.png'),
        require('../../assets/imgs/Solutions_img/slider/7poscoInt.png'),
        require('../../assets/imgs/Solutions_img/slider/8poventures.png'),
        require('../../assets/imgs/Solutions_img/slider/9kosa.png'),
        require('../../assets/imgs/Solutions_img/slider/10megazone.png'),
        require('../../assets/imgs/Solutions_img/slider/11kova.png'),
        require('../../assets/imgs/Solutions_img/slider/12ministry_startup.png'),
        require('../../assets/imgs/Solutions_img/slider/13ministry_science.png'),
        require('../../assets/imgs/Solutions_img/slider/14cloocus.png'),
        require('../../assets/imgs/Solutions_img/slider/15kfq.png'),
        require('../../assets/imgs/Solutions_img/slider/16CJOlivenetworks.png'),
        require('../../assets/imgs/Solutions_img/slider/17hanaloop.png')
      ],
      tabsEN: [
        {
          title: 'ESG Assessment',
          showInfo: false,
          defaultText: 'Assess your current ESG status quickly and accurately.',
          information: [
            'Various assessment tools for user-friendly selection',
            'Detailed guide for users who lack expertise',
            'Feedback on each item to identify improvement areas',
            'Overall result and peer comparison analysis'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab0.png') // Replace with the actual image URL if needed
        },
        {
          title: 'Materiality Issue Identification',
          showInfo: false,
          defaultText: 'Identify key materiality issues for strategic business focus.',
          information: [
            'ESG materiality issues by SASB classification',
            'Quick materiality issue identification by industry',
            'Materiality issue survey distribution to stakeholders',
            'Stakeholders’ data consolidation in a materiality matrix'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab1.png') // Replace with the actual image URL if needed
        },
        {
          title: 'ESG Reporting Automation',
          showInfo: false,
          defaultText: 'Optimize reporting by integrating scattered tasks.',
          information: [
            'i-ESG recommendation algorithm  (support latest global standards: GRI2021, SASB, TCFD, etc.)',
            'Simplified collaboration tools',
            'AI writing guide and reporting automation',
            'Reliability enhancement by a third-party verification'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab2.png') // Replace with the actual image URL if needed
        },
        {
          title: 'ESG Management',
          showInfo: false,
          defaultText: 'Improve ESG performance through continuous management.',
          information: [
            'ESG Data collection and integration',
            'API connection and reliable data approval',
            'ESG data history management dashboard',
            'ESG data-based strategic KPI management'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab3.png') // Replace with the actual image URL if needed
        },
        {
          title: 'GHG Management',
          showInfo: false,
          defaultText: 'Track and mitigate GHG emissions effortlessly on a platform. ',
          information: [
            'Tree chart format showing GHG emissions relationship',
            'Data integration management via API connection',
            'Automatic GHG measure via objective emissions factors',
            'Emissions reduction roadmap',
            'Reduction strategy and Cash Flow setting'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab4.png') // Replace with the actual image URL if needed
        },
        {
          title: 'Supply Chain Management',
          showInfo: false,
          defaultText: 'Monitor and manage potential ESG risks from suppliers.',
          information: [
            'Customized survey creation and distribution',
            'Streamlined supplier engagement process',
            'ESG performance monitoring via dashboard analytics',
            'Potential ESG risk identification and immediate response'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab5.png') // Replace with the actual image URL if needed
        }
      ],
      tabsKR: [
        {
          title: 'ESG 자가진단',
          showInfo: false,
          defaultText: '기업의 ESG 수준을 빠르고 정확하게 진단합니다.',
          information: [
            '다양한 진단 설문 유형 선택지 제공',
            '가이드를 통해 사전지식 없이도 쉽게 ESG 진단 가능',
            '진단 항목별 피드백으로 개선점 확인',
            '종합 진단 결과 분석으로 ESG 현황 파악'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab0_kr.png') // Replace with the actual image URL if needed
        },
        {
          title: '중요성 이슈파악',
          showInfo: false,
          defaultText: '우리회사가 중점을 두어야 하는 중요성 이슈를 파악합니다.',
          information: [
            'SASB 산업 분류에 따른 ESG 중요 이슈를 확인',
            '비즈니스 중요도에 따라 이슈 선정',
            '이해관계자에게 중요성 이슈 설문 전송',
            '이해관계자 의견을 종합한 ESG 중요성 이슈 보고서 제공'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab1_kr.png') // Replace with the actual image URL if needed
        },
        {
          title: 'ESG 공시 자동화',
          showInfo: false,
          defaultText: '흩어져 있던 리포트 공시 작업들을 ONE-STOP으로 해결합니다.',
          information: [
            'i-ESG의 추천 알고리즘으로 적합한 공시기준 선택 \n (GRI, SASB, TCFD, UN SDGs 등 글로벌 공시기준 지원)',
            '협업자 초대 기능으로 간편한 부서 간 협업',
            'AI 작성 가이드를 통한 리포팅 자동화',
            '제 3자 검증기관을 통한 신뢰도 확보'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab2_kr.png') // Replace with the actual image URL if needed
        },
        {
          title: 'ESG 관리',
          showInfo: false,
          defaultText: '우리 회사의 지속적인 ESG 개선 및 통합관리를 가능케 합니다',
          information: [
            '필요한 데이터만 테이블 별로 취합한 후 간단히 병합 ',
            'API 연결 기능과 신뢰도있는 데이터만 승인 ',
            '대시보드에서 기업의 ESG 데이터 이력 관리',
            'ESG 데이터 기반의 KPI 관리로 전략 수립'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab3_kr.png') // Replace with the actual image URL if needed
        },
        {
          title: 'GHG 관리',
          showInfo: false,
          defaultText: '배출과 감축 모두 한번에 GHG 관리에서 해결합니다.',
          information: [
            '트리구조로 GHG 배출원 관계 설정',
            '설문조사 메일링 및 API 연결 기능으로 데이터 통합 관리 ',
            '객관적인 GHG 배출 계수로 배출량 자동 산출',
            'GHG 배출 흐름에 따른 예상 감축 로드맵 설정',
            '감축 전략 및 Cash Flow 설정으로 배출 관리 진척 상황 관리'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab4_kr.png') // Replace with the actual image URL if needed
        },
        {
          title: '공급망 관리',
          showInfo: false,
          defaultText: '협력사들의 ESG 현황을 파악하고, 공급망 RISK를 효율적으로 식별/관리합니다',
          information: [
            '협력사 맞춤형 ESG 진단 설문 제작 및 배포',
            '협력사는 근거 서류를 첨부하여 설문 응답',
            '전체 공급망 ESG 결과를 대시보드와 PDF 보고서로 확인',
            '개별 협력사 ESG 현황 파악으로 즉각적인 리스크 대응'
          ],
          class: 'interactive-tab', // Initially set to the default class
          image: require('@/assets/imgs/Solutions_img/tab5_kr.png') // Replace with the actual image URL if needed
        }
      ],
      modaldata: {
        Mtitle: '데모 요청',
        Mname: '담당자 성함 / 직급',
        Mposition: '담당자 직급',
        Mcompany: '회사명',
        Mdepartment: '담당 부서',
        Mphone: '연락처',
        Memail: '이메일 주소',
        Mquestions: '문의사항',
        Mbutton: '문의하기',
        Mtype: '데모 요청'
      },
      modaldataEN: {
        Mtitle: 'Request a demo',
        Mname: 'Name / Position',
        Mposition: 'Position',
        Mcompany: 'Company Name',
        Mdepartment: 'Department',
        Mphone: 'Phone',
        Memail: 'Business Email',
        Mquestions: 'Message',
        Mbutton: 'Submit',
        Mtype: '데모 요청(EN)'
      }
    }
  },
  methods: {
    toggleInfo(index) {
      if (this.language == 'ko') {
        // Toggle between classes when the element is clicked
        this.tabsKR.forEach((tab, i) => {
          if (i === index) {
            tab.class = tab.class === 'interactive-tab' ? 'click-tab' : 'interactive-tab'

            if (tab.class === 'interactive-tab') {
              tab.showInfo = false
              tab.image = require(`@/assets/imgs/Solutions_img/tab${index}_kr.png`)
            } else {
              tab.showInfo = true
              tab.image = require(`@/assets/imgs/Solutions_img/clicktab${index}_kr.png`)
            }
          }
        })
      } else if (this.language == 'en') {
        this.tabsEN.forEach((tab, i) => {
          if (i === index) {
            tab.class = tab.class === 'interactive-tab' ? 'click-tab' : 'interactive-tab'

            if (tab.class === 'interactive-tab') {
              tab.showInfo = false
              tab.image = require(`@/assets/imgs/Solutions_img/tab${index}.png`)
            } else {
              tab.showInfo = true
              tab.image = require(`@/assets/imgs/Solutions_img/clicktab${index}.png`)
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped src="./solutions.css"></style>
