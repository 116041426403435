import { createWebHistory, createRouter } from 'vue-router'
import Home from './components/Home/Home.vue'
import About from './components/About/About.vue'
import Solutions from './components/Solutions/Solutions.vue'
import Insights from './components/Insights/Insights.vue'
import Clients from './components/Clients/Clients.vue'
import Partners from './components/Partners/Partners.vue'
import Insight_more from './components/Insights/Insights_more.vue'
import Junbi from './components/Junbi.vue'
import Lending from './components/Lending.vue'
import TempDetail from './components/Insights/post/Temp_detail.vue'
import Media from './components/Media/Media.vue'
import MediaDetail from './components/Media/mediaDetail.vue'
import Pricing from './components/Pricing/Pricing.vue'
import iesgCloud from './components/iesgCloud.vue'
import VKlanding from './components/Landing/landing.vue'
import VKMlanding from './components/Landing/landing.vue'

const routes = [
  {
    path: '/',
    component: Home,
    name: 'Home'
  },
  {
    path: '/about',
    component: About,
    props: true,
    name: 'About'
  },
  {
    path: '/solutions',
    component: Solutions,
    props: true,
    name: 'Solutions'
  },
  {
    path: '/pricing',
    component: Pricing,
    props: true,
    name: 'Pricing'
  },
  {
    path: '/insights',
    component: Insights,
    props: true,
    name: 'Insights'
  },
  {
    path: '/esg-media',
    component: Media,
    props: true,
    name: 'Media'
  },
  {
    path: '/clients',
    component: Clients,
    props: true,
    name: 'Clients'
  },
  {
    path: '/partners',
    component: Partners,
    props: true,
    name: 'Partners'
  },
  {
    path: '/insights_more',
    component: Insight_more,
    props: true,
    name: 'Insight_more'
  },
  {
    path: '/junbi',
    component: Junbi
  },
  {
    path: '/landing',
    component: Lending
  },
  // {
  //   path : "/iesgDV",
  //   component : iesgDV,
  // },
  {
    path: '/cloud',
    component: iesgCloud
  },
  {
    path: '/TempDetail/:id',
    name: 'TempDetail',
    component: TempDetail,
    props: true
  },
  {
    path: '/media_detail/:id',
    name: 'MediaDetail',
    component: MediaDetail,
    props: true
  },
  {
    path: '/VKlanding',
    component: VKlanding,
    name: 'VKlanding'
  },
  {
    path: '/VKMlanding',
    component: VKMlanding,
    name: 'VKMlanding'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const el = window.location.href.split('#')[1]
      if (el.length) {
        setTimeout(() => {
          document.getElementById(el).scrollIntoView({ behavior: 'smooth' })
        }, 100) // Adjust delay as needed
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      setTimeout(() => {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
      }, 100) // Adjust delay as needed
    }
  }
})

export default router
