<template>
  <div class="more-p1">
    <div class="inner">
      <card v-for="(post, i) in posts" :key ="i">
        <a v-if="semi[i]" :href="findItems[i]?.site_source" target="_blank">
          <article class="card">
            <img :src='findItems[i]?.site_image' @error="replaceByDefault"
            class="img"
            alt="세미나 이미지"/>
            <div class="description">
              <h1 class="Pretendard-600 title">{{findItems[i]?.site_subject}}</h1>
              <p class="Pretendard-400">
                일시 : {{$dayjs(findItems[i]?.created_at).format('YYYY-MM-DD')}} <br/>
                행사 주최/주관 : {{findItems[i]?.site_organizer}}
              </p>
            </div>
          </article>
        </a>
      </card>
    </div>
  </div>
</template>

<script>

export default {
  name : "Post_semi",
  data() {
    return {
      data1 : [],
    }
  },
  props : {
    semi : Array,
    posts : Number,
    findWord : Array,
    imgSrc : String
  },
  methods: {
    replaceByDefault(e) {
      const randNum = Math.floor(Math.random() * 100);
      e.target.src = this.imgSrc+randNum
    }
  },
  created() {
    for (let i in this.semi) {
      this.data1.push(this.semi[i].title)
    }
  },

  computed : {
    // 검색 기능 만들 때
    findItems: function() {
      if (this.findWord) {
        return this.data1.filter(function(value) {
          return (value.indexOf(this.findWord) > -1);
        }, this);
      } else {
        return this.semi;
      }
    }
  }


}
</script>

<style src="./post.css"> 

</style>