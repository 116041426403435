<template>
  <div>
    <eduCarousel />
  </div>
</template>

<script>
import eduCarousel from "../../Carousel/eduCarousel.vue"

export default {
  name : "postEdu",
  components : {
    eduCarousel
  }

}
</script>

<style scoped>
  div{
    background-color: #FBFFFD;
  }
</style>