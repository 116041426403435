<template>
  <div id="pagetop" class="totopbutton" v-show="scY > 300" @click="toTop">
  <i class="fas fa-arrow-up fa-lg"></i>
  </div>
</template>

<script>
export default {
  name : 'top',
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
    toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        this.currentPage = 1;
      },
  },
}
</script>

<style>
  .totopbutton {
    height: 54px;
    width: 54px;
    border-radius: 15px;
    z-index: 1;
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: rgba(163, 221, 196, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }
  .totopbutton i{
    z-index: 1;
  }

  /* @media screen and (max-width: 930px) {
    .totopbutton {
      height: 45px;
      width: 45px;
      font-size: 15px;
      font-family: "Pretendard-SemiBold";
      font-weight: 600;
      display: none;
    }
  }
  @media screen and (max-width: 700px) {
    .totopbutton {
      height: 40px;
      width: 40px;
      font-size: 13.5px;
      display: none;
    }
  } */
</style>