<template>
  <div>
    <section
      v-if="this.$route.name !== 'VKlanding' && this.$route.name !== 'VKMlanding'"
      :class="[bgClass]"
      class="bg"
    >
      <div class="inner">
        <hr class="title-line mobile-display-none" :class="[lineColor]" />
        <h1 v-if="language === 'ko'" class="Pretendard-600 title" :class="[titleColor]">
          {{ title[this.$route.name] }}
        </h1>
        <h1 v-if="language === 'en'" class="Pretendard-600 title" :class="[titleColor]">
          {{ titleEn[this.$route.name] }}
        </h1>

        <h2
          v-if="this.$route.name == 'Pricing' && language === 'ko'"
          class="pricing-sub-title Pretendard-600 mobile-display-none"
        >
          우리회사에 꼭 맞는 <span class="pricing-title">ESG 솔루션 패키지</span>를 선택하세요.
        </h2>

        <h2
          v-if="this.$route.name == 'Pricing' && language === 'en'"
          class="pricing-sub-title Pretendard-600 mobile-display-none"
        >
          Choose the best <span class="pricing-title">ESG solution package</span> for your company
        </h2>
      </div>
    </section>
    <section
      v-if="this.$route.name === 'VKlanding' || this.$route.name === 'VKMlanding'"
      :class="[bgClass]"
      class="bg2"
    >
      <div class="inner2">
        <h1 v-if="language === 'ko'" class="Pretendard-600 title2">
          {{ title[this.$route.name] }}
        </h1>
        <h1 v-if="language === 'en'" class="Pretendard-600 title2">
          {{ titleEn[this.$route.name] }}
        </h1>

        <h2
          v-if="this.$route.name == 'VKlanding' && language === 'ko'"
          class="pricing-sub-title2 Pretendard-600 mobile-display-none"
        >
          벤처기업협회 회원사 대상 ESG 특화 솔루션 i-ESG가 연간이용료
          <span class="spanTitle">40%할인</span>을 제공합니다.
        </h2>

        <h2
          v-if="this.$route.name == 'VKMlanding' && language === 'ko'"
          class="pricing-sub-title2 Pretendard-600 mobile-display-none"
        >
          벤처기업협회 회원사 대상 ESG 특화 솔루션 i-ESG가 연간이용료
          <span class="spanTitle">30%할인</span>을 제공합니다.
        </h2>

        <h1
          v-if="this.$route.name == 'VKlanding' && language === 'ko'"
          class="Pretendard-600 title2"
        >
          최대 90% 저렴한 가격으로 지속 활용가능한 ESG 관리체계를 구축할 수 있는 합리적인
          솔루션입니다.
        </h1>
        <h1
          v-if="this.$route.name == 'VKMlanding' && language === 'ko'"
          class="Pretendard-600 title2"
        >
          지속적인 성장을 위해서는 ESG 대응 및 관리는 필수입니다.
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PageSection1',
  props: {
    language: String
  },
  data() {
    return {
      title: {
        About: 'Who we are',
        Media: 'Media - 언론 속의 i-ESG',
        Partners: 'Partners',
        Solutions: 'Solution',
        Pricing: 'Pricing',
        Insights: 'ESG Insights',
        Clients: 'Client - 더 나은 세상을 만들어가는 우리의 고객들',
        MediaDetail: 'Media - 언론 속의 i-ESG',
        VKlanding:
          'i-ESG 솔루션은 수많은 기업들과의 인터뷰를 통해 검증된 ESG 특화 관리 솔루션입니다.',
        VKMlanding: 'ESG로 인한 거래중단과 같은 기업의 Risk가 증가하고 있습니다.'
      },
      titleEn: {
        About: 'Who we are',
        Media: 'Media - i-ESG on Media',
        Partners: 'Partners',
        Solutions: 'Solution',
        Pricing: 'Pricing',
        Insights: 'ESG Insights',
        Clients: 'Client - 더 나은 세상을 만들어가는 우리의 고객들',
        MediaDetail: 'Media - i-ESG on Media',
        VKlanding: 'asdfasdfasdfadfsasdfasd',
        VKMlanding: 'dsafnvnvnmkckcv'
      }
    };
  },
  mounted() {
    console.log(this.$route.name);
  },
  computed: {
    bgClass() {
      return {
        bg: true,
        [`bg-${this.$route.name}`]: true
      };
    },
    titleColor() {
      if (this.$route.name == 'Pricing') {
        return {
          title: true,
          [`pricing-title`]: true
        };
      } else {
        return {
          title: true
        };
      }
    },
    isPricing() {
      return this.$route.name === 'Pricing';
    },
    lineColor() {
      if (this.$route.name == 'Pricing') {
        return {
          [`title-line`]: true,
          [`mobile-display-none`]: true,
          [`pricing-line`]: true
        };
      } else {
        return {
          [`title-line`]: true,
          [`mobile-display-none`]: true
        };
      }
    }
  }
};
</script>

<style scoped>
.bg {
  width: 100%;
  height: 29rem;
  background-size: 100% 100%;
}
.bg2 {
  text-align: center;
  width: 100%;
  height: 29rem;
  background-size: 100% 100%;
}
.inner {
  flex-direction: column;
  display: flex;
  padding-top: 4rem;
  width: auto;
  height: 100%;
  margin: 0 12.5vw;
}
.inner2 {
  flex-direction: column;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 12.5vw;
}
.title-line {
  width: 4.938rem;
  height: 0px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  margin-top: 8.3rem;
  animation-duration: 0.7s;
  animation-name: slidein-left;
  opacity: 1;
}
.title {
  font-size: 2rem;
  color: #ffffff;
  margin-top: 1.5rem;
  animation-duration: 0.5s;
  animation-name: slide;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.title2 {
  font-size: 1.5rem;
  color: #ffffff;
  animation-duration: 0.5s;
  animation-name: slide;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.pricing-title {
  color: #00ae66;
}
.pricing-line {
  border: 1px solid #3ed998;
}
.pricing-sub-title {
  color: #000000;
  font-size: 2rem;
  margin-top: 1rem;
  animation-duration: 0.5s;
  animation-name: slide;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.pricing-sub-title2 {
  color: #fff;
  font-size: 2rem;
  margin: 1.9rem 0;
  animation-duration: 0.5s;
  animation-name: slide;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.spanTitle {
  color: #96f9bd;
}

.bg-About {
  background-image: url(../assets/imgs/About_img/about-p1-img.png);
}
.bg-Media {
  background-image: url(../assets/imgs/Media_img/media_bg.png);
}
.bg-MediaDetail {
  background-image: url(../assets/imgs/Media_img/media_bg.png);
}
.bg-Partners {
  background-image: url(../assets/imgs/Partners/partners-p1-bg.jpg);
}
.bg-Partners {
  background-image: url(../assets/imgs/Partners/partners-p1-bg.jpg);
}
.bg-Pricing {
  background-image: url(../assets/imgs/pricing_bg.png);
}
.bg-Solutions {
  background-image: url(../assets/imgs/Solutions_img/solutions_p1.png);
}
.bg-VKlanding {
  background-image: url(../assets/imgs/Lending_img/VKLanding.png);
}
.bg-VKMlanding {
  background-image: url(../assets/imgs/Lending_img/VKMLanding.png);
}
.bg-Insights {
  background-image: url(../assets/imgs/Insights_img/insights_p1.png);
}
.bg-Clients {
  background-image: url(../assets/imgs/Client/Client-p1-img.png);
}

/* @media screen and (max-width: 1040px) {
.bg {
  height: 236px;
}
.inner{
  padding-top: 66px;
  margin: 0 2.5rem;
  justify-content: center;
  box-sizing: border-box;

}
.title-line {
  margin-top: 0;
}
.title {
  font-size: 22px;
  margin-top: 0.8rem;
}
.pricing-sub-title { 
  font-size: 22px;
}
  

}


@media screen and (max-width: 500px) {
.bg {
  height: 132px;
  
}
.mobile-display-none{
  display: none;
}
.inner{
  padding-top: 65px;
  margin: 0 1rem;
  justify-content: center;
  box-sizing: border-box;
}
.title {
  font-size: 15px;
  margin-top: 0;
}
} */
</style>
