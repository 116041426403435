<template>
  <div>
    <card class="card" v-if="type === 'price'">
      <LandingPriceTableMobile
        v-if="type === 'price'"
        :monthly="monthly"
        :yearly="yearly"
        :discountPer="discountPer"
        :discountPri="discountPri"
        :discountPri2="discountPri2"
      />
    </card>
    <LandingContactFormMobile v-if="type === 'contact'" :modaldata="modaldata" />
  </div>
</template>

<script>
import LandingPriceTableMobile from './LandingPriceTableMobile.vue'
import LandingContactFormMobile from './LandingContactFormMobile.vue'

export default {
  props: {
    cardName: String,
    cardContent: String,
    type: String,
    monthly: String,
    yearly: String,
    discountPer: String,
    discountPri: String,
    discountPri2: String
  },
  data() {
    return {
      modaldata: {
        Mtitle: '궁금한 사항은 문의주세요.',
        Mname: '담당자 이름 / 직급 (이름과 직급 사이에 슬래쉬(/) 기호 입력 필요)',
        Mposition: '담당자 직급',
        Mcompany: '회사명',
        Mdepartment: '담당 부서',
        Mphone: '연락처',
        Memail: '이메일 주소',
        Mquestions: '문의사항',
        Mbutton: '문의하기',
        Mtype: '홈페이지 문의사항'
      }
    }
  },
  components: {
    LandingPriceTableMobile,
    LandingContactFormMobile
  }
}
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(191, 206, 196, 0.5);
  margin-top: 40px;
}

.title {
  color: #1da76d;
  font-family: Pretendard;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.content {
  color: #7a8087;
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
